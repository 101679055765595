import React, { useCallback, useEffect, useState } from "react";
import { PageChangeParams } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import { FetchTasksSearchParams, Task } from "entities/tasks/TaskEntity";
import { makeStyles } from "@material-ui/core";
import {
  fetchTasksCompanyFormationsSearched,
  fetchTasksKYCSearched,
  fetchTasksAccountsSearched,
  fetchWorkflowGBGIndividualIdentityTasksSearch,
  fetchWorkflowGBGIndividualEkycTasksSearch,
} from "api/tasks";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
}));

interface taskProps {
  customerId: FetchTasksSearchParams;
}

const VerificationTask: React.FC<taskProps> = ({ customerId }) => {
  const [pageNo, setPageNo] = useState(0);
  const [task, setTask] = useState<Task[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      void fetchTasks(customerId as number);
    }
  }, [customerId]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const classes = useStyles();
  const [columnsVerification] = useVerificationColumns({
    pageNo,
  });

  const fetchTasks = useCallback(
    async (id: number | string): Promise<void> => {
      setLoading(true);
      try {
        const resKYC = await fetchTasksKYCSearched({
          customerId: id as number,
        });
        const resGBGIdentity = await fetchWorkflowGBGIndividualIdentityTasksSearch(
          {
            customerId: id as number,
          }
        );
        const resGBGEkyc = await fetchWorkflowGBGIndividualEkycTasksSearch({
          customerId: id as number,
        });
        switch (process.env.REACT_APP_PROJECT) {
          case "quid":
            const resAccountQuid = await fetchTasksAccountsSearched({
              customerId: id as number,
            });
            const resCompany = await fetchTasksCompanyFormationsSearched({
              customerId: id as number,
            });
            setTask(
              resAccountQuid.tasks.concat(resCompany.tasks).concat(resKYC.tasks)
            );
            setTotal(resAccountQuid.total + resCompany.total + resKYC.total);
            break;

          case "toonie":
            const resAccountToonie = await fetchTasksAccountsSearched({
              customerId: id as number,
            });

            setTask([
              ...resAccountToonie.tasks,
              ...resKYC.tasks,
              ...resGBGIdentity.tasks,
              ...resGBGEkyc.tasks,
            ]);
            setTotal(
              resAccountToonie.total +
                resKYC.total +
                resGBGIdentity.total +
                resGBGEkyc.total
            );
            break;

          case "elviria":
            const resAccountElviria = await fetchTasksAccountsSearched({
              customerId: id as number,
            });

            setTask(resAccountElviria.tasks.concat(resKYC.tasks));
            setTotal(resAccountElviria.total + resKYC.total);
            break;

          case "strfiinvest":
            const resAccountStrfi = await fetchTasksAccountsSearched({
              customerId: id as number,
            });

            setTask(resAccountStrfi.tasks.concat(resKYC.tasks));
            setTotal(resAccountStrfi.total + resKYC.total);
            break;

          case "cosmo":
            setTask(resKYC.tasks);
            setTotal(+resKYC.total);
            break;
        }
      } catch (err: any) {
        // Split per project
      } finally {
        setLoading(false);
      }
    },
    [setTask]
  );

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      className={classes.root}
      rows={task}
      columns={columnsVerification}
      disableSelectionOnClick
      hideHeader
      rowCount={total}
      loading={loading}
    />
  );
};

export default VerificationTask;
