import React, { ReactNode, useState } from "react";
import { ColDef } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import DocumentUploadModal from "components/atoms/DocumentUploadModal";
import { DocumentUploadParams } from "../../../shared/hooks/useDocHandlers";
import DownloadButton from "components/atoms/DownloadButton";
import CheckButton from "components/atoms/CheckButton";
import CloseButton from "components/atoms/CloseButton";
import StatusBadge from "components/atoms/StatusBadge";
import {
  CustomerDocument,
  CompanyDocument,
} from "entities/clients/DocumentEntity";

interface DocumentListProps {
  children?: ReactNode | ReactNode[];
  totalDocuments?: number;
  displayApproveRejectActions?: boolean;
  loading: boolean;
  documents?: CustomerDocument[] | CompanyDocument[];
  accept: (id: number) => Promise<void>;
  reject: (id: number) => Promise<void>;
  download: (id: number, fileName: string) => Promise<void>;
  onUploadDocuments: (values: DocumentUploadParams) => Promise<void>;
}

const useStyles = makeStyles(() =>
  createStyles({
    documentSpacing: {
      marginTop: 30,
      marginBottom: 30,
    },
    uploadDocumentWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    documentContainer: {
      minHeight: 500,
    },
    rejectBtn: { margin: "0 10px" },
    btnContainer: {
      "& .MuiButton-root": { width: "auto !important" },
    },
  })
);

const DocumentList: React.FC<DocumentListProps> = ({
  accept,
  reject,
  download,
  documents,
  totalDocuments,
  onUploadDocuments,
  displayApproveRejectActions,
}) => {
  const { t } = useTranslation("document");
  const classes = useStyles();
  const documentHeaders: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: t("document__header_name__status"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <>
          <StatusBadge status={params?.row?.status.toLowerCase()} />
        </>
      ),
    },
    {
      field: "partyId",
      headerName: t("document__header_name__party_id"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "uploader",
      headerName: t("document__header_name__uploaderId"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "profileId",
      headerName: t("document__header_name__profileId"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "type",
      headerName: t("document__header_name__type"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "sub_type",
      headerName: t("document__header_name__subtype"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "uploadDate",
      headerName: t("document__header_name__uploadDate"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "originalName",
      headerName: t("document__header_name__originalName"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "",
      headerName: t("document__header_name__actions"),
      flex: displayApproveRejectActions ? 1 : 1,
      renderCell: (params) => (
        <div className={classes.btnContainer}>
          {displayApproveRejectActions && (
            <CheckButton onClick={() => accept(+params?.row?.id)} />
          )}
          {displayApproveRejectActions && (
            <CloseButton onClick={() => reject(+params?.row?.id)} />
          )}
          <DownloadButton
            onClick={() =>
              download(+params?.row?.id, params?.row?.originalName)
            }
          />
        </div>
      ),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const onUpload = async (values: DocumentUploadParams) => {
    await onUploadDocuments(values);
    setModalOpen(false);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <DocumentUploadModal
        open={modalOpen}
        onUpload={onUpload}
        onCloseModal={onCloseModal}
      />
      <QuidTitle variant="h1">Documents</QuidTitle>

      <div className={classes.documentContainer}>
        <div className={classes.uploadDocumentWrapper}>
          <Button
            variant="contained"
            color="primary"
            component="label"
            onClick={handleClickOpen}
          >
            Upload
          </Button>
        </div>
        <QuidDataGrid
          rows={documents || []}
          rowCount={totalDocuments}
          columns={documentHeaders}
        />
      </div>
    </>
  );
};

export default DocumentList;
