import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { fetchCustomerDetails } from "services/customers";
import { RouteComponentProps, useHistory } from "react-router";
import FinancialInfoCustomer from "components/organisms/customers/FinancialInfoCustomer";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { updateCustomerFinancial } from "api/customers";
import { FinancialData } from "entities/clients/FinancialEntity";
import { fetchCompanyDetails } from "services/companies";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import { updateCompanyFinancial } from "api/companies";
import FinancialInfoCompany from "components/organisms/companies/FinancialInfoCompany";

const useStyles = makeStyles((theme) => ({
  watchIcon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#37383C",
  },
  offerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const UpdateFinancialInfo: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("user");
  const dispatch = useDispatch();
  const id: number = +match.params.id;
  const history = useHistory();
  const from = history.location.state as Object;
  const [financialLoading, setFinancialLoading] = useState(false);

  const detailsInitialStateCustomer: CustomerDetails = ({
    customer: null,
    profiles: null,
    documents: null,
    addresses: null,
    financialData: null,
    history: null,
    companies: null,
  } as unknown) as CustomerDetails;
  const [detailsCustomer, setDetailsCustomer] = useState(
    detailsInitialStateCustomer
  );

  const detailsInitialStateCompany: CompanyDetails = ({
    customer: null,
    profiles: null,
    documents: null,
    addresses: null,
    financialData: null,
    history: null,
    companies: null,
  } as unknown) as CompanyDetails;
  const [detailsCompany, setDetailsCompany] = useState(
    detailsInitialStateCompany
  );

  useEffect(() => {
    if (id && Object.values(from)[0] === "customer") {
      void getCustomerDetails(id);
    }
    if (id && Object.values(from)[0] === "company") {
      void getCompanyDetails(id);
    }
  }, [id]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetailsCustomer(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetailsCustomer, dispatch]
  );

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetailsCompany(companyDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetailsCompany, dispatch]
  );

  const updateFinancialsCompany = async (params: FinancialData) => {
    setFinancialLoading(true);

    try {
      const financialData = await updateCompanyFinancial({
        ...params,
        customerId: detailsCompany?.company?.id,
      });

      handleSuccessfulMessage(t("document__upload__successful__message"));
      setDetailsCompany((detailsCompany: CompanyDetails) => ({
        ...detailsCompany,
        financialData,
      }));
      history.push({
        pathname: `/companies/${id}/financial`,
        state: { type: "company" },
      });
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setFinancialLoading(false);
    }
  };

  const updateFinancialsCustomer = async (params: FinancialData) => {
    setFinancialLoading(true);

    try {
      const financialData = await updateCustomerFinancial({
        ...params,
        customerId: detailsCustomer?.customer?.id,
      });

      handleSuccessfulMessage(t("document__upload__successful__message"));
      setDetailsCustomer((detailsCustomer: CustomerDetails) => ({
        ...detailsCustomer,
        financialData,
      }));
      history.push({
        pathname: `/customers/${id}/financial`,
        state: { type: "customer" },
      });
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setFinancialLoading(false);
    }
  };

  return (
    <MainTemplate>
      <div className={classes.offerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("user__title__personal__info")}</QuidTitle>
          </Grid>
        </Grid>
        {Object.values(from)[0] === "customer" && (
          <FinancialInfoCustomer
            loading={financialLoading}
            financialData={detailsCustomer?.financialData}
            onUpdateFinancials={updateFinancialsCustomer}
          />
        )}
        {Object.values(from)[0] === "company" && (
          <FinancialInfoCompany
            loading={financialLoading}
            financialData={detailsCompany?.financialData}
            onUpdateFinancials={updateFinancialsCompany}
          />
        )}
      </div>
    </MainTemplate>
  );
};

export default UpdateFinancialInfo;
