import { makeStyles } from "@material-ui/core";
import InfoBox from "components/molecules/InfoBox";
import { InvestmentPreferences } from "entities/clients/Investements";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
  },
}));

interface InvestementInfoProps {
  investmentPreferences?: InvestmentPreferences;
}

const InvestementPreference: React.FC<InvestementInfoProps> = ({
  investmentPreferences,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("customer");

  const investmentPreferencesData = [
    {
      title: t("customer_investment_preferenceId"),
      value: investmentPreferences?.id || "n/a",
    },
    {
      title: t("customer_investment_minDebt_maxDebt"),
      value:
        `${investmentPreferences?.minDebt} / ${investmentPreferences?.maxDebt}` ||
        "n/a",
    },
    {
      title: t("customer_investment_minEquity_maxEquity"),
      value:
        `${investmentPreferences?.minEquity} / ${investmentPreferences?.maxEquity}` ||
        "n/a",
    },
    {
      title: t("customer_investment_minHorizon_maxHorizon"),
      value:
        `${investmentPreferences?.minHorizon} / ${investmentPreferences?.maxHorizon}` ||
        "n/a",
    },
    {
      title: t("customer_investment_minLoan_maxLoan"),
      value:
        `${investmentPreferences?.minLoan} / ${investmentPreferences?.maxLoan}` ||
        "n/a",
    },
    {
      title: t("customer_investment_equityStages"),
      value: `${investmentPreferences?.equityStages?.join(", ")}` || "",
      type: "array",
    },
    {
      title: t("customer_investment__sectors"),
      value: `${investmentPreferences?.sectors?.join(", ")}` || "",
      type: "array",
    },
  ];

  return (
    <div className={classes.container}>
      <InfoBox
        title={t("customer__investment_preferences")}
        items={investmentPreferencesData}
      />
    </div>
  );
};

export default InvestementPreference;
