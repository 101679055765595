import {
  Button,
  Checkbox,
  Grid,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import QuidFormControl from "components/atoms/QuidFormControl";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { BalanceStatus } from "entities/accounts/Balance";
import { Currency } from "entities/clients/CurrencyEntity";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPartiesCurrencies } from "store/reducers/app.reducer";

export interface CreateAccountModalProps {
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  mt56: {
    marginTop: 56,
  },
}));

type FormValues = {
  accountName: string;
  profile: string;
  currency: string;
  description: string;
  status: string;
  initialBalance: string;
  allowDeposits: boolean;
  allowWithdrawals: boolean;
};

const CreateAccountModal: React.FC<CreateAccountModalProps> = ({ onClose }) => {
  const defaultValues: FormValues = {
    accountName: "",
    profile: "",
    currency: "",
    description: "",
    status: "",
    initialBalance: "",
    allowDeposits: false,
    allowWithdrawals: false,
  };

  const onFormSubmit = async () => {
    /* TODO: add it when we get all the fields
    try {
      const res = await createNewAccount({
        account_name: values.accountName,
        description: values.description,

      });
      reset(defaultValues);
      onClose();
    } catch (err: any) {
      return handleFailure(failure);
    }
    */

    onClose(); // TODO: remove it when fold will be used
  };
  const classes = useStyles();
  const { t } = useTranslation("account");
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const currencies = useSelector(getPartiesCurrencies);

  const { handleSubmit } = methods;
  return (
    <div className={classes.modalBody}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container direction={"column"}>
            <Grid item xs={12} className={classes.mt56}>
              <QuidTitle>{t("title__add__account")}</QuidTitle>
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="accountName"
                rules={{
                  required: t(
                    "add__account__account__name__required"
                  ) as string,
                }}
                label={t("add__account__account__name")}
                defaultValues={defaultValues}
                textFieldProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="profile"
                rules={{
                  required: t("add__account__profile__required") as string,
                }}
                label={t("add__account__profile")}
                defaultValues={defaultValues}
                textFieldProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="currency"
                rules={{
                  required: t("add__account__currency__required") as string,
                }}
                label={t("add__account__currency")}
                defaultValues={defaultValues}
                textFieldProps={{ select: true, fullWidth: true }}
              >
                <MenuItem value="">{t("add__account__currency")}</MenuItem>
                {currencies?.map((currency: Currency, index: number) => (
                  <MenuItem
                    key={`${currency.code}-${index}`}
                    value={currency.code}
                  >
                    {currency.text}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="description"
                rules={{
                  required: t("add__account__description__required") as string,
                }}
                label={t("add__account__description")}
                defaultValues={defaultValues}
                textFieldProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="status"
                rules={{
                  required: t("add__account__status__required") as string,
                }}
                label={t("add__account__status")}
                defaultValues={defaultValues}
                textFieldProps={{ select: true, fullWidth: true }}
              >
                <MenuItem value="">{t("add__account__status")}</MenuItem>
                {Object.keys(BalanceStatus).map(
                  (option: string, index: number) => (
                    <MenuItem key={`${option}-${index}`} value={option}>
                      {BalanceStatus[option]}
                    </MenuItem>
                  )
                )}
              </QuidTextField>
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="initialBalance"
                rules={{
                  required: t(
                    "add__account__initial__balance__required"
                  ) as string,
                  validate: (value?: string): string | boolean => {
                    if (!!value && parseFloat(value) > 0) {
                      return true;
                    }
                    return t(
                      "add__account__initial__balance__positive"
                    ) as string;
                  },
                }}
                label={t("add__account__initial__balance")}
                textFieldProps={{ fullWidth: true }}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidFormControl
                formControl={<Checkbox color="primary" />}
                name="allowDeposits"
                defaultValues={defaultValues}
                label={t("add__account__allow__deposits")}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidFormControl
                formControl={<Checkbox color="primary" />}
                name="allowWithdrawals"
                defaultValues={defaultValues}
                label={t("add__account__allow__withdrawals")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                {t("add__account__create__button")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateAccountModal;
