import React from "react";

export interface UploadIconProps {
  color: string;
}

const UploadIcon: React.FC<UploadIconProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.264826 7.07599C0.18088 6.99226 0.114277 6.89279 0.0688341 6.78327C0.0233908 6.67376 1.0287e-07 6.55636 1.18418e-07 6.43779C1.33967e-07 6.31922 0.0233909 6.20182 0.0688342 6.0923C0.114277 5.98279 0.18088 5.88332 0.264826 5.79958L5.67335 0.391056C5.75709 0.30711 5.85656 0.240509 5.96607 0.195065C6.07559 0.149622 6.19299 0.126231 6.31156 0.126231C6.43013 0.126231 6.54753 0.149622 6.65704 0.195065C6.76656 0.240509 6.86603 0.30711 6.94977 0.391056L12.3583 5.79958C12.5276 5.96885 12.6226 6.19842 12.6226 6.43779C12.6226 6.67716 12.5276 6.90673 12.3583 7.076C12.189 7.24526 11.9595 7.34035 11.7201 7.34035C11.4807 7.34035 11.2511 7.24526 11.0819 7.076L6.31156 2.30387L1.54124 7.07599C1.4575 7.15994 1.35803 7.22654 1.24852 7.27199C1.139 7.31743 1.0216 7.34082 0.903032 7.34082C0.784464 7.34082 0.667061 7.31743 0.557547 7.27199C0.448033 7.22654 0.34856 7.15994 0.264826 7.07599Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31158 14.5508C6.07251 14.5508 5.84323 14.4558 5.67418 14.2868C5.50513 14.1177 5.41016 13.8884 5.41016 13.6494L5.41016 1.93089C5.41016 1.69181 5.50513 1.46253 5.67418 1.29348C5.84323 1.12443 6.07251 1.02946 6.31158 1.02946C6.55065 1.02946 6.77993 1.12443 6.94898 1.29348C7.11803 1.46253 7.213 1.69181 7.213 1.93089L7.213 13.6494C7.213 13.8884 7.11803 14.1177 6.94898 14.2868C6.77993 14.4558 6.55065 14.5508 6.31158 14.5508Z"
        fill={color}
      />
    </svg>
  );
};

export default UploadIcon;
