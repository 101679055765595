import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import DetailIcon from "components/atoms/icons/DetailIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleFailure } from "../../../resHandlers";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DetailButton from "components/atoms/DetailButton";
import AccountStatusBadge from "./AccountStatusBadge";
import { fetchBalancesByAccountId } from "api/accounts";
import { Pagination } from "entities/accounts/Pagination";
import { Balance } from "entities/accounts/Balance";
import {
  downloadAccountBalancesCsv,
  downloadTransactionsCsv,
} from "services/accounts";
import CsvIcon from "components/atoms/icons/CsvIcon";
import { formatDate } from "utils";
export interface AccountBalancesProps {
  accountId: number;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginTop: "100px",
    marginBottom: 32,
  },
  csvBtn: {
    height: "100%",
    minHeight: "54px",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
}));

const AccountBalances: React.FC<AccountBalancesProps> = ({ accountId }) => {
  const { t } = useTranslation("account");
  const classes = useStyles();
  const [pagination, setPagination] = useState({} as Pagination);
  const [] = useState(1);
  const [balances, setBalances] = useState([] as Balance[]);
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBalances = async () => {
      setLoading(true);
      try {
        const balanceRes = await fetchBalancesByAccountId({
          account_id: accountId,
          page: pageNo,
        });
        const { pagination, balances } = balanceRes;
        setPagination(pagination);
        setBalances(
          balances.map((balance) => ({ id: balance.balance_id, ...balance }))
        );
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setLoading(false);
      }
    };
    fetchBalances();
  }, [pageNo, accountId]);

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "type",
      headerName: t("transactionTable__header_name__type"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("transactionTable__header_name__created_at"),
      flex: 1,
      renderCell: (params: CellParams) => (
        <>{formatDate(params?.row?.created_at)}</>
      ),
    },
    {
      field: "status",
      headerName: t("transactionTable__header_name__status"),
      flex: 1,
      renderCell: (params) => (
        <>
          <AccountStatusBadge status={params?.row?.status} />
          {params?.row?.status}
        </>
      ),
    },
    {
      field: "amount",
      headerName: t("transactionTable__header_name__amount"),
      flex: 1,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.amount ?? 0, params?.row?.currency),
    },
    {
      field: "current_amount",
      headerName: t("transactionTable__header_name__currentAmount"),
      flex: 1,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.current_amount ?? 0, params?.row?.currency),
    },
    {
      field: "available_amount",
      headerName: t("transactionTable__header_name__availableAmount"),
      flex: 1,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(
          params?.row?.available_amount ?? 0,
          params?.row?.currency
        ),
    },
    {
      field: "account_name",
      headerName: t("transactionTable__header_name__balanceName"),
      flex: 1,
    },
    {
      field: "is_main_balance",
      headerName: t("transactionTable__header_name__is_main_balance"),
      flex: 1,
    },
    {
      field: "receipt",
      width: 100,
      headerName: t("transactionTable__header_name__details"),
      renderCell: (params) => {
        const id = params?.row?.id as number;
        if (!id) {
          return <DetailIcon />;
        }
        return (
          <DetailButton
            to={`/accounts/${params?.row?.account_id}/balances/${params?.row?.balance_id}`}
          />
        );
      },
    },
  ];

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const BalancesCsv = () => {
    downloadAccountBalancesCsv({
      fileName: "account balances",
      account_id: accountId,
    });
  };

  const TransactionsCsv = () => {
    downloadTransactionsCsv({
      fileName: "account transaction",
      account_id: accountId,
    });
  };

  return (
    <>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {t("account__balances")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => BalancesCsv()}
                className={classes.csvBtn}
                variant="outlined"
                title="Balances"
              >
                <Typography variant={"body1"}>
                  {t("account__balances")}
                </Typography>
                <CsvIcon />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => TransactionsCsv()}
                className={classes.csvBtn}
                variant="outlined"
                title="Transactions"
              >
                <Typography variant={"body1"}>
                  {t("account__transactions")}
                </Typography>
                <CsvIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <QuidDataGrid
        onPageChange={onPageChange}
        loading={loading}
        columns={columns}
        rows={balances}
        rowCount={pagination?.total_entries}
      />
    </>
  );
};

export default AccountBalances;
