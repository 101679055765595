import { Realm } from "./apiRealm";
import { apiClientAxios } from "./apiClientAxios";
import { JsonSerializer } from "../JsonSerializer";
import axios from "axios";

const apiURL = ((): string | undefined => {
  switch (process.env.REACT_APP_DEMO_MODE) {
    case "develop":
      return process.env.REACT_APP_API_DEVELOP_URL;
    case "demo":
      return process.env.REACT_APP_API_DEMO_URL;
    case "production":
      return process.env.REACT_APP_API_PRODUCTION_URL;
    default:
      return "";
  }
})();

const getKeycloackUrl = (): string | undefined => {
  switch (process.env.REACT_APP_DEMO_MODE) {
    case "develop":
      return process.env.REACT_APP_KEYCLOAK_DEVELOP_URL;
    case "demo":
      return process.env.REACT_APP_KEYCLOAK_DEMO_URL;
    case "production":
      return process.env.REACT_APP_KEYCLOAK_PRODUCTION_URL;
    default:
      return "";
  }
};

const keycloakConf = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  baseURL: getKeycloackUrl(),
};

const jsonSerializer = new JsonSerializer();

const axiosInstance = axios.create();

export const apiClient = new apiClientAxios(
  axiosInstance,
  (url: string, realm: Realm): string => {
    if (url.startsWith("/")) {
      url = url.substring(1);
    }

    if (realm === "auth") {
      switch (url) {
        case "accesslog":
          return `${apiURL}parties/v1/security/access/events`;
        case "refresh":
        case "authenticate":
          return `${keycloakConf.baseURL}realms/${keycloakConf.realm}/protocol/openid-connect/token`;
        case "logout":
          return `${keycloakConf.baseURL}realms/${keycloakConf.realm}/protocol/openid-connect/logout`;
        default:
          throw new Error(`Unresolved url: ${url}`);
      }
    }

    return `${apiURL}${url}`;
  },
  jsonSerializer
);

export const attachResponseInterceptor = (cb: () => void) =>
  axiosInstance.interceptors.response.use(cb);
