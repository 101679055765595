import { Button, makeStyles, Grid, Typography } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import FullScreenModal from "components/atoms/FullScreenModal";
import QuidTextareaField from "components/atoms/QuidTextareaField";
import { postCryptoBeneficiariesManagement } from "@portit/core/api/Crypto";

export interface CreateBeneficiaryModalProps {
  open: boolean;
  partyId: number;
  refetch: () => void;
  // currencies: Array<Currency>;
  onCloseModal: (open: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  form: {
    maxWidth: "100%",
  },
  mt56: {
    marginTop: 56,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

interface FormValues {
  name: string;
  note: string;
  network: string;
  currency: string;
  walletAddress: string;
}

const CreateBeneficiaryModal: React.FC<CreateBeneficiaryModalProps> = ({
  open,
  partyId,
  refetch,
  // currencies,
  onCloseModal,
}) => {
  const { t } = useTranslation("crypto");
  const dispatch = useDispatch();
  const classes = useStyles();

  const defaultValues: FormValues = {
    name: "",
    note: "",
    network: "",
    currency: "",
    walletAddress: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onFormSubmit = async (values: FormValues) => {
    try {
      await postCryptoBeneficiariesManagement({
        partyId,
        name: values.name,
        note: values.note,
        network: values.network,
        currency: values.currency || "",
        walletAddress: values.walletAddress,
      });
      refetch();
      reset(defaultValues);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("beneficiary__modal__successful__message"),
          type: "success",
        })
      );
      onCloseModal(false);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  return (
    <FullScreenModal open={open} onClose={() => onCloseModal(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt56}>
            <Typography variant="h1" className={classes.title}>
              {t("beneficiary__modal__title")}
            </Typography>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12}>
                <Grid item>
                  <QuidTextField
                    name="name"
                    rules={{
                      required: t(
                        "beneficiary__modal__name__required"
                      ) as string,
                    }}
                    textFieldProps={{
                      select: false,
                      fullWidth: true,
                    }}
                    label={t("beneficiary__modal__name")}
                    defaultValues={defaultValues}
                  />
                </Grid>
                <Grid container direction={"row"} spacing={1}>
                  <Grid xs={6} item>
                    <QuidTextField
                      name="network"
                      rules={{
                        required: t(
                          "beneficiary__modal__network__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        select: false,
                        fullWidth: true,
                      }}
                      label={t("beneficiary__modal__network")}
                      defaultValues={defaultValues}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <QuidTextField
                      name="currency"
                      rules={{
                        required: t(
                          "beneficiary__modal__currency__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        select: false,
                        fullWidth: true,
                      }}
                      label={t("beneficiary__modal__currency")}
                      defaultValues={defaultValues}
                    />
                  </Grid>
                </Grid>
                {/* TODO to implement when the network api will be created
                <Grid container direction={"row"} spacing={1}>
                  <Grid xs={6} item>
                    <QuidTextField
                      name="network"
                      label={t("beneficiary__modal__network")}
                      rules={{
                        required: t(
                          "beneficiary__modal__network__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                      }}
                      defaultValues={defaultValues}
                    ></QuidTextField>
                  </Grid>
                  <Grid xs={6} item>
                    <QuidTextField
                      name="currency"
                      label={t("beneficiary__modal__currency")}
                      rules={{
                        required: t(
                          "beneficiary__modal__currency__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                      }}
                      defaultValues={defaultValues}
                    >
                      {currencies.map((currency: Currency) => (
                        <MenuItem key={currency.code} value={currency.code}>
                          {currency.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                  </Grid>
                </Grid> */}
                <Grid item>
                  <QuidTextareaField
                    name="walletAddress"
                    label={t("beneficiary__modal__wallet_address")}
                    textFieldProps={{
                      fullWidth: true,
                    }}
                    rules={{
                      required: t(
                        "beneficiary__modal__wallet_address__required"
                      ) as string,
                    }}
                    defaultValues={defaultValues}
                  />
                </Grid>
                <Grid item>
                  <QuidTextareaField
                    name="note"
                    label={t("beneficiary__modal__note")}
                    textFieldProps={{
                      fullWidth: true,
                    }}
                    rules={{}}
                    defaultValues={defaultValues}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="actions actions-approve"
                >
                  {t("beneficiary__modal__action__save__beneficiary")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </FullScreenModal>
  );
};

export default CreateBeneficiaryModal;
