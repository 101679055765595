import { downloadAccessLogsDocument } from "api/accessLog";
import { format } from "date-fns";
import { DownloadAccessLogsParams } from "entities/accounts/AccessLogs";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import fileDownload from "js-file-download";

export async function downloadAccessLogsCsv(
  params: DownloadAccessLogsParams
): Promise<FilePayload> {
  try {
    const res = await downloadAccessLogsDocument(params);
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.username}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}
