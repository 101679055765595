import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ServerFailure } from "features/core/Failure";
import { Button, Grid } from "@material-ui/core";
import MainTemplate from "templates/MainTemplate";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import QuidTitle from "components/atoms/QuidTitle";
import {
  lockUser,
  sendEmailVerificationCode,
  sendSmsVerificationCode,
  unlockUser,
} from "api/customers";
import { fetchCustomerDetails } from "services/customers";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import InfoBox from "components/molecules/InfoBox";
import CardDetails from "components/molecules/CardDetails";
import { useHistory } from "react-router";
import { formatDate } from "utils";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  mainTitle: {
    marginTop: 30,
  },
  title: {
    marginTop: 60,
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
  },
  container: {
    minHeight: "100px",
  },
  verticalAlign: {
    alignItems: "center",
  },
}));

const UsersDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("user");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerId: number = +match.params.id;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<CustomerDetails>();

  useEffect(() => {
    if (customerId) {
      void getCustomerDetails(customerId);
    }
  }, [customerId]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const unlock = async () => {
    setLoading(true);
    try {
      await unlockUser(customerId);

      handleSuccessfulMessage(t("unlock__user__successful__message"));
      void getCustomerDetails(customerId);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const lock = async () => {
    setLoading(true);
    try {
      await lockUser(customerId);
      handleSuccessfulMessage(t("lock__user__successful__message"));
      void getCustomerDetails(customerId);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const sendSmsCode = async (customerId: number) => {
    setLoading(true);
    try {
      if (customerId !== undefined) {
        await sendSmsVerificationCode(customerId);
        handleSuccessfulMessage(t("sms__verification__user__sent__message"));
      }
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const sendEmailVerification = async (username: string) => {
    setLoading(true);

    try {
      if (username !== undefined) {
        await sendEmailVerificationCode(username);
        handleSuccessfulMessage(t("email__verification__user__sent__message"));
      }
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const userDetailsData = [
    {
      title: t("user_details_info_registration_ip"),
      value: details?.customer?.registrationIp || "n/a",
    },
    {
      title: t("user_details_info_account_name"),
      value: details?.customer?.user?.username || "n/a",
    },
    {
      title: t("user_details_info_email"),
      value: details?.customer?.email || "n/a",
    },
    {
      title: t("user_details_info_first_name"),
      value: details?.customer?.name || "n/a",
    },
    {
      title: t("user_details_info_last_name"),
      value: details?.customer?.surname || "n/a",
    },
    {
      title: t("user_details_info_phone_number"),
      value:
        `${details?.customer?.prefix} ${details?.customer?.mobileNumber}` ||
        "n/a",
    },
    {
      title: t("user_details_info_birth_date"),
      value: formatDate(details?.customer?.birthDate),
    },
    {
      title: t("user_details_info_country_birthday"),
      value: details?.customer?.countryOfBirth || "n/a",
    },
    {
      title: t("user_details_info_type"),
      value: details?.customer?.type || "n/a",
    },
    {
      title: t("user_details_info_receive_news"),
      value: details?.customer?.receiveQuidNews?.toString() || "n/a",
    },
    {
      title: t("user_details_info_terms_use"),
      value: details?.customer?.termsOfUse || "false",
    },
  ];
  const cardLabelsUserQuidColumn = [
    {
      label: t("user_details_card_access_log"),
      path: `/users/${customerId}/accessLogs/${details?.customer?.user?.userId}`,
    },
  ];

  const btnMl = clsx(`${classes.buttonMargin}--left`);

  return (
    <MainTemplate>
      <div className={classes.root}>
        <div className={classes.mainTitle}>
          <QuidTitle>{t("page__title__user__details")}</QuidTitle>
        </div>
        <Grid container className={(classes.container, classes.verticalAlign)}>
          <Grid item xs={8}>
            <InfoBox
              title={t("user_details_info_title")}
              items={userDetailsData}
              customer={details?.customer}
            />
          </Grid>
          <Grid item xs={4}>
            <CardDetails
              isColumn={true}
              items={cardLabelsUserQuidColumn}
              from={"user"}
            ></CardDetails>
          </Grid>
        </Grid>
        <Grid container>
          <div className={classes.actionButtons}>
            {details?.customer?.id && !details?.customer?.mobileNumberVerified && (
              <Button
                variant="contained"
                color="primary"
                className={btnMl}
                disabled={loading}
                onClick={() => sendSmsCode(details?.customer?.id)}
              >
                {t("user__page__btn__send__sms")}
              </Button>
            )}
            {details?.customer?.user?.username && (
              <Button
                variant="contained"
                color="primary"
                className={btnMl}
                disabled={loading}
                onClick={() =>
                  sendEmailVerification(details?.customer?.user?.username)
                }
              >
                {t("user__page__btn__send__email")}
              </Button>
            )}
            {details?.customer?.locked ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={btnMl}
                  disabled={loading}
                  onClick={unlock}
                >
                  {t("user__page__btn__unblock")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={btnMl}
                  disabled={loading}
                  onClick={lock}
                >
                  {t("user__page__btn__block")}
                </Button>
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              className={btnMl}
              disabled={loading}
              onClick={() =>
                history.push({
                  pathname: `/users/${customerId}/view`,
                  state: { type: "users" },
                })
              }
            >
              {t("user__page__btn__edit")}
            </Button>
          </div>
        </Grid>
      </div>
    </MainTemplate>
  );
};

export default UsersDetails;
