import React, { useEffect, ReactNode, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import StatusBadge from "components/atoms/StatusBadge";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { updateCustomerDetails } from "api/customers";
import { CustomerBackoffice } from "entities/clients/CustomerBackofficeEntity";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,

    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
}));

type FormValues = {
  name: string;
  surname: string;
  birthDate: string;
  locked: boolean;
  mobileNumber: string;
  nationality: string;
  countryOfBirth: string;
  prefix: string;
  email: string;
  type: string;
  fiscalCode: string;
  receiveQuidNews: boolean;
  thirdPartyNotifications: boolean;
  mobileNumberVerified: boolean;
};

export interface CustomerDetailsProps {
  customer?: CustomerBackoffice;
  typeCustomer?: string;
  children?: ReactNode;
  refetch: () => Promise<void>;
}

const CustomerInfo: React.FC<CustomerDetailsProps> = ({
  customer,
  typeCustomer,
}: CustomerDetailsProps) => {
  const { t } = useTranslation("customer");
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultValues: FormValues = {
    name: "",
    surname: "",
    birthDate: "",
    locked: false,
    mobileNumber: "",
    prefix: "",
    email: "",
    type: "",
    nationality: "",
    countryOfBirth: "",
    fiscalCode: "",
    receiveQuidNews: false,
    thirdPartyNotifications: false,
    mobileNumberVerified: false,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;
  const onFormSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await updateCustomerDetails({
        id: customer?.id as number,
        body: { id: customer?.id as number, ...values },
      });

      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("customer__update__success__message"),
          type: "success",
        })
      );
      history.push(`/${typeCustomer}/${customer?.id}`);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && customer) {
      reset({
        prefix: customer?.prefix || "",
        name: customer?.name || "",
        surname: customer?.surname || "",
        birthDate: customer?.birthDate
          ? format(new Date(customer?.birthDate), "yyyy-MM-dd")
          : "",
        locked: customer?.locked || false,
        mobileNumber: customer?.mobileNumber || "",
        type: customer?.type || "",
        email: customer?.email || "",
        nationality: customer?.nationality || "",
        fiscalCode: customer?.fiscalCode || "",
        countryOfBirth: customer?.countryOfBirth || "",
        receiveQuidNews: customer?.receiveQuidNews || false,
        thirdPartyNotifications: customer?.thirdPartyNotifications || false,
        mobileNumberVerified: (customer as any)?.mobileNumberVerified || false,
      } as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  }, [customer]);

  const emailVerified = customer?.emailVerified ? "done" : "pending";
  const mobileVerified = customer?.mobileNumberVerified ? "done" : "pending";

  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid
            container
            spacing={2}
            direction="row"
            className={classes.gridContainer}
          >
            <Grid item xs={4}>
              <QuidTextField
                name="email"
                rules={{
                  required: t("details__label__email__required") as string,
                }}
                textFieldProps={{
                  fullWidth: true,
                  InputProps: {
                    endAdornment: <StatusBadge status={emailVerified} />,
                  },
                }}
                label={t("details__label__email")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={1}>
              <QuidTextField
                name="prefix"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__phonePrefix__required"
                  ) as string,
                }}
                label={t("details__label__phonePrefix")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={3}>
              <QuidTextField
                name="mobileNumber"
                rules={{
                  required: t(
                    "details__label__mobileNumber__required"
                  ) as string,
                }}
                textFieldProps={{
                  fullWidth: true,
                  InputProps: {
                    endAdornment: <StatusBadge status={mobileVerified} />,
                  },
                }}
                label={t("details__label__mobileNumber")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={3}>
              <QuidTextField
                disabled={false}
                name="fiscalCode"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                label={t("details__label__fiscal_code")}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.updateBtn}
            variant="contained"
            color="primary"
            disabled={loading || !customer || !Object.keys(customer).length}
            type="submit"
          >
            Update
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default CustomerInfo;
