import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import taskModalsSlice from "store/reducers/taskModals.reducer";
import { getAttachmentModalState } from "store/reducers/app.reducer";
import { useDispatch, useSelector } from "react-redux";
import CrossIcon from "./icons/CrossIcon";
import CommentRow from "components/molecules/verifications/CommentRow";
import CommentsAdd from "components/molecules/verifications/CommentsAdd";
import { Comment } from "entities/tasks/TaskCommentEntity";
import { Attachment } from "entities/clients/Attachment";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    zIndex: 999,
    boxShadow: "-12px 22px 61px -11px rgba(0,0,0,0.75);",
    padding: 14,
    backgroundColor: "#fff",

    "& .MuiOutlinedInput-root": {
      borderRadius: 40,
    },
  },
  uploadingFiles: {
    fontSize: 14,
    color: "#B0B0B0",
  },
  pointer: { cursor: "pointer" },
  removePointerEvts: { pointerEvents: "none" },
  attachmentsContainer: {
    width: 365,
    maxHeight: 400,
    minHeight: 100,
    overflowY: "scroll",
    padding: 14,
  },
  attachmentsCloseButtonWithoutAttachments: {
    width: "auto !important",
    position: "absolute",
    top: 14,
    right: 14,
  },
  attachmentsCloseButtonWithAttachments: {
    width: "auto !important",
    minWidth: "auto",
    marginBottom: 12,
    marginLeft: "auto",
    padding: 0,
    marginRight: 0,
    display: "block",
    "& .MuiButton-label": {
      minWidth: "auto",
    },
  },
  commentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 16,

    "& >p:first-child": {
      wordBreak: "break-all",
      fontSize: 16,
    },
  },
  commentBody: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    padding: 20,
  },
  time: {
    fontSize: 10,
    color: "#929292",
  },
  commentText: {
    fontSize: 14,
    color: "#fff",
    overflowWrap: "break-word",
  },
  inputWrapper: {
    backgroundColor: "#fff",
  },
  inputBtn: { height: 40, width: "40px !important", minWidth: 40 },
  rowContainer: { marginBottom: 24 },
}));

interface TaskAttachmentsModalProps {
  taskKey: Task["taskDefinitionKey"];
  taskId: string;
  attachments: Attachment[];
  comments: Comment[];
  refetch: any;
  isHistory: Boolean;
}

const TaskAttachmentsModal: React.FC<TaskAttachmentsModalProps> = ({
  comments,
  attachments,
  taskKey,
  taskId,
  refetch,
  isHistory,
}) => {
  const classes = useStyles();
  const attachmentsModal = useSelector(getAttachmentModalState);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(taskModalsSlice.actions.closeAttachments());
  };

  if (!attachmentsModal) {
    return null;
  }

  return (
    <div
      className={classes.container}
      style={{
        top: attachmentsModal.y + 60,
        left: attachmentsModal.x - 200,
        maxWidth: 379,
      }}
    >
      <div className={classes.attachmentsContainer}>
        {!attachments || !attachments.length ? (
          <div>
            <Typography>There are no attachments yet</Typography>
            <Button
              onClick={close}
              className={classes.attachmentsCloseButtonWithoutAttachments}
            >
              <CrossIcon color="black" />
            </Button>
          </div>
        ) : (
          <div>
            <div>
              <Button
                onClick={close}
                className={classes.attachmentsCloseButtonWithAttachments}
              >
                <CrossIcon color="black" />
              </Button>
            </div>
            {comments?.map((comment: Comment, idx: number) => {
              return attachments?.map((attachment: Attachment) => {
                if (comment.id === attachment.comment) {
                  return (
                    <CommentRow
                      key={comment.id}
                      idx={idx}
                      comment={comment}
                      taskKey={taskKey}
                      taskId={taskId}
                      attachments={attachments?.filter(
                        (a) => a.comment === comment.id
                      )}
                      isHistory={isHistory}
                    />
                  );
                }
              });
            })}
          </div>
        )}
      </div>
      {!isHistory && (
        <div className={classes.inputWrapper}>
          <CommentsAdd
            taskKey={taskKey}
            taskId={taskId}
            refetch={refetch}
            hasAttachment={true}
          />
        </div>
      )}
    </div>
  );
};

export default TaskAttachmentsModal;
