import { Grid } from "@material-ui/core";
import { CellParams, ColDef } from "@material-ui/data-grid";
import { fetchCryptoWalletManagementTransactionsByPartyId } from "@portit/core/api/Crypto";
import { WalletTransactionsResponse } from "@portit/core/entities/Crypto";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const CryptoWalletTransactions: React.FC<
  RouteComponentProps<{ partyId: string; id: string }>
> = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("crypto");
  const [loading, setLoading] = useState(false);
  const partyId = parseInt(match.params.partyId, 10);
  // const id = parseInt(match.params.id, 10);

  const [walletTransactions, setWalletTransactions] = useState<
    WalletTransactionsResponse[]
  >();

  useEffect(() => {
    const getWalletTransactions = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCryptoWalletManagementTransactionsByPartyId(
          partyId
        );
        setWalletTransactions(res?.walletTransactions);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getWalletTransactions();
  }, [dispatch]);

  const columns: ColDef[] = [
    { field: "id", headerName: t("listTable__header_name__id"), width: 75 },
    {
      field: "fromWalletAddress",
      headerName: t("listTable__header_name__wallet_from"),
      width: 350,
    },
    {
      field: "toWalletAddress",
      headerName: t("listTable__header_name__wallet_to"),
      width: 350,
    },
    {
      field: "amount",
      headerName: t("listTable__header_name__uint_amount"),
      width: 75,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(
          parseFloat(params?.row?.amount ?? 0),
          params?.row?.currency
        ),
    },
    {
      field: "currency",
      headerName: t("listTable__header_name__currency"),
      width: 100,
    },
    {
      field: "contractAddress",
      headerName: t("listTable__header_name__contract_address"),
      flex: 1,
    },
  ];

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("cryptowallet__transactions__page__title")}</QuidTitle>
        </Grid>
      </Grid>
      {walletTransactions && (
        <Grid item xs={12}>
          <QuidDataGrid
            disableSelectionOnClick
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={
              walletTransactions[0]?.transactions
                ? walletTransactions[0].transactions
                : []
            }
            rowCount={walletTransactions?.length}
          />
        </Grid>
      )}
    </MainTemplate>
  );
};

export default CryptoWalletTransactions;
