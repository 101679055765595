import { createSlice } from "@reduxjs/toolkit";

export type taskModalsSliceState = {
  comments: { x: number; y: number } | null;
  attachments: { x: number; y: number } | null;
};

export const defaultState: taskModalsSliceState = {
  comments: null,
  attachments: null,
};

const taskModalsSlice = createSlice({
  name: "taskModals",
  initialState: defaultState,
  reducers: {
    openComments: (
      state,
      { payload: { x, y } }: { payload: { x: number; y: number } }
    ) => {
      return {
        ...state,
        attachments: null,
        comments: { x, y },
      };
    },
    openAttachments: (
      state,
      { payload: { x, y } }: { payload: { x: number; y: number } }
    ) => {
      return {
        ...state,
        comments: null,
        attachments: { x, y },
      };
    },
    closeComments: (state) => {
      return {
        ...state,
        comments: null,
      };
    },
    closeAttachments: (state) => {
      return {
        ...state,
        attachments: null,
      };
    },
  },
  extraReducers: {},
});

export const getCommentsModalState = (state: taskModalsSliceState) =>
  state.comments;
export const getAttachmentModalState = (state: taskModalsSliceState) =>
  state.attachments;

export default taskModalsSlice;
