import {
  getCountries,
  getCurrencies,
  fetchFinancialEmploymentTypes,
  fetchFinancialEmploymentSectors,
  fetchFinancialSourceTypes,
  fetchFinancialCompanySourceTypes,
  fetchAddressTypes,
  fetchCompanyTypes,
  fetchAddressUses,
  fetchPersonTitle,
  fetchDocumentTypes,
  fetchDocumentSubTypes,
} from "api/enums";
import { Dataset, FinancialEnums } from "entities/clients/Enums";

export async function fetchFinancialEnums(): Promise<FinancialEnums> {
  try {
    const res = await Promise.all<any>([
      getCountries(),
      getCurrencies(),
      fetchFinancialEmploymentTypes(),
      fetchFinancialEmploymentSectors(),
      fetchFinancialSourceTypes(),
      fetchFinancialCompanySourceTypes(),
    ]);

    const [
      countries,
      currencies,
      employmentTypes,
      employmentSector,
      sourceTypes,
      companySourceType,
    ] = res;

    return {
      countries,
      currencies,
      employmentTypes,
      employmentSector,
      sourceTypes,
      companySourceType,
    } as FinancialEnums;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchAllDatasets(): Promise<Dataset> {
  try {
    const res = await Promise.all<any>([
      getCountries(),
      getCurrencies(),
      fetchFinancialEmploymentTypes(),
      fetchFinancialEmploymentSectors(),
      fetchFinancialSourceTypes(),
      fetchFinancialCompanySourceTypes(),
      fetchAddressTypes(),
      fetchCompanyTypes(),
      fetchAddressUses(),
      fetchPersonTitle(),
      fetchDocumentTypes(),
      fetchDocumentSubTypes(),
    ]);
    const [
      countries,
      currencies,
      financialEmploymentTypes,
      financialEmploymentSectors,
      financialSourceTypes,
      financialCompanySourceTypes,
      addressTypes,
      companyTypes,
      addressUses,
      personTitles,
      documentTypes,
      subDocumentTypes,
    ] = res;

    return {
      countries,
      currencies,
      financialEmploymentTypes,
      financialEmploymentSectors,
      financialSourceTypes,
      financialCompanySourceTypes,
      addressTypes,
      companyTypes,
      addressUses,
      personTitles,
      documentTypes,
      subDocumentTypes,
    } as Dataset;
  } catch (err: any) {
    throw err;
  }
}
