import { makeStyles, Typography } from "@material-ui/core";
import QuidUploadField from "components/atoms/QuidUploadField";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { DetailsRow } from "./DetailsBlock";
import Attachment from "components/molecules/verifications/Attachment";
import { updateDocument } from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { Document } from "entities/clients/DocumentEntity";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  selfieDocContainer: {
    "& .MuiTextField-root": {
      display: "block",
    },
  },
  imgPreview: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 28,
  },
}));

interface DocumentsProofProps {
  doc: Document;
  refetch: () => Promise<void>;
  taskId: string;
  inputId: string;
  title: string;
  taskKey: Task["taskDefinitionKey"];
  canReplace: boolean;
  isHistory: boolean;
}

const DocumentsProofBlock: React.FC<DocumentsProofProps> = ({
  doc,
  refetch,
  inputId,
  taskId,
  taskKey,
  title,
  canReplace,
  isHistory,
}) => {
  const { t } = useTranslation(["validations", "documentTypes", "countries"]);
  const classes = useStyles();

  const onDocChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length === 1) {
      try {
        await updateDocument({
          taskId,
          documentId: (doc.id as unknown) as string,
          request: { document: e.target.files[0] },
          taskKey,
        });
        handleSuccessfulMessage("Document uploaded");
        refetch();
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };
  return (
    <div className={classes.selfieDocContainer}>
      <Typography variant="h6" className="section-title">
        {title}
      </Typography>
      <Attachment
        downloadDoc={(params) =>
          isHistory === false
            ? fetchDocument({ ...params, taskId, taskKey })
            : fetchHistoricalDocument({ ...params, taskId, taskKey })
        }
        attachment={doc}
        imgClassName={classes.imgPreview}
      />
      <DetailsRow label="Document type" value={t(doc.subType)} index={0} />
      {canReplace ? (
        <div>
          <Typography variant="h6" className="section-title">
            Replace document
          </Typography>
          <QuidUploadField
            inputId={inputId}
            onChange={onDocChange}
            label="Upload document"
          />
        </div>
      ) : (
        <div>
          <></>
        </div>
      )}
    </div>
  );
};

export default DocumentsProofBlock;
