import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import CustomerInfo from "components/organisms/customers/CustomerInfo";
import {
  CustomerBackoffice,
  CustomerDetails,
} from "entities/clients/CustomerBackofficeEntity";
import { fetchCustomerDetails } from "services/customers";
import { RouteComponentProps, useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  watchIcon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#37383C",
  },
  offerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const UsersUpdateInfo: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("user");
  const dispatch = useDispatch();
  const history = useHistory();
  const customerId: number = +match.params.id;
  const typeCustomer = history?.location?.state as Object;
  const [details, setDetails] = useState<CustomerDetails>();

  useEffect(() => {
    if (customerId) {
      void getCustomerDetails(customerId);
    }
  }, [customerId]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetails]
  );

  return (
    <MainTemplate>
      <div className={classes.offerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("user__title__personal__info")}</QuidTitle>
          </Grid>
        </Grid>
        <CustomerInfo
          refetch={() => getCustomerDetails(customerId)}
          customer={details?.customer as CustomerBackoffice}
          typeCustomer={Object.values(typeCustomer)[0]}
        />
      </div>
    </MainTemplate>
  );
};

export default UsersUpdateInfo;
