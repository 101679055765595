import {
  fetchAllTasks,
  fetchAllTasksAccounts,
  fetchAllTasksCompanyFormations,
} from "api/tasks";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { useEffect, useState } from "react";

interface DashboardTasksKPIProps {
  taskType: string;
}

const DashboardTasksKPI: React.FC<DashboardTasksKPIProps> = ({ taskType }) => {
  const [totalTask, setTotalTask] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const getTotalTask = async (): Promise<void> => {
      try {
        if (taskType === "accounts") {
          const customerListRes = await fetchAllTasksAccounts();
          setTotalTask(customerListRes?.total);
        } else if (taskType === "companyformation") {
          const customerListRes = await fetchAllTasksCompanyFormations();
          setTotalTask(customerListRes?.total);
        } else {
          const customerListRes = await fetchAllTasks();
          setTotalTask(customerListRes?.total);
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };
    void getTotalTask();
  }, []);

  return <div>{totalTask}</div>;
};

export default DashboardTasksKPI;
