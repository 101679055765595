import React from "react";
import { makeStyles, Tabs as MUITabs } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabs: (props: { maxTabWidth: string }) => ({
    backgroundColor: "#fff",
    "& .MuiTab-root": {
      textTransform: "none",
      maxWidth: props.maxTabWidth,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      fontSize: 16,
      lineHeight: "45px",
      color: "#fff",
      marginRight: 2,
      opacity: 1,
    },
    "& .Mui-selected": {
      color: "#FFFFFF",
    },
  }),
}));

interface TabsProp {
  value: number;
  maxTabWidth: string;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const Tabs: React.FC<TabsProp> = ({
  handleChange,
  value,
  maxTabWidth,
  children,
}) => {
  const classes = useStyles({ maxTabWidth });

  return (
    <MUITabs
      orientation="horizontal"
      value={value}
      onChange={handleChange}
      aria-label="Horizontal navigation tabs"
      variant="scrollable"
      className={classes.tabs}
    >
      {children}
    </MUITabs>
  );
};

export default Tabs;
