import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ColDef } from "@material-ui/data-grid";
import { useRouteMatch } from "react-router";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { AccountInfo } from "entities/accounts/Account";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import { formatDate } from "utils";
import VerificationTemplate from "templates/VerificationTemplate";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { acceptTask, rejectTask } from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { FinancialData } from "entities/clients/FinancialEntity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

const corporateCheckAccountDetailsDirectCheck = {
  COMPANY_JURISDICTION_PERMITTED: false,
};

export interface CompanyDetails {
  [key: string]: string;
}

export interface QuidAccountInformationInterface {
  quidReference: string;
  currency: string;
  account_type: string;
}

const CorporateCheckAccountDetailsDirectComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation(["validations"]);
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const companyDetail = task?.variables?.company;
  const companyAddressDetail = task?.variables?.address;
  const customerDetail = task?.variables?.customer;
  const companyDocumentProofOfBusiness = task?.variables?.companyDocuments?.find(
    (d: any) => d.type === "PROOF_OF_BUSINESS"
  );
  const taskAccountInfo = task?.variables?.accountInfo;
  const taskFinancial = task?.variables?.financial;
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const companyToPrint: CompanyDetails = {
    companyName: companyDetail?.companyName || "n/a",
    legalEntityType: companyDetail?.legalEntityType || "n/a",
    legalEntityValue: companyDetail?.legalEntityValue || "n/a",
    companyNumber: companyDetail?.companyNumber || "n/a",
    vatNumber: companyDetail?.vatNumber || "n/a",
    "CONTACT INFORMATIONS": "",
    mobileNumber: customerDetail?.mobileNumber || "n/a",
    email: customerDetail?.email || "n/a",
    INCORPORATION: "",
    incorporationDate: companyDetail?.incorporationDate
      ? formatDate(companyDetail.incorporationDate)
      : "n/a",
    countryOfIncorporation: companyDetail?.incorporationCountry || "n/a",
    ADDRESS: "",
    addressLine1: companyAddressDetail?.addressLine1,
    addressLine2: companyAddressDetail?.addressLine2,
    city: companyAddressDetail?.city,
    state: companyAddressDetail?.state || "",
    country: getCountry(companyAddressDetail?.country) || "",
    postalCode: companyAddressDetail?.postalCode,
  };

  const taskFinancialToPrint: Partial<FinancialData> = {
    fundsSourceType: taskFinancial?.fundsSourceType || "--",
    fundsSourceValue: taskFinancial?.fundsSourceValue || "--",
    companySector: taskFinancial?.companySector || "--",
    companySectorDescription: taskFinancial?.companySectorDescription || "--",
    companySubsector: taskFinancial?.companySubsector || "--",
  };

  const taskMarketingToPrint: Partial<FinancialData> = {
    companyWebsiteUrl: taskFinancial?.companyWebsiteUrl || "--",
    companyMarketingDescription:
      taskFinancial?.companyMarketingDescription || "--",
  };

  const taskAccountInfoToPrint: Partial<AccountInfo> = {
    accountPurpose: taskAccountInfo?.accountPurpose || "n/a",
    paymentOrigin: getCountry(taskAccountInfo?.paymentOrigin) || "",
    paymentDestination: getCountry(taskAccountInfo?.paymentDestination) || "",
    paymentNumberIncomingPerMonth:
      taskAccountInfo?.paymentNumberIncomingPerMonth || "n/a",
    paymentVolumeIncomingPerMonth:
      taskAccountInfo?.paymentVolumeIncomingPerMonth || "n/a",
    paymentNumberOutcomingPerMonth:
      taskAccountInfo?.paymentNumberOutcomingPerMonth || "n/a",
    paymentVolumeOutcomingPerMonth:
      taskAccountInfo?.paymentVolumeOutcomingPerMonth || "n/a",
  };

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {companyDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={companyToPrint}
              label="corporate__details__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          {taskFinancialToPrint && (
            <DetailsBlock
              toPrint={taskFinancialToPrint}
              label="financial__details__title"
            />
          )}
          <br />
          {taskAccountInfoToPrint && (
            <DetailsBlock
              toPrint={taskAccountInfoToPrint}
              label="account__details__title"
            />
          )}
          <br />
          {taskMarketingToPrint && (
            <DetailsBlock
              toPrint={taskMarketingToPrint}
              label="marketing__details__title"
            />
          )}
        </div>
        <div className={classes.detailsWrapperThird}>
          {companyDocumentProofOfBusiness && (
            <div>
              <DetailsBlock label={t("proof__of__business__title")} />
              <AttachmentWithoutPreview
                attachment={companyDocumentProofOfBusiness}
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
              />
            </div>
          )}
        </div>
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            checks={corporateCheckAccountDetailsDirectCheck}
            task={task}
            accept={(request) => handleApproveTask(request)}
            reject={(request) => handleRejectTask(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default CorporateCheckAccountDetailsDirectComp;
