import React from "react";
import { makeStyles } from "@material-ui/core";

export interface IconProps {
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.primary.main,
  },
}));

const CommentIcon: React.FC<IconProps> = ({ color }) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M16.391 0H1.82122C0.816816 0 0 0.816816 0 1.82122V12.7485C0 13.7529 0.816816 14.5697 1.82122 14.5697H4.55304V18L10.2689 14.5697H16.391C17.3954 14.5697 18.2122 13.7529 18.2122 12.7485V1.82122C18.2122 0.816816 17.3954 0 16.391 0ZM16.391 12.7485H9.76446L6.37426 14.7819V12.7485H1.82122V1.82122H16.391V12.7485Z"
        className={classes.icon}
        fill={color}
      />
    </svg>
  );
};

export default CommentIcon;
