import { makeStyles, Typography } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidUploadField from "components/atoms/QuidUploadField";
import Attachment from "components/molecules/verifications/Attachment";
import React, { ChangeEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { DetailsRow } from "./DetailsBlock";
import { format } from "date-fns";
import { Document } from "entities/clients/DocumentEntity";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import {
  VerificationDocumentUploadParams,
  VerificationDocumentAdditionalParams,
} from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  selfieDocContainer: {
    "& .MuiTextField-root": {
      display: "block",
    },
  },
  uploadDocument: {
    marginBottom: "50px",
  },
  imgPreview: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 28,
  },
}));

interface ProofOfAddressBlockProps {
  doc: Document;
  refetch: () => Promise<void>;
  taskId: string;
  updateDoc: (params: VerificationDocumentUploadParams) => Promise<void>;
  updateDocDetails: (
    params: VerificationDocumentAdditionalParams
  ) => Promise<void>;
  downloadDoc: (params: any) => Promise<FilePayload>;
  canReplace: boolean;
}

interface FormValues {
  issuingDate: string;
  expiringDate: string;
}

const defaultValues: FormValues = {
  issuingDate: "",
  expiringDate: "",
};

const ProofOfAddressBlock: React.FC<ProofOfAddressBlockProps> = ({
  doc,
  refetch,
  taskId,
  updateDoc,
  updateDocDetails,
  downloadDoc,
  canReplace,
}) => {
  const { t } = useTranslation(["validations", "documentTypes", "countries"]);
  const classes = useStyles();
  const [currentIssueDate, setCurrentIssueDate] = useState("");

  const methods = useForm({
    mode: "onBlur",
    defaultValues: doc.additionalInfo
      ? JSON.parse(doc.additionalInfo)
      : defaultValues,
  });

  const onDocChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length === 1) {
      try {
        await updateDoc({
          taskId,
          documentId: (doc.id as unknown) as string,
          request: { document: e.target.files[0] },
        });
        handleSuccessfulMessage("Document uploaded");
        refetch();
        methods.reset();
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const onFormSubmit = async (values: FormValues) => {
    if (values.issuingDate === "" || values.expiringDate === "") return;

    try {
      await updateDocDetails({
        taskId,
        documentId: (doc.id as unknown) as string,
        request: { additionalDetails: JSON.stringify(values) },
      });
      handleSuccessfulMessage("Document info changed");
      refetch();
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <div className={classes.selfieDocContainer}>
      <Typography variant="h6" className="section-title">
        {t("document__proofOfAddressPreview__title")}
      </Typography>
      <Attachment
        downloadDoc={downloadDoc}
        attachment={doc}
        imgClassName={classes.imgPreview}
      />
      <DetailsRow label="Document type" value={t(doc.subType)} index={0} />
      {canReplace ? (
        <div>
          <Typography variant="h6" className="section-title">
            Replace document
          </Typography>
          <QuidUploadField
            inputId="identity-doc"
            onChange={onDocChange}
            label="Upload document"
            className={classes.uploadDocument}
          />
        </div>
      ) : (
        <></>
      )}
      {canReplace && (
        <div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onFormSubmit)}>
              <QuidTextField
                name="issuingDate"
                label="Issuing date"
                rules={{
                  required: t("issueDate__required") as string,
                }}
                defaultValues={defaultValues}
                onBlur={methods.handleSubmit(onFormSubmit)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCurrentIssueDate(e?.target?.value);
                  methods.setValue("expiringDate", e?.target?.value);
                }}
                inputProps={{ max: format(new Date(), "yyyy-MM-dd") }}
                textFieldProps={{
                  margin: "normal",
                  type: "date",
                  InputLabelProps: { shrink: true },
                  onBlur: methods.handleSubmit(onFormSubmit),
                }}
              />
              <QuidTextField
                name="expiringDate"
                label="Expiring date"
                rules={{
                  required: t("expiryDate__required") as string,
                }}
                defaultValues={defaultValues}
                onBlur={methods.handleSubmit(onFormSubmit)}
                inputProps={{
                  min: currentIssueDate,
                }}
                textFieldProps={{
                  margin: "normal",
                  type: "date",
                  InputLabelProps: { shrink: true },
                  onBlur: methods.handleSubmit(onFormSubmit),
                }}
              />
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default ProofOfAddressBlock;
