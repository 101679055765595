import {
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { ReactNode } from "react";

interface QuidTitleStyleProps {
  weight: number;
  fontSize: number;
  textAlign:
    | "left"
    | "right"
    | "-moz-initial"
    | "inherit"
    | "initial"
    | "revert"
    | "unset"
    | "center"
    | "end"
    | "start"
    | "justify"
    | "match-parent"
    | undefined;
  marginBottom: string;
}

const useStyles = makeStyles<Theme, QuidTitleStyleProps>((theme) => ({
  text: {
    fontSize: (props) => props.fontSize || 28,
    color: theme.palette.primary.main,
    fontWeight: (props) => props.weight || "normal",
    marginTop: 0,
    marginBottom: (props) => props.marginBottom || "32px",
    textAlign: (props) => props.textAlign || "center",
  },
}));

interface QuidTitleProps extends TypographyProps, Partial<QuidTitleStyleProps> {
  children: ReactNode;
}

const QuidTitle: React.FC<QuidTitleProps> = ({ children, ...props }) => {
  const classes = useStyles(props as QuidTitleStyleProps);

  return (
    <Typography {...props} className={classes.text}>
      {children}
    </Typography>
  );
};

export default QuidTitle;
