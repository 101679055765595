import React from "react";
import "./app.css";
import { BrowserRouter as Router } from "react-router-dom";
import Theme from "./Theme";
import { Provider } from "react-redux";
import store from "./store/store";
import { QuidRoutes } from "./QuidRoutes";
import Modal from "./components/atoms/Modal";
import Snackbar from "./components/atoms/Snackbar";
import QuidBootstrap from "./QuidBootstrap";
import AppVersion from "./components/atoms/AppVersion";
import ClearCacheLink from "components/atoms/ClearCacheLink";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  footerUtilities: {
    position: "fixed",
    bottom: 0,
    right: 10,
    display: "flex",
    alignItems: "center",
  },
}));

export const QuidApp: React.FC = () => {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <Theme>
        <Modal />
        <Snackbar />
        <QuidBootstrap>
          <Router>
            <QuidRoutes />
          </Router>
        </QuidBootstrap>
        {/* This is temporary */}
        <div className={classes.footerUtilities}>
          <ClearCacheLink />
          <AppVersion />
        </div>
      </Theme>
    </Provider>
  );
};
