import { makeStyles, Typography } from "@material-ui/core";
import StatusBadge from "components/atoms/StatusBadge";
import { Company } from "entities/clients/CompanyEntity";
import { CustomerBackoffice } from "entities/clients/CustomerBackofficeEntity";
import { Opportunity } from "entities/opportunities/opportunityEntity";
import { ContractDetailResponse } from "entities/products/ProductEntity";

const useStyles = makeStyles(() => ({
  block: {
    padding: 15,
  },
  blockInside: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "#fff",
    padding: "20px 40px",
    borderRadius: 31,
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
    minHeight: "310px",
  },
  blockItem: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  blockItemTitleFull: {
    width: "100%",
    paddingRight: 15,
    paddingBottom: 5,
  },
  blockItemTitleHalf: {
    width: "50%",
    paddingRight: 15,
  },
  containerStatusValue: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

interface Item {
  title: string;
  value: string | number | undefined;
  type?: string | undefined;
}

interface InfoBoxProps {
  title: string;
  items: Item[];
  customer?: CustomerBackoffice;
  company?: Company;
  opportunity?: Opportunity;
  product?: ContractDetailResponse;
}

const InfoBox: React.FC<InfoBoxProps> = ({
  title,
  items,
  customer,
  product,
}) => {
  const classes = useStyles();
  const emailVerified = customer?.emailVerified ? "done" : "pending";
  const mobileVerified = customer?.mobileNumberVerified ? "done" : "pending";

  return (
    <div className={classes.block}>
      <div className={classes.blockInside}>
        <Typography variant="h6" color="primary">
          <strong>{title}</strong>
        </Typography>
        {items?.map(({ title, value, type }, index: number) => (
          <div className={classes.blockItem} key={index}>
            <Typography
              className={
                type === "array"
                  ? classes.blockItemTitleFull
                  : classes.blockItemTitleHalf
              }
              variant="body1"
              color="textPrimary"
            >
              {title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <div className={classes.containerStatusValue}>
                {value}
                {title === "Email" && (
                  <StatusBadge status={emailVerified} viewStatusText={false} />
                )}
                {title === "Phone number" && (
                  <StatusBadge status={mobileVerified} viewStatusText={false} />
                )}
                {title === "Status" && (
                  <StatusBadge
                    status={product?.status}
                    viewStatusText={false}
                  />
                )}
              </div>
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoBox;
