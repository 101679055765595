import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 94,
  },
  infoRows: {
    color: "#929292",
    fontWeight: "lighter",
    lineHeight: 2,
  },
}));

const BeneficiaryBankInfo = () => {
  const { t } = useTranslation("bankTransfer");
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography color="secondary" variant="subtitle1">
        {t("bank__transfer__beneficiary__label")}
      </Typography>
      <Typography className={classes.infoRows}>
        {t("bank__transfer__bank__name")}
        <br />
        {t("bank__transfer__swift__bic")}
        <br />
        {t("bank__transfer__bank__address")}
        <br />
        {t("bank__transfer__location")}
        <br />
        {t("bank__transfer__country")}
        <br />
        {t("bank__transfer__aba__rtn")}
        <br />
      </Typography>

      <FormControlLabel
        control={<Checkbox color="primary" />}
        label={t("bank__transfer__intermediary__check")}
        onChange={() => null}
        checked={true}
        name="intermediary"
      />
    </div>
  );
};

export default BeneficiaryBankInfo;
