import MerchantOffersList from "components/organisms/clients/MerchantOfferList";
import CustomerOffersList from "components/organisms/customers/CustomersOfferList";
import { RouteComponentProps, useHistory } from "react-router";
import MainTemplate from "templates/MainTemplate";

const OffersPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const history = useHistory();
  const customerId: number = +match.params.id;
  const from = history.location.state as Object;

  return (
    <MainTemplate>
      {Object.values(from)[0] === "customer" && (
        <CustomerOffersList customerId={customerId} />
      )}
      {Object.values(from)[0] === "company" && (
        <MerchantOffersList partyId={customerId} />
      )}
    </MainTemplate>
  );
};

export default OffersPage;
