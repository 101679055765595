import React, { ReactNode } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { useRouteMatch } from "react-router";
import PersonCard from "components/organisms/PersonCard";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { formatDate } from "utils";
import { useTranslation } from "react-i18next";
import { deleteCompanyUbo } from "api/companies";
import { Ubo } from "entities/clients/PersonEntity";

interface UbosProps {
  children?: ReactNode | ReactNode[];
  ubos?: Ubo[];
  refetch: () => Promise<void>;
}

const useStyles = makeStyles(() => ({
  container: { paddingBottom: "60px" },
}));

const UbosList: React.FC<UbosProps> = ({ ubos, refetch }) => {
  const { t } = useTranslation("ubo");
  const classes = useStyles();
  const match: { params: { id: string } } = useRouteMatch();
  const dispatch = useDispatch();
  const countries = useSelector(getPartiesCountries);
  const companyId: number = parseInt(match.params.id, 10);

  const onRemove = (personId: number) => {
    dispatch(
      promptsSlice.actions.openModal({
        message: t("modal__delete__ubo__body"),
        actions: [
          {
            label: t("action__yes"),
            handler: async () => {
              await deleteCompanyUbo({ personId, companyId });
              refetch();
              dispatch(promptsSlice.actions.closeModal());
            },
          },
          {
            label: t("action__no"),
            handler: () => dispatch(promptsSlice.actions.closeModal()),
          },
        ],
      })
    );
  };

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  return (
    <div className={classes.container}>
      {(!ubos || !ubos?.length) && (
        <Typography variant="h4" align="center">
          {t("detail__no__ubo__found")}
        </Typography>
      )}
      {ubos && ubos?.length ? (
        <Grid container spacing={10}>
          {ubos?.map((u: Ubo) => (
            <PersonCard
              key={u?.person?.id}
              person={u?.person}
              onRemove={onRemove}
              personType="ubo"
              companyId={companyId}
            >
              <Typography variant="body1">
                {u?.person?.surname || "n/a"}
              </Typography>
              <Typography variant="body2">
                {formatDate(u?.person?.birthDate, "dd/MM/yyyy")}
              </Typography>
              <Typography variant="body1">
                {getCountry(u?.person?.nationality)}
              </Typography>
              <Typography variant="body1">
                {getCountry(u?.person?.countryOfBirth)}
              </Typography>
            </PersonCard>
          ))}
        </Grid>
      ) : null}
    </div>
  );
};

export default UbosList;
