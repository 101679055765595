import React, { ReactElement, ChangeEvent, useEffect, useState } from "react";
import { Grid, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import { ServerFailure } from "features/core/Failure";
import { useTranslation } from "react-i18next";
import { TABLE_PAGE_SIZE } from "shared/constants";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import QuidTitle from "components/atoms/QuidTitle";
import { fetchLandBuyersList } from "api/Commerce";
import { PartiesPagination } from "entities/accounts/Pagination";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { LandBuyer } from "entities/clients/CommerceEntity";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  companyContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
    display: "none",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
  },
}));

const LandBuyers: React.FC = () => {
  const { t } = useTranslation("commerce");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [buyers, setBuyers] = useState([] as LandBuyer[]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    from: "",
    to: "",
  });
  const [pagination, setPagination] = useState({} as PartiesPagination);

  useEffect(() => {
    const getCarts = async (): Promise<void> => {
      setLoading(true);
      try {
        const buyersRes = await fetchLandBuyersList({
          pageNo,
          pageSize: TABLE_PAGE_SIZE,
          ...(filters.search && { keyword: filters.search }),
          ...(filters.from && { from: (filters.from as unknown) as number }),
          ...(filters.to && { to: (filters.to as unknown) as number }),
        });
        setPagination({
          pageNo: buyersRes.number,
          pageSize: buyersRes.size,
          totalEntries: buyersRes.totalElements,
          totalPages: buyersRes.totalPages,
        });
        setBuyers(buyersRes.content);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCarts();
  }, [pageNo, filters]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "partyId",
      headerName: "Party Id",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params: CellParams): ReactElement => {
        const payment = params?.row as LandBuyer;
        let value = "";
        if (payment?.customer?.type === "INDIVIDUAL") {
          value = `${payment?.customer?.name} ${payment?.customer?.surname}`;
        } else {
          value = `${payment?.customer?.company?.companyName}`;
        }
        return <div>{value}</div>;
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "purchaseFlag",
      headerName: "Purchase Flag",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
  ];

  return (
    <>
      <MainTemplate>
        <div className={classes.companyContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("details__title__land_buyers")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={2}>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("details__search")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      search: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={buyers}
            rowCount={pagination?.totalEntries}
            disableSelectionOnClick
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default LandBuyers;
