import {
  ContractDetailResponse,
  ContractResponse,
  ProductManagementParams,
  ProductsManagementCountResponse,
  ProductsResponse,
} from "entities/products/ProductEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function getProducts(): Promise<ProductsResponse> {
  try {
    const res = await apiClient.get<ProductsResponse>(
      `/products/v1/products`,
      "product"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchProductDetails(
  productId: number
): Promise<ContractDetailResponse> {
  try {
    const res = await apiClient.get<ContractDetailResponse>(
      `/products/v1/contracts/${productId}`,
      "product"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerProductsByPartyId(
  partyId: number
): Promise<ContractResponse> {
  try {
    const res = await apiClient.get<ContractResponse>(
      `/products/v1/contracts/parties?partyId=${partyId}`,
      "product"
    );
    res.contracts.map(function (contract) {
      contract.id = contract.contractId;
    });
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getProductsManagementCount(): Promise<ProductsManagementCountResponse> {
  try {
    const res = await apiClient.get<ProductsManagementCountResponse>(
      `/products/v1/productsManagement/count`,
      "product"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getProductsManagementPartiesByProductId(
  params: ProductManagementParams
): Promise<ContractResponse> {
  try {
    const res = await apiClient.get<ContractResponse>(
      `/products/v1/productsManagement/${params.productId}/parties`,
      "product",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function setProductStatusByContactId(
  contractId: number,
  contractStatus: string
): Promise<ContractResponse> {
  try {
    const res = await apiClient.put<ContractResponse>(
      `/products/v1/contracts/${contractId}?contractStatus=${contractStatus}`,
      "product"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createProductByProductId(
  productId: number,
  partyId: number
): Promise<ContractDetailResponse> {
  try {
    const res = await apiClient.post<ContractDetailResponse>(
      `/products/v1/contracts/${productId}/parties?partyId=${partyId}`,
      "product"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createProfileProductByProductIdAndProfileId(
  contractId: number,
  profileId: number
): Promise<ContractResponse> {
  try {
    const res = await apiClient.post<ContractResponse>(
      `accounts/v1/accounts/bo/create`,
      "product",
      {
        contract_id: contractId,
        profile_id: profileId,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
