import {
  getCompanyByCompanyId,
  getCompanyDirectorByCompanyId,
  getCompanyDocumentsByCompanyId,
  getCompanyFinancialByCompanyId,
  getCompanyUbosByCompanyID,
  getCompanyAccounts,
  downloadCompanyDocument,
  fetchCompanyAddresses,
  downloadCompaniesCSVDocument,
} from "api/companies";
import {
  associateCompanyToCustomer,
  getCustomerByCustomerId,
  getCustomerProfilesByCustomerId,
} from "api/customers";
import { getCustomerProductsByPartyId } from "api/product";
import { format } from "date-fns";
import {
  DownloadCompanyDocumentParams,
  CompanyDetailsParams,
  CompanyDetails,
  Company,
  CompanyDocumentsResponse,
  DownloadCompaniesParams,
  AssociateCompaniesToCustomer,
} from "entities/clients/CompanyEntity";
import {
  FilePayload,
  EnhancedAddress,
  AccountInfoResponse,
  CustomerResponse,
} from "entities/clients/CustomerBackofficeEntity";
import { FinancialData } from "entities/clients/FinancialEntity";
import { Director, Ubo } from "entities/clients/PersonEntity";
import { Profile } from "entities/clients/ProfileEntity";
import { ContractResponse } from "entities/products/ProductEntity";

import fileDownload from "js-file-download";

export async function downloadCompanyDocumentById(
  params: DownloadCompanyDocumentParams
): Promise<FilePayload> {
  try {
    const res = await downloadCompanyDocument(
      params.companyId,
      params.documentId
    );
    fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function fetchCompanyDetails(
  params: CompanyDetailsParams
): Promise<CompanyDetails> {
  try {
    const result: [
      Company,
      EnhancedAddress[],
      Director[],
      CompanyDocumentsResponse,
      FinancialData,
      Ubo[],
      AccountInfoResponse,
      ContractResponse
    ] = await Promise.all([
      getCompanyByCompanyId(params.companyId),
      fetchCompanyAddresses(params.companyId),
      getCompanyDirectorByCompanyId(params.companyId),
      getCompanyDocumentsByCompanyId(params.companyId),
      getCompanyFinancialByCompanyId(params.companyId),
      getCompanyUbosByCompanyID(params.companyId),
      getCompanyAccounts(params.companyId),
      getCustomerProductsByPartyId(params.companyId),
    ]);

    const [
      company,
      addresses,
      directors,
      documentResponse,
      financialData,
      ubos,
      accountsResponse,
      products,
    ]: [
      company: Company,
      addresses: EnhancedAddress[],
      directors: Director[],
      documentResponse: CompanyDocumentsResponse,
      financialData: FinancialData,
      ubos: Ubo[],
      accountsResponse: AccountInfoResponse,
      products: ContractResponse
    ] = result;

    const {
      documents,
      total: totalDocuments,
    }: CompanyDocumentsResponse = documentResponse;

    const {
      total: totalAccounts,
      accountInfos: accounts,
    }: AccountInfoResponse = accountsResponse;

    if (!company.customer) {
      return {
        company,
        directors,
        addresses,
        documents,
        financialData,
        totalDocuments,
        ubos,
        accounts,
        totalAccounts,
        products,
      };
    }

    const profiles: Profile[] = await getCustomerProfilesByCustomerId(
      company.customer?.id
    );

    return {
      company,
      directors,
      documents,
      addresses,
      financialData,
      ubos,
      totalDocuments,
      accounts,
      profiles,
      totalAccounts,
      products,
    };
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadCompaniesCsv(
  params: DownloadCompaniesParams
): Promise<FilePayload> {
  try {
    const res = await downloadCompaniesCSVDocument({
      keyword: params.keyword,
      from: params.from,
      to: params.to,
    });
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function getAssociationCompanyToCustomer(
  customerId: number
): Promise<{
  customer: CustomerResponse;
  associetedCompanies: AssociateCompaniesToCustomer;
}> {
  try {
    const result: [
      CustomerResponse,
      AssociateCompaniesToCustomer
    ] = await Promise.all([
      getCustomerByCustomerId(customerId),
      associateCompanyToCustomer(customerId),
    ]);

    const [customer, associetedCompanies]: [
      customer: CustomerResponse,
      associetedCompanies: AssociateCompaniesToCustomer
    ] = result;

    return {
      customer,
      associetedCompanies,
    };
  } catch (err) {
    throw err;
  }
}
