import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { Pagination } from "entities/accounts/Pagination";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { AccountDatasetCurrency } from "entities/accounts/AccountDataset";
import { getAccountsCurrencies } from "store/reducers/app.reducer";
import { fetchRewardsList } from "api/rewadrs";
import { Reward } from "entities/rewards/RewardEntity";
import { format } from "date-fns";
import { RouteComponentProps } from "react-router";
import RewardsModal from "components/organisms/payments/RewardsModal";

const useStyles = makeStyles((theme) => ({
  watchIcon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#37383C",
  },
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  offerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const Rewards: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const classes = useStyles();
  const TABLE_PAGE_SIZE = 10;
  const id: number = +match.params.id;
  const { t } = useTranslation("account");
  const dispatch = useDispatch();
  const [rewards, setRewards] = useState([] as Reward[]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);
  const [modalOpen, setModalOpen] = useState<boolean | undefined>();
  const [rewardDetail, setRewardDetail] = useState<Reward | undefined>();
  const [filter, setFilter] = useState({
    status: "",
    keyword: "",
    offerType: "",
    currency: "",
  });

  const currencies = useSelector(getAccountsCurrencies);

  useEffect(() => {
    const getRewards = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchRewardsList({
          page,
          size: TABLE_PAGE_SIZE,
          id,
          currency: filter?.currency,
          keyword: filter?.keyword,
        });
        setPagination(res?.pagination);
        setRewards(
          res.operationStakingResponse?.map((reward) => {
            return { ...reward, id: reward.relatedUniqueId };
          })
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getRewards();
  }, [page, filter.offerType, filter.status, filter.keyword, filter.currency]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      field: "relatedUniqueId",
      headerName: t("listTable__header_name__id"),
      width: 350,
    },
    {
      field: "created",
      headerName: t("listTable__header_name__created_at"),
      width: 300,
      renderCell: (params) => (
        <div>{format(new Date(params?.row.created), "yyyy-MM-dd")}</div>
      ),
    },
    {
      field: "currency",
      headerName: t("listTable__header_name__currency"),
      width: 300,
    },
    {
      field: "tier",
      headerName: t("listTable__header_name__tier"),
      width: 250,
    },
    {
      field: "operationType",
      headerName: t("listTable__header_name__type"),
      width: 250,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      width: 100,
      renderCell: (params) => (
        <DetailButton
          onClick={() => {
            setRewardDetail((params.row as unknown) as Reward);
            setModalOpen(true);
          }}
        />
      ),
    },
  ];

  const closeAndResetModal = () => {
    setModalOpen(false);
    setRewardDetail(undefined);
  };

  return (
    <MainTemplate>
      <RewardsModal
        open={modalOpen}
        setOpen={closeAndResetModal}
        reward={rewardDetail}
      />
      <div className={classes.offerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("rewards__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.filterContainer}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                label={t("filter__search")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilter((state) => ({
                    ...state,
                    keyword: e.target.value,
                  }));
                }}
                value={filter.keyword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <SearchIcon color="#fff" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("filter__currency")}
                variant="outlined"
                select
                fullWidth
                value={filter.currency}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    currency: e.target.value,
                  }))
                }
              >
                <MenuItem value="">{t("filter__currency")}</MenuItem>
                {currencies?.map(
                  (currency: AccountDatasetCurrency, index: number) => (
                    <MenuItem
                      key={`${currency.code}-${index}`}
                      value={currency.code}
                    >
                      {currency?.text}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "relatedUniqueId", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={rewards}
          rowCount={pagination?.total_entries}
          pageSize={10}
        />
      </div>
    </MainTemplate>
  );
};

export default Rewards;
