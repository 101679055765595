import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import HistoryCustomer from "components/organisms/customers/HistoryCustomerList";
import { RouteComponentProps } from "react-router";

const History: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { t } = useTranslation("history");
  const customerId: string = match.params.id.toString();

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle variant="h1">{t("history__title")}</QuidTitle>
        </Grid>
      </Grid>
      <HistoryCustomer customerId={customerId} />
    </MainTemplate>
  );
};

export default History;
