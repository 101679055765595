import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Check } from "entities/tasks/Identity";
import ReactJson from "react-json-view";

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  status: {
    display: "flex",
    justifyContent: "center",
  },
  sectionTitle: {
    textAlign: "center",
    marginTop: 16,
    color: theme.palette.primary.main,
    fontSize: 18,
    marginBottom: 16,
  },
  rows: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailColumn: {
    width: "50%",
  },
  badgeGreen: {
    backgroundColor: "#199619",
    borderRadius: 50,
    width: 8,
    height: 8,
  },
  badgeRed: {
    backgroundColor: "#FF0000",
    borderRadius: 50,
    width: 8,
    height: 8,
  },
  fullWidth: {
    width: "100%",
  },
  textarea: {
    marginTop: 18,
    marginBottom: 22,
  },
  mt24: {
    marginTop: 24,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    padding: 50,
    backgroundColor: "#FAFAFA",
    "& .MuiTextField-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .box": {
      display: "flex",
      flexWrap: "wrap",
      padding: "50px 100px",
      backgroundColor: "#FFF",
      borderRadius: 60,

      "& .MuiFormControlLabel-root, & .MuiTextField-root": {
        width: "100%",
      },

      "& .reject": {
        textAlign: "center",
        marginBottom: 20,
        color: "#FF6F0F",
      },

      "& .actions": {
        minHeight: 50,
        margin: "0 10px",

        "&-reject": {
          backgroundColor: "#FF6F0F",
        },
      },
    },
  },
}));

export interface ApproveRejectKycBoxProps {
  checks: Check<string>[];
  status: "ERROR" | "ACCEPT" | "REJECT";
  reason: string;
  description: string;
}

const ApproveRejectKycBox: React.FC<ApproveRejectKycBoxProps> = ({
  checks,
  status,
  reason,
  description,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("validations");

  const isJson = (str: string) => {
    try {
      str = typeof str !== "string" ? JSON.stringify(str) : str;
      str = JSON.parse(str);
    } catch (e) {
      return false;
    }

    if (typeof str === "object" && str !== null) {
      return true;
    }

    return false;
  };

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid item xs={10} className="box">
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.sectionTitle}>
            {status}
          </Typography>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography variant="h6">{t("kyc_checks")}</Typography>
            {Object.entries(checks)?.map((check) => {
              return (
                <div>
                  {check[1] ? (
                    <div className={classes.rows}>
                      <Typography variant="body1">
                        {t(`approvalChecks.${check[0]}`)}
                      </Typography>
                      <div className={classes.badgeGreen}>&#160;</div>
                    </div>
                  ) : (
                    <div className={classes.rows}>
                      <Typography variant="body1">
                        {t(`approvalChecks.${check[0]}`)}
                      </Typography>
                      <div className={classes.badgeRed}>&#160;</div>
                    </div>
                  )}
                </div>
              );
            })}
          </Grid>
          <Grid item xs={6} className={classes.detailColumn}>
            <Typography variant="h6">{t("kyc_reason")}</Typography>
            <Typography variant="body1">
              {reason === "" ? "--" : reason}
            </Typography>
            <Typography className={classes.mt24} variant="h6">
              {t("kyc_description")}
            </Typography>
            {isJson(description) ? (
              <ReactJson
                style={{ fontSize: 12 }}
                src={JSON.parse(description)}
                collapsed={false}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                quotesOnKeys={false}
              />
            ) : (
              <div>{description}</div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApproveRejectKycBox;
