import React from "react";
import {
  Dialog,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-root": {
      maxWidth: 1000,
      width: "100%",
      padding: "0 50px",
      "& .MuiDialogTitle-root .MuiTypography-root": {
        margin: "25px 0",
        fontSize: 28,
        color: theme.palette.secondary.main,
        textAlign: "center",
      },
    },
  },
  dialogContent: { padding: 50 },
}));

export interface DocumentUploadModalProps {
  title: string;
  isOpen: boolean;
  description: string;
  labelButtonClose: string;
  onCloseModal: () => void;
  labelButtonSubmit: string;
  onSubmit: (values: any) => void;
}

const ConfirmationModal: React.FC<DocumentUploadModalProps> = ({
  title,
  isOpen,
  onSubmit,
  description,
  onCloseModal,
  labelButtonClose,
  labelButtonSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      className={classes.dialogContainer}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="description-confirmation-modal">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>{labelButtonClose}</Button>
        <Button onClick={onSubmit} autoFocus>
          {labelButtonSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
