import {
  getPeopleAddresses,
  downloadPeopleDocument,
  getPeopleDetails,
  getPeopleDocuments,
  getDirectorDetails,
  getUboDetails,
} from "api/people";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { PeopleDocument } from "entities/clients/DocumentEntity";
import {
  PeopleDetails,
  PeopleDocumentResponse,
  AddressResponse,
  DownloadPeopleDocumentParams,
  DirectorDetails,
  UboDetails,
} from "entities/clients/People";
import { Director, Person, Ubo } from "entities/clients/PersonEntity";
import fileDownload from "js-file-download";

export async function getPeopleDetailsByPersonId(
  personId: string
): Promise<PeopleDetails> {
  try {
    const result: [
      Person,
      PeopleDocumentResponse,
      AddressResponse
    ] = await Promise.all([
      getPeopleDetails(personId),
      getPeopleDocuments(personId),
      getPeopleAddresses(personId),
    ]);

    const [person, docResponse, addrResponse]: [
      person: Person,
      docResponse: PeopleDocumentResponse,
      addrResponse: AddressResponse
    ] = result;

    const {
      total: totalDocuments,
      documents,
    }: { total: number; documents: PeopleDocument[] } = docResponse;

    const { addresses, total: totalAddresses } = addrResponse;

    return {
      totalDocuments,
      totalAddresses,
      documents,
      person,
      addresses,
    };
  } catch (e: unknown) {
    throw e;
  }
}

export async function getUboDetailsByPersonId(
  companyId: string,
  personId: string
): Promise<UboDetails> {
  try {
    const result: [
      Ubo,
      PeopleDocumentResponse,
      AddressResponse
    ] = await Promise.all([
      getUboDetails(companyId, personId),
      getPeopleDocuments(personId),
      getPeopleAddresses(personId),
    ]);

    const [ubo, docResponse, addrResponse]: [
      ubo: Ubo,
      docResponse: PeopleDocumentResponse,
      addrResponse: AddressResponse
    ] = result;

    const {
      total: totalDocuments,
      documents,
    }: { total: number; documents: PeopleDocument[] } = docResponse;

    const { addresses, total: totalAddresses } = addrResponse;

    return {
      totalDocuments,
      totalAddresses,
      documents,
      ubo,
      addresses,
    };
  } catch (e: unknown) {
    throw e;
  }
}

export async function getDirectorDetailsByPersonId(
  companyId: string,
  personId: string
): Promise<DirectorDetails> {
  try {
    const result: [
      Director,
      PeopleDocumentResponse,
      AddressResponse
    ] = await Promise.all([
      getDirectorDetails(companyId, personId),
      getPeopleDocuments(personId),
      getPeopleAddresses(personId),
    ]);

    const [director, docResponse, addrResponse]: [
      director: Director,
      docResponse: PeopleDocumentResponse,
      addrResponse: AddressResponse
    ] = result;

    const {
      total: totalDocuments,
      documents,
    }: { total: number; documents: PeopleDocument[] } = docResponse;

    const { addresses, total: totalAddresses } = addrResponse;

    return {
      totalDocuments,
      totalAddresses,
      documents,
      director,
      addresses,
    };
  } catch (e: unknown) {
    throw e;
  }
}

export const downloadPeopleDocumentById = async (
  params: DownloadPeopleDocumentParams
): Promise<FilePayload> => {
  try {
    const res = await downloadPeopleDocument(
      params.personId,
      params.documentId
    );
    fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    return res;
  } catch (e: unknown) {
    throw e;
  }
};
