import { useSelector } from "react-redux";
import { getPartiesCountry } from "store/reducers/app.reducer";
import * as fromDataset from "store/reducers/dataset.reducer";
import { createStyles, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      paddingLeft: "60px",
    },
  })
);

const NationFormatter: React.FC<{ countryId: string }> = ({ countryId }) => {
  const nation = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, countryId)
  );
  const classes = useStyles();

  return <Typography className={classes.center}>{nation}</Typography>;
};

export default NationFormatter;
