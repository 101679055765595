import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import CompanyTask from "components/organisms/customers/CompanyTask";
import CompanyPastTask from "components/organisms/customers/CompanyPastTask";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  pastTask: {
    marginTop: "50px",
  },
}));

const CompanyTaskPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("customer");
  const customerId: number = parseInt(match.params.id, 10);
  const classes = useStyles();

  return (
    <MainTemplate>
      <div>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle className={classes.root}>
              {t("customer__company__title__tasks")}
            </QuidTitle>
          </Grid>
        </Grid>
        <CompanyTask customerId={customerId}></CompanyTask>
      </div>
      <div className={classes.pastTask}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle className={classes.root}>
              {t("customer__company__past__title__tasks")}
            </QuidTitle>
          </Grid>
        </Grid>
        <CompanyPastTask customerId={customerId}></CompanyPastTask>
      </div>
    </MainTemplate>
  );
};

export default CompanyTaskPage;
