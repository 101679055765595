import { makeStyles, Grid, Button } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { uplaodNewBulkInternalPayment } from "api/accounts";
import Loader from "components/atoms/icons/Loader";
import QuidUploadField from "components/atoms/QuidUploadField";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  container: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  uploadContainer: {
    display: "flex",
    rowGap: 24,
    alignItems: "center",
    flexDirection: "column",
  },
}));

const defaultValues: FormValues = {
  document: "",
};

type FormValues = {
  document: "";
};

const PaymentsInternalNewBulk: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("payments");
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<File>();

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onFormSubmit = async () => {
    if (document) {
      setLoading(true);
      try {
        await uplaodNewBulkInternalPayment({ file: document });
        history.push("/payments/bulk");
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <>
        {loading && (
          <div className={classes.downloadFile}>
            <Loader />
          </div>
        )}
      </>
      <MainTemplate>
        <div className={classes.container}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("new__bulk__payments__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className={classes.uploadContainer}>
                <QuidUploadField
                  inputId="bulk-doc"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setDocument(e?.target?.files?.[0])
                  }
                  value={document?.name}
                  label={!document?.name && "Upload document"}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {t("uplaod__file__button")}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </MainTemplate>
    </>
  );
};

export default PaymentsInternalNewBulk;
