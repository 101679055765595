import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { getCommentsModalState } from "store/reducers/app.reducer";
import taskModalsSlice from "store/reducers/taskModals.reducer";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import CrossIcon from "./icons/CrossIcon";
import CommentsAdd from "components/molecules/verifications/CommentsAdd";
import { fetchAttachment } from "api/tasks";
import { Comment } from "entities/tasks/TaskCommentEntity";
import { Attachment } from "entities/clients/Attachment";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    zIndex: 999,
    boxShadow: "-12px 22px 61px -11px rgba(0,0,0,0.75);",
    padding: 14,
    backgroundColor: "#fff",

    "& .MuiOutlinedInput-root": {
      borderRadius: 40,
    },
  },
  uploadingFiles: {
    fontSize: 14,
    color: "#B0B0B0",
  },
  pointer: { cursor: "pointer" },
  removePointerEvts: { pointerEvents: "none" },
  commentsContainer: {
    width: 365,
    maxHeight: 400,
    minHeight: 100,
    overflowY: "scroll",
    padding: 14,
  },
  commentsCloseButtonWithoutComments: {
    width: "auto !important",
    position: "absolute",
    top: 14,
    right: 14,
  },
  commentsCloseButtonWithComments: {
    width: "auto !important",
    minWidth: "auto",
    marginBottom: 12,
    marginLeft: "auto",
    padding: 0,
    marginRight: 0,
    display: "block",
    "& .MuiButton-label": {
      minWidth: "auto",
    },
  },
  commentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 16,

    "& >p:first-child": {
      wordBreak: "break-all",
      fontSize: 16,
    },
  },
  commentBody: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    padding: 20,
  },
  time: {
    fontSize: 10,
    color: "#929292",
  },
  commentText: {
    fontSize: 14,
    color: "#fff",
    overflowWrap: "break-word",
  },
  inputWrapper: {
    backgroundColor: "#fff",
  },
  inputBtn: { height: 40, width: "40px !important", minWidth: 40 },
  rowContainer: { marginBottom: 24 },
}));

interface CommentRowProps {
  comment: Comment;
  idx: number;
  attachments: Attachment[];
  taskKey: Task["taskDefinitionKey"];
  taskId: string;
}

interface TaskCommentsModalProps {
  comments?: Comment[];
  taskKey: Task["taskDefinitionKey"];
  taskId: string;
  refetch: any;
  attachments: Attachment[];
  isHistory: Boolean;
}

const CommentRow: React.FC<CommentRowProps> = ({
  comment,
  idx,
  attachments,
  taskId,
  taskKey,
}) => {
  const classes = useStyles();

  const { fullMessage, username, time } = comment;

  return (
    <div className={classes.rowContainer}>
      <div className={classes.commentHeader}>
        <Typography>{username}</Typography>
        <Typography className={classes.time}>
          {format(new Date(time), "dd/MM/yyyy HH:mm:ss")}
        </Typography>
      </div>
      <div
        className={classes.commentBody}
        style={{ backgroundColor: idx % 2 ? "#B3B3B3" : "#32135A" }}
      >
        <Typography className={classes.commentText}>{fullMessage}</Typography>
      </div>
      {attachments?.map((a) => (
        <AttachmentWithoutPreview
          attachment={a}
          downloadDoc={(params) =>
            fetchAttachment({
              ...params,
              taskId,
              taskKey,
              commentId: a.comment,
              attachmentId: a.id,
            })
          }
        />
      ))}
    </div>
  );
};

const TaskCommentsModal: React.FC<TaskCommentsModalProps> = ({
  comments,
  attachments,
  taskKey,
  taskId,
  refetch,
  isHistory,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const commentsModal = useSelector(getCommentsModalState);

  const close = () => {
    dispatch(taskModalsSlice.actions.closeComments());
  };

  if (!commentsModal) {
    return null;
  }

  return (
    <div
      className={classes.container}
      style={{
        top: commentsModal.y + 60,
        left: commentsModal.x - 200,
        maxWidth: 379,
      }}
    >
      <div className={classes.commentsContainer}>
        {!comments || !comments.length ? (
          <div>
            <Typography>There are no comments yet</Typography>
            <Button
              onClick={close}
              className={classes.commentsCloseButtonWithoutComments}
            >
              <CrossIcon color="black" />
            </Button>
          </div>
        ) : (
          <div>
            <Button
              onClick={close}
              className={classes.commentsCloseButtonWithComments}
            >
              <CrossIcon color="black" />
            </Button>
            {comments?.map((comment: Comment, idx: number) => {
              let hasAttachments = false;
              attachments?.map((attachment: Attachment) => {
                if (comment.id === attachment.comment) {
                  hasAttachments = true;
                }
              });
              if (hasAttachments === false) {
                return (
                  <CommentRow
                    key={idx}
                    idx={idx}
                    comment={comment}
                    taskKey={taskKey}
                    taskId={taskId}
                    attachments={attachments?.filter(
                      (a) => a.comment === comment.id
                    )}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
      {!isHistory && (
        <div className={classes.inputWrapper}>
          <CommentsAdd
            taskKey={taskKey}
            taskId={taskId}
            refetch={refetch}
            hasAttachment={true}
          />
        </div>
      )}
    </div>
  );
};

export default TaskCommentsModal;
