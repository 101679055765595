import React, { useEffect } from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { FxManagementChangeableItem } from "entities/clients/ConversionEntity";
import QuidTextField from "components/atoms/QuidTextField";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { postManagementChangeable } from "api/conversion";

interface ChangeFxModalProps {
  refetch: () => void;
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  data?: FxManagementChangeableItem;
  dataInv?: FxManagementChangeableItem;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  currencyTextCn: {
    marginBottom: 15,
    display: "flex",
    gap: 8,
  },
  rateCn: {
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
  },
  rateEntity: {
    display: "flex",
    gap: 32,
    alignItems: "center",
  },
  formGridCn: {
    display: "flex",
    justifyContent: "center",
  },
  buttonsCn: {
    marginTop: 32,
    display: "flex",
    gap: 32,
    justifyContent: "center",
  },
}));
const ChangeFxModal: React.FC<ChangeFxModalProps> = ({
  setOpen,
  open,
  data,
  dataInv,
  refetch,
}) => {
  const { t } = useTranslation(["payments", "account"]);
  const classes = useStyles();

  const defaultValues = {
    buyRate: 0,
    sellRate: 0,
    buyRateInverted: 0,
    sellRateInverted: 0,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      reset({
        buyRate: data?.clientBuyRate,
        sellRate: data?.clientSellRate,
        buyRateInverted: dataInv?.clientBuyRate,
        sellRateInverted: dataInv?.clientSellRate,
      });
    }

    return (): void => {
      isMounted = false;
    };
  }, [data]);

  const onFormSubmit = async (values: any) => {
    if (data && dataInv && values) {
      values = {
        ...values,
        buyRate: values?.buyRate === "" ? null : (values?.buyRate as number),
        sellRate: values?.sellRate === "" ? null : (values?.sellRate as number),
        buyRateInverted:
          values?.buyRateInverted === ""
            ? null
            : (values?.buyRateInverted as number),
        sellRateInverted:
          values?.sellRateInverted === ""
            ? null
            : (values?.sellRateInverted as number),
      };
      try {
        const params = {
          rateEntity: {
            id: data?.id,
            currencyFrom: data?.clientSellCurrency,
            currencyTo: data?.clientBuyCurrency,
            buyRate: values?.buyRate,
            sellRate: values?.sellRate,
            warningThreshold: 0,
            providerName: "string",
            changeable: true,
          },
          rateEntityInverted: {
            id: dataInv?.id,
            currencyFrom: dataInv?.clientSellCurrency,
            currencyTo: dataInv?.clientBuyCurrency,
            buyRate: values?.buyRateInverted,
            sellRate: values?.sellRateInverted,
            warningThreshold: 0,
            providerName: "string",
            changeable: true,
          },
          sellRate: values?.sellRate,
          buyRate: values?.buyRate,
          sellRateInverted: values?.sellRateInverted,
          buyRateInverted: values?.buyRateInverted,
        };
        await postManagementChangeable(params);
        refetch();
        handleSuccessfulMessage("Action received");
        setOpen(false);
      } catch (err: any) {
        handleFailure(err);
      }
    }
  };

  const resetValues = () => {
    reset({
      sellRate: data?.clientSellRate,
      buyRate: data?.clientBuyRate,
      sellRateInverted: data?.clientBuyRate,
      buyRateInverted: data?.clientSellRate,
    });
  };

  return (
    <FullScreenModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("conversion__details__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          className={classes.formGridCn}
        >
          <Grid item xs={8}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={classes.rateCn}>
                  <div className={classes.rateEntity}>
                    <div className={classes.currencyTextCn}>
                      <div>{data?.clientBuyCurrency}</div>
                      <div>{data?.clientSellCurrency}</div>
                    </div>
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="buyRate"
                      rules={{}}
                      label={t("buy_rate__amount")}
                      defaultValues={defaultValues}
                    />
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="sellRate"
                      rules={{}}
                      label={t("sell_rate__amount")}
                      defaultValues={defaultValues}
                    />
                  </div>
                  <div className={classes.rateEntity}>
                    <div className={classes.currencyTextCn}>
                      <div>{data?.clientSellCurrency}</div>
                      <div>{data?.clientBuyCurrency}</div>
                    </div>
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="buyRateInverted"
                      rules={{}}
                      label={t("buy_rate__amount")}
                      defaultValues={defaultValues}
                    />
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="sellRateInverted"
                      rules={{}}
                      label={t("sell_rate__amount")}
                      defaultValues={defaultValues}
                    />
                  </div>
                </div>
                <div className={classes.buttonsCn}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={resetValues}
                    className="actions actions-approve"
                  >
                    {t("rate_entity__button__reset")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="actions actions-approve"
                  >
                    {t("rate_entity__button__approve")}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </div>
    </FullScreenModal>
  );
};

export default ChangeFxModal;
