/**
 * A common interface for the params that shall be passed over a use case
 */
export interface Params {}

export interface NoParams {}

/**
 * Base use case class.
 */
export abstract class UseCase<P, T> {
  abstract run(params?: P): Promise<T>;
}
