import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ServerFailure } from "features/core/Failure";
import { Button, Grid } from "@material-ui/core";
import MainTemplate from "templates/MainTemplate";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import QuidTitle from "components/atoms/QuidTitle";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import InfoBox from "components/molecules/InfoBox";
import CardDetails from "components/molecules/CardDetails";
import { useHistory } from "react-router";
import clsx from "clsx";
import { fetchCustomerDetails } from "services/customers";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
  actionWrapper: {
    display: "flex",
  },
  usernameWrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontFamily: "Comfortaa,sans-serif",
    color: "#000000",
    fontSize: 28,
    fontWeight: 700,
  },
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
  },
  tabsWrapper: {
    backgroundColor: "#FAFAFA",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  customerLink: {
    textAlign: "right",
    color: theme.palette.primary.main,
    marginBottom: 10,
    textDecoration: "none",
  },
  title: {
    marginTop: 60,
  },
  container: {
    minHeight: "100px",
  },
  verticalAlign: {
    alignItems: "center",
  },
}));

interface Item {
  label: string;
  path: string;
}

const CustomersDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerId: number = +match.params.id;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<CustomerDetails>();

  const [rightSections, setRightSections] = useState<Item[]>([]);
  const [bottomSections, setBottomSections] = useState<Item[]>([]);

  const customerDetailsData = [
    {
      title: t("customer_details_info_network_code"),
      value: details?.customer?.networkCode || "n/a",
    },
    {
      title: t("customer_details_info_network_client_code"),
      value: details?.customer?.networkClientCode || "n/a",
    },
    {
      title: t("customer_details_info_external_id"),
      value: details?.customer?.externalId || "n/a",
    },
    {
      title: t("customer_details_info_registration_ip"),
      value: details?.customer?.registrationIp || "n/a",
    },
    {
      title: t("customer_details_info_account_name"),
      value: details?.customer?.user?.username || "n/a",
    },
    {
      title: t("customer_details_info_email"),
      value: details?.customer?.email || "n/a",
    },
    {
      title: t("customer_details_info_first_name"),
      value: details?.customer?.name || "n/a",
    },
    {
      title: t("customer_details_info_last_name"),
      value: details?.customer?.surname || "n/a",
    },
    {
      title: t("customer_details_info_fiscal_code"),
      value: details?.customer?.fiscalCode || "n/a",
    },
    {
      title: t("customer_details_info_gender"),
      value: details?.customer?.gender || "n/a",
    },
    {
      title: t("customer_details_info_phone_number"),
      value:
        `${details?.customer?.prefix} ${details?.customer?.mobileNumber}` ||
        "n/a",
    },
    {
      title: t("customer_details_info_birth_date"),
      value: formatDate(details?.customer?.birthDate),
    },
    {
      title: t("customer_details_info_country_birthday"),
      value: details?.customer?.countryOfBirth || "n/a",
    },
    {
      title: t("customer_details_info_type"),
      value: details?.customer?.type || "n/a",
    },
    {
      title: t("customer_details_info_receive_news"),
      value: details?.customer?.receiveQuidNews?.toString() || "n/a",
    },
    {
      title: t("customer_details_info_terms_use"),
      value: details?.customer?.termsOfUse || "false",
    },
  ];
  const cardLabelsCustomerQuidColumn = [
    {
      label: t("customer_details_card_products"),
      path: `/customers/${customerId}/products`,
    },
    {
      label: t("customer_details_card_profiles"),
      path: `/customers/${customerId}/profiles`,
    },
    {
      label: t("customer_details_card_linked_companies"),
      path: `/customers/${customerId}/companies`,
    },
  ];
  const cardLabelsCustomerQuidHorizontal = [
    {
      label: t("customer_details_card_history"),
      path: `/customers/${customerId}/history`,
    },
    {
      label: t("customer_details_card_addresses"),
      path: `/customers/${customerId}/addresses`,
    },
    {
      label: t("customer_details_card_task"),
      path: `/customers/${customerId}/pending/task`,
    },
    {
      label: t("customer_details_card_documents"),
      path: `/customers/${customerId}/documents`,
    },
    {
      label: t("customer_details_card_task_process"),
      path: `/customers/${customerId}/taskprocess`,
    },
    {
      label: t("customer_details_card_task_kyc_levels"),
      path: `/customers/${customerId}/kyclevels`,
    },
  ];
  const cardLabelsCustomerToonieColumn = [
    {
      label: t("customer_details_card_products"),
      path: `/customers/${customerId}/products`,
    },
    {
      label: t("customer_details_card_wallets"),
      path: `/customers/${customerId}/wallets`,
    },
    {
      label: t("customer_details_card_profiles"),
      path: `/customers/${customerId}/profiles`,
    },
  ];
  const cardLabelsCustomerToonieHorizontal = [
    {
      label: t("customer_details_card_documents"),
      path: `/customers/${customerId}/documents`,
    },
    {
      label: t("customer_details_card_history"),
      path: `/customers/${customerId}/history`,
    },
    {
      label: t("customer_details_card_addresses"),
      path: `/customers/${customerId}/addresses`,
    },
    {
      label: t("customer_details_card_task"),
      path: `/customers/${customerId}/pending/task`,
    },
    {
      label: t("customer_details_card_financials"),
      path: `/customers/${customerId}/financial`,
    },
    {
      label: t("customer_details_card_offers"),
      path: `/customers/${customerId}/offers`,
    },
    {
      label: t("customer_details_card_account_usage"),
      path: `/customers/${customerId}/accounts`,
    },
    {
      label: t("customer_details_card_task_process"),
      path: `/customers/${customerId}/taskprocess`,
    },
    {
      label: t("customer_details_card_task_kyc_levels"),
      path: `/customers/${customerId}/kyclevels`,
    },
    {
      label: t("customer_details_card_acquiring_payments"),
      path: `/customers/${customerId}/acquiring`,
    },
    {
      label: t("customer_details_card_rewards"),
      path: `/customers/${customerId}/rewards`,
    },
  ];
  const cardLabelsCustomerElviriaColumn = [
    {
      label: t("customer_details_card_products"),
      path: `/customers/${customerId}/products`,
    },
    {
      label: t("customer_details_card_profiles"),
      path: `/customers/${customerId}/profiles`,
    },
    {
      label: t("customer_details_card_companies"),
      path: `/customers/${customerId}/companies`,
    },
  ];
  const cardLabelsCustomerElviriaHorizontal = [
    {
      label: t("customer_details_card_history"),
      path: `/customers/${customerId}/history`,
    },
    {
      label: t("customer_details_card_addresses"),
      path: `/customers/${customerId}/addresses`,
    },
    {
      label: t("customer_details_card_task"),
      path: `/customers/${customerId}/pending/task`,
    },
    {
      label: t("customer_details_card_documents"),
      path: `/customers/${customerId}/documents`,
    },
    {
      label: t("customer_details_card_investments"),
      path: `/customers/${customerId}/investments/info`,
    },
    {
      label: t("customer_details_card_task_process"),
      path: `/customers/${customerId}/taskprocess`,
    },
    {
      label: t("customer_details_card_investments_preferences"),
      path: `/customers/${customerId}/investments/preferences`,
    },
  ];
  const cardLabelsCustomerVaffaColumn = [
    {
      label: t("customer_details_card_products"),
      path: `/customers/${customerId}/products`,
    },
  ];
  const cardLabelsCustomerVaffaHorizontal = [
    {
      label: t("customer_details_card_wallets"),
      path: `/customers/${customerId}/crypto/wallets`,
    },
    {
      label: t("customer_details_card_addresses"),
      path: `/customers/${customerId}/addresses`,
    },
    {
      label: t("customer_details_card_task"),
      path: `/customers/${customerId}/pending/task`,
    },
    {
      label: t("customer_details_card_documents"),
      path: `/customers/${customerId}/documents`,
    },
    {
      label: t("customer_details_card_history"),
      path: `/customers/${customerId}/history`,
    },
    {
      label: t("customer_details_card_profiles"),
      path: `/customers/${customerId}/profiles`,
    },
    {
      label: t("customer_details_card_task_process"),
      path: `/customers/${customerId}/taskprocess`,
    },
    {
      label: t("customer_details_card_linked_companies"),
      path: `/customers/${customerId}/companies`,
    },
  ];

  useEffect(() => {
    if (customerId) {
      void getCustomerDetails(customerId);
    }

    switch (process.env.REACT_APP_PROJECT) {
      case "quid":
        setRightSections(cardLabelsCustomerQuidColumn);
        setBottomSections(cardLabelsCustomerQuidHorizontal);
        break;
      case "toonie":
        setRightSections(cardLabelsCustomerToonieColumn);
        setBottomSections(cardLabelsCustomerToonieHorizontal);
        break;
      case "elviria":
        setRightSections(cardLabelsCustomerElviriaColumn);
        setBottomSections(cardLabelsCustomerElviriaHorizontal);
        break;
      case "strfiinvest":
        setRightSections(cardLabelsCustomerElviriaColumn);
        setBottomSections(cardLabelsCustomerElviriaHorizontal);
        break;
      case "vaffa":
        setRightSections(cardLabelsCustomerVaffaColumn);
        setBottomSections(cardLabelsCustomerVaffaHorizontal);
        break;
      case "ats":
        setRightSections(cardLabelsCustomerVaffaColumn);
        setBottomSections(cardLabelsCustomerVaffaHorizontal);
        break;
    }
  }, [customerId]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const btnMl = clsx(`${classes.buttonMargin}--left`);

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("page__title__customerDetails")}</QuidTitle>
        <Grid container className={(classes.container, classes.verticalAlign)}>
          <Grid item xs={8}>
            <InfoBox
              title={t("customer_details_info_title")}
              items={customerDetailsData}
              customer={details?.customer}
            />
          </Grid>
          <Grid item xs={4}>
            <CardDetails
              isColumn={true}
              items={rightSections}
              from={"customer"}
            ></CardDetails>
          </Grid>
        </Grid>
        <Grid container>
          <CardDetails
            isColumn={false}
            items={bottomSections}
            from={"customer"}
          ></CardDetails>
        </Grid>
        <Grid container>
          <div className={classes.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              className={btnMl}
              disabled={loading}
              onClick={() => history.push(`/users/${customerId}`)}
            >
              {t("customer__page__btn__view__user")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={btnMl}
              disabled={loading}
              onClick={() =>
                history.push({
                  pathname: `/customers/${customerId}/view`,
                  state: { type: "customers" },
                })
              }
            >
              {t("customer__page__btn__edit")}
            </Button>
          </div>
        </Grid>
      </div>
    </MainTemplate>
  );
};

export default CustomersDetails;
