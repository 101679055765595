import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { createProductByProductId, getProducts } from "api/product";
import ArrowIcon from "components/atoms/icons/ArrowIcon";
import QuidTitle from "components/atoms/QuidTitle";
import { useHistory, useRouteMatch } from "react-router";
import {
  ProductDetailResponse,
  ProductsResponse,
} from "entities/products/ProductEntity";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { fetchCustomerDetails } from "services/customers";
import promptsSlice from "store/reducers/prompts.reducer";
import { formatDate } from "utils";
import InfoBox from "components/molecules/InfoBox";
import { Profile } from "entities/clients/ProfileEntity";
import { createProductAccount } from "services/products";
import clsx from "clsx";
import { fetchCompanyDetails } from "services/companies";

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      paddingTop: 15,
      paddingRight: 50,
      paddingLeft: 20,
    },
    buttonMargin: {
      "&--left": {
        marginLeft: 16,
      },
      "&--right": {
        marginRight: 16,
      },
    },
    rejectButton: {
      backgroundColor: "#FF6F0F",
      color: "white",
    },
    approveButton: {
      color: "white",
    },
    "& .actions": {
      minHeight: 50,
      margin: "0 10px",

      "& .reject": {
        backgroundColor: "#FF6F0F",
      },
    },
    actionButtons: {
      marginBottom: 30,
      flexGrow: 3,
      display: "flex",
      alignItems: "center",
      paddingTop: "40px",
      justifyContent: "center",
    },
    blockInside: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      background: "#fff",
      padding: "20px 40px",
      borderRadius: 31,
      boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
      minHeight: "50px",
      justifyContent: "space-between",
      marginLeft: "10px",
    },
    selectedBlockInside: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      background: "#fff",
      padding: "20px 40px",
      borderRadius: 31,
      boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
      minHeight: "50px",
      justifyContent: "space-between",
      marginLeft: "10px",
    },
    unselectedBlockInside: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      background: "#e2e2e2",
      padding: "20px 40px",
      borderRadius: 31,
      boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
      minHeight: "50px",
      justifyContent: "space-between",
      marginLeft: "10px",
    },
    container: {
      paddingBottom: "60px",
      display: "flex",
    },
  })
);
const ProductCreationPage = () => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const [productId, setProductId] = useState<number | undefined>();
  const match: { params: { id: string } } = useRouteMatch();
  const [products, setProducts] = useState<ProductsResponse>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAccount, setIsAccount] = useState(false);
  const partyId = parseInt(match.params.id, 10);
  const [profileId, setProfileId] = useState<number | undefined>();
  const [details, setDetails] = useState<any>();
  const isCustomer = history.location.state;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await getProducts();
        setProducts(res);
      } catch (err: any) {
        handleFailure(err);
      }
    };
    void getCustomerDetails(partyId);
    fetchProducts();
  }, [dispatch]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        if (isCustomer) {
          const customerDetails = await fetchCustomerDetails({
            customerId: id as number,
          });
          setDetails(customerDetails);
        } else {
          const customerDetails = await fetchCompanyDetails({
            companyId: id as number,
          });
          setDetails(customerDetails.company);
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetails]
  );

  const createProduct = async () => {
    try {
      if (productId) {
        if (isAccount && profileId) {
          await createProductAccount(productId, partyId, profileId);
        } else {
          await createProductByProductId(productId, partyId);
        }
        handleSuccessfulMessage("Action received");
        if (isCustomer) {
          history.push(`/customers/${partyId}/`);
        } else {
          history.push(`/companies/${partyId}/`);
        }
      }
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const onProductClick = (productId: number) => {
    setProductId(productId);
    if (productId === 2) {
      setIsAccount(true);
    }
  };

  const customerDetailsData = [
    {
      title: t("customer_details_info_registration_ip"),
      value: details?.customer?.registrationIp || "n/a",
    },
    {
      title: t("customer_details_info_account_name"),
      value: details?.customer?.user?.username || "n/a",
    },
    {
      title: t("customer_details_info_email"),
      value: details?.customer?.email || "n/a",
    },
    {
      title: t("customer_details_info_first_name"),
      value: details?.customer?.name || "n/a",
    },
    {
      title: t("customer_details_info_last_name"),
      value: details?.customer?.surname || "n/a",
    },
    {
      title: t("customer_details_info_phone_number"),
      value:
        `${details?.customer?.prefix} ${details?.customer?.mobileNumber}` ||
        "n/a",
    },
    {
      title: t("customer_details_info_birth_date"),
      value: formatDate(details?.customer?.birthDate),
    },
    {
      title: t("customer_details_info_country_birthday"),
      value: details?.customer?.countryOfBirth || "n/a",
    },
    {
      title: t("customer_details_info_type"),
      value: details?.customer?.type || "n/a",
    },
    {
      title: t("customer_details_info_receive_news"),
      value: details?.customer?.receiveQuidNews?.toString() || "n/a",
    },
    {
      title: t("customer_details_info_terms_use"),
      value: details?.customer?.termsOfUse || "false",
    },
  ];

  const btnMl = clsx(`${classes.buttonMargin}--left`);

  return (
    <MainTemplate>
      <QuidTitle variant="h1">
        {t("customer_details__product_creation_page__title")}
      </QuidTitle>
      <div className={classes.container}>
        <Grid item xs={4}>
          <InfoBox
            title={t("customer_details_info_title")}
            items={customerDetailsData}
            customer={details?.customer}
          />
        </Grid>
        <Grid item xs={4} className={classes.block}>
          {products &&
            products.products?.map((option: ProductDetailResponse) => (
              <div>
                {productId === undefined ? (
                  <div
                    className={classes.block}
                    onClick={() => {
                      onProductClick(option.id);
                    }}
                  >
                    <div className={classes.blockInside}>
                      <Typography variant="body1" color="textSecondary">
                        {option.description}
                      </Typography>
                      <ArrowIcon />
                    </div>
                  </div>
                ) : (
                  <div className={classes.block}>
                    {productId === option.id ? (
                      <div className={classes.selectedBlockInside}>
                        <Typography variant="body1" color="textSecondary">
                          {option.description}
                        </Typography>
                        <ArrowIcon />
                      </div>
                    ) : (
                      <div className={classes.unselectedBlockInside}>
                        <Typography variant="body1" color="textSecondary">
                          {option.description}
                        </Typography>
                        <ArrowIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </Grid>
        <Grid item xs={4} className={classes.block}>
          {productId &&
            productId === 2 &&
            details?.profiles?.map((profile: Profile) => (
              <div
                className={classes.block}
                onClick={() => {
                  setProfileId(profile?.id);
                }}
              >
                <div className={classes.blockInside}>
                  <Typography variant="body1" color="textSecondary">
                    {profile.name}
                  </Typography>
                  <ArrowIcon />
                </div>
              </div>
            ))}
        </Grid>
      </div>
      <Grid item xs={12}>
        {productId && ((productId === 2 && profileId) || productId !== 2) && (
          <div className={classes.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              className={btnMl}
              onClick={() => {
                createProduct();
              }}
            >
              {t("customer_details__product_creation_page__create_button")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={btnMl}
              onClick={() => {
                setProfileId(undefined);
                setIsAccount(false);
                setProductId(undefined);
              }}
            >
              {t("customer_details__product_creation_page__cancel_button")}
            </Button>
          </div>
        )}
      </Grid>
    </MainTemplate>
  );
};

export default ProductCreationPage;
