import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ApproveRejectBox from "components/atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { ColDef } from "@material-ui/data-grid";
import DocumentsProofBlock from "components/molecules/verifications/DocumentsProofBlock";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils";
import VerificationTemplate from "templates/VerificationTemplate";
import jwt_decode from "jwt-decode";
import { acceptTask, rejectTask } from "api/tasks";
import { DocumentsTypes } from "entities/clients/DocumentEntity";
import { GenericObject } from "entities/tasks/Identity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Document } from "entities/clients/DocumentEntity";
import { VerificationRequest } from "entities/tasks/Common";
import {
  acceptDocumentCompanies,
  rejectDocumentCompanies,
} from "api/companies";
import { handleFailure } from "resHandlers";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

const identityVerificationChecks = {
  DOCUMENT_READABLE: false,
  DETAILS_MATCH: false,
  COMPANY_ACTIVE: false,
  DIRECTOR_IDENTIFIED: false,
  UBO_IDENTIFIED: false,
};

export interface CompanyDetails {
  [key: string]: string;
}

const CorporateIdentityVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();
  const { t } = useTranslation("validations");
  const companyDetail = task?.variables?.company;
  const companyAddressDetail = task?.variables?.address;
  const customerDetail = task?.variables?.customer;
  const companyDocumentIncorporationCertificate = task?.documents?.find(
    (d) => d.type === "INCORPORATION_CERTIFICATE"
  );
  const companyDocumentProofOfBusiness = task?.documents?.find(
    (d) => d.type === "PROOF_OF_BUSINESS"
  );
  const companyDocumentOwnershipDocument = task?.documents?.find(
    (d) => d.type === "BUSINESS_OWNERSHIP"
  );
  const documents = [
    companyDocumentIncorporationCertificate,
    companyDocumentProofOfBusiness,
    companyDocumentOwnershipDocument,
  ];
  const companyDirectors = task?.variables?.directors;
  const companyUBOs = task?.variables?.ubos;
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const companyToPrint: CompanyDetails = {
    companyName: companyDetail?.companyName || "n/a",
    legalEntityType: companyDetail?.legalEntityType || "n/a",
    legalEntityValue: companyDetail?.legalEntityValue || "n/a",
    companyNumber: companyDetail?.companyNumber || "n/a",
    vatNumber: companyDetail?.vatNumber || "n/a",
    "CONTACT INFORMATIONS": "",
    prefix: customerDetail?.prefix || "n/a",
    mobileNumber: customerDetail?.mobileNumber || "n/a",
    email: customerDetail?.email || "n/a",
    INCORPORATION: "",
    incorporationDate: companyDetail?.incorporationDate
      ? formatDate(companyDetail.incorporationDate)
      : "n/a",
    countryOfIncorporation: companyDetail?.incorporationCountry || "n/a",
    ADDRESS: "",
    addressLine1: companyAddressDetail?.addressLine1,
    addressLine2: companyAddressDetail?.addressLine2,
    city: companyAddressDetail?.city,
    state: companyAddressDetail?.state || "",
    country: getCountry(companyAddressDetail?.country) || "",
    postalCode: companyAddressDetail?.postalCode,
  };

  const companyDirectorsToPrint = (companyDirector: GenericObject) => ({
    directorId: companyDirector?.person?.id,
    firstName: companyDirector?.person?.name,
    lastName: companyDirector?.person?.surname,
    dateOfBirth: formatDate(companyDirector?.person?.birthDate),
    countryOfBirth: getCountry(companyDirector?.person?.countryOfBirth),
    nationality: getCountry(companyDirector?.person?.nationality),
    ADDRESS: "",
    addressLine1: companyDirector?.address?.addressLine1,
    addressLine2: companyDirector?.address?.addressLine2,
    city: companyDirector?.address?.city,
    state: companyDirector?.address?.state || "",
    country: getCountry(companyDirector?.address?.country) || "",
    postalCode: companyDirector?.address?.postalCode,
  });

  const companyUBOsToPrint = (companyUBO: GenericObject) => ({
    directorId: companyUBO?.person?.id,
    firstName: companyUBO?.person?.name,
    lastName: companyUBO?.person?.surname,
    dateOfBirth: formatDate(companyUBO?.person?.birthDate),
    countryOfBirth: getCountry(companyUBO?.person?.countryOfBirth),
    nationality: getCountry(companyUBO?.person?.nationality),
    ADDRESS: "",
    addressLine1: companyUBO?.address?.addressLine1,
    addressLine2: companyUBO?.address?.addressLine2,
    city: companyUBO?.address?.city,
    state: companyUBO?.address?.state || "",
    country: getCountry(companyUBO?.address?.country) || "",
    postalCode: companyUBO?.address?.postalCode,
  });

  const availableDocuments: DocumentsTypes[] = [
    {
      code: "incorporation_document",
      text: "Incorporation Document",
      doc: companyDocumentIncorporationCertificate,
    },
    {
      code: "proofOfBusiess_document",
      text: "Proof of Business Document",
      doc: companyDocumentProofOfBusiness,
    },
    {
      code: "businessOwnership_document",
      text: "Business Ownership Document",
      doc: companyDocumentOwnershipDocument,
    },
  ];

  const onClickApprove = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      documents?.map((document?: Document) => {
        if (document) {
          acceptDocumentCompanies(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickReject = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      documents?.map((document?: Document) => {
        if (document) {
          rejectDocumentCompanies(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VerificationTemplate
      refetch={fetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {companyDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={companyToPrint}
              label="corporate__details__title"
            />
          </div>
        )}
        {availableDocuments.map(
          (value: DocumentsTypes) =>
            value.doc && (
              <div className={classes.detailsWrapperThird}>
                <DocumentsProofBlock
                  title={value.text}
                  taskKey={taskKey}
                  inputId={value.code}
                  refetch={refetch}
                  doc={value.doc}
                  taskId={taskId}
                  isHistory={isHistory ? true : false}
                  canReplace={isHistory === undefined && isAssignedToUser}
                />
              </div>
            )
        )}
      </div>
      <div className={classes.topContainer}>
        <div className={classes.detailsWrapperFull}>
          <Typography variant="h6" className="section-title-no-margin">
            {t("company__directors__title")}
          </Typography>
        </div>
        {companyDirectors?.map((director: GenericObject, index: number) => (
          <div
            className={classes.detailsWrapperThird}
            key={`cmp-director-${index}`}
          >
            <DetailsBlock
              toPrint={companyDirectorsToPrint(director)}
              label=""
              key={`cmp-director-${index}`}
            />
          </div>
        ))}
      </div>
      <div className={classes.topContainer}>
        <div className={classes.detailsWrapperFull}>
          <Typography variant="h6" className="section-title-no-margin">
            {t("company__UBOs__title")}
          </Typography>
        </div>
        {companyUBOs?.map((ubo: GenericObject, index: number) => (
          <div className={classes.detailsWrapperThird} key={`cmp-ubo-${index}`}>
            <DetailsBlock
              toPrint={companyUBOsToPrint(ubo)}
              label=""
              key={`cmp-ubo-${index}`}
            />
          </div>
        ))}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            checks={identityVerificationChecks}
            task={task}
            accept={(request) => onClickApprove(request)}
            reject={(request) => onClickReject(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default CorporateIdentityVerificationComp;
