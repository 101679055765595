import { Button, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import QuidTitle from "components/atoms/QuidTitle";
import InfoBox from "components/molecules/InfoBox";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { fetchCompanyDetails } from "services/companies";
import { fetchCustomerDetails } from "services/customers";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const useStyles = makeStyles(() => ({
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
}));

const FinancialInfoPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("customer");
  const id: number = +match.params.id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const from = history.location?.state as Object;
  const [details, setDetails] = useState<CustomerDetails | CompanyDetails>();

  const financialDataCustomer = [
    {
      title: t("financial__label__id"),
      value: details?.financialData?.id || "n/a",
    },
    {
      title: t("financial__label__expected__annual_incoming"),
      value: details?.financialData?.expectedAnnualIncoming || "n/a",
    },
    {
      title: t("financial__label__currency"),
      value: details?.financialData?.currency || "n/a",
    },
    {
      title: t("financial__label__funds__src_type__incoming"),
      value: details?.financialData?.fundsSourceType || "n/a",
    },
    {
      title: t("financial__label__funds_src__value"),
      value: details?.financialData?.fundsSourceValue || "n/a",
    },
    {
      title: t("financial__label__employment_status__type"),
      value: details?.financialData?.employmentStatusType || "n/a",
    },
    {
      title: t("financial__label__employment_status__value"),
      value: details?.financialData?.employmentStatusValue || "n/a",
    },
    {
      title: t("financial__label__employment_sector__type"),
      value: details?.financialData?.employmentSectorType || "n/a",
    },
    {
      title: t("financial__label__employment_sector__value"),
      value: details?.financialData?.employmentSectorValue || "n/a",
    },
  ];

  const financialDataCompany = [
    {
      title: t("financial__label__id"),
      value: details?.financialData?.id || "n/a",
    },
    {
      title: t("financial_details_info_company_sector"),
      value: details?.financialData?.companySector || "n/a",
    },
    {
      title: t("financial_details_info_company_sector_description"),
      value: details?.financialData?.companySectorDescription || "n/a",
    },
    {
      title: t("financial_details_info_company_website_url"),
      value: details?.financialData?.companyWebsiteUrl || "n/a",
    },
    {
      title: t("financial_details_info_company_marketing_description"),
      value: details?.financialData?.companyMarketingDescription || "n/a",
    },
    {
      title: t("financial__label__funds__src_type__incoming"),
      value: details?.financialData?.fundsSourceType || "n/a",
    },
    {
      title: t("financial__label__funds_src__value"),
      value: details?.financialData?.fundsSourceValue || "n/a",
    },
  ];

  useEffect(() => {
    if (id && Object.values(from)[0] === "customer") {
      void getCustomerDetails(id);
    }
    if (id && Object.values(from)[0] === "company") {
      void getCompanyDetails(id);
    }
  }, [id]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetails(companyDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const btnMl = clsx(`${classes.buttonMargin}--left`);

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("customer__tab__title__financials")}</QuidTitle>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        {Object.values(from)[0] === "customer" && (
          <InfoBox
            title={t("customer_details_info_title")}
            items={financialDataCustomer}
          />
        )}
        {Object.values(from)[0] === "company" && (
          <InfoBox
            title={t("customer_details_info_title")}
            items={financialDataCompany}
          />
        )}
      </Grid>
      <Grid container>
        <div className={classes.actionButtons}>
          <Button
            variant="contained"
            color="primary"
            className={btnMl}
            disabled={loading}
            onClick={() =>
              history.push({
                pathname: `/${
                  Object.values(from)[0] === "company"
                    ? "companies"
                    : "customers"
                }/${id}/financial/view`,
                state: {
                  type: `${Object.values(from)[0]}`,
                },
              })
            }
          >
            {t("customer__page__btn__edit")}
          </Button>
        </div>
      </Grid>
    </MainTemplate>
  );
};

export default FinancialInfoPage;
