import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import ApproveRejectKycIndividualBox from "components/atoms/ApproveRejectKyIndividualBox";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { acceptTask, rejectTask } from "api/tasks";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import VerificationTemplate from "templates/VerificationTemplate";
import { formatDate } from "utils";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import { Document } from "entities/clients/DocumentEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

export interface IdentityDetails {
  [key: string]: string;
}

const PeopleEKycVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();

  const taskVariables = task?.variables;
  const personDetail = taskVariables?.person;
  const addressDetail = taskVariables?.address;
  const identityDocs = task?.variables?.identityDocuments?.filter(
    (d: any) => d.type === "ID_DOCUMENT"
  );
  const proofOfAddressDocument = task?.variables?.addressDocuments?.find(
    (d: any) => d.type === "PROOF_OF_ADDRESS"
  );
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const toPrintPerson: IdentityDetails = {
    personId: personDetail?.id,
    personName: personDetail?.name,
    personSurname: personDetail?.surname,
    personFiscalCode: personDetail?.fiscalCode || "n/a",
    personGender: personDetail?.gender || "n/a",
    personBirthDate: personDetail?.birthDate
      ? formatDate(personDetail.birthDate)
      : "n/a",
    personCountryOfBirth: getCountry(personDetail?.countryOfBirth) || "",
    personNationality: getCountry(personDetail?.nationality) || "",
    personType: taskVariables?.personType,
  };

  const toPrintAddress: IdentityDetails = {
    addressLine1: addressDetail?.addressLine1,
    addressLine2: addressDetail?.addressLine2 || "--",
    city: addressDetail?.city,
    country: getCountry(addressDetail?.country) || "",
    postalCode: addressDetail?.postalCode,
  };

  if (!task) {
    return null;
  }

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {personDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintPerson}
              label="person_details__title"
            />
          </div>
        )}
        {addressDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintAddress}
              label="addressDetails__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          <DetailsBlock label="document__proofOfAddressPreview__title" />
          {proofOfAddressDocument && (
            <div>
              <AttachmentWithoutPreview
                attachment={proofOfAddressDocument}
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
              />
              <br />
            </div>
          )}
          <DetailsBlock label="document__proofOfIdentity__title" />
          {identityDocs?.length &&
            identityDocs.map((iDoc: Document) => (
              <div>
                <AttachmentWithoutPreview
                  attachment={iDoc}
                  downloadDoc={(params) =>
                    isHistory === undefined
                      ? fetchDocument({ ...params, taskId, taskKey })
                      : fetchHistoricalDocument({ ...params, taskId, taskKey })
                  }
                />
                <br />
              </div>
            ))}
        </div>
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectKycIndividualBox
            taskKey={taskKey}
            task={task}
            accept={(request) => handleApproveTask(request)}
            reject={(request) => handleRejectTask(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default PeopleEKycVerificationComp;
