import { Serializer } from "./Serializer";

/**
 * Regular Expression for date patterns
 * Example of matching pattern: 1979-10-15T00:39:55Z
 */
const dateRegexp = new RegExp(
  "^(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:(\\d{2}|(\\d{2}.\\d{1,3}))Z)$"
);
// for search millisecond in date
// const millisecondRegexp = new RegExp("[.\\d]{0,4}[Z]")

export class JsonSerializer implements Serializer {
  /**
   * From JS object to JSON map
   *
   * @param object
   */
  serialize(object: any): any {
    if (object === undefined || object === null) {
      return object;
    } else if (object instanceof Date) {
      return this.serializeDate(object);
    } else if (object instanceof Array) {
      return object.map((o) => this.serialize(o));
    } else if (object instanceof Object) {
      for (const key of Object.keys(object)) {
        object[key] = this.serialize(object[key]);
      }
    }
    return object;
  }

  /**
   * From JSON map to JS object
   *
   * @param object
   */
  deserialize(object: any): any {
    if (object === undefined || object === null) {
      return object;
    } else if (typeof object === "string" && dateRegexp.test(object)) {
      return this.deserializeDate(object);
    } else if (object instanceof Array) {
      return object.map((o) => this.deserialize(o));
    } else if (object instanceof Object) {
      for (const key of Object.keys(object)) {
        object[key] = this.deserialize(object[key]);
      }
    }
    return object;
  }

  protected serializeDate(date: Date): string {
    return date.toISOString();
  }

  protected deserializeDate(str: string): Date {
    return new Date(str);
  }
}
