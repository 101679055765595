import {
  fetchOpportunitiesStatus,
  fetchInvestmentsStatus,
} from "api/investmentsDatasets";
import { InvestmentsDatasets } from "entities/investments/InvestmentsDatasets";

export async function fetchAllInvestmentsDatasets(): Promise<InvestmentsDatasets> {
  try {
    const res = await Promise.all<any>([
      fetchOpportunitiesStatus(),
      fetchInvestmentsStatus(),
    ]);

    const mapRes = res.map(({ values }) => values);

    const [opportunitiesStatus, investmentsStatus] = mapRes;

    return {
      opportunitiesStatus,
      investmentsStatus,
    };
  } catch (e) {
    throw e;
  }
}
