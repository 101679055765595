import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { TFunction } from "i18next";
import { useState } from "react";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";

type useDocHandlersParams = {
  t: TFunction;
  entity: { id: string | number; propName: string };
  refetchEntity: (id: string | number) => Promise<void>;
  download: (params: any) => Promise<FilePayload>;
  accept: (params: any) => Promise<void>;
  reject: (params: any) => Promise<void>;
  upload: any;
};

export interface DocumentUploadParams {
  id: number;
  type: string;
  subType: string;
  document: File;
}

const useDocHandlers = ({
  t,
  entity,
  refetchEntity,
  download,
  accept,
  reject,
  upload,
}: useDocHandlersParams) => {
  const [documentLoading, setDocumentLoading] = useState(false);

  const onDocAccept = async (documentId: number) => {
    setDocumentLoading(true);
    try {
      await accept({ [entity.propName]: entity.id, documentId });
      handleSuccessfulMessage(t("document__accept__successful__message"));
      void refetchEntity(entity.id);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setDocumentLoading(false);
    }
  };

  const onDocReject = async (documentId: number) => {
    setDocumentLoading(true);
    try {
      await reject({ [entity.propName]: entity.id, documentId });
      handleSuccessfulMessage(t("document__reject__successful__message"));
      void refetchEntity(entity.id);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setDocumentLoading(false);
    }
  };

  const onDocDownload = async (documentId: number, fileName: string) => {
    setDocumentLoading(true);
    try {
      await download({
        [entity.propName]: entity.id,
        documentId,
        fileName,
      });

      handleSuccessfulMessage(t("document__download__successful__message"));
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setDocumentLoading(false);
    }
  };

  const onDocUpload = async (docUploadParams: DocumentUploadParams) => {
    setDocumentLoading(true);

    try {
      await upload({
        id: entity.id,
        body: { document: docUploadParams.document },
        type: docUploadParams.type,
        subType: docUploadParams.subType,
      });

      handleSuccessfulMessage(t("document__upload__successful__message"));
      void refetchEntity(entity.id);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setDocumentLoading(false);
    }
  };

  return {
    documentLoading,
    onDocAccept,
    onDocDownload,
    onDocReject,
    onDocUpload,
  };
};

export default useDocHandlers;
