import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import {
  OpportunityListParams,
  OpportunityListResponse,
  OpportunityDetailsParams,
  OpportunityDetailsResponse,
  Opportunity,
  OpportunityImageDetailsParams,
  UpdateVerificationsParams,
  UpdateInfoParams,
  UpdateDataFileVerificationsParams,
  FileTypessResponse,
  UpdateAPYParams,
} from "entities/opportunities/opportunityEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function fetchOpportunities(
  params: OpportunityListParams
): Promise<OpportunityListResponse> {
  try {
    const res = await apiClient.get<OpportunityListResponse>(
      `/investments/v1/opportunities`,
      "company",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
        name: params.name,
        status: params.status,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOpportunityById(
  params: OpportunityDetailsParams
): Promise<OpportunityDetailsResponse> {
  try {
    const res = await apiClient.get<OpportunityDetailsResponse>(
      `/investments/v1/opportunities/${params.id}`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createOpportunity(params: Opportunity): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities`,
      "account",
      { ...params }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function approveOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/approve`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function closeOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/close`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function endOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/end`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function hideOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/hide`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function cancelOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/cancel`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function publishOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/publish`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function activateOpportunity(id: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/opportunities/${id}/active`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOpportunityImageByImageId(
  params: OpportunityImageDetailsParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/investments/v1/opportunities/images/${params.imageId}`,
      "account",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOpportunityLogoByLogoId(
  params: OpportunityImageDetailsParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/investments/v1/opportunities/images/${params.imageId}`,
      "account",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchOpportunityFileByFileId(
  fileId: number
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/investments/v1/opportunities/datafiles/${fileId}`,
      "investments",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateImage(
  params: UpdateVerificationsParams
): Promise<any> {
  try {
    const { request, opportunityId } = params;
    await apiClient.post<void>(
      `/investments/v1/opportunities/${opportunityId}/image`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function deleateDataFileByDataFileId(
  fileId: number
): Promise<any> {
  try {
    await apiClient.delete<void>(
      `/investments/v1/opportunities/datafiles/${fileId}`,
      "workflow"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateLogo(
  params: UpdateVerificationsParams
): Promise<any> {
  try {
    const { request, opportunityId } = params;
    await apiClient.post<void>(
      `/investments/v1/opportunities/${opportunityId}/logo`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateMediImage(
  params: UpdateVerificationsParams
): Promise<any> {
  try {
    const { request, opportunityId } = params;
    await apiClient.post<void>(
      `/investments/v1/opportunities/${opportunityId}/medimage`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateDataFile(
  params: UpdateDataFileVerificationsParams
): Promise<any> {
  try {
    const { request, opportunityId, dataFileType } = params;
    await apiClient.post<void>(
      `/investments/v1/opportunities/${opportunityId}/datafiles`,
      "workflow",
      { fileCategory: dataFileType, datafile: request?.datafile },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function updateOpportunity(
  params: UpdateInfoParams
): Promise<void> {
  try {
    const { request, opportunityId } = params;
    await apiClient.put(
      `/investments/v1/opportunities/${opportunityId}`,
      "investments",
      request
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateOpportunityAPY(
  params: UpdateAPYParams
): Promise<void> {
  try {
    const { apy, opportunityId } = params;
    await apiClient.put(
      `/investments/v1/management/apy/${opportunityId}?apy=${apy}`,
      "investments"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchFileTypesEnums(): Promise<FileTypessResponse> {
  try {
    const res = await apiClient.get<FileTypessResponse>(
      `/investments/v1/referenceData`,
      "crypto"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}
