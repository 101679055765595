import { ServerFailure } from "features/core/Failure";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllDatasets as fetchAllPartiesDatasets } from "services/enums";
import promptsSlice from "../reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { fetchAllAccountDatasets } from "services/accountDatasets";
import { fetchAllInvestmentsDatasets } from "services/investmentsDatasets";

export const fetchAccountDatasets = createAsyncThunk(
  "dataset/fetchAllAccountDataset",
  async (_, { dispatch }) => {
    try {
      const datasets = await fetchAllAccountDatasets();
      return datasets;
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
      throw err;
    }
  }
);

export const fetchPartiesDatasets = createAsyncThunk(
  "dataset/fetchAllPartiesDataset",
  async (_, { dispatch }) => {
    try {
      const dataset = await fetchAllPartiesDatasets();
      return dataset;
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
      throw err;
    }
  }
);

export const fetchInvestmentsDatasets = createAsyncThunk(
  "dataset/fetchAllInvestmentsDataset",
  async (_, { dispatch }) => {
    try {
      const dataset = await fetchAllInvestmentsDatasets();
      return dataset;
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
      throw err;
    }
  }
);
