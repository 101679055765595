import React, { ChangeEvent, useState, useEffect } from "react";
import {
  makeStyles,
  MenuItem,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import QuidTextField from "components/atoms/QuidTextField";
import { NetworkFailure } from "features/core/NetworkFailure";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { ServerFailure } from "../../features/core/Failure";
import {
  getPartiesCountries,
  getPartiesDocumentTypes,
} from "store/reducers/app.reducer";
import QuidUploadField from "./QuidUploadField";
import FullScreenModal from "components/atoms/FullScreenModal";
import { fetchDocumentSubTypesByType } from "api/enums";
import { Address } from "entities/clients/AddressEntity";
import { Country } from "entities/clients/CountryEntity";
import { DocumentSubType, DocumentType } from "entities/clients/DocumentEntity";
import { Profile } from "entities/clients/ProfileEntity";

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

type FormValues = {
  type: string;
  subType: string;
  country?: string;
};

const defaultValues: FormValues = {
  type: "",
  subType: "",
  country: "",
};

export interface DocumentUploadModalProps {
  open: boolean;
  onUpload: CallableFunction;
  onCloseModal: () => void;
}

export interface CreateAddressModalProps {
  open: boolean;
  onCloseModal: (open: boolean) => void;
  customerProfiles?: Profile[];
  createAddress: (
    address: Address,
    uses: string,
    partyId?: number
  ) => Promise<void>;
  refetch: () => void;
  onUpload: CallableFunction;
}

const DocumentUploadModal: React.FC<DocumentUploadModalProps> = ({
  open,
  onUpload,
  onCloseModal,
}) => {
  const { t } = useTranslation("document");
  const dispatch = useDispatch();
  const classes = useStyles();

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { formState, handleSubmit, reset } = methods;
  const docTypes: Array<DocumentType> = useSelector(getPartiesDocumentTypes);
  const [documentType, setDocumentType] = useState("");
  const [isCountryVisible, setIsCountryVisible] = useState(false);
  const [documentSubTypes, setDocumentSubTypes] = useState(
    [] as DocumentSubType[]
  );
  const countries = useSelector(getPartiesCountries);
  const [document, setDocument] = useState<File | "missing">();
  const documentIsMissing = document === "missing";

  const docValue = !documentIsMissing
    ? (document as File)?.name?.split(/(\\|\/)/g)?.pop()
    : "";

  if (formState?.isSubmitted && !document) {
    setDocument("missing");
  }

  useEffect(() => {
    const getDocumentSubTypes = async (): Promise<void> => {
      if (documentType === "") {
        return;
      }
      try {
        const documentSubTypes = await fetchDocumentSubTypesByType(
          documentType
        );
        setDocumentSubTypes(documentSubTypes?.enumValues);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };

    void getDocumentSubTypes();
  }, [documentType]);

  const onFormSubmit = async (values: FormValues) => {
    if (!document) {
      setDocument("missing");
      return;
    }

    await onUpload({
      document,
      type: values.type,
      subType: values.subType,
      country: values.country,
    });

    reset(defaultValues);
    setDocument(undefined);
    onCloseModal();
  };

  return (
    <FullScreenModal open={open} onClose={() => onCloseModal()}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt56}>
            <Typography variant="h1" className={classes.title}>
              {t("document__upload__title")}
            </Typography>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <QuidTextField
              name="type"
              rules={{
                required: t("document__upload__type__required") as string,
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setDocumentType(e?.target?.value);
                if (
                  e?.target?.value === "BUSINESS_OWNERSHIP" ||
                  e?.target?.value === "SELFIE" ||
                  e?.target?.value === "CERTIFICATE_OWNERSHIP" ||
                  e?.target?.value === "PROOF_OF_BUSINESS" ||
                  e?.target?.value === "ACCOUNT_USAGE"
                ) {
                  setIsCountryVisible(false);
                } else {
                  setIsCountryVisible(true);
                }
                methods.setValue("subType", "");
              }}
              textFieldProps={{
                select: true,
                fullWidth: true,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              label={t("document__upload__type")}
              defaultValues={defaultValues}
            >
              <MenuItem value="">{t("document__upload__type")}</MenuItem>
              {docTypes.map((docType: DocumentType) => (
                <MenuItem key={docType.code} value={docType.code}>
                  {docType.text}
                </MenuItem>
              ))}
            </QuidTextField>
            <QuidTextField
              name="subType"
              disabled={documentType === "" ? true : false}
              rules={{
                required: t("document__upload__subtype__required") as string,
              }}
              textFieldProps={{
                select: true,
                fullWidth: true,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              label={t("document__upload__subtype")}
              defaultValues={defaultValues}
            >
              <MenuItem value="">{t("document__upload__subtype")}</MenuItem>
              {documentSubTypes?.map((docSubType: DocumentSubType) => (
                <MenuItem key={docSubType.code} value={docSubType.code}>
                  {docSubType.text}
                </MenuItem>
              ))}
            </QuidTextField>
            {isCountryVisible && (
              <QuidTextField
                name="country"
                rules={{}}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                label={t("document__upload__country")}
                defaultValues={defaultValues}
              >
                <MenuItem value="">{t("document__upload__country")}</MenuItem>
                {countries.map((country: Country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.text}
                  </MenuItem>
                ))}
              </QuidTextField>
            )}
            <QuidUploadField
              inputId="doc-upload"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDocument(e?.target?.files?.[0])
              }
              value={documentIsMissing ? "" : docValue}
              label={t("document__upload__label")}
              error={documentIsMissing}
              helperText={documentIsMissing ? "Document required" : null}
            />
            <div className={classes.buttonsWrapper}>
              <Button
                variant="contained"
                color="primary"
                className="actions actions-approve"
                onClick={methods.handleSubmit(onFormSubmit)}
              >
                upload
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </FullScreenModal>
  );
};

export default DocumentUploadModal;
