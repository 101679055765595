import React, { useEffect } from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { DetailsNews } from "entities/news/NewsEntity";
import { format } from "date-fns";
import QuidTextareaField from "components/atoms/QuidTextareaField";

const useStyles = makeStyles((theme) => ({
  gridContainerInfo: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,

    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },
}));

type FormValues = {
  date: string | Date;
  title: string;
  subTitle: string;
  body: string;
  opportunityId?: number | null;
  url?: string;
};

export interface NewsDetailsProps {
  news?: DetailsNews;
  mode: string;
  onFormSubmit: any;
  isLoading: boolean;
}

const NewsCreateOrEdit: React.FC<NewsDetailsProps> = ({
  news,
  mode,
  onFormSubmit,
  isLoading,
}: NewsDetailsProps) => {
  const { t } = useTranslation("news");
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultValues: FormValues = {
    date: news?.date || "",
    title: news?.title || "",
    subTitle: news?.subTitle || "",
    body: news?.body || "",
    opportunityId: news?.opportunityId || null,
    url: news?.url || "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = (values: FormValues) => {
    if (values) {
      return onFormSubmit({
        ...values,
        date: new Date(values?.date),
      });
    }
  };

  useEffect(() => {
    if (mode === "update") {
      resetValues();
    }
  }, [dispatch, news]);

  const resetValues = () => {
    let isMounted = true;

    if (isMounted) {
      reset({
        date:
          news?.date instanceof Date
            ? format(news?.date, "yyyy-MM-dd")
            : (news?.date as string),
        title: news?.title,
        subTitle: news?.subTitle,
        body: news?.body,
        opportunityId: news?.opportunityId ?? null,
        url: news?.url ?? null,
      } as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
            direction="row"
            className={classes.gridContainerInfo}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("news__section_title__genaral_info")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="title"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__news_title__required") as string,
                }}
                label={t("details__label__news_title")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="subTitle"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__news_subtitle_required"
                  ) as string,
                }}
                label={t("details__label__news_subtitle")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={8}>
              <QuidTextareaField
                name="body"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__news_body_required") as string,
                }}
                rows="4"
                label={t("details__label__news_body")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("news__section_title__other_info")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="date"
                type="date"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__news_date_required") as string,
                }}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="url"
                type="string"
                textFieldProps={{
                  fullWidth: true,
                }}
                defaultValues={defaultValues}
                label={t("details__label__news_url")}
                rules={{}}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="opportunityId"
                label={t("news__opportunity_id")}
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.updateBtn}
              variant="contained"
              color="primary"
              disabled={isLoading}
              type="submit"
            >
              {t("label_button__save")}
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default NewsCreateOrEdit;
