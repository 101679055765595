import { combineReducers } from "@reduxjs/toolkit";
import datasetSlice, * as fromDataset from "./dataset.reducer";
import { reqStatus } from "shared/entities/reqStatus";
import authSlice, * as fromAuth from "./authentication.reducer";
import tasksSlice, * as fromTasks from "./tasks.reducer";
import promptsSlice, * as fromPrompts from "./prompts.reducer";
import taskModalsSlice, * as fromTaskModals from "./taskModals.reducer";
import { AddressType, AddressUse } from "entities/clients/AddressEntity";
import { CompanyType } from "entities/clients/CompanyEntity";
import { Country } from "entities/clients/CountryEntity";
import { DocumentType, DocumentSubType } from "entities/clients/DocumentEntity";
import {
  FinancialEmploymentType,
  FinancialEmploymentSector,
  FinancialSourceType,
  FinancialCompanySourceType,
} from "entities/clients/FinancialEntity";
import { PersonTitle } from "entities/clients/PersonEntity";
import { GenericObject } from "entities/tasks/Identity";
import { Task } from "entities/tasks/TaskEntity";
import { Currency } from "entities/clients/CurrencyEntity";

const appReducer = combineReducers({
  auth: authSlice.reducer,
  tasks: tasksSlice.reducer,
  dataset: datasetSlice.reducer,
  prompts: promptsSlice.reducer,
  taskModals: taskModalsSlice.reducer,
});

export default appReducer;

// auth
export const getIsLoggedIn = (state: {
  auth: fromAuth.authSliceState;
}): boolean => fromAuth.getIsLoggedIn(state.auth);

// taskmodals

export const getCommentsModalState = (state: {
  taskModals: fromTaskModals.taskModalsSliceState;
}) => fromTaskModals.getCommentsModalState(state.taskModals);
export const getAttachmentModalState = (state: {
  taskModals: fromTaskModals.taskModalsSliceState;
}) => fromTaskModals.getAttachmentModalState(state.taskModals);

// tasks

export const getTasks = (
  state: GenericObject,
  taskKey: fromTasks.TaskKey
): Task[] => fromTasks.getTasks(state.tasks, taskKey);

export const getTasksTotal = (
  state: GenericObject,
  taskKey: fromTasks.TaskKey
): number => fromTasks.getTasksTotal(state.tasks, taskKey);

export const getTaskRequestStatus = (
  state: GenericObject,
  taskKey: fromTasks.TaskKey
): reqStatus => fromTasks.getTaskRequestStatus(state.tasks, taskKey);

// prompts
export const getDialogOpen = (state: {
  prompts: fromPrompts.promptsSliceState;
}): boolean => fromPrompts.getModalOpen(state.prompts);

export const getModalState = (state: {
  prompts: fromPrompts.promptsSliceState;
}): fromPrompts.ModalState => fromPrompts.getModalState(state.prompts);

export const getSnackbarState = (state: {
  prompts: fromPrompts.promptsSliceState;
}): fromPrompts.SnackbarState => fromPrompts.getSnackbarState(state.prompts);

// Enums

export const getPartiesCountries = (state: {
  dataset: fromDataset.DatasetSliceState;
}): Country[] => fromDataset.getPartiesCountries(state.dataset);

export const getPartiesCountry = (
  state: {
    dataset: fromDataset.DatasetSliceState;
  },
  countryId: string
): string | undefined =>
  fromDataset
    .getPartiesCountries(state.dataset)
    ?.find((c) => c.code === countryId)?.text;

export const getPartiesCurrencies = (state: {
  dataset: fromDataset.DatasetSliceState;
}): Currency[] => fromDataset.getPartiesCurrencies(state.dataset);

export const getPartiesFinancialEmploymentTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): FinancialEmploymentType[] =>
  fromDataset.getPartiesFinancialEmploymentTypes(state.dataset);

export const getPartiesFinancialEmploymentSectors = (state: {
  dataset: fromDataset.DatasetSliceState;
}): FinancialEmploymentSector[] =>
  fromDataset.getPartiesFinancialEmploymentSectors(state.dataset);

export const getPartiesFinancialSourceTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): FinancialSourceType[] =>
  fromDataset.getPartiesFinancialSourceTypes(state.dataset);

export const getPartiesFinancialCompanySourceTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): FinancialCompanySourceType[] =>
  fromDataset.getPartiesFinancialCompanySourceTypes(state.dataset);

export const getPartiesAddressTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): AddressType[] => fromDataset.getPartiesAddressTypes(state.dataset);

export const getCompanyTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): CompanyType[] => fromDataset.getCompanyTypes(state.dataset);

export const getPartiesAddressUses = (state: {
  dataset: fromDataset.DatasetSliceState;
}): AddressUse[] => fromDataset.getPartiesAddressUses(state.dataset);

export const getPartiesDocumentTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): DocumentType[] => fromDataset.getPartiesDocumentTypes(state.dataset);

export const getPartiesDocumentSubTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}): DocumentSubType[] => fromDataset.getPartiesDocumentSubTypes(state.dataset);

export const getPartiesPersonTitles = (state: {
  dataset: fromDataset.DatasetSliceState;
}): PersonTitle[] => fromDataset.getPartiesPersonTitles(state.dataset);

export const getAccountsBankAccountTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsBankAccountTypes(state.dataset);

export const getAccountsIdentificationTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsIdentificationTypes(state.dataset);

export const getAccountsPaymentStatus = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsPaymentStatus(state.dataset);

export const getAccountsTransactionStatus = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsTransactionStatus(state.dataset);

export const getAccountTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountTypes(state.dataset);

export const getAccountsTransactionTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsTransactionTypes(state.dataset);

export const getAccountsTransactionInboundTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsTransactionInboundTypes(state.dataset);

export const getAccountsTransactionOutboundTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsTransactionOutboundTypes(state.dataset);

export const getAccountsTransactionSubtypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsTransactionSubtypes(state.dataset);

export const getAccountsTransactionDirection = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsTransactionDirection(state.dataset);

export const getAccountsPaymentTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsPaymentTypes(state.dataset);

export const getAccountsCurrencies = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsCurrencies(state.dataset);

export const getAccountsCountries = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsCountries(state.dataset);

export const getAccountsLegalEntities = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsLegalEntities(state.dataset);

export const getAccountStatus = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountStatus(state.dataset);

export const getAccountsRoutingCodeTypes = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getAccountsRoutingCodeTypes(state.dataset);

export const getInvestmentsInvestmentsStatus = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getInvestmentsInvestmentsStatus(state.dataset);

export const getInvestmentsOpportunitiesStatus = (state: {
  dataset: fromDataset.DatasetSliceState;
}) => fromDataset.getInvestmentsOpportunitiesStatus(state.dataset);
