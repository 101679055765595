/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { authRepo } from "shared/singletons";
import { AuthState } from "features/authentication/domain/usecases/GetAuthenticationState";
import { createSlice } from "@reduxjs/toolkit";

export const defaultState: authSliceState = {
  authState: authRepo.loggedIn ? AuthState.LOGGED_IN : AuthState.LOGGED_OUT,
};

export type authSliceState = { authState: AuthState };

const authSlice = createSlice({
  name: "auth",
  initialState: defaultState,
  reducers: {
    refreshAuthState: (state, { payload }) => ({
      ...state,
      authState: payload,
    }),
  },
  extraReducers: {
    "auth/authenticate/fulfilled": (state, { payload }) => {
      return {
        ...state,
        authState:
          payload?.failure !== undefined
            ? AuthState.LOGGED_OUT
            : AuthState.LOGGED_IN,
      };
    },
    "auth/logout/fulfilled": (state, { payload }) => ({
      ...state,
      authState:
        payload?.failure !== undefined
          ? AuthState.LOGGED_IN
          : AuthState.LOGGED_OUT,
    }),
  },
});

export const getIsLoggedIn = (state: authSliceState): boolean =>
  state.authState === AuthState.LOGGED_IN;

export default authSlice;
