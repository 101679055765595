import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AccountInfo } from "entities/accounts/Account";
import React from "react";
import { useTranslation } from "react-i18next";

interface AccountDetailModalProps {
  accounts: AccountInfo[];
  accountId?: number;
  onCloseModal: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-root": {
      maxWidth: 1000,
      width: "100%",
      padding: "0 50px",
      "& .MuiDialogTitle-root .MuiTypography-root": {
        margin: "25px 0",
        fontSize: 28,
        color: theme.palette.secondary.main,
        textAlign: "center",
      },
    },
  },
  dialogContent: { padding: 50 },
}));

const AccountDetailModal: React.FC<AccountDetailModalProps> = ({
  onCloseModal,
  accountId,
  accounts,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("customer");

  if (!accountId) {
    return null;
  }

  const currAccount = accounts.find((a) => a.id === accountId);

  if (!currAccount) {
    return null;
  }

  return (
    <Dialog
      open={!!accountId}
      onClose={onCloseModal}
      className={classes.dialogContainer}
    >
      <DialogTitle>{t("account__detail__title")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>ID: {currAccount.id}</Typography>
        <Typography>
          {t("accounts__header_name__account_purpose")}:{" "}
          {currAccount.accountPurpose}
        </Typography>
        <Typography>
          {t("accounts__header_name_payment_origin")}:{" "}
          {currAccount.paymentOrigin}
        </Typography>
        <Typography>
          {t("accounts__header_name__payment_destination")}:{" "}
          {currAccount.paymentDestination}
        </Typography>
        <Typography>
          {t("accounts__header_name__payment_number__incoming_per_month")}:{" "}
          {currAccount.paymentNumberIncomingPerMonth}
        </Typography>
        <Typography>
          {t("accounts__header_name__payment_volume__incoming_per_month")}:{" "}
          {currAccount.paymentVolumeIncomingPerMonth}
        </Typography>
        <Typography>
          {t("accounts__header_name__payment_number__outcoming_per_month")}:{" "}
          {currAccount.paymentNumberOutcomingPerMonth}
        </Typography>
        <Typography>
          {t("accounts__header_name__payment_volume__outcoming_per_month")}:{" "}
          {currAccount.paymentVolumeOutcomingPerMonth}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AccountDetailModal;
