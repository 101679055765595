export interface DownloadIconProps {
  color: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M15 15H1C0.447715 15 0 15.4477 0 16C0 16.5523 0.447715 17 1 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15Z"
        fill={color}
      />
      <path
        d="M0 14L0 16C0 16.5523 0.447715 17 1 17C1.55228 17 2 16.5523 2 16V14C2 13.4477 1.55228 13 1 13C0.447715 13 0 13.4477 0 14Z"
        fill={color}
      />
      <path
        d="M14 14V16C14 16.5523 14.4477 17 15 17C15.5523 17 16 16.5523 16 16V14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14Z"
        fill={color}
      />
      <path
        d="M8.00044 12C7.79312 12.0016 7.59044 11.9387 7.42044 11.82L3.42044 9C3.20485 8.84707 3.05858 8.615 3.01361 8.35453C2.96864 8.09406 3.02862 7.82638 3.18044 7.61C3.25623 7.50185 3.35268 7.40978 3.46424 7.33911C3.57581 7.26844 3.70026 7.22056 3.83042 7.19825C3.96059 7.17594 4.09388 7.17962 4.22261 7.2091C4.35134 7.23858 4.47296 7.29327 4.58044 7.37L8.00044 9.76L11.4004 7.2C11.6126 7.04087 11.8793 6.97255 12.1419 7.01005C12.4044 7.04756 12.6413 7.18783 12.8004 7.4C12.9596 7.61218 13.0279 7.87887 12.9904 8.14143C12.9529 8.40398 12.8126 8.64087 12.6004 8.8L8.60044 11.8C8.42734 11.9298 8.21681 12 8.00044 12Z"
        fill={color}
      />
      <path
        d="M8 10C7.73478 10 7.48043 9.89464 7.29289 9.70711C7.10536 9.51957 7 9.26522 7 9V1C7 0.734784 7.10536 0.48043 7.29289 0.292893C7.48043 0.105357 7.73478 0 8 0C8.26522 0 8.51957 0.105357 8.70711 0.292893C8.89464 0.48043 9 0.734784 9 1V9C9 9.26522 8.89464 9.51957 8.70711 9.70711C8.51957 9.89464 8.26522 10 8 10Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
