import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { useTranslation } from "react-i18next";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { Pagination } from "entities/accounts/Pagination";
import { fetchCustomerOffersByCustomerId } from "api/offers";
import { CustomerOffer } from "entities/offer/OfferEntity";

interface Props {
  customerId: number;
}

const CustomerOffersList: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation("offer");
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([] as CustomerOffer[]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);

  useEffect(() => {
    const getOffers = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCustomerOffersByCustomerId(customerId, {
          page,
          size: TABLE_PAGE_SIZE,
        });
        setPagination(res?.pagination);
        setOffers(res.customerOffers);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getOffers();
  }, [page]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const columns: ColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      renderCell: (params) => params?.row?.offer?.title,
      flex: 1,
    },
    {
      field: "locationType",
      headerName: t("locationType"),
      renderCell: (params) => params?.row?.offer?.locationType,
      width: 150,
      flex: 1,
    },
    {
      field: "offerType",
      headerName: t("offerType"),
      renderCell: (params) => params?.row?.offer?.offerType,
      width: 150,
      flex: 1,
    },
    {
      field: "redeemType",
      headerName: t("redeemType"),
      renderCell: (params) => params?.row?.offer?.redeemType,
      width: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("status"),
      renderCell: (params) => params?.row?.status,
      width: 150,
      flex: 1,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      flex: 1,
      renderCell: (params) => (
        <DetailButton to={`/offers/${params?.row?.id}`} />
      ),
    },
  ];

  return (
    <div>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("offer__page__title")}</QuidTitle>
        </Grid>
      </Grid>
      <QuidDataGrid
        disableSelectionOnClick
        onPageChange={onPageChange}
        sortModel={[{ field: "id", sort: "desc" }]}
        loading={loading}
        columns={columns}
        rows={offers}
        rowCount={pagination?.total_entries}
      />
    </div>
  );
};

export default CustomerOffersList;
