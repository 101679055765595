import React, { ChangeEvent, useEffect, useState } from "react";
import { Lock, LockOpen } from "@material-ui/icons";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { ColDef, CellParams, PageChangeParams } from "@material-ui/data-grid";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { fetchCustomerList } from "api/customers";
import { PartiesPagination } from "entities/accounts/Pagination";
import { CustomerBackoffice } from "entities/clients/CustomerBackofficeEntity";
import { downloadCustomersCsv } from "services/customers";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  customerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const Customers: React.FC = () => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const dispatch = useDispatch();

  const [customers, setCustomers] = useState([] as CustomerBackoffice[]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    from: "",
    to: "",
  });
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as PartiesPagination);

  const type = "INDIVIDUAL";

  useEffect(() => {
    const getCustomers = async (): Promise<void> => {
      setLoading(true);
      try {
        const customerListRes = await fetchCustomerList({
          ...(filters.search && { keyword: filters.search }),
          ...(filters.from && { from: (filters.from as unknown) as number }),
          ...(filters.to && { to: (filters.to as unknown) as number }),
          type,
          pageNo,
          pageSize: TABLE_PAGE_SIZE,
        });

        setPagination(customerListRes?.pagination);
        setCustomers(customerListRes?.customerList);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCustomers();
  }, [pageNo, filters]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloadCustomersCsv({
        fileName: "customer",
        ...(filters.search && { keyword: filters.search }),
        ...(filters.from && { from: (filters.from as unknown) as number }),
        ...(filters.to && { to: (filters.to as unknown) as number }),
      }),
  });

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "name",
      headerName: t("listTable__header_name__name"),
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "20px",
          }}
        >
          <span>{params.row.name}</span>

          <span>{params.row.surname}</span>
        </div>
      ),
    },

    {
      field: "mobileNumber",
      headerName: t("listTable__header_name__phone_number"),
      type: "number",
      flex: 1,
      sortable: false,
      valueFormatter: (params: CellParams) =>
        `${params?.row?.prefix as string} ${
          params?.row?.mobileNumber as string
        }`,
    },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "username",
      headerName: t("listTable__header_name__username"),
      flex: 1,
      valueFormatter: (params: CellParams) => {
        const customer = params?.row as CustomerBackoffice;
        return `${customer?.user?.username || ""}`;
      },
    },
    {
      field: "locked",
      headerName: t("listTable__header_name__locked"),
      renderCell: (params) => (params.value ? <Lock /> : <LockOpen />),
      flex: 0.5,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => (
        <DetailButton to={`/customers/${params?.row?.id}`} />
      ),
    },
  ];

  return (
    <>
      {documentLoading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.customerContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("customer__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  onClick={() => onCsvDownload()}
                  className={classes.csvBtn}
                >
                  <CsvIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("customer__account__from_id")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      from: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.from}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("customer__account__to_id")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      to: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.to}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("details__search")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      search: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={customers}
            rowCount={pagination?.totalEntries}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default Customers;
