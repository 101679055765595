import {
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  CellParams,
  ColDef,
  PageChangeParams,
  SearchIcon,
} from "@material-ui/data-grid";
import {
  fetchCryptoCurrenciesEnums,
  fetchCryptoPaymentsManagement,
} from "@portit/core/api/Crypto";
import {
  Currency,
  Pagination,
  PaymentDetailResponse,
} from "@portit/core/entities/Crypto";
import DetailButton from "components/atoms/DetailButton";
import Loader from "components/atoms/icons/Loader";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ONE_HOUR, TABLE_PAGE_SIZE } from "shared/constants";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  customerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  redStatus: {
    color: "#ff0000",
  },
}));

const CryptoPayments: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("crypto");
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);
  const [payments, setPayments] = useState([] as PaymentDetailResponse[]);
  const [currencies, setCurrencies] = useState([] as Currency[]);

  const [filters, setFilters] = useState({
    transactionId: "",
    network: "",
    amount: "",
    currency: "",
    SmartContractId: "",
    date: "",
  });

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const res = await fetchCryptoCurrenciesEnums();
        setCurrencies(res?.currencies);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };
    void getCurrencies();
  }, [pageNo, filters]);

  useEffect(() => {
    const getCryptoPayments = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCryptoPaymentsManagement({
          size: TABLE_PAGE_SIZE,
          page: pageNo,
          ...(filters?.SmartContractId && {
            SmartContractId: filters.SmartContractId,
          }),
          ...(filters?.transactionId && {
            transactionId: filters.transactionId,
          }),
          ...(filters?.amount && { amount: filters.amount }),
          ...(filters?.date && { date: filters.date }),
          ...(filters?.currency && { currency: filters.currency }),
        });
        setPagination(res?.pagination);
        setPayments(
          res?.payments?.map((payment) => {
            return {
              ...payment,
              id: payment.paymentId,
            };
          })
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCryptoPayments();
  }, [pageNo, filters]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const checkIfPaymentIsRed = (params: any) => {
    if (params?.status && params?.date) {
      const isPendingBlockchain =
        params?.status?.toLowerCase() === "pending_blockchain";

      const currentDate = new Date().toISOString().slice(0, 10);
      const investmentDate = new Date(params?.date).toISOString().slice(0, 10);

      const currentTime = new Date().getTime();
      const investmentTime = new Date(params?.date).getTime();

      return (
        isPendingBlockchain &&
        (investmentDate < currentDate ||
          (investmentDate === currentDate &&
            investmentTime - currentTime >= ONE_HOUR))
      );
    }
  };

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 75 },
    { field: "walletIdFrom", headerName: "From", width: 75 },
    { field: "beneficiaryWalletIdTo", headerName: "To", width: 75 },
    { field: "network", headerName: "Network", width: 150 },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.amount ?? 0, params?.row?.currency),
    },
    { field: "smartContractId", headerName: "SmartContractId", width: 200 },
    { field: "transactionId", headerName: "TransactionId", width: 300 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        if (checkIfPaymentIsRed(params?.row)) {
          return (
            <span className={classes.redStatus}>{params?.row?.status}</span>
          );
        } else {
          return <span>{params?.row?.status}</span>;
        }
      },
    },
    {
      field: "date",
      headerName: "Date",
      valueFormatter: (params: CellParams) => {
        const date = formatDate(
          (params?.row?.date as unknown) as Date,
          "dd/MM/yyyy"
        );
        return `${date || ""}`;
      },
      width: 150,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => (
        <DetailButton to={`/crypto/payments/${params?.row?.id}`} />
      ),
    },
  ];

  return (
    <>
      {loading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.customerContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("cryptopayments__page_title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__transaction_id")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      transactionId: e.target.value,
                    }))
                  }
                  value={filters.transactionId}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__smart_contract_id")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      SmartContractId: e.target.value,
                    }))
                  }
                  value={filters.SmartContractId}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__amount")}
                  variant="outlined"
                  fullWidth
                  type="number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      amount: e.target.value,
                    }))
                  }
                  value={filters.amount}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__currency")}
                  variant="outlined"
                  fullWidth
                  select
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      currency: e.target.value,
                    }))
                  }
                  value={filters.currency}
                >
                  <MenuItem value="">{t("currency")}</MenuItem>
                  {currencies.map((currency) => {
                    return (
                      <MenuItem value={currency.code}>{currency.text}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("filter__date")}
                  variant="outlined"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.date}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({ ...state, date: e.target.value }))
                  }
                />
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={payments}
            rowCount={pagination?.totalEntries}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default CryptoPayments;
