import { RouteComponentProps } from "react-router";
import MainTemplate from "templates/MainTemplate";
import InvestementInfo from "components/organisms/common/InvestementInfo";
import { useEffect, useState } from "react";
import { handleFailure } from "resHandlers";
import { InvestmentInfo } from "entities/clients/Investements";
import { getInvestementInfoByPartyId } from "api/investments";

const InvestmentsInfoPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const id: number = +match.params.id;
  const [details, setDetails] = useState<InvestmentInfo>({});

  useEffect(() => {
    if (id) {
      void getInvestmentInfoById(id);
    }
  }, [id]);

  const getInvestmentInfoById = async (id: number): Promise<void> => {
    try {
      const investmentInfoDetails = await getInvestementInfoByPartyId(id);
      setDetails(investmentInfoDetails);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <MainTemplate>
      <InvestementInfo investmentInfo={details} />
    </MainTemplate>
  );
};

export default InvestmentsInfoPage;
