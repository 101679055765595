import React, { ReactElement, useEffect, useState } from "react";
import { CellParams, ColDef } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles } from "@material-ui/core";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import DetailButton from "components/atoms/DetailButton";
import {
  AssociateCompanyToCustomer,
  Company,
} from "entities/clients/CompanyEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { getAssociationCompanyToCustomer } from "services/companies";
import { formatDate } from "utils";

const useStyles = makeStyles(() =>
  createStyles({
    dateType: {
      display: "flex",
      flexDirection: "column",
      lineHeight: "20px",
    },
  })
);

interface CompaniesProps {
  customerId: number;
}

const CompanyList: React.FC<CompaniesProps> = ({ customerId }) => {
  const { t } = useTranslation("customer");
  const dispatch = useDispatch();
  const classes = useStyles();
  const [details, setDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      void getCustomerDetails();
    }
  }, [customerId, setDetails, setLoading]);

  const getCustomerDetails = async (): Promise<void> => {
    if (customerId) {
      try {
        setLoading(true);
        const res = await getAssociationCompanyToCustomer(customerId);
        /**
         * Merge two different response into once
         */
        setDetails(
          res.customer.customer.companies?.map((company) => {
            const currentCompany = res.associetedCompanies.companies?.filter(
              (d: AssociateCompanyToCustomer) => d?.company.id === company.id
            )[0];

            return (company = {
              ...company,
              relationshipType: currentCompany.relationship,
            });
          })
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "companyName",
      headerName: t("company__header_name__name"),
      flex: 1,
    },
    {
      field: "relationshipType",
      headerName: t("company__header_name__relationshipType"),
      width: 200,
      hide: process.env.REACT_APP_KEYCLOAK_REALM !== "quid",
      renderCell: () => {
        return <div>n/a</div>;
      },
    },
    {
      field: "companyNumber",
      headerName: t("company__header_name__companyNumber"),
      width: 200,
    },
    {
      field: "legalEntityType",
      headerName: t("company__header_name__type"),
      width: 150,
    },
    {
      field: "legalEntityValue",
      headerName: t("company__header_name__legalEntityValue"),
      width: 180,
    },
    {
      field: "incorporationDate",
      type: "date",
      headerName: t("company__header_name__incorporationDate"),
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.incorporationDate)}
        </div>
      ),
      width: 170,
    },
    {
      field: "companyDetails",
      headerName: "Details",
      renderCell: (params: CellParams): ReactElement => {
        const company = params?.row as Company;
        return <DetailButton to={`/companies/${company.id}`} />;
      },
    },
  ];

  return (
    <QuidDataGrid
      rows={details || []}
      columns={columns}
      loading={loading}
      rowCount={details?.length}
      pageSize={details?.length}
      hideFooter={true}
    />
  );
};

export default CompanyList;
