import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import QuidTextField from "components/atoms/QuidTextField";
import { authenticate } from "store/actions/authentication";
import Quid from "../components/atoms/icons/Quid";
import Toonie from "../components/atoms/icons/Toonie";
import Elviria from "../components/atoms/icons/Elviria";
import Vaffa from "../components/atoms/icons/Vaffa";
import Strfi from "../components/atoms/icons/Strfi";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "calc(100% / 12 * 3)",
    margin: "calc(100% / 12 * 1) auto 0px auto",
  },
  loginLogo: {
    textAlign: "center",
    margin: "30px auto 60px",
  },
  loginTitle: {
    textAlign: "center",
    margin: "30px auto 60px",
  },
  loginFormInputs: {
    maxWidth: "calc(100% / 12 * 9)",
    margin: "0px auto",
  },
  loginFormButton: {
    textAlign: "center",
    margin: "30px auto 60px",
  },
}));

const defaultValues: FormValues = {
  username: "",
  password: "",
};

type FormValues = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const [snackOpen, setSnackOpen] = useState(false);
  const { t } = useTranslation("login");
  const dispatch = useDispatch();
  const classes = useStyles();
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onFormSubmit = async (values: FormValues) => {
    const { username, password } = values;
    dispatch(authenticate({ username, password }) as any);
  };

  const IconLoginValue =
    process.env.REACT_APP_PROJECT === "quid" ? (
      <Quid />
    ) : process.env.REACT_APP_PROJECT === "toonie" ? (
      <Toonie />
    ) : process.env.REACT_APP_PROJECT === "elviria" ? (
      <Elviria />
    ) : process.env.REACT_APP_PROJECT === "strfiinvest" ? (
      <Strfi />
    ) : (
      <Vaffa />
    );

  return (
    <FormProvider {...methods}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="error">
          {t("login__wrongCredentials")}
        </Alert>
      </Snackbar>
      <form className={classes.root} onSubmit={handleSubmit(onFormSubmit)}>
        <div className={classes.loginLogo}>{IconLoginValue}</div>
        <div className={classes.loginTitle}>
          <Typography variant="h4">{t("login__title")}</Typography>
        </div>
        <div className={classes.loginFormInputs}>
          <QuidTextField
            name="username"
            label={t("login__label__username")}
            rules={{
              required: t("login__username__required") as string,
            }}
            textFieldProps={{
              fullWidth: true,
            }}
            defaultValues={defaultValues}
          />
          <QuidTextField
            name="password"
            label={t("login__label__password")}
            rules={{
              required: t("login__password__required") as string,
            }}
            textFieldProps={{
              fullWidth: true,
              type: "password",
            }}
            defaultValues={defaultValues}
          />
        </div>
        <div className={classes.loginFormButton}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("login__form__button")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Login;
