import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { Pagination } from "entities/accounts/Pagination";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { fetchCardsPrograms } from "api/cards";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ProgramDetailsResponse } from "entities/cards/CardEntity";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { getAccountsCurrencies } from "store/reducers/app.reducer";
import { useSelector } from "react-redux";
import { AccountDatasetCurrency } from "entities/accounts/AccountDataset";
import DetailButton from "components/atoms/DetailButton";
import DetailIcon from "components/atoms/icons/DetailIcon";

const useStyles = makeStyles((theme) => ({
  programContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  flexCsv: {
    flex: 0.1,
    marginLeft: "auto",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      color: "#000",
    },
  },
}));

const statuses = ["Active", "Suspended", "Cancelled", "Stolen"];

const CardsPrograms: React.FC<
  RouteComponentProps<{ accountId: string }>
> = () => {
  const classes = useStyles();
  const { t } = useTranslation("cards");
  const dispatch = useDispatch();
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cardsPrograms, setCardsPrograms] = useState(
    [] as ProgramDetailsResponse[]
  );
  const [pagination, setPagination] = useState({} as Pagination);
  const [filter, setFilter] = useState({
    type: "",
    status: "",
    currency: "",
    programmeName: "",
  });

  const currencies = useSelector(getAccountsCurrencies);

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "name",
      flex: 1,
      headerName: t("listTable__header_name__name"),
    },
    {
      field: "logo_name",
      flex: 1,
      headerName: t("listTable__header_name__logo_name"),
    },
    {
      field: "description",
      flex: 1,
      headerName: t("listTable__header_name__description"),
    },
    {
      field: "action",
      flex: 1,
      headerName: t("listTable__header_name__action"),
    },
    {
      field: "active",
      width: 150,
      headerName: t("listTable__header_name__active"),
    },
    {
      field: "details",
      width: 100,
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        const id = params?.row?.id as number;
        if (!id) {
          return <DetailIcon />;
        }
        return <DetailButton to={`/cards/gift-cards/programs/${id}/details`} />;
      },
    },
  ];

  useEffect(() => {
    const getCardsPrograms = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCardsPrograms({
          ...(filter?.type && { type: filter?.type }),
          ...(filter?.status && { status: filter?.status }),
          ...(filter?.currency && { currency: filter?.currency }),
          ...(filter?.programmeName && {
            programmeName: filter?.programmeName,
          }),
          page,
          size: TABLE_PAGE_SIZE,
        });
        setPagination(res?.pagination);
        setCardsPrograms(res.programs);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCardsPrograms();
  }, [page, filter.type, filter.currency, filter.programmeName, filter.status]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  return (
    <MainTemplate>
      <div className={classes.programContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("gift_cards_programs__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.flexCsv}>
          <Button
            variant="outlined"
            className={classes.csvBtn}
            onClick={() => history.push("/cards/gift-cards/programs/bulk/new")}
          >
            {t("button__new_bulk_program")}
          </Button>
        </div>
        <div className={classes.filterContainer}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                label={t("filter__type")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilter((state) => ({
                    ...state,
                    type: e.target.value,
                  }));
                }}
                value={filter.type}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <SearchIcon color="#fff" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("filter__programme_name")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilter((state) => ({
                    ...state,
                    programmeName: e.target.value,
                  }));
                }}
                value={filter.programmeName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <SearchIcon color="#fff" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("filter__status")}
                variant="outlined"
                select
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    status: e.target.value,
                  }))
                }
                fullWidth
                value={filter.status}
              >
                <MenuItem value="">
                  <em>{t("filter__status")}</em>
                </MenuItem>
                {statuses?.map((status: any, index: number) => (
                  <MenuItem key={`${status}-${index}`} value={status}>
                    {`${t(status)}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("filter__currency")}
                variant="outlined"
                select
                fullWidth
                value={filter.currency}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    currency: e.target.value,
                  }))
                }
              >
                <MenuItem value="">{t("filter__currency")}</MenuItem>
                {currencies?.map(
                  (currency: AccountDatasetCurrency, index: number) => (
                    <MenuItem
                      key={`${currency.code}-${index}`}
                      value={currency.code}
                    >
                      {currency?.text}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={cardsPrograms}
          rowCount={pagination?.total_entries}
          pageSize={10}
        />
      </div>
    </MainTemplate>
  );
};

export default CardsPrograms;
