import { FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import { GenericObject } from "entities/tasks/Identity";
import React from "react";
import {
  useController,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";

interface QuidFormControlProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  defaultValues: GenericObject;
  formControlProps?: FormControlLabelProps;
  formControl: React.ReactElement<any, any>;
}

const QuidFormControl: React.FC<QuidFormControlProps> = ({
  name,
  label,
  rules,
  defaultValues,
  formControlProps,
  formControl,
}) => {
  const { control } = useFormContext();

  const {
    field: { ref, onChange, onBlur, value },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValues[name],
  });

  return (
    <FormControlLabel
      {...formControlProps}
      control={formControl}
      label={label}
      onChange={() => onChange(!value)}
      onBlur={onBlur}
      inputRef={ref}
      checked={value}
    />
  );
};

export default QuidFormControl;
