import {
  DeleteNewsParams,
  NewsDetailsParams,
  NewsDetailsResponse,
  NewsListParams,
  NewsListResponse,
  UpdateNewsParams,
} from "entities/news/NewsEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function getNews(
  params: NewsListParams
): Promise<NewsListResponse> {
  try {
    const res = await apiClient.get<NewsListResponse>(
      `/investments/v1/news`,
      "company",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getNewsById(
  params: NewsDetailsParams
): Promise<NewsDetailsResponse> {
  try {
    const res = await apiClient.get<NewsDetailsResponse>(
      `/investments/v1/news/${params.newsId}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function deleteNewsById(params: DeleteNewsParams): Promise<void> {
  try {
    await apiClient.delete(`/investments/v1/news/${params?.newsId}`, "company");
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateNews(params: UpdateNewsParams): Promise<void> {
  try {
    const { values, newsId } = params;
    await apiClient.put(`/investments/v1/news/${newsId}`, "company", values);
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createNews(params: UpdateNewsParams): Promise<void> {
  try {
    const { values } = params;
    await apiClient.post(`/investments/v1/news`, "company", values);
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
