import { DownloadAccessLogsParams } from "entities/accounts/AccessLogs";
import {
  AccessLogParams,
  CustomerAccessLog,
  CustomerAccessLogListResponse,
} from "entities/clients/AccessLogEntity";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function fetchAccessLog(
  params: AccessLogParams
): Promise<CustomerAccessLogListResponse> {
  try {
    const res = await apiClient.get<CustomerAccessLogListResponse>(
      "accesslog",
      "auth",
      {
        userid: params.userId,
        pageNo: params.page,
        pageSize: params.size,
      }
    );

    const customerAccessLogs = res.events?.map(
      ({ sessionId, ...rest }): CustomerAccessLog => ({
        id: sessionId,
        ...rest,
      })
    );
    res.events = customerAccessLogs;
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadAccessLogsDocument(
  params: DownloadAccessLogsParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/security/access/events/csv`,
      "account",
      {
        ...(params && {
          userid: params?.userid,
          username: params?.username,
          valid: params?.valid,
        }),
      },
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
