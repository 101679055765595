import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import { Link, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { arrayBufferToBase64 } from "utils";
import { fetchOfferImageByImageId } from "api/offers";
import { handleFailure } from "resHandlers";

const useStyle = makeStyles(() => ({
  image: {
    maxWidth: "100%",
  },
}));

type OfferImageProps = {
  imageId: number;
};

const OfferImage: React.FC<OfferImageProps> = ({ imageId }) => {
  const { t } = useTranslation("validations");
  const [image, setImage] = useState<any>();
  const classes = useStyle();

  useEffect(() => {
    const fetchOfferImage = async () => {
      try {
        const offerRes = await fetchOfferImageByImageId({
          imageId,
        });
        setImage(offerRes);
      } catch (err: any) {
        handleFailure(err);
      }
    };

    if (imageId) {
      fetchOfferImage();
    }
  }, [imageId]);

  if (!image) {
    return (
      <div>
        <Link>
          <ErrorIcon />
          {t("document__document_not_found")}
        </Link>
      </div>
    );
  }

  return (
    <>
      <img
        src={`data:image/png;base64,${arrayBufferToBase64(image.data.buffer)}`}
        className={classes.image}
      />
    </>
  );
};

export default OfferImage;
