import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import appReducer from "./reducers/app.reducer";
import promptsSlice from "./reducers/prompts.reducer";

const store = configureStore({
  reducer: appReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        promptsSlice.actions.openModal.toString(),
        promptsSlice.actions.openSnackbar.toString(),
      ],
    },
  }),
});

export default store;
