import { AuthenticateUser } from "features/authentication/domain/usecases/AuthenticateUser";
import { TimeoutScheduler } from "features/core/platform/TimeoutScheduler";
import { LocalStoragePersistence } from "features/core/platform/LocalStoragePersistence";
import { AuthenticationRepositoryImpl } from "features/authentication/domain/repositories/AuthenticationRepositoryImpl";
import { GetAuthenticationState } from "features/authentication/domain/usecases/GetAuthenticationState";
import { LogoutUser } from "features/authentication/domain/usecases/LogoutUser";
import { FakeAuthenticationRepository } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

const useFakeRepository = process.env.REACT_APP_USE_FAKE_REPOSITORY === "true";

const scheduler = new TimeoutScheduler();

const storage = new LocalStoragePersistence();

// --- REGISTRATION ---
const authRepo = useFakeRepository
  ? new FakeAuthenticationRepository()
  : new AuthenticationRepositoryImpl(
      {
        realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
        id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
        baseURL: process.env.REACT_APP_KEYCLOAK_URL as string,
      },
      apiClient,
      scheduler,
      storage
    );

const authenticateUserUseCase = new AuthenticateUser(authRepo);
const checkAuthenticationStateUseCase = new GetAuthenticationState(authRepo);

const logoutUserUseCase = new LogoutUser(authRepo);

export {
  authRepo,
  authenticateUserUseCase,
  checkAuthenticationStateUseCase,
  logoutUserUseCase,
};
