import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";

interface ButtonButtonProps {
  to?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  arrowForward: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ButtonButton: React.FC<ButtonButtonProps> = ({ to, onClick }) => {
  const classes = useStyles();

  if (to) {
    return (
      <IconButton>
        <Link to={to} className={classes.arrowForward}>
          <CheckIcon />
        </Link>
      </IconButton>
    );
  }

  return (
    <IconButton onClick={onClick}>
      <CheckIcon className={classes.arrowForward} />
    </IconButton>
  );
};

export default ButtonButton;
