import React, { useEffect, ReactNode } from "react";
import { Button, Grid, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { getPartiesFinancialCompanySourceTypes } from "store/reducers/app.reducer";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import {
  FinancialData,
  FinancialCompanySourceType,
} from "entities/clients/FinancialEntity";
export interface FinancialDetailsProps {
  financialData: FinancialData;
  loading: boolean;
  onUpdateFinancials: (params: FinancialData) => Promise<void>;
  children?: ReactNode | ReactNode[];
}

const defaultValues: FormValues = {
  companyMarketingDescription: "",
  companySector: "",
  companySectorDescription: "",
  companySubsector: "",
  companyWebsiteUrl: "",
  currency: "",
  employmentSectorType: "",
  employmentSectorValue: "",
  employmentStatusType: "",
  employmentStatusValue: "",
  expectedAnnualIncoming: "",
  fundsSourceType: "",
  fundsSourceValue: "",
};

const useStyles = makeStyles(() => ({
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  textField: {
    width: "90%",
    backgroundColor: "#FFFFFF",
  },
  updateBtn: { marginTop: 30, marginBottom: 30 },
}));

type FormValues = {
  companyMarketingDescription: "";
  companySector: "";
  companySectorDescription: "";
  companySubsector: "";
  companyWebsiteUrl: "";
  currency: "";
  employmentSectorType: "";
  employmentSectorValue: "";
  employmentStatusType: "";
  employmentStatusValue: "";
  expectedAnnualIncoming: "";
  fundsSourceType: "";
  fundsSourceValue: "";
};

const FinancialInfo: React.FC<FinancialDetailsProps> = (props) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const {
    financialData,
    loading,
  }: { financialData: FinancialData; loading: boolean } = props;
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;
  const sourceTypes = useSelector(getPartiesFinancialCompanySourceTypes);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && financialData) {
      reset({
        companyMarketingDescription:
          financialData?.companyMarketingDescription || "",
        companySector: financialData?.companySector || "",
        companySectorDescription: financialData?.companySectorDescription || "",
        companySubsector: financialData?.companySubsector || "",
        companyWebsiteUrl: financialData?.companyWebsiteUrl || "",
        currency: financialData?.currency || "",
        employmentSectorType: financialData?.employmentSectorType || "",
        employmentSectorValue: financialData?.employmentSectorValue || "",
        employmentStatusType: financialData?.employmentStatusType || "",
        employmentStatusValue: financialData?.employmentStatusValue || "",
        expectedAnnualIncoming: financialData?.expectedAnnualIncoming || "",
        fundsSourceType: financialData?.fundsSourceType || "",
        fundsSourceValue: financialData?.fundsSourceValue || "",
      } as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  }, [financialData]);

  const onFormSubmit = (values: FormValues) => {
    props.onUpdateFinancials({
      id: financialData?.id as number,
      companyMarketingDescription: values?.companyMarketingDescription,
      companySector: values?.companySector,
      companySectorDescription: values?.companySectorDescription,
      companySubsector: values?.companySubsector,
      companyWebsiteUrl: values?.companyWebsiteUrl,
      currency: values?.currency,
      employmentSectorType: values?.employmentSectorType,
      employmentSectorValue: values?.employmentSectorValue,
      employmentStatusType: values?.employmentStatusType,
      employmentStatusValue: values?.employmentStatusValue,
      expectedAnnualIncoming: values?.expectedAnnualIncoming,
      fundsSourceType: values?.fundsSourceType,
      fundsSourceValue: values?.fundsSourceValue,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={4}>
              <QuidTextField
                name="companySector"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "financial__label__company_sector__required"
                  ) as string,
                }}
                label={t("financial__label__company__sector")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="companySectorDescription"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "financial__label__company_sector__description__required"
                  ) as string,
                }}
                label={t("financial__label__company_sector__description")}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={4}>
              <QuidTextField
                name="fundsSourceType"
                rules={{
                  required: t(
                    "financial__label__funds__src_type__incoming__required"
                  ) as string,
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                }}
                label={t("financial__label__funds__src_type__incoming")}
                defaultValues={defaultValues}
              >
                <MenuItem value="">
                  {t("financial__label__funds__src_type__incoming")}
                </MenuItem>
                {sourceTypes.map(
                  (sourceType: FinancialCompanySourceType, index: number) => (
                    <MenuItem
                      key={`${sourceType.code}-${index}`}
                      value={sourceType.code}
                    >
                      {sourceType.text}
                    </MenuItem>
                  )
                )}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="fundsSourceValue"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "financial__label__funds_src__value__required"
                  ) as string,
                }}
                label={t("financial__label__funds_src__value")}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={4}>
              <QuidTextField
                name="companyWebsiteUrl"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                label={t("financial__label__company_website_Url")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="companyMarketingDescription"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                label={t(
                  "financial__label__company_sector__marketing_description"
                )}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.updateBtn}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {t("details__update")}
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default FinancialInfo;
