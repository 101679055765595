import { Grid } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import InvestmentsList from "components/organisms/investments/InvestmentsList";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";

const InvestmentsPage: React.FC = () => {
  const { t } = useTranslation("investments");
  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("investments__page_title")}</QuidTitle>
        </Grid>
      </Grid>
      <InvestmentsList />
    </MainTemplate>
  );
};

export default InvestmentsPage;
