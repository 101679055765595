import { Grid, makeStyles } from "@material-ui/core";
import DashboardTasksKPI from "components/molecules/verifications/DashboardTasksKPI";
import QuidTitle from "components/atoms/QuidTitle";
import { useTranslation } from "react-i18next";
import DashboardUserKPI from "components/molecules/verifications/DashboardPartyKPI";
import SidebarComponent from "components/atoms/SidebarComponent";
import TimeComponent from "components/atoms/TimeComponent";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/authentication";
import DashboardOffersKPI from "components/molecules/verifications/DashboardOffersKPI";
import DashboardProducts from "components/molecules/verifications/DashboardProducts";

const useStyles = makeStyles(() => ({
  page: {
    maxWidth: "calc(100% / 12 * 11)",
    marginLeft: "max(calc(100% / 12 * 1), 120px)",
  },
  pageWrapper: {
    maxWidth: "calc(120vw / 12 * 9)",
    padding: "calc(100vw / 12 * 0.3) calc(100vw / 12 * 0.6)",
    margin: "0px auto",
  },
  section: { paddingTop: "20px" },
  rowAllign: {
    display: "flex",
    width: "100%",
    marginTop: 20,
    textAlign: "center",
  },
  customerContainer: {
    background: "#FAFAFA",
    padding: "20",
    margin: "20px",
    paddingTop: "30px",
  },
  title: {
    textAlign: "center",
  },
}));

const dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation("dashboardPage");
  const dispatch = useDispatch();

  const onLogout = () => {
    void dispatch(logout() as any);
  };

  return (
    <>
      <SidebarComponent onLogout={onLogout} />
      <TimeComponent />
      <div className={classes.page}>
        <div className={classes.pageWrapper}>
          {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" ? (
            <div className={classes.section}>
              <QuidTitle marginBottom="10px">{t("Dashboard__tasks")}</QuidTitle>
              <div className={classes.rowAllign}>
                <Grid item xs={4} className={classes.customerContainer}>
                  <QuidTitle className={classes.title}>
                    {t("dashboard__task__kyc")}
                  </QuidTitle>
                  <QuidTitle>
                    <DashboardTasksKPI taskType="" />
                  </QuidTitle>
                </Grid>
                <Grid item xs={4} className={classes.customerContainer}>
                  <QuidTitle>{t("dashboard__party__users")}</QuidTitle>
                  <QuidTitle>
                    <DashboardUserKPI taskType="users" />
                  </QuidTitle>
                </Grid>
                <Grid item xs={4} className={classes.customerContainer}>
                  <QuidTitle className={classes.title}>{t("offers")}</QuidTitle>
                  <QuidTitle>
                    <DashboardOffersKPI />
                  </QuidTitle>
                </Grid>
              </div>
              <div className={classes.rowAllign}>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} className={classes.customerContainer}>
                  <QuidTitle>{t("dashboard__party__customers")}</QuidTitle>
                  <QuidTitle>
                    <DashboardUserKPI taskType="customers" />
                  </QuidTitle>
                </Grid>
                <Grid item xs={4} className={classes.customerContainer}>
                  <QuidTitle>{t("dashboard__party__companies")}</QuidTitle>
                  <QuidTitle>
                    <DashboardUserKPI taskType="companies" />
                  </QuidTitle>
                </Grid>
                <Grid item xs={2}></Grid>
              </div>
            </div>
          ) : (
            <div>
              {process.env.REACT_APP_PROJECT === "strfiinvest" ||
              process.env.REACT_APP_PROJECT === "elviria" ||
              process.env.REACT_APP_PROJECT === "vaffa" ||
              process.env.REACT_APP_PROJECT === "ats" ? (
                <div className={classes.section}>
                  <QuidTitle marginBottom="10px">
                    {t("Dashboard__tasks")}
                  </QuidTitle>
                  <div className={classes.rowAllign}>
                    <Grid item xs={6} className={classes.customerContainer}>
                      <QuidTitle className={classes.title}>
                        {t("dashboard__task__kyc")}
                      </QuidTitle>
                      <QuidTitle>
                        <DashboardTasksKPI taskType="" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__task__accounts")}</QuidTitle>
                      <QuidTitle>
                        <DashboardTasksKPI taskType="accounts" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={6} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__party__users")}</QuidTitle>
                      <QuidTitle>
                        <DashboardUserKPI taskType="users" />
                      </QuidTitle>
                    </Grid>
                  </div>
                  <div className={classes.rowAllign}>
                    <Grid item xs={6} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__party__customers")}</QuidTitle>
                      <QuidTitle>
                        <DashboardUserKPI taskType="customers" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={6} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__party__companies")}</QuidTitle>
                      <QuidTitle>
                        <DashboardUserKPI taskType="companies" />
                      </QuidTitle>
                    </Grid>
                  </div>
                </div>
              ) : (
                <div className={classes.section}>
                  <QuidTitle marginBottom="10px">
                    {t("Dashboard__tasks")}
                  </QuidTitle>
                  <div className={classes.rowAllign}>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle className={classes.title}>
                        {t("dashboard__task__kyc")}
                      </QuidTitle>
                      <QuidTitle>
                        <DashboardTasksKPI taskType="" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__task__accounts")}</QuidTitle>
                      <QuidTitle>
                        <DashboardTasksKPI taskType="accounts" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle>
                        {t("dashboard__task__companyFormation")}
                      </QuidTitle>
                      <QuidTitle>
                        <DashboardTasksKPI taskType="companyformation" />
                      </QuidTitle>
                    </Grid>
                  </div>
                  <div className={classes.rowAllign}>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__party__users")}</QuidTitle>
                      <QuidTitle>
                        <DashboardUserKPI taskType="users" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__party__customers")}</QuidTitle>
                      <QuidTitle>
                        <DashboardUserKPI taskType="customers" />
                      </QuidTitle>
                    </Grid>
                    <Grid item xs={4} className={classes.customerContainer}>
                      <QuidTitle>{t("dashboard__party__companies")}</QuidTitle>
                      <QuidTitle>
                        <DashboardUserKPI taskType="companies" />
                      </QuidTitle>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classes.pageWrapper}>
          <DashboardProducts />
        </div>
      </div>
    </>
  );
};

export default dashboard;
