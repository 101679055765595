import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
    color: "blue",
    marginRight: 10,
  },
}));

const ClearCacheLink = () => {
  const classes = useStyles();

  return (
    <Typography>
      <a
        href="#"
        className={classes.link}
        onClick={() => {
          localStorage.clear();
          location.reload();
        }}
      >
        Clear cache
      </a>
    </Typography>
  );
};

export default ClearCacheLink;
