import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { Pagination } from "@portit/core/entities/Products";
import { getContractsByProductId } from "@portit/core/api/Products";
import { RouteComponentProps } from "react-router";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { ContractDetailResponse } from "@portit/core/entities/Products";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  customerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const DashboardProductDetails: React.FC<
  RouteComponentProps<{ id: string }>
> = ({ match }) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const dispatch = useDispatch();
  const productId: number = parseInt(match.params.id, 10);
  const [contracts, setContracts] = useState([] as ContractDetailResponse[]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);
  const pageSize = TABLE_PAGE_SIZE;
  useEffect(() => {
    const getcontracts = async (): Promise<void> => {
      setLoading(true);

      try {
        const partiesRes = await getContractsByProductId({
          page: pageNo,
          size: pageSize,
          productId,
          orderType: "desc",
        });
        setPagination(partiesRes.pagination);
        setContracts(
          partiesRes.contracts.map((contract) => ({
            id: contract.contractId,
            ...contract,
          }))
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getcontracts();
  }, [pageNo, productId]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__contractId"),
      flex: 1,
    },
    {
      field: "ownerId",
      headerName: t("listTable__header_name__ownerId"),
      flex: 1,
    },
    {
      flex: 1,
      field: "ownerName",
      headerName: t("listTable__header_name__owner_name"),
      renderCell: (params) => {
        if (params?.row?.customer?.type === "INDIVIDUAL") {
          return (
            <>
              {params?.row?.customer?.surname} {params?.row?.customer?.name}
            </>
          );
        } else {
          return <>{params?.row?.customer?.company?.companyName}</>;
        }
      },
    },
    {
      field: "status",
      headerName: t("listTable__header_name__status"),
      flex: 1,
    },
  ];

  return (
    <MainTemplate>
      <div className={classes.customerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("page__title__contracts")}</QuidTitle>
          </Grid>
        </Grid>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={contracts}
          rowCount={pagination?.total_entries}
        />
      </div>
    </MainTemplate>
  );
};

export default DashboardProductDetails;
