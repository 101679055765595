import { IconButton, makeStyles } from "@material-ui/core";
import React, { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import taskModalsSlice from "store/reducers/taskModals.reducer";
import { getAttachmentModalState } from "store/reducers/app.reducer";
import AttachmentIcon from "./icons/attachment";
import { Task } from "entities/tasks/TaskEntity";

interface TaskAttachmentsButtonProps {
  disableModal: boolean;
  task: Task;
}

const useStyles = makeStyles(() => ({
  btn: {
    backgroundColor: "#320B5A",
    "&:hover svg > path": {
      fill: "#320B5A !important",
    },
    "& svg > path": {
      fill: "#fff",
    },
  },
}));

const TaskAttachmentsButton: React.FC<TaskAttachmentsButtonProps> = ({
  disableModal,
  task,
}) => {
  const dispatch = useDispatch();
  const attachmentsModal = useSelector(getAttachmentModalState);
  const classes = useStyles();

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!disableModal) {
      const pos = e.currentTarget.getClientRects()[0];

      if (!attachmentsModal) {
        dispatch(
          taskModalsSlice.actions.openAttachments({ x: pos.left, y: pos.top })
        );
      } else {
        dispatch(taskModalsSlice.actions.closeAttachments());
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={onClick}
        className={attachmentsModal ? classes.btn : undefined}
      >
        <AttachmentIcon />
      </IconButton>
      {task.totalAttachments || 0}
    </>
  );
};

export default TaskAttachmentsButton;
