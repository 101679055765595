import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";
import {
  FxManagementChangeable,
  FxManagementChangeableItemParams,
} from "entities/clients/ConversionEntity";

export async function getManagementChangeable(): Promise<FxManagementChangeable> {
  try {
    const res = await apiClient.get<FxManagementChangeable>(
      `/fx/v1/management/all`,
      "party"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function postManagementChangeable(
  params: FxManagementChangeableItemParams
): Promise<FxManagementChangeable> {
  try {
    const res = await apiClient.post<FxManagementChangeable>(
      `/fx/v1/management/changeable`,
      "party",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
