import { Scheduler } from "features/core/Scheduler";

export class TimeoutScheduler implements Scheduler {
  exec(after: number, action: Function): string {
    const jobId = setTimeout(() => {
      action();
    }, after * 1000);
    return jobId.toString();
  }

  remove(jobId: string): void {
    clearTimeout(parseInt(jobId, 10));
  }
}
