import { Failure, ServerFailure } from "features/core/Failure";
import store from "./store/store";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { AxiosError } from "axios";

export const handleSuccessfulMessage = (message: string): void => {
  store.dispatch(
    promptsSlice.actions.openSnackbar({
      message,
      type: "success",
    })
  );
};

export const handleFailure = (failure: Failure): void => {
  const { error } = failure as { error: AxiosError };
  const message =
    failure instanceof ServerFailure
      ? (failure as ServerFailure)?.error?.message
      : (failure as NetworkFailure)?.message;
  store.dispatch(
    promptsSlice.actions.openSnackbar({
      message,
      type: "error",
      url: error?.config?.url,
      data: error?.config?.data ? JSON.stringify(error.config.data) : undefined,
    })
  );
};
