import { useCallback, useEffect, useState } from "react";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getNews } from "api/news";
import { DetailsNews } from "entities/news/NewsEntity";
import { formatDate } from "utils";
import DetailButton from "components/atoms/DetailButton";
import { NewsPagination } from "entities/news/Pagination";
import { useHistory } from "react-router";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingBottom: "60px",
    },
    detailIconContainer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    newsContainer: {
      width: "100%",
      marginTop: 30,
      minHeight: 500,
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "end",
    },
  })
);

const NewsList = () => {
  const { t } = useTranslation("news");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([] as Array<DetailsNews>);
  const [pagination, setPagination] = useState({} as NewsPagination);

  const fetchNewsList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getNews({
        page,
        size: TABLE_PAGE_SIZE,
      });
      setPagination(pagination);
      setNews(res?.newsList);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      promptsSlice.actions.openSnackbar({
        message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchNewsList();
  }, [dispatch, page]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      flex: 1,
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      flex: 1,
      field: "date",
      headerName: t("listTable__header_name__date"),
      renderCell: (params) => <>{formatDate(params.row?.date, "dd/MM/yyyy")}</>,
    },
    {
      flex: 1,
      field: "title",
      headerName: t("listTable__header_name__title"),
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        return (
          <>
            <div className={classes.detailIconContainer}>
              <DetailButton to={`/news/${params?.row?.id}`} />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.btnWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/news/create")}
        >
          {t("label_button__create_news")}
        </Button>
      </div>
      <div className={classes.newsContainer}>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={news}
          rowCount={pagination?.totalEntries}
        />
      </div>
    </div>
  );
};

export default NewsList;
