import React from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import { Reward } from "entities/rewards/RewardEntity";

interface PaymentDetailModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  reward?: Reward;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginTop: "16px",
    fontSize: 16,
  },
}));

const RewardsModal: React.FC<PaymentDetailModalProps> = ({
  setOpen,
  open,
  reward,
}) => {
  const { t } = useTranslation("account");

  const classes = useStyles();

  const rewardDetailsToPrint: Reward = {
    id: reward?.id || "n/a",
    planId: reward?.planId || 0,
    partyId: reward?.partyId || 0,
    streamAmountInvested: reward?.streamAmountInvested || 0,
    projectedFiatAmount: reward?.projectedFiatAmount || 0,
    projectedStreamAmount: reward?.projectedStreamAmount || 0,
    currency: reward?.currency || "n/a",
    fiatAmountInvested: reward?.fiatAmountInvested || 0,
    created: reward ? formatDate(reward?.created as Date, "dd/MM/yyyy") : "",
    lockDays: reward ? formatDate(reward?.created as Date, "dd/MM/yyyy") : "",
    withdrawDate: reward
      ? formatDate(reward?.created as Date, "dd/MM/yyyy")
      : "",
    operationType: reward?.operationType || "n/a",
    referenceId: reward?.referenceId || "n/a",
    relatedUniqueId: reward?.relatedUniqueId || "n/a",
    accruedFlow: reward?.accruedFlow || "n/a",
    yieldFlow: reward?.yieldFlow || "n/a",
    tier: reward?.tier || "n/a",
  };

  const onResetAndCloseModal = () => {
    setOpen(false);
  };

  return (
    <FullScreenModal open={open} onClose={onResetAndCloseModal}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("rewards__page__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            <DetailsBlock
              label={t("details__reward__details")}
              toPrint={rewardDetailsToPrint}
            />
          </Grid>
        </Grid>
      </div>
    </FullScreenModal>
  );
};

export default RewardsModal;
