import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import QuidTitle from "components/atoms/QuidTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleFailure } from "resHandlers";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import TransactionRevenueDetail from "components/organisms/accounts/TransactionRevenueDetail";
import { MoneyFormatter } from "../shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { fetchBalanceByBalanceId } from "api/accounts";
import { BalanceResponse } from "entities/accounts/Balance";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  detailsContainer: {
    background: "#FAFAFA",
    fontFamily: "Comfortaa,sans-serif",
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  ml16: {
    marginLeft: 16,
  },
  mr16: {
    marginRight: 16,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  flex1: {
    flex: 1,
  },
}));

const AccountRevenueDetail: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const balanceId = parseInt(match.params.id, 10);
  const { t } = useTranslation("account");
  const [] = useState(false);
  const [] = useState(1);
  const [details, setDetails] = useState({} as BalanceResponse);

  useEffect(() => {
    const fetchBalanceDetails = async () => {
      try {
        const balanceRes = await fetchBalanceByBalanceId({
          balance_id: balanceId,
        });
        setDetails(balanceRes);
      } catch (err: any) {
        handleFailure(err);
      }
    };
    fetchBalanceDetails();
  }, []);

  const detailsSummary = [
    {
      title: t("revenue__details__account__name"),
      value: details?.account_name || "n/a",
    },
    {
      title: t("revenue__details__currency"),
      value: details?.currency || "n/a",
    },
    {
      title: t("revenue__details__iban"),
      value: details?.iban || "n/a",
    },
  ];

  const balancesSummary = [
    {
      title: t("revenue__balance__total__balance"),
      value: MoneyFormatter(
        details?.current_amount,
        details?.currency as DineroFactory.Currency
      ),
    },
    {
      title: t("revenue__balance__available__balance"),
      value: MoneyFormatter(
        details?.available_amount,
        details?.currency as DineroFactory.Currency
      ),
    },
  ];

  const detailTitle = clsx(classes.flex1, classes.detailTitle, classes.mt16);
  const listTitle = clsx(classes.flex1, classes.listTitle, classes.mt16);
  const listValue = clsx(classes.flex1, classes.listValue, classes.mt16);
  const margins = clsx(classes.ml16, classes.mr16, classes.mt32, classes.mb32);

  const detailsContainer = clsx(
    classes.detailsContainer,
    classes.mt32,
    classes.mb32
  );
  const flexRow = clsx(classes.flexContainer, classes.flexRow);
  const flexColumn = clsx(classes.flexContainer, classes.flexColumn);

  return (
    <MainTemplate>
      <div className={classes.accountsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("revenue__accounts__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={detailsContainer}>
          <Grid container direction={"row"} className={margins}>
            <Grid item xs={6}>
              <div className={flexColumn}>
                <div className={detailTitle}>
                  {t("revenue__details__title")}
                </div>
                {detailsSummary.map(({ title, value }, index) => (
                  <div className={flexRow} key={`details-revenue-${index}`}>
                    <div className={listTitle}>{title}</div>
                    <div className={listValue}>{value}</div>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={flexColumn}>
                <div className={detailTitle}>
                  {t("revenue__balance__title")}
                </div>
                {balancesSummary.map(({ title, value }, index) => (
                  <div className={flexRow} key={`balance-revenue-${index}`}>
                    <div className={listTitle}>{title}</div>
                    <div className={listValue}>{value}</div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
        <TransactionRevenueDetail balanceId={balanceId} />
      </div>
    </MainTemplate>
  );
};

export default AccountRevenueDetail;
