import {
  fetchCompanyDocument,
  fetchCompanyPersonDocument,
  fetchHistoricalCompanyDocument,
  fetchHistoricalCompanyPersonDocument,
  getDocument,
  getHistoricalDocument,
  getTask,
} from "api/tasks";
import {
  FetchTaskParams,
  FetchDocumentParams,
  GetCompanyDocumentParams,
  GetCompanyPersonDocumentParams,
} from "entities/tasks/TaskEntity";

import fileDownload from "js-file-download";
import sortBy from "lodash/sortBy";

export async function fetchTask<T>(params: FetchTaskParams): Promise<T> {
  try {
    const res = await getTask(params);
    // we need to sort the documents because order is not guaranteed from the api
    // and we rely on it considering that we set additionalInfo only on the first one
    const newDocuments = sortBy(res.documents, ["id"]);
    return { ...res, documents: newDocuments };
  } catch (e) {
    throw e;
  }
}

export async function fetchDocument<T>(
  params: FetchDocumentParams
): Promise<T> {
  try {
    const res = await getDocument(params);
    if (params.startDownload) {
      fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    }
    return res;
  } catch (e) {
    throw e;
  }
}

export async function fetchHistoricalDocument<T>(
  params: FetchDocumentParams
): Promise<T> {
  try {
    const res = await getHistoricalDocument(params);
    if (params.startDownload) {
      fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    }
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getCompanyDocument<T>(
  params: GetCompanyDocumentParams
): Promise<T> {
  try {
    const res = await fetchCompanyDocument(params);
    if (params.startDownload) {
      fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    }
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getHistoricalCompanyDocument<T>(
  params: GetCompanyDocumentParams
): Promise<T> {
  try {
    const res = await fetchHistoricalCompanyDocument(params);
    if (params.startDownload) {
      fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    }
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getCompanyPersonDocument<T>(
  params: GetCompanyPersonDocumentParams
): Promise<T> {
  try {
    const res = await fetchCompanyPersonDocument(params);
    if (params.startDownload) {
      fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    }
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getHistoricalCompanyPersonDocument<T>(
  params: GetCompanyPersonDocumentParams
): Promise<T> {
  try {
    const res = await fetchHistoricalCompanyPersonDocument(params);
    if (params.startDownload) {
      fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    }
    return res;
  } catch (e) {
    throw e;
  }
}
