import React, { useEffect, ReactNode, useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { FormProvider, useForm } from "react-hook-form";
import { format } from "date-fns";
import QuidTextField from "components/atoms/QuidTextField";
import { getCompanyTypes } from "store/reducers/app.reducer";
import { useSelector } from "react-redux";
import { updateCompanyDetails } from "api/companies";
import { Company, CompanyType } from "entities/clients/CompanyEntity";
import { useHistory } from "react-router";

export interface CompanyDetailsProps {
  company: Company;
  children?: ReactNode;
  refetch: () => Promise<void>;
}

type FormValues = {
  companyName: string;
  companyNumber: string;
  legalEntityType: string;
  legalEntityValue: string;
  incorporationDate: string;
  vatNumber: string;
  tradingName: string;
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
    "& .MuiFormControl-marginNormal": {
      width: "90%",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
}));

const CompanyInfo: React.FC<CompanyDetailsProps> = (
  props: CompanyDetailsProps
) => {
  const { t } = useTranslation("company");
  const dispatch = useDispatch();
  const history = useHistory();
  const { company }: { company: Company } = props;

  const companyTypes = useSelector(getCompanyTypes);

  const defaultValues: FormValues = {
    companyName: "",
    companyNumber: "",
    legalEntityType: "",
    legalEntityValue: "",
    incorporationDate: "",
    vatNumber: "",
    tradingName: "",
  };
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let isMounted = true;
    if (isMounted && company) {
      reset({
        companyName: company?.companyName || "",
        companyNumber: company?.companyNumber || "",
        legalEntityType: company?.legalEntityType || "",
        legalEntityValue: company?.legalEntityValue || "",
        incorporationDate: company?.incorporationDate
          ? format(new Date(company?.incorporationDate), "yyyy-MM-dd")
          : "",
        vatNumber: company?.vatNumber || "",
        tradingName: company?.tradingName || "",
      } as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  }, [company]);
  const classes = useStyles();

  const onFormSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await updateCompanyDetails({
        id: company?.id,
        body: { id: company?.id, ...values },
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("company__update__success__message"),
          type: "success",
        })
      );
      props.refetch();
      history.push(`/companies/${company?.id}`);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.gridContainer}
          >
            <Grid item xs={4}>
              <TextField
                label={t("details__label__companyId")}
                variant="outlined"
                disabled
                fullWidth
                value={company?.id || "n/a"}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="companyName"
                rules={{
                  required: t(
                    "details__label__companyName__required"
                  ) as string,
                }}
                label={t("details__label__companyName")}
                defaultValues={defaultValues}
                textFieldProps={{
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="companyNumber"
                rules={{
                  required: t(
                    "details__label__companyNumber__required"
                  ) as string,
                }}
                label={t("details__label__companyNumber")}
                defaultValues={defaultValues}
                textFieldProps={{
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="legalEntityType"
                rules={{
                  required: t(
                    "details__label__legalEntityType__required"
                  ) as string,
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                }}
                label={t("details__label__legalEntityType")}
                defaultValues={defaultValues}
              >
                {companyTypes.map((compType: CompanyType) => (
                  <MenuItem key={compType.code} value={compType.code}>
                    {compType.text}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="legalEntityValue"
                rules={{}}
                label={t("details__label__legalEntityValue")}
                defaultValues={defaultValues}
                textFieldProps={{
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="incorporationDate"
                inputProps={{ max: format(new Date(), "yyyy-MM-dd") }}
                textFieldProps={{
                  type: "date",
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                rules={{
                  required: t(
                    "details__label__incorporationDate__required"
                  ) as string,
                }}
                label={t("details__label__incorporationDate")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="vatNumber"
                rules={{
                  required: t("details__label__vatNumber__required") as string,
                }}
                label={t("details__label__vatNumber")}
                defaultValues={defaultValues}
                textFieldProps={{
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="tradingName"
                rules={{
                  required: t(
                    "details__label__tradingName__required"
                  ) as string,
                }}
                label={t("details__label__tradingName")}
                defaultValues={defaultValues}
                textFieldProps={{
                  fullWidth: true,
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !company || !Object.keys(company).length}
            className={classes.updateBtn}
          >
            {t("company__details__update")}
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default CompanyInfo;
