import { AuthState } from "features/authentication/domain/usecases/GetAuthenticationState";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  authenticateUserUseCase,
  checkAuthenticationStateUseCase,
  logoutUserUseCase,
} from "shared/singletons";
import { handleFailure } from "resHandlers";

export const refreshAuthState = (userIsAuthenticated: AuthState) => ({
  type: "ActionTypes.REFRESH_AUTH_STATE",
  payload: userIsAuthenticated,
});

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    const res = await logoutUserUseCase.run({});
    await checkAuthenticationStateUseCase.run({});
    return res;
  } catch (err: any) {
    throw err;
  }
});

export const authenticate = createAsyncThunk(
  "auth/authenticate",
  async ({ username, password }: { username: string; password: string }) => {
    try {
      const res = await authenticateUserUseCase.run({ username, password });
      return res;
    } catch (err: any) {
      handleFailure(err);
      throw err;
    }
  }
);
