import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModalState } from "store/reducers/app.reducer";
import promptsSlice from "store/reducers/prompts.reducer";

// Generic modal to show messages with buttons

const Modal: React.FC = () => {
  const modalState = useSelector(getModalState);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(promptsSlice.actions.closeModal());

  return (
    <Dialog open={!!modalState.message} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{modalState?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {modalState.actions?.map((a) => (
          <Button key={a.label} autoFocus onClick={a.handler} color="primary">
            {a.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
