import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ServerFailure } from "features/core/Failure";
import { Button, Grid, Typography } from "@material-ui/core";
import MainTemplate from "templates/MainTemplate";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import QuidTitle from "components/atoms/QuidTitle";
import InfoBox from "components/molecules/InfoBox";
import {
  activateOpportunity,
  approveOpportunity,
  cancelOpportunity,
  closeOpportunity,
  deleateDataFileByDataFileId,
  endOpportunity,
  fetchFileTypesEnums,
  fetchOpportunityById,
  fetchOpportunityFileByFileId,
  hideOpportunity,
  publishOpportunity,
} from "api/opportunities";
import {
  DataFile,
  Opportunity,
} from "entities/opportunities/opportunityEntity";
import OpportunityImage from "components/molecules/verifications/OpportunityImage";
import clsx from "clsx";
import { formatDate } from "utils";
import fileDownload from "js-file-download";
import { handleSuccessfulMessage, handleFailure } from "resHandlers";
import OpportunitiesDataFiles from "components/organisms/opportunities/OpportunitiesDataFiles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  dateAndSize: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
  actionWrapper: {
    display: "flex",
  },
  usernameWrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontFamily: "Comfortaa,sans-serif",
    color: "#000000",
    fontSize: 28,
    fontWeight: 700,
  },
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
  },
  tabsWrapper: {
    backgroundColor: "#FAFAFA",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  customerLink: {
    textAlign: "right",
    color: theme.palette.primary.main,
    marginBottom: 10,
    textDecoration: "none",
  },
  title: {
    marginTop: 60,
  },
  container: {
    minHeight: "100px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  verticalAlign: {
    alignItems: "center",
  },

  tableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: 12,
    padding: 20,
  },
  tableCol: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  actionCn: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  containerButton: {
    marginBottom: "50px",
    display: "flex",
    justifyContent: "center",
    "& .actions-reject": {
      backgroundColor: "#FF6F0F",
    },
    "& .actions-cancel": {
      backgroundColor: " #ff0000",
    },
    "& .actions-approve": {
      backgroundColor: "#32CD32",
    },
    "& .actions-end": {
      backgroundColor: "#ffff00",
    },
    "& .actions-hide": {
      backgroundColor: "#A865C9",
    },
    "& .actions-activate": {
      backgroundColor: "#6f4e37",
    },
  },
}));

const OpportunityDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("opportunities");
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const opportunityId: number = +match.params.id;
  const [fileTypes, setFileTypes] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<Opportunity>();
  const btnMl = clsx(`${classes.buttonMargin}--left`);

  const opportunityDetailsData = [
    {
      title: t("opportunity__id"),
      value: details?.id || "n/a",
    },
    {
      title: t("details__label__opportunityName"),
      value: details?.name || "n/a",
    },
    {
      title: t("details__label__sector"),
      value: details?.sector || "n/a",
    },
    {
      title: t("details__label__assetClass"),
      value: details?.assetClass || "n/a",
    },
    {
      title: t("details__label__step"),
      value: details?.step || "n/a",
    },
    {
      title: t("details__label__minRequested"),
      value: details?.minRequested || "n/a",
    },
    {
      title: t("details__label__maxRequested"),
      value: details?.maxRequested || "n/a",
    },
    {
      title: t("details__label__horizon"),
      value: details?.horizon || "n/a",
    },
    {
      title: t("details__label__stage"),
      value: details?.stage || "n/a",
    },
    {
      title: t("details__label__tokenPrice"),
      value: details?.tokenPrice || "n/a",
    },
    {
      title: t("details__label__noToken"),
      value: details?.noToken || "false",
    },
    {
      title: t("details__label__valuation"),
      value: details?.valuation || "n/a",
    },
    {
      title: t("details__label__currency"),
      value: details?.beneficiaryWallets?.wallets[0]?.currency || "n/a",
    },
    {
      title: t("details__label__wallet_id"),
      value: details?.beneficiaryWallets?.wallets[0]?.walletId || "n/a",
    },
    {
      title: t("details__label__status"),
      value: details?.status || "n/a",
    },
    {
      title: t("details__label__shortDescription"),
      value: details?.shortDescription || "n/a",
    },
    {
      title: t("details__label__longDescription"),
      value: details?.longDescription || "n/a",
    },
    {
      title: t("details__label__start_date"),
      value: formatDate(details?.startDate, "dd/MM/yyyy") || "n/a",
    },
    {
      title: t("details__label__end_date"),
      value: formatDate(details?.endDate, "dd/MM/yyyy") || "n/a",
    },
  ];

  useEffect(() => {
    const getFileTypes = async () => {
      try {
        const res = await fetchFileTypesEnums();
        setFileTypes(res.fileCategories);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };
    void getFileTypes();
  }, []);

  useEffect(() => {
    if (opportunityId) {
      void getOpportunityDetails(opportunityId);
    }
  }, [dispatch, opportunityId]);

  const getOpportunityDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const opportunityDetails = await fetchOpportunityById({
          id: id as number,
        });
        setDetails(opportunityDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const deleteDataFile = async (fileId: number) => {
    if (opportunityId) {
      try {
        await deleateDataFileByDataFileId(fileId);
        handleSuccessfulMessage(t("details__label__datafile_deleated"));
        getOpportunityDetails(opportunityId);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const downloadDataFile = async (file: DataFile) => {
    if (file.id) {
      try {
        const res = await fetchOpportunityFileByFileId(file.id);
        fileDownload(
          res.data.buffer,
          file.originalFileName,
          res.data.contentType
        );
        handleSuccessfulMessage(t("details__label__datafile_downloaded"));
        getOpportunityDetails(opportunityId);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const accept = async (id: number) => {
    try {
      await approveOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__approved"),
          type: "success",
        })
      );
      getOpportunityDetails(opportunityId);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const close = async (id: number) => {
    try {
      await closeOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__closed"),
          type: "success",
        })
      );
      getOpportunityDetails(opportunityId);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const end = async (id: number) => {
    try {
      await endOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__ended"),
          type: "success",
        })
      );
      getOpportunityDetails(opportunityId);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const hide = async (id: number) => {
    try {
      await hideOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__hidded"),
          type: "success",
        })
      );
      getOpportunityDetails(opportunityId);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const activate = async (id: number) => {
    try {
      await activateOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__activated"),
          type: "success",
        })
      );
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const cancel = async (id: number) => {
    try {
      await cancelOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__canceled"),
          type: "success",
        })
      );
      getOpportunityDetails(opportunityId);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const publish = async (id: number) => {
    try {
      await publishOpportunity(id);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__opportunity__published"),
          type: "success",
        })
      );
      getOpportunityDetails(opportunityId);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("opportunity__details__header_name__tab")}</QuidTitle>
        <Grid container className={(classes.container, classes.verticalAlign)}>
          <Grid item xs={9}>
            <InfoBox
              title={t("opportunity__info__tab")}
              items={opportunityDetailsData}
              opportunity={details}
            />
          </Grid>
          <Grid item xs={3}>
            <Grid item xs={12}>
              <OpportunityImage
                imageId={details?.mediumImageId as number}
                type="image"
              />
            </Grid>
            <Grid item xs={12}>
              <OpportunityImage
                imageId={details?.bigImageId as number}
                type="image"
              />
            </Grid>
            <Grid item xs={12}>
              <OpportunityImage
                imageId={details?.logoImageId as number}
                type="logo"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.tableCol}>
          <div className={classes.containerButton}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              className={btnMl}
              onClick={() =>
                history.push(`/opportunities/${opportunityId}/edit`)
              }
            >
              {t("opportunity__page__btn__edit")}
            </Button>
            {details?.status !== "Cancelled" &&
              details?.status !== "Approved" &&
              details?.status !== "Closed" &&
              details?.status !== "Hidden" && (
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondary"
                  className={`actions-reject ${btnMl}`}
                  onClick={() => close(opportunityId)}
                >
                  {t("opportunity__page__btn__close")}
                </Button>
              )}
            {details?.status !== "Cancelled" &&
              details?.status !== "Approved" &&
              details?.status !== "Active" &&
              details?.status !== "Closed" &&
              details?.status !== "Published" &&
              details?.status !== "UnderDueDiligence" &&
              details?.status !== "Ended" && (
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondary"
                  className={`actions-publish ${btnMl}`}
                  onClick={() => publish(opportunityId)}
                >
                  {t("opportunity__page__btn__publish")}
                </Button>
              )}
            {details?.status !== "Cancelled" &&
              details?.status !== "Approved" &&
              details?.status !== "Hidden" &&
              details?.status !== "Closed" &&
              details?.status !== "UnderDueDiligence" &&
              details?.status !== "Ended" && (
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondary"
                  className={`actions-end ${btnMl}`}
                  onClick={() => end(opportunityId)}
                >
                  {t("opportunity__page__btn__end")}
                </Button>
              )}
            {details?.status !== "Cancelled" &&
              details?.status !== "Approved" &&
              details?.status !== "Closed" &&
              details?.status !== "Active" &&
              details?.status !== "Hidden" &&
              details?.status !== "Ended" && (
                <Button
                  disabled={false}
                  variant="contained"
                  color="primary"
                  className={`actions-activate ${btnMl}`}
                  onClick={() => activate(opportunityId)}
                >
                  {t("opportunity__page__btn__activate")}
                </Button>
              )}
            {details?.status !== "Cancelled" &&
              details?.status !== "Approved" &&
              details?.status !== "Closed" &&
              details?.status !== "Active" &&
              details?.status !== "UnderDueDiligence" &&
              details?.status !== "Hidden" && (
                <>
                  <Button
                    disabled={false}
                    variant="contained"
                    color="primary"
                    className={`actions-approve ${btnMl}`}
                    onClick={() => accept(opportunityId)}
                  >
                    {t("opportunity__page__btn__approve")}
                  </Button>
                  <Button
                    disabled={false}
                    variant="contained"
                    color="primary"
                    className={`actions-hide ${btnMl}`}
                    onClick={() => hide(opportunityId)}
                  >
                    {t("opportunity__page__btn__hide")}
                  </Button>
                  <Button
                    disabled={false}
                    variant="contained"
                    color="primary"
                    className={`actions-cancel ${btnMl}`}
                    onClick={() => cancel(opportunityId)}
                  >
                    {t("opportunity__page__btn__cancel")}
                  </Button>
                </>
              )}
          </div>
          <QuidTitle>{t("opportunity__details__files")}</QuidTitle>
          <OpportunitiesDataFiles
            opportunityId={opportunityId}
            fetch={getOpportunityDetails}
            datafileTypes={fileTypes}
          />
          {fileTypes?.map((fileType) => {
            return (
              <>
                <QuidTitle>{t(`opportunity__details__${fileType}`)}</QuidTitle>
                {details?.datafiles
                  ?.filter((file) => file.fileCategory === fileType)
                  .map((file) => (
                    <Grid
                      container
                      direction="row"
                      className={classes.tableRow}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        className="d-flex flex-col text-align-center"
                      >
                        <strong>{file.originalFileName}</strong>
                        <Typography className={classes.dateAndSize}>
                          <div>{formatDate(file.date, "dd/MM/yyyy")}</div>
                          <div>{file.size} MB</div>
                        </Typography>
                        <div>{file.description}</div>
                      </Typography>
                      <div className={classes.actionCn}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => downloadDataFile(file)}
                        >
                          {t("opportunity__page__btn__download")}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => deleteDataFile(file.id)}
                        >
                          {t("opportunity__page__btn__remove")}
                        </Button>
                      </div>
                    </Grid>
                  ))}
              </>
            );
          })}
        </Grid>
      </div>
    </MainTemplate>
  );
};

export default OpportunityDetails;
