import { Button, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import AccessLogsList from "components/organisms/clients/AccessLogsList";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloadAccessLogsCsv } from "services/accessLogs";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useEffect, useState } from "react";
import { fetchCustomerDetails } from "services/customers";
import promptsSlice from "store/reducers/prompts.reducer";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { useDispatch } from "react-redux";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles(() => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
}));

const AccessLogs: React.FC<
  RouteComponentProps<{ userid: string; sessionid: string }>
> = ({ match }) => {
  const { t } = useTranslation("accessLog");
  const dispatch = useDispatch();
  const classes = useStyles();
  const userId: string = match.params.userid;
  const sessionid: string = match.params.sessionid;

  const [details, setDetails] = useState<CustomerDetails>();
  const username = details?.customer?.user?.username as string;

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloadAccessLogsCsv({
        username,
        userid: userId,
        valid: true,
      }),
  });

  const getCustomerDetails = async (userId: string) => {
    try {
      const customerDetails = await fetchCustomerDetails({
        customerId: (userId as unknown) as number,
      });
      setDetails(customerDetails);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (userId) {
      void getCustomerDetails(userId);
    }
  }, [setDetails]);

  return (
    <>
      {documentLoading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("accessLog__title")}</QuidTitle>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            className={classes.csvBtn}
            onClick={() => onCsvDownload()}
          >
            <CsvIcon />
          </Button>
        </Grid>
        <AccessLogsList sessionid={sessionid}></AccessLogsList>
      </MainTemplate>
    </>
  );
};

export default AccessLogs;
