import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import VisibilityIcon from "@material-ui/icons/Visibility";
interface DetailButtonProps {
  to?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  arrowForward: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const DetailButton: React.FC<DetailButtonProps> = ({ to, onClick }) => {
  const classes = useStyles();

  if (to) {
    return (
      <IconButton>
        <Link to={to} className={classes.arrowForward}>
          <ArrowForwardIcon />
        </Link>
      </IconButton>
    );
  }

  return (
    <IconButton onClick={onClick}>
      <VisibilityIcon className={classes.arrowForward} />
    </IconButton>
  );
};

export default DetailButton;
