import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { useEffect, useState } from "react";
import { fetchCustomerList } from "api/customers";

interface DashboardUserKPIProps {
  taskType: string;
}

const DashboardUserKPI: React.FC<DashboardUserKPIProps> = ({
  taskType: userType,
}) => {
  const [totalusers, setTotalUsers] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const getTotalTask = async (): Promise<void> => {
      try {
        if (userType === "customers") {
          const usersListRes = await fetchCustomerList({
            pageNo: 1,
            type: "INDIVIDUAL",
            pageSize: 1,
          });
          setTotalUsers(usersListRes?.pagination?.totalEntries);
        } else if (userType === "users") {
          const customerListRes = await fetchCustomerList({
            pageNo: 1,
            pageSize: 1,
          });
          setTotalUsers(customerListRes?.pagination?.totalEntries);
        } else {
          const customerListRes = await fetchCustomerList({
            pageNo: 1,
            type: "CORPORATE",
            pageSize: 1,
          });
          setTotalUsers(customerListRes?.pagination.totalEntries);
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };
    void getTotalTask();
  }, []);
  return <div>{totalusers}</div>;
};

export default DashboardUserKPI;
