import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { useState } from "react";
import { handleFailure } from "resHandlers";

export interface UseCsvDownloadHandlerParams {
  download: () => Promise<FilePayload>;
}

const useCsvDownloadHandler = ({ download }: UseCsvDownloadHandlerParams) => {
  const [documentLoading, setDocumentLoading] = useState(false);
  const onCsvDownload = async () => {
    try {
      setDocumentLoading(true);
      await download();
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setDocumentLoading(false);
    }
  };

  return {
    documentLoading,
    onCsvDownload,
  };
};

export default useCsvDownloadHandler;
