import React from "react";
import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import Logo from "assets/logo.svg";
// import oCircle from "assets/organge-half-circle.svg";
// import pCircle from "assets/purple-half-circle.svg";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#ec6c1c",
      fontSize: "160px",
      lineHeight: 1.2,
      marginTop: "50px",
    },
    container: {
      paddingTop: "100px",
      textAlign: "center",
    },
    subtitle: {
      color: "#37383c",
      fontSize: "18px",
    },
    circle: {
      position: "fixed",
      bottom: 0,
      right: 0,
    },
    homeLink: { marginTop: 82 },
  })
);

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* <img src={Logo} alt="QuidApp Logo" /> */}

      <Typography className={classes.title}>404</Typography>
      <Typography className={classes.subtitle}>
        {t("app__page_not_found")}
      </Typography>

      <Link to="/">
        <Button
          variant="contained"
          color="primary"
          className={classes.homeLink}
        >
          go to homepage
        </Button>
      </Link>
      {/*
      <img className={classes.circle} src={pCircle} alt="Quid - Login Vector" />
      <img className={classes.circle} src={oCircle} alt="Quid - Login Vector" /> */}
    </div>
  );
};

export default NotFound;
