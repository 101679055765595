import GetAppIcon from "@material-ui/icons/GetApp";
import ErrorIcon from "@material-ui/icons/Error";
import { useEffect, useState } from "react";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { CircularProgress, Link, makeStyles } from "@material-ui/core";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { Attachment } from "entities/clients/Attachment";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { Document } from "entities/clients/DocumentEntity";

const useStyles = makeStyles((theme) => ({
  downLink: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    textDecoration: "underline",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 28,
  },
  imgPreview: {
    marginBottom: 28,
    maxWidth: 300,
  },
  infoBlock: {
    marginBottom: 50,
  },
}));

interface FourStopInfoToPrint {
  verificationId: number | string;
  fourStopReference: string;
  fourStopCompleted: string;
  fourStopResult: string | JSX.Element;
}

interface AdditionalInfoToPrint {
  issuingCountry: string;
  documentNumber: string;
  issuingDate: string;
  expiringDate: string;
}

type AttachmentProps = {
  attachment: Document | Attachment;
  downloadDoc: (params: any) => Promise<FilePayload>;
  showAdditionalInfo?: boolean;
};

const AttachmentWithoutPreview = ({
  attachment,
  downloadDoc,
  showAdditionalInfo,
}: AttachmentProps) => {
  const { t } = useTranslation("validations");
  const classes = useStyles();
  const [docFetchRes, setDocFetchRes] = useState<any>();
  const [loading, setLoading] = useState(false);
  const fourStopInfo: FourStopInfoToPrint = {
    verificationId: attachment?.fourStopDocumentId || "n/a",
    fourStopCompleted: attachment?.fourStopCompleted ? "true" : "false",
    fourStopReference: attachment?.fourStopReference || "n/a",
    fourStopResult: attachment?.fourStopResult || "n/a",
    /* Missing info bellow, to be addded once arrives from BE
    faceAnalysesSucceded: attachment?.faceAnallysesSucceded || "n/a",
    faceRecognitionSucceded: attachment?.faceRecognitionSucceded || "n/a",
    */
  };
  showAdditionalInfo = showAdditionalInfo || false;
  const additionalInfoToJson: AdditionalInfoToPrint =
    attachment?.additionalInfo && JSON.parse(attachment?.additionalInfo);
  const additionalInfo: AdditionalInfoToPrint = {
    issuingCountry: additionalInfoToJson?.issuingCountry || "n/a",
    documentNumber: additionalInfoToJson?.documentNumber || "n/a",
    issuingDate: additionalInfoToJson?.issuingDate || "n/a",
    expiringDate: additionalInfoToJson?.expiringDate || "n/a",
  };

  const download = async () => {
    fileDownload(
      docFetchRes.data.buffer,
      attachment.name,
      docFetchRes.data.contentType
    );
  };
  useEffect(() => {
    const fetchDocument = async () => {
      setLoading(true);
      try {
        const res = await downloadDoc({
          documentId: attachment.id,
          fileName: attachment.name,
        });

        setDocFetchRes(res);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    if (attachment) {
      fetchDocument();
    }
  }, [attachment]);

  if (!docFetchRes) {
    return (
      <div>
        <Link className={classes.downLink}>
          <ErrorIcon />
          {t("document__document_not_found")}
        </Link>
      </div>
    );
  }

  if (loading) {
    return <CircularProgress className={classes.downLink} />;
  }
  return (
    <div>
      <Link onClick={download} className={classes.downLink}>
        <GetAppIcon />
        {attachment.name}
      </Link>
      {additionalInfoToJson && showAdditionalInfo && (
        <div className={classes.infoBlock}>
          <DetailsBlock
            label={t("document__additional__info")}
            toPrint={additionalInfo}
          />
        </div>
      )}
      {attachment?.fourStopCompleted && (
        <div className={classes.infoBlock}>
          <DetailsBlock
            label={t("document__four__stop__info")}
            toPrint={fourStopInfo}
          />
        </div>
      )}
    </div>
  );
};

export default AttachmentWithoutPreview;
