import { createAccountFromBO } from "api/accounts";
import { createProductByProductId } from "api/product";
import { ContractDetailResponse } from "entities/products/ProductEntity";

export async function createProductAccount(
  productId: number,
  partyId: number,
  profileId: number
): Promise<ContractDetailResponse> {
  try {
    const contract = await createProductByProductId(productId, partyId);
    await createAccountFromBO(contract.contractId.toString(), profileId);

    return contract;
  } catch (e) {
    throw e;
  }
}
