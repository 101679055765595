import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./i18n";
import { QuidApp } from "./QuidApp";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { sentryKey } from "shared/constants";

Sentry.init({
  dsn: sentryKey,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_DEMO_MODE,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Suspense fallback={"loading"}>
    <QuidApp />
  </Suspense>,
  document.getElementById("root")
);
