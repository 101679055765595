import {
  InputAdornment,
  makeStyles,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import UploadIcon from "./icons/UploadIcon";

const useStyles = makeStyles((theme) => ({
  uploadField: {
    cursor: "pointer",
    "& .MuiInputBase-root": {
      pointerEvents: "none",
    },
  },
  uploadBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

type QuidUploadFieldProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputId: string;
} & TextFieldProps;

const QuidUploadField: React.FC<QuidUploadFieldProps> = ({
  inputId,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <input type="file" id={inputId} hidden onChange={props.onChange} />
      <label htmlFor={inputId}>
        <TextField
          className={classes.uploadField}
          variant="outlined"
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <div className={classes.uploadBox}>
                  <UploadIcon color="white" />
                </div>
              </InputAdornment>
            ),
          }}
          {...props}
        />
      </label>
    </>
  );
};

export default QuidUploadField;
