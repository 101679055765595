import React, { ReactNode } from "react";
import { Profile } from "entities/clients/ProfileEntity";
import { realm } from "utils";
import CustomerProfilesQuid from "../quid/CustomerProfilesQuid";
import CustomerProfilesElviria from "../elviria/CustomerProfilesElviria";
import CustomerProfilesToonie from "../toonie/CustomerProfilesToonie";

interface ProfilesProp {
  profiles?: Profile[];
  children?: ReactNode | ReactNode[];
  loading: boolean;
}

const CustomerProfiles: React.FC<ProfilesProp> = ({ profiles, loading }) => {
  if (realm() === "quid") {
    return (
      <CustomerProfilesQuid
        profiles={profiles}
        loading={loading}
      ></CustomerProfilesQuid>
    );
  } else if (realm() === "toonie") {
    return (
      <CustomerProfilesToonie
        profiles={profiles}
        loading={loading}
      ></CustomerProfilesToonie>
    );
  } else {
    return (
      <CustomerProfilesElviria
        profiles={profiles}
        loading={loading}
      ></CustomerProfilesElviria>
    );
  }
};

export default CustomerProfiles;
