import { makeStyles, Theme } from "@material-ui/core";
import {
  ColDef,
  DataGrid,
  PageChangeParams,
  ComponentProps,
} from "@material-ui/data-grid";
import { Pagination } from "@material-ui/lab";
import React, { useLayoutEffect, useRef } from "react";
import { TABLE_PAGE_SIZE } from "shared/constants";

const useStyles = makeStyles<Theme, { rowCount?: number; rowColor?: string }>(
  (theme: Theme) => ({
    root: {
      minHeight: (props) => (!props.rowCount ? 500 : undefined),
      color: "#37383C",
      marginTop: 30,
      "& .MuiDataGrid": {
        "&-colCellWrapper": {
          height: "40px",
        },
        "&-row": {
          backgroundColor: (props) => props?.rowColor || "#FFFFFF",
          borderBottom: "2px solid #FFFFFF",
          borderTop: "1px solid #FFFFFF",
        },
        "&-columnsContainer": {
          backgroundColor: theme.palette.primary.main,
          color: "#FFFFFF",
        },
        "&-iconSeparator": {
          display: "none",
        },
        "&-sortIconContainer": {
          display: "none",
        },
      },
    },
    pagination: {
      "& > *": {
        margin: theme.spacing(2),
      },
    },
  })
);

const CustomPagination: React.FC<ComponentProps> = (props) => {
  const { pagination, api } = props;
  const classes = useStyles({ rowCount: undefined });

  return (
    <Pagination
      className={classes.pagination}
      color="primary"
      page={pagination.page}
      showFirstButton
      showLastButton
      count={pagination.pageCount}
      onChange={(event, value) => api.current.setPage(value)}
    />
  );
};

type QuidDataGridProps = {
  loading?: boolean;
  columns: ColDef[];
  onPageChange?: (param: PageChangeParams) => void;
  rows: any[];
  rowCount?: number;
  hideHeader?: boolean;
  pageSize?: number;
  disableSelectionOnClick?: boolean;
  hideFooter?: boolean;
  rowColor?: string;
  className?: any;
  sortModel?: any;
};

const QuidDataGrid: React.FC<QuidDataGridProps> = ({
  rows,
  loading,
  columns,
  onPageChange,
  rowCount,
  rowColor,
  disableSelectionOnClick,
  hideHeader,
  hideFooter,
  pageSize,
  className,
  sortModel,
}) => {
  const classes = useStyles({ rowCount, rowColor });
  const gridWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const gridEl: HTMLDivElement = gridDiv.querySelector("div")!;
      gridEl.style.height = "";
    }
  });

  return (
    <div ref={gridWrapperRef}>
      <DataGrid
        sortModel={sortModel ?? sortModel}
        hideFooter={hideFooter}
        className={className || classes.root}
        rows={rows || []}
        paginationMode="server"
        loading={loading}
        components={{ pagination: CustomPagination }}
        headerHeight={hideHeader ? 0 : 40}
        rowHeight={60}
        autoHeight={true}
        pagination
        disableSelectionOnClick={disableSelectionOnClick ?? true}
        columns={columns.map((column) => ({
          ...column,
          sortable: column.sortable ? true : false,
        }))}
        pageSize={pageSize || TABLE_PAGE_SIZE}
        onPageChange={onPageChange}
        rowCount={rowCount}
      />
    </div>
  );
};

export default QuidDataGrid;
