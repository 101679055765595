import { Failure } from "features/core/Failure";

export class NetworkFailure extends Failure {
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}
