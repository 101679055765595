import {
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
} from "@material-ui/core";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import CsvIcon from "components/atoms/icons/CsvIcon";
import DetailIcon from "components/atoms/icons/DetailIcon";
import SearchIcon from "components/atoms/icons/SearchIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import StatusBadge from "components/atoms/StatusBadge";
import React, { ChangeEvent, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsCurrencies,
  getAccountsTransactionStatus,
} from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import PaymentDetailModal from "components/organisms/payments/PaymentDetailModal";
import QuidTitle from "components/atoms/QuidTitle";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";

import {
  fetchTransactionDetailsByTransactionId,
  fetchAccountTransactions,
  getAllProviders,
} from "api/accounts";
import {
  downloadOptimizedTransactionsCsv,
  downloadTransactionsCsv,
} from "services/accounts";
import {
  AccountDataset,
  AccountDatasetCurrency,
} from "entities/accounts/AccountDataset";
import {
  Transaction,
  TransactionDetailResponse,
  FetchTransactionDetailParams,
} from "entities/accounts/Transaction";
import { Pagination } from "entities/accounts/Pagination";
import Loader from "components/atoms/icons/Loader";
import { formatDate } from "utils";
import { handleFailure } from "resHandlers";
import { sanitizeProvidersString } from "utils/sanitizeProvidersString";
import CsvIconDemo from "components/atoms/icons/CsvIconDemo";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  paymentsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexTextFields: {
    flex: 1,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  detailLink: {
    color: theme.palette.primary.main,
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  statusType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  typeContainer: {
    marginLeft: "19px",
  },
}));

const Conversions: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["payments", "account"]);
  const [pagination, setPagination] = useState<Partial<Pagination>>({});
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [pageNo, setPageNo] = useState(0);
  const [transactionDetail, setTransactionDetail] = useState<
    TransactionDetailResponse | undefined
  >(undefined);
  const [allProviders, setAllProviders] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState({
    search: "",
    from: "",
    to: "",
    currency: "",
    status: "",
    provider: "",
  });

  const fetchTransactionDetail = async (
    params: FetchTransactionDetailParams
  ) => {
    setLoading(true);
    try {
      const res = await fetchTransactionDetailsByTransactionId(params);
      setTransactionDetail(res);
      setModalOpen(true);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const currencies = useSelector(getAccountsCurrencies);
  const paymentStatuses = useSelector(getAccountsTransactionStatus);

  const fetchAllProviders = useCallback(async ({ ignore }): Promise<void> => {
    try {
      if (!ignore) {
        const res = await getAllProviders();
        setAllProviders(res);
      }
    } catch (e: any) {
      handleFailure(e);
    }
  }, []);

  useEffect(() => {
    let ignore = false;

    fetchAllProviders({ ignore });
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    const fetchConversions = async (): Promise<void> => {
      setLoading(true);

      try {
        const res = await fetchAccountTransactions({
          ...(filter?.search && { keyword: filter?.search }),
          ...(filter?.currency && { currency: filter?.currency }),
          ...(filter?.from && { date_from: filter?.from }),
          ...(filter?.to && { date_to: filter?.to }),
          ...(filter?.provider && { provider: filter?.provider }),
          ...(filter?.status && { status: filter?.status }),
          page: pageNo,
          size: TABLE_PAGE_SIZE,
          transaction_type: "conversion",
        });

        setPagination(res?.pagination);
        setTransactions(res?.transaction_list);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void fetchConversions();
  }, [
    pageNo,
    filter?.search,
    filter?.from,
    filter?.to,
    filter?.currency,
    filter?.status,
    filter?.provider,
  ]);

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 75 },
    {
      field: "status",
      width: 150,
      headerName: t("transactionTable__header_name__status"),
      renderCell: (params) => (
        <div className={classes.statusType}>
          <small>
            <StatusBadge status={params?.row?.status} />
          </small>
        </div>
      ),
    },
    {
      field: "type",
      width: 150,
      headerName: t("transactionTable__header_name__type"),
    },
    {
      field: "created_at",
      headerName: t("account:transactionTable__header_name__createdAt"),
      type: "date",
      width: 150,
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.created_at, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.created_at, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: t("account:transactionTable__header_name__amount"),
      width: 150,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.amount, params?.row?.currency),
    },
    {
      field: "name",
      headerName: t("transactionTable__header_name__customerName"),
      flex: 1,
    },
    {
      field: "unique_id",
      headerName: t("transactionTable__header_name__reference"),
      flex: 1,
    },
    {
      field: "receipt",
      width: 100,
      headerName: "Details",
      renderCell: (params) => {
        const transaction = params?.row as Transaction;
        if (!transaction.id) {
          return <></>;
        }
        return (
          <Button
            onClick={() =>
              fetchTransactionDetail({
                balance_id: transaction.balance_id,
                transaction_id: transaction.id,
              })
            }
          >
            <DetailIcon />
          </Button>
        );
      },
    },
  ];

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloadTransactionsCsv({
        fileName: "outgoing-payments",
        transaction_type: "conversion",
        date_from: filter.from,
        date_to: filter.to,
        ...(filter?.provider && { provider: filter?.provider }),
      }),
  });

  const {
    onCsvDownload: onCsvOptimizedDownload,
    documentLoading: documentOptimizedLoading,
  } = useCsvDownloadHandler({
    download: () =>
      downloadOptimizedTransactionsCsv({
        fileName: "outgoing-payments",
        transaction_type: "conversion",
        date_from: filter.from,
        date_to: filter.to,
        ...(filter?.provider && { provider: filter?.provider }),
      }),
  });

  return (
    <>
      <>
        {(documentLoading || documentOptimizedLoading) && (
          <div className={classes.downloadFile}>
            <Loader />
          </div>
        )}
      </>
      <MainTemplate>
        <PaymentDetailModal
          open={modalOpen}
          setOpen={setModalOpen}
          data={transactionDetail}
        />
        <div className={classes.paymentsContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("conversions__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label={t("filter__search")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({ ...state, search: e.target.value }))
                  }
                  value={filter.search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="white" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("outgoing__payment__page__add__status")}
                  variant="outlined"
                  select
                  fullWidth
                  value={filter.status}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({
                      ...state,
                      status: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">
                    {t("outgoing__payment__page__add__status")}
                  </MenuItem>
                  {paymentStatuses?.map(
                    (option: AccountDataset, index: number) => (
                      <MenuItem key={`${option}-${index}`} value={option.code}>
                        {option.text}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("filter__from")}
                  variant="outlined"
                  type="date"
                  fullWidth
                  value={filter.from}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({ ...state, from: e.target.value }))
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("filter__to")}
                  variant="outlined"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filter.to}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({ ...state, to: e.target.value }))
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("filter__providers")}
                  variant="outlined"
                  select
                  fullWidth
                  value={filter.provider}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({
                      ...state,
                      provider: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">{t("filter__provider")}</MenuItem>
                  {allProviders?.map((provider: string, index: number) => (
                    <MenuItem
                      key={`${provider}-${index}`}
                      value={provider?.toLowerCase()}
                    >
                      {sanitizeProvidersString(provider)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("filter__currency")}
                  variant="outlined"
                  select
                  fullWidth
                  value={filter.currency}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({
                      ...state,
                      currency: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">{t("filter__currency")}</MenuItem>
                  {currencies?.map(
                    (currency: AccountDatasetCurrency, index: number) => (
                      <MenuItem
                        key={`${currency.code}-${index}`}
                        value={currency.code}
                      >
                        {currency?.text}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  className={classes.csvBtn}
                  onClick={() => onCsvDownload()}
                >
                  <CsvIcon />
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.csvBtn}
                  onClick={() => onCsvOptimizedDownload()}
                >
                  <CsvIconDemo />
                </Button>
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={transactions}
            rowCount={pagination?.total_entries}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default Conversions;
