import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams, SearchIcon } from "@material-ui/data-grid";
import FullScreenModal from "components/atoms/FullScreenModal";
import DetailIcon from "components/atoms/icons/DetailIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import React, { ChangeEvent, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getAccountsLegalEntities,
  getAccountStatus,
  getAccountsCurrencies,
} from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import { handleFailure } from "resHandlers";
import AccountStatusBadge from "components/organisms/accounts/AccountStatusBadge";
import CreateAccountModal from "../components/organisms/accounts/CreateAccountModal";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { AccountResponse } from "entities/accounts/Account";
import DetailButton from "components/atoms/DetailButton";
import {
  downloadAccountsCsv,
  downloadOptimizedTransactionsCsv,
  fetchUserAccountBo,
} from "services/accounts";
import { Pagination } from "entities/accounts/Pagination";
import { AccountDataset } from "entities/accounts/AccountDataset";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import Loader from "components/atoms/icons/Loader";
import { formatDate } from "utils";
import CsvIconDemo from "components/atoms/icons/CsvIconDemo";
import { getAllProviders } from "api/accounts";
import { sanitizeProvidersString } from "utils/sanitizeProvidersString";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  flexCsv: {
    flex: 0.1,
  },
  csvBtn: {
    height: "100%",
    minHeight: "54px",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
}));

const Accounts: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("account");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountsCustomer, setAccountsCustomer] = useState(
    [] as AccountResponse[]
  );
  const [pageNo, setPageNo] = useState(0);
  const [allProviders, setAllProviders] = useState<string[]>([]);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filter, setFilter] = useState({
    type: "customer",
    status: "",
    entity: "",
    keyword: "",
    feeCurrency: "",
    provider: "",
  });

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloadAccountsCsv({
        fileName: "customer-accounts",
        ...(filter?.type && { type: filter?.type }),
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.entity && { entity: filter?.entity }),
        ...(filter?.feeCurrency && { feeCurrency: filter?.feeCurrency }),
        ...(filter?.keyword && { keyword: filter?.keyword }),
        ...(filter?.provider && { provider: filter?.provider }),
      }),
  });

  const {
    onCsvDownload: onCsvOptimizedDownload,
    documentLoading: documentOptimizedLoading,
  } = useCsvDownloadHandler({
    download: () =>
      downloadOptimizedTransactionsCsv({
        fileName: "customer-accounts-demo",
        ...(filter?.type && { type: filter?.type }),
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.entity && { entity: filter?.entity }),
        ...(filter?.feeCurrency && { feeCurrency: filter?.feeCurrency }),
        ...(filter?.keyword && { keyword: filter?.keyword }),
        ...(filter?.provider && { provider: filter?.provider }),
      }),
  });

  const accountStatus = useSelector(getAccountStatus);
  const accountEntitys = useSelector(getAccountsLegalEntities);
  const accountCurrencies = useSelector(getAccountsCurrencies);

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "account_name",
      headerName: t("listTable__header_name__accountHolder"),
      flex: 1,
    },
    {
      field: "legal_entity",
      flex: 1,
      headerName: t("listTable__header_name__customer_type"),
    },
    {
      field: "status",
      flex: 1.2,
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => (
        <>
          <AccountStatusBadge status={params?.row?.status} />
          {params?.row?.status}
        </>
      ),
    },
    {
      field: "created_at",
      flex: 1,
      headerName: t("listTable__header_name__created_at"),
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.created_at, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.created_at, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "details",
      width: 100,
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        const accountId = params?.row?.account_id as number;
        if (!accountId) {
          return <DetailIcon />;
        }
        return <DetailButton to={`/accounts/${accountId}`} />;
      },
    },
  ];

  const fetchAllProviders = useCallback(async ({ ignore }): Promise<void> => {
    try {
      if (!ignore) {
        const res = await getAllProviders();
        setAllProviders(res);
      }
    } catch (e: any) {
      handleFailure(e);
    }
  }, []);

  useEffect(() => {
    let ignore = false;

    fetchAllProviders({ ignore });
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    const fetchAllCustomerAccounts = async () => {
      setLoading(true);
      try {
        const accountRes = await fetchUserAccountBo({
          ...(filter?.type && { type: filter?.type }),
          ...(filter?.status && { status: filter?.status }),
          ...(filter?.entity && { entity: filter?.entity }),
          ...(filter?.feeCurrency && { feeCurrency: filter?.feeCurrency }),
          ...(filter?.keyword && { keyword: filter?.keyword }),
          ...(filter?.provider && { provider: filter?.provider }),
          size: TABLE_PAGE_SIZE,
          page: pageNo,
        });

        const { pagination, accounts } = accountRes;
        setPagination(pagination);
        setAccountsCustomer(accounts);
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setLoading(false);
      }
    };

    void fetchAllCustomerAccounts();
  }, [pageNo, filter]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {(documentLoading || documentOptimizedLoading) && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <FullScreenModal open={modalOpen} onClose={onCloseModal}>
          <CreateAccountModal onClose={onCloseModal} />
        </FullScreenModal>
        <div className={classes.accountsContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("customers__accounts__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                label={t("filter__search")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({ ...state, keyword: e.target.value }))
                }
                value={filter.keyword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <SearchIcon color="primary" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__status")}
                variant="outlined"
                select
                fullWidth
                value={filter.status}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="">{t("filter__status")}</MenuItem>
                {accountStatus?.map((option: AccountDataset, index: number) => (
                  <MenuItem key={`${option}-${index}`} value={option.code}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__providers")}
                variant="outlined"
                select
                fullWidth
                value={filter.provider}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    provider: e.target.value,
                  }))
                }
              >
                <MenuItem value="">{t("filter__provider")}</MenuItem>
                {allProviders?.map((provider: string, index: number) => (
                  <MenuItem
                    key={`${provider}-${index}`}
                    value={provider?.toLowerCase()}
                  >
                    {sanitizeProvidersString(provider)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("customers__accounts__customer__type")}
                variant="outlined"
                select
                fullWidth
                value={filter.entity}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    entity: e.target.value,
                  }))
                }
              >
                <MenuItem value="">
                  {t("customers__accounts__customer__type")}
                </MenuItem>
                {accountEntitys?.map(
                  (option: AccountDataset, index: number) => (
                    <MenuItem key={`${option}-${index}`} value={option.code}>
                      {option.text}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("customers__accounts__currencies")}
                variant="outlined"
                select
                fullWidth
                value={filter.feeCurrency}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    feeCurrency: e.target.value,
                  }))
                }
              >
                <MenuItem value="">
                  {t("customers__accounts__currencies")}
                </MenuItem>
                {accountCurrencies?.map(
                  (option: AccountDataset, index: number) => (
                    <MenuItem key={`${option}-${index}`} value={option.code}>
                      {option.text}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                className={classes.csvBtn}
                onClick={() => onCsvDownload()}
              >
                <CsvIcon />
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                className={classes.csvBtn}
                onClick={() => onCsvOptimizedDownload()}
              >
                <CsvIconDemo />
              </Button>
            </Grid>
          </Grid>
          <QuidDataGrid
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={accountsCustomer}
            rowCount={pagination?.total_entries}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default Accounts;
