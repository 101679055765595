import React, { ReactElement } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { CellParams, ColDef } from "@material-ui/data-grid";
import { format } from "date-fns";
import clsx from "clsx";
import AssignTaskButton from "components/atoms/AssignTaskButton";
import TaskCommentsButton from "components/atoms/TaskCommentsButton";
import TaskAttachmentsButton from "components/atoms/TaskAttachmentsButton";
import DetailButton from "components/atoms/DetailButton";
import jwt_decode from "jwt-decode";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles((theme) => ({
  clientName: { color: "#888" },
  flexContainerCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&-column": {
      flexDirection: "column",
    },
  },
  cellIdContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 8,
    lineHeight: "normal",
    height: "100%",
    color: "#37383C",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  newCircle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  taskDefinition: {
    fontSize: "18px",
  },
  taskCreation: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 4,
    alignItems: "center",
    color: "#929292",
    fontSize: "14px",
    justifyContent: "center",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const useVerificationColumns = ({
  detailMode,
  fetch,
  onUnassignRedirectTo,
  isTaskUnassignable,
  isHistory,
}: {
  pageNo?: number;
  detailMode?: boolean;
  fetch?: () => void;
  onUnassignRedirectTo?: string;
  isTaskUnassignable?: boolean;
  isHistory?: string;
}) => {
  const classes = useStyles();

  const columns: ColDef[] = [
    {
      field: "id",
      flex: 6,
      renderCell: (params: CellParams) => {
        const values = params?.row as Task;
        const statusCn = clsx(
          classes.statusCircle,
          `${classes.statusCircle}-${values.execution}`
        );

        const corporateToMatch = [
          "PersonCorporateIdentityVerification",
          "PersonCorporateAddressVerification",
          "PersonCorporateEKYCVerification",
          "CorporateFinancialVerificationAccount",
          "CheckCompanyDetails",
          "UploadCompanyFormationConfirmation",
          "CorporateCheckAccountDetailsExternal",
          "CorporateCheckAccountDetailsDirect",
          "CompanyDetailsError",
          "CompanyFormationError",
        ];

        const getAuthor = () => {
          if (corporateToMatch.includes(values?.taskDefinitionKey)) {
            return (
              values?.workflowInformation?.company ||
              values?.workflowInformation?.source ||
              "n/a"
            );
          }
          return (
            values?.workflowInformation?.source ||
            values?.workflowInformation?.company ||
            "n/a"
          );
        };
        return (
          <div className={classes.flexContainerCenter}>
            {true && <div className={classes.newCircle}></div>}
            <div className={classes.cellIdContainer}>
              <Typography className={classes.taskDefinition}>
                {values.name}{" "}
                <span className={classes.clientName}>
                  {`by  `}
                  {getAuthor()}
                </span>
              </Typography>
              <div className={classes.taskCreation}>
                <div>
                  {values.id} <small>created </small>
                  {format(
                    new Date(values.createTime),
                    "dd MMM yyyy, hh:mm:ss aaaa"
                  )}
                </div>
                <div className={statusCn}></div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      flex: 2,
      renderCell: (params: CellParams): ReactElement => {
        const task = params?.row as Task;
        const token: { preferred_username: string } = jwt_decode(
          JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
        );
        const backofficeUsername = token.preferred_username;
        if (
          fetch === undefined ||
          (task.assignee !== "" && task.assignee !== backofficeUsername)
        ) {
          return (
            <AssignTaskButton
              task={task}
              unassignable={false}
              onUnassignRedirectTo={onUnassignRedirectTo}
              refetch={fetch}
            />
          );
        } else {
          return (
            <AssignTaskButton
              task={task}
              unassignable={!!isTaskUnassignable}
              onUnassignRedirectTo={onUnassignRedirectTo}
              refetch={fetch}
            />
          );
        }
      },
    },
    {
      field: "createTime",
      hide: true,
    },
    {
      field: "comments",
      flex: 1,
      renderCell: (params: CellParams) => {
        const task = params?.row as Task;
        return <TaskCommentsButton task={task} disableModal={!detailMode} />;
      },
    },
    {
      field: "attachment",
      flex: 1,
      renderCell: (params: CellParams) => {
        const task = params?.row as Task;
        return <TaskAttachmentsButton task={task} disableModal={!detailMode} />;
      },
    },
  ];

  if (!detailMode) {
    columns.push({
      field: "menu",
      renderCell: (params: CellParams): ReactElement => {
        const task = params?.row as Task;
        if (isHistory !== undefined) {
          return (
            <DetailButton
              to={`/history/${task.taskDefinitionKey}/${task.id}`}
            />
          );
        }
        return <DetailButton to={`/${task.taskDefinitionKey}/${task.id}`} />;
      },
    });
  }
  return [columns];
};

export default useVerificationColumns;
