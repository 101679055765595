import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { GenericObject } from "entities/tasks/Identity";
import React, { ChangeEvent, ReactNode, KeyboardEventHandler } from "react";
import {
  useController,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";

const useStyles = makeStyles({
  textField: {
    background: "#ffffff",
    marginBottom: 15,
  },
});

interface QuidTextFieldProps {
  name: string;
  label?: string;
  rules: RegisterOptions | undefined;
  defaultValues: GenericObject;
  children?: ReactNode;
  textFieldProps?: TextFieldProps;
  inputProps?: object;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  type?: string;
  disabled?: boolean;
  rows?: string;
  helperText?: string;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement> | undefined;
}
const QuidTextField: React.FC<QuidTextFieldProps> = ({
  name,
  label,
  rules,
  defaultValues,
  children,
  textFieldProps,
  disabled,
  onBlur: onOuterBlur,
  onChange: onOuterChange,
  type,
  onKeyDown,
  inputProps,
  helperText,
}) => {
  const { errors, control } = useFormContext();
  const classes = useStyles();

  const {
    field: { ref, onChange, onBlur, value },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValues[name],
  });

  return (
    <TextField
      disabled={disabled}
      type={type ?? "text"}
      {...textFieldProps}
      inputProps={inputProps}
      label={label}
      variant="outlined"
      margin="none"
      onKeyDown={onKeyDown}
      className={classes.textField}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        if (onOuterChange) {
          onOuterChange(e);
        }
      }}
      onBlur={() => {
        onBlur();
        if (onOuterBlur) {
          onOuterBlur();
        }
      }}
      value={value}
      inputRef={ref}
      helperText={
        helperText && errors?.[name] ? (
          <>
            {errors[name]?.message} <br /> {helperText}
          </>
        ) : errors?.[name] ? (
          errors[name]?.message
        ) : helperText ? (
          helperText
        ) : null
      }
      error={!!errors[name]}
    >
      {children}
    </TextField>
  );
};

export default QuidTextField;
