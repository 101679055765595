import { Button, makeStyles, Grid, Typography } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import FullScreenModal from "components/atoms/FullScreenModal";
import { loadCardById, unloadCardById } from "api/cards";

export interface LoadUnloadModalProps {
  id: number;
  type: string;
  open: boolean;
  refetch: () => void;
  onCloseModal: (open: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  form: {
    maxWidth: "100%",
  },
  mt56: {
    marginTop: 56,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

interface FormValues {
  amount: number;
}

const LoadUnloadModal: React.FC<LoadUnloadModalProps> = ({
  id,
  type,
  open,
  refetch,
  onCloseModal,
}) => {
  const { t } = useTranslation("cards");
  const dispatch = useDispatch();
  const classes = useStyles();

  const defaultValues: FormValues = {
    amount: 0,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onFormSubmit = async (values: FormValues) => {
    try {
      if (values?.amount >= 0 || values?.amount < 0) {
        if (type === "load") {
          await loadCardById({ id, amount: values?.amount });
        } else if (type === "unload") {
          await unloadCardById({ id, amount: values?.amount });
        } else {
          return;
        }
        refetch();
        reset(defaultValues);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t(`${type}__amount__modal__successful__message`),
            type: "success",
          })
        );
        onCloseModal(false);
      }
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const handleCloseModal = () => {
    reset(defaultValues);
    onCloseModal(false);
  };

  return (
    <FullScreenModal open={open} onClose={handleCloseModal}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt56}>
            <Typography variant="h1" className={classes.title}>
              {t(`card__${type}__modal__title`)}
            </Typography>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12}>
                <Grid item>
                  <QuidTextField
                    name="amount"
                    type="number"
                    rules={{
                      required: t("amount__modal__required") as string,
                    }}
                    textFieldProps={{
                      select: false,
                      fullWidth: true,
                    }}
                    label={t("amount__modal__name")}
                    defaultValues={defaultValues}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="actions actions-approve"
                >
                  {t("button__save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </FullScreenModal>
  );
};

export default LoadUnloadModal;
