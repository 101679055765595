import React, { useState } from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import { InvestmentDetailResponse } from "@portit/core/entities/Investments";
import {
  acceptInvestement,
  refundInvestment,
  rejectInvestement,
  stopInvestment,
} from "api/investments";
import { useHistory } from "react-router";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

interface InvestmentDetailModalProps {
  isVisibleConfirmButton?: boolean;
  open: boolean | undefined;
  confirmOpportunity: () => void;
  data: InvestmentDetailResponse;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    marginTop: "30px",
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginBottom: "20px",
  },
  actionsCancel: {
    backgroundColor: "#ff0000",
  },
  actionsApprove: {
    backgroundColor: "#32CD32",
  },
  actionsView: {
    backgroundColor: "#ffff00",
  },
  actionsConfirm: {
    backgroundColor: "#FF6F0F",
  },
  actionsStop: {
    backgroundColor: "#ff0000",
  },
  actionsRefund: {
    backgroundColor: "#FF6F0F",
  },
  buttonViewOpportunity: {
    width: "100%",
    display: "flex",
    marginTop: 16,
    alignItems: "center",
    flexDirection: "column",
  },
}));

const InvestmentDetailModal: React.FC<InvestmentDetailModalProps> = ({
  open,
  data,
  setOpen,
  confirmOpportunity,
  isVisibleConfirmButton,
}) => {
  const { t } = useTranslation("investments");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const investmentStatus = data?.status?.toLowerCase();
  const [buttonClicked, setButtonClicked] = useState(false);

  const investmentDetailsToPrint: InvestmentDetailResponse =
    data?.currency === "USDT" ||
    data?.currency === "BTC" ||
    data?.currency === "SBB"
      ? {
          tokenPrice: data?.tokenPrice
            ? data?.tokenPrice === "0E-18"
              ? "0.00000000000000000"
              : data?.tokenPrice
            : "n/a",
          calculatedPrice: data?.calculatedPrice
            ? data?.calculatedPrice === "0E-18"
              ? "0.00000000000000000"
              : data?.calculatedPrice
            : "n/a",
          feeAmount: data?.feeAmount
            ? data?.feeAmount === "0E-18"
              ? "0.00000000000000000"
              : data?.feeAmount
            : "n/a",
          totalPrice: data?.totalPrice
            ? data?.totalPrice === "0E-18"
              ? "0.00000000000000000"
              : data?.totalPrice
            : "n/a",
          walletId: data?.walletId || "n/a",
          lastUpdated:
            formatDate(data?.lastUpdated, "dd/MM/yyyy HH:mm:ss") || "n/a",
          reference: data?.reference || "n/a",
          id: data?.id || 0,
          partyId: data?.partyId || 0,
          date: data?.date
            ? format(new Date(data?.date), "dd/MM/yyyy HH:mm:ss")
            : "",
          status: data?.status,
          investmentType: data?.investmentType,
          noToken: data?.noToken || 0,
          currency: data?.currency,
          agreedTandC: false,
          investedAmount: data?.investedAmount,
          totalAmountInvested: data?.totalAmountInvested,
        }
      : {
          tokenPrice: data?.tokenPrice
            ? data?.tokenPrice === "0E-18"
              ? "0.00"
              : data?.tokenPrice.slice(0, -16)
            : "n/a",
          calculatedPrice: data?.calculatedPrice
            ? data?.calculatedPrice === "0E-18"
              ? "0.00"
              : data?.calculatedPrice.slice(0, -16)
            : "n/a",
          feeAmount: data?.feeAmount
            ? data?.feeAmount === "0E-18"
              ? "0.00"
              : data?.feeAmount.slice(0, -16)
            : "n/a",
          totalPrice: data?.totalPrice
            ? data?.totalPrice === "0E-18"
              ? "0.00"
              : data?.totalPrice.slice(0, -16)
            : "n/a",
          walletId: data?.walletId || "n/a",
          lastUpdated:
            formatDate(data?.lastUpdated, "dd/MM/yyyy HH:mm:ss") || "n/a",
          reference: data?.reference || "n/a",
          id: data?.id || 0,
          partyId: data?.partyId || 0,
          date: data?.date
            ? format(new Date(data?.date), "dd/MM/yyyy HH:mm:ss")
            : "",
          status: data?.status ?? "Pending",
          investmentType: data?.investmentType ?? "Buy",
          noToken: data?.noToken || 0,
          currency: data?.currency ?? "EUR",
          agreedTandC: false,
          investedAmount: data?.investedAmount,
          totalAmountInvested: data?.totalAmountInvested,
        };

  const opportunityDetailsToPrint: any = {
    name: data?.opportunity?.name,
    status: data?.opportunity?.status,
    currency: data?.opportunity?.currency,
    referralPercentage: `${data?.opportunity?.referralPercentage}%`,
  };

  const opportunitycustomerDetailsToPrint: any = {
    id: data?.customer?.id,
    firstname: data?.customer?.name,
    lastname: data?.customer?.surname,
    type: data?.customer?.type,
  };

  const opportunitycustomerbalanceDetailsToPrint: any = {
    walletId: data.walletId,
  };

  const handleOnClick = async (type: string) => {
    try {
      setButtonClicked(true);
      if (type === "cancel") {
        await rejectInvestement(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement rejected",
            type: "error",
          })
        );
      } else if (
        type === "accept" &&
        process.env.REACT_APP_PROJECT !== "elviria"
      ) {
        await acceptInvestement(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement accepted",
            type: "success",
          })
        );
      } else if (type === "stop") {
        await stopInvestment(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement stopped",
            type: "success",
          })
        );
      } else if (type === "refund") {
        await refundInvestment(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement refunded",
            type: "success",
          })
        );
      } else {
        return null;
      }
      setButtonClicked(false);
      setOpen(false);
    } catch (err: any) {
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: err?.error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const viewOpportunity = () => {
    if (data?.opportunity?.id) {
      history.push(`/opportunities/${data?.opportunity?.id}`);
    }
  };

  return (
    <FullScreenModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("modal__title__investment_details")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            <DetailsBlock
              label="details_title"
              toPrint={investmentDetailsToPrint}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <DetailsBlock
              label="details_investment_opportunity"
              toPrint={opportunityDetailsToPrint}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <DetailsBlock
              label="details_investment_customer"
              toPrint={opportunitycustomerDetailsToPrint}
            />
            <DetailsBlock
              label="details_investment_customer_balance"
              toPrint={opportunitycustomerbalanceDetailsToPrint}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          {investmentStatus !== "pending_blockchain" &&
            investmentStatus !== "Cancelled" &&
            investmentStatus !== "processed" &&
            investmentStatus !== "done" &&
            !buttonClicked && (
              <>
                {process.env.REACT_APP_PROJECT !== "elviria" && (
                  <Button
                    variant="contained"
                    className={classes.actionsApprove}
                    onClick={() => handleOnClick("accept")}
                  >
                    {t("button_accept")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  className={classes.actionsCancel}
                  onClick={() => handleOnClick("cancel")}
                >
                  {t("button_cancel")}
                </Button>
              </>
            )}
          <Button
            variant="contained"
            className={classes.actionsView}
            onClick={viewOpportunity}
          >
            {t("button_view_opportunity")}
          </Button>
          {isVisibleConfirmButton && (
            <Button
              variant="contained"
              className={classes.actionsConfirm}
              onClick={confirmOpportunity}
            >
              {t("button_try_to_confirm")}
            </Button>
          )}
          {investmentStatus === "done" &&
            data?.opportunity?.opportunitySetup?.type ===
              "LOCKED_STREAMABLE_TYPE" && (
              <>
                <Button
                  variant="contained"
                  className={classes.actionsStop}
                  onClick={() => handleOnClick("stop")}
                >
                  {t("button_stop")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionsRefund}
                  onClick={() => handleOnClick("refund")}
                >
                  {t("button_refund")}
                </Button>
              </>
            )}
        </div>
      </div>
    </FullScreenModal>
  );
};

export default InvestmentDetailModal;
