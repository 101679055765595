import { Button, Grid, createStyles, makeStyles } from "@material-ui/core";
import { ColDef } from "@material-ui/data-grid";
import { fetchCryptoBeneficiariesManagementByPartyId } from "@portit/core/api/Crypto";
import { BeneficiaryDetailResponse } from "@portit/core/entities/Crypto";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import CreateBeneficiaryModal from "components/organisms/crypto/CreateBeneficiaryModal";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const useStyles = makeStyles(() =>
  createStyles({
    btnContainer: {
      width: "100%",
    },
  })
);

const CryptoWalletBeneficiares: React.FC<
  RouteComponentProps<{ partyId: string; id: string }>
> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("crypto");
  const [loading, setLoading] = useState(false);
  const partyId = parseInt(match.params.partyId, 10);
  // const id = parseInt(match.params.id, 10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [currencies, setCurrencies] = useState([] as Currency[]);
  const [beneficiaries, setBeneficiaries] = useState(
    [] as BeneficiaryDetailResponse[]
  );

  const getCryptoWalletBeneficiaries = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await fetchCryptoBeneficiariesManagementByPartyId(partyId);
      const resMapped = res?.beneficiaries?.map((item, index) => {
        return {
          ...item,
          id: `${item.name}-${index}`,
        };
      });
      setBeneficiaries(resMapped);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [t, dispatch, partyId]);

  // TODO to implement when the network api will be created
  // const getCurrencies = useCallback(async () => {
  //   try {
  //     const res = await fetchCryptoCurrenciesEnums();
  //     setCurrencies(res?.currencies);
  //   } catch (err: any) {
  //     const message =
  //       err instanceof ServerFailure
  //         ? (err as ServerFailure)?.error?.message
  //         : (err as NetworkFailure)?.message;
  //     dispatch(
  //       promptsSlice.actions.openSnackbar({
  //         message,
  //         type: "error",
  //       })
  //     );
  //   }
  // }, [t, dispatch]);

  useEffect(() => {
    // void getCurrencies();
    void getCryptoWalletBeneficiaries();
  }, []);

  const columns: ColDef[] = [
    { field: "id", headerName: t("listTable__header_name__id"), flex: 1 },
    {
      field: "name",
      headerName: t("listTable__header_name__name"),
      flex: 1,
    },
    {
      field: "",
      flex: 1,
      headerName: t("listTable__header_name__wallets_number"),
      renderCell: ({ row }) => {
        return <div>{row?.wallets?.length}</div>;
      },
    },
  ];

  return (
    <MainTemplate>
      <CreateBeneficiaryModal
        partyId={partyId}
        open={isModalOpen}
        // currencies={currencies}
        refetch={getCryptoWalletBeneficiaries}
        onCloseModal={(state) => setIsModalOpen(state)}
      />
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("cryptowallet__crypto__page__title")}</QuidTitle>
        </Grid>
        <Grid item xs={12} className={classes.btnContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsModalOpen(true)}
          >
            {t("label_button__beneficiary_create")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <QuidDataGrid
          disableSelectionOnClick
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={beneficiaries ?? []}
          rowCount={beneficiaries?.length}
        />
      </Grid>
    </MainTemplate>
  );
};

export default CryptoWalletBeneficiares;
