import { apiClient } from "@portit/core/utils/api/apiClient";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import {
  FetchTasksSearchParams,
  ResponseTasks,
  FetchTasksParams,
  FetchTaskParams,
  mapHistoryTaskToEndpoint,
  mapTaskKeyToEndpoint,
  AcceptTaskParams,
  RejectTaskParams,
  AssignMeTaskParams,
  UnassignMeTaskParams,
  FetchDocumentParams,
  FetchAttachmentParams,
  CreateCommentParams,
  GetCompanyDocumentParams,
  GetCompanyPersonDocumentParams,
  CreateCompanyDocumentParams,
  UpdateCompanyDocumentParams,
  UpdateCompanyPersonDocumentParams,
  UpdateCompanyDocumentDetailsParams,
  UpdateDocumentParams,
  UpdateDocumentDetailsParams,
  FetchHistoryFlowsSearchParams,
  ResponseFetchHistoryFlowsSearch,
} from "entities/tasks/TaskEntity";
import { handleApiClientError } from "utils";

export async function fetchTasksKYCSearched(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/verification/v1/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchHistoricalSearchedTasks(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/verification/v1/history/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchWorkflowGBGIndividualIdentityTasksSearch(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/gbg/individual/v1/identity/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchWorkflowGBGIndividualIdentityHistoryTasksSearch(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/gbg/individual/v1/identity/history/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchWorkflowGBGIndividualEkycTasksSearch(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/gbg/individual/v1/ekyc/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchWorkflowGBGIndividualEkycHistoryTasksSearch(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/gbg/individual/v1/ekyc/history/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchTasksAccountsSearched(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/accountopening/v1/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchHistoricalSearchedTasksAccounts(
  params?: FetchTasksSearchParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/accountopening/v1/history/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchAllTasks(
  params?: FetchTasksParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.get<ResponseTasks>(
      `workflow/verification/v1/tasks/?onlyUnasigned=false`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchAllTasksAccounts(
  params?: FetchTasksParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.get<ResponseTasks>(
      `workflow/accountopening/v1/tasks/?onlyUnasigned=false`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchAllTasksCompanyFormations(
  params?: FetchTasksParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.get<ResponseTasks>(
      `workflow/companyformation/v1/tasks/?onlyUnasigned=false`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchTasksCompanyFormationsSearched(
  params?: FetchTasksParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/companyformation/v1/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchHistoricalTasksCompanyFormationsSearch(
  params?: FetchTasksParams
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.post<ResponseTasks>(
      `/workflow/companyformation/v1/history/tasks/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getTask(params: FetchTaskParams): Promise<any> {
  const { taskId, taskKey, isHistory } = params;
  try {
    if (isHistory) {
      const res = await apiClient.get<any>(
        `${mapHistoryTaskToEndpoint[taskKey]}/${taskId}`,
        "workflow"
      );
      return res;
    } else {
      const res = await apiClient.get<any>(
        `${mapTaskKeyToEndpoint[taskKey]}/${taskId}`,
        "workflow"
      );
      return res;
    }
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function acceptTask(params: AcceptTaskParams): Promise<any> {
  try {
    const { taskId, taskKey, request } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/accept`,
      "workflow",
      request
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function TaskDocumentQualify(params: {
  processId: string;
  documentId: number;
  qualify: "OCR" | "MANUAL";
}): Promise<any> {
  try {
    const res = await apiClient.put<ResponseTasks>(
      `/workflow/fourstop/individual/verification/v1/processes/${params.processId}/documents/${params.documentId}/?qualify=${params.qualify}`,
      "workflow",
      {}
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function rejectTask(params: RejectTaskParams): Promise<any> {
  try {
    const { taskId, taskKey, request } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/reject`,
      "workflow",
      request
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function assignMeTask(params: AssignMeTaskParams): Promise<any> {
  try {
    const { taskId, taskKey } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/users/me`,
      "workflow"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function unassignMeTask(
  params: UnassignMeTaskParams
): Promise<any> {
  try {
    const { taskId, taskKey } = params;
    await apiClient.delete<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/users/me`,
      "workflow"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getDocument(params: FetchDocumentParams): Promise<any> {
  try {
    const { taskId, taskKey, documentId } = params;
    const res = await apiClient.get<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/documents/${documentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getHistoricalDocument(
  params: FetchDocumentParams
): Promise<any> {
  try {
    const { taskId, taskKey, documentId } = params;
    const res = await apiClient.get<void>(
      `${mapHistoryTaskToEndpoint[taskKey]}/${taskId}/documents/${documentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchAttachment(
  params: FetchAttachmentParams
): Promise<any> {
  try {
    const { taskId, taskKey, commentId, attachmentId } = params;
    const res = await apiClient.get<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/comments/${commentId}/attachments/${attachmentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchHistorycalAttachment(
  params: FetchAttachmentParams
): Promise<any> {
  try {
    const { taskId, taskKey, commentId, attachmentId } = params;
    const res = await apiClient.get<void>(
      `${mapHistoryTaskToEndpoint[taskKey]}/${taskId}/comments/${commentId}/attachments/${attachmentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createComment(params: CreateCommentParams): Promise<any> {
  try {
    const { taskId, taskKey, request } = params;
    await apiClient.post<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/comments`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCompanyDocument(
  params: GetCompanyDocumentParams
): Promise<any> {
  try {
    const { taskId, taskKey, companyId, documentId } = params;
    const res = await apiClient.get<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/companies/${companyId}/documents/${documentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchHistoricalCompanyDocument(
  params: GetCompanyDocumentParams
): Promise<any> {
  try {
    const { taskId, taskKey, companyId, documentId } = params;
    const res = await apiClient.get<void>(
      `${mapHistoryTaskToEndpoint[taskKey]}/${taskId}/companies/${companyId}/documents/${documentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCompanyPersonDocument(
  params: GetCompanyPersonDocumentParams
): Promise<any> {
  try {
    const {
      taskId,
      taskKey,
      companyId,
      documentId,
      personId,
      personType,
    } = params;
    const res = await apiClient.get<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/companies/${companyId}/${personType}/${personId}/documents/${documentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchHistoricalCompanyPersonDocument(
  params: GetCompanyPersonDocumentParams
): Promise<any> {
  try {
    const {
      taskId,
      taskKey,
      companyId,
      documentId,
      personId,
      personType,
    } = params;
    const res = await apiClient.get<void>(
      `${mapHistoryTaskToEndpoint[taskKey]}/${taskId}/companies/${companyId}/${personType}/${personId}/documents/${documentId}`,
      "workflow",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createCompanyDocument(
  params: CreateCompanyDocumentParams
): Promise<any> {
  try {
    const { taskId, taskKey, request, companyId } = params;
    await apiClient.post<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/companies/${companyId}/documents/`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCompanyDocument(
  params: UpdateCompanyDocumentParams
): Promise<any> {
  try {
    const { taskId, taskKey, request, companyId, documentId } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/companies/${companyId}/documents/${documentId}`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCompanyPersonDocument(
  params: UpdateCompanyPersonDocumentParams
): Promise<any> {
  try {
    const {
      taskId,
      taskKey,
      request,
      companyId,
      documentId,
      personType,
      personId,
    } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/companies/${companyId}/${personType}/${personId}/documents/${documentId}`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCompanyDocumentDetails(
  params: UpdateCompanyDocumentDetailsParams
): Promise<void> {
  try {
    const { taskId, taskKey, request, companyId, documentId } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/companies/${companyId}/documents/${documentId}/additional-details`,
      "workflow",
      request
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateDocument(
  params: UpdateDocumentParams
): Promise<any> {
  try {
    const { taskId, taskKey, request, documentId } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/documents/${documentId}`,
      "workflow",
      request,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateDocumentDetails(
  params: UpdateDocumentDetailsParams
): Promise<any> {
  try {
    const { taskId, taskKey, request, documentId } = params;
    await apiClient.put<void>(
      `${mapTaskKeyToEndpoint[taskKey]}/${taskId}/documents/${documentId}/additional-details`,
      "workflow",
      request
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchHistoryFlowsSearch(
  params?: FetchHistoryFlowsSearchParams
): Promise<ResponseFetchHistoryFlowsSearch> {
  try {
    const res = await apiClient.post<ResponseFetchHistoryFlowsSearch>(
      `workflow/verification/v1/history/flows/searchs`,
      "workflow",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
