import { makeStyles } from "@material-ui/core";
import InfoBox from "components/molecules/InfoBox";
import { InvestmentInfo } from "entities/clients/Investements";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
  },
}));

interface InvestementInfoProps {
  investmentInfo?: InvestmentInfo;
}

const InvestementInfo: React.FC<InvestementInfoProps> = ({
  investmentInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("customer");

  const investmentInfoData = [
    {
      title: t("customer_investment_id"),
      value: investmentInfo?.id || "n/a",
    },
    {
      title: t("customer_investment_idea_allocated_investment"),
      value: `${investmentInfo?.ideaAllocateInvestment}` || "n/a",
    },
    {
      title: t("customer_investment_invested_liquid"),
      value: `${investmentInfo?.investedIliquid}` || "n/a",
    },
    {
      title: t("customer_investment_invested_tokenizacion"),
      value: `${investmentInfo?.investedTokenizacion}` || "n/a",
    },
    {
      title: t("customer_investment_most_liked_assets"),
      value: `${investmentInfo?.mostLikedAssets}` || "n/a",
    },
    {
      title: t("customer_investment_portfolio"),
      value: `${investmentInfo?.portfolio}` || "n/a",
    },
    {
      title: t("customer_investment_professional_investor"),
      value: `${investmentInfo?.professionalInvestor}` || "n/a",
    },
    {
      title: t("customer_investment_quantity_allocate_investment"),
      value: `${investmentInfo?.quantityAllocateInvestment}` || "n/a",
    },
    {
      title: t("customer_investment_wallet_owner"),
      value: `${investmentInfo?.walletOwner}` || "n/a",
    },
  ];

  return (
    <div className={classes.container}>
      <InfoBox
        title={t("customer__investment_info")}
        items={investmentInfoData}
      />
    </div>
  );
};

export default InvestementInfo;
