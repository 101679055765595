import { RowId, ColDef } from "@material-ui/data-grid";
import { associateCostumerOnBehalf } from "api/customers";
import DetailButton from "components/atoms/DetailButton";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { Profile } from "entities/clients/ProfileEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";

interface ProfilesProp {
  profiles?: Profile[];
  children?: ReactNode | ReactNode[];
  loading: boolean;
}

const CustomerProfilesElviria: React.FC<ProfilesProp> = ({
  profiles,
  loading,
}) => {
  const { t } = useTranslation("profiles");
  const dispatch = useDispatch();
  const getFrontofficeUrl = (): string | undefined => {
    switch (process.env.REACT_APP_DEMO_MODE) {
      case "develop":
        return process.env.REACT_APP_BACKOFFICE_DEVELOP_URL;
      case "demo":
        return process.env.REACT_APP_BACKOFFICE_DEMO_URL;
      case "production":
        return process.env.REACT_APP_BACKOFFICE_PRODUCTION_URL;
      default:
        return "";
    }
  };

  const createProfileWithCustomer = async (profileId: RowId) => {
    try {
      await associateCostumerOnBehalf(profileId);
      window.open(
        `${getFrontofficeUrl()}?profileId=${profileId}/#/dashboard`,
        "_blank"
      );
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("profile_id"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "partyId",
      headerName: t("profile_partyId"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("profile_name"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "realationship",
      headerName: t("profile_realationship"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "default",
      headerName: t("profile_default"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address.country",
      headerName: t("profile_country"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "",
      headerName: t("profile_access"),
      renderCell: (params) => (
        <div>
          <DetailButton
            to={""}
            onClick={() => createProfileWithCustomer(params.row.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <QuidDataGrid
        rows={profiles || []}
        columns={columns}
        loading={loading}
        rowCount={profiles?.length}
        pageSize={profiles?.length}
        hideFooter={true}
      />
    </>
  );
};

export default CustomerProfilesElviria;
