import React from "react";
import { makeStyles } from "@material-ui/core";

export interface IconProps {
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.primary.main,
  },
}));

const AttachmentIcon: React.FC<IconProps> = ({ color }) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M19.7143 5.274C19.7143 6.078 19.5578 6.855 19.2449 7.605C18.932 8.355 18.498 9.012 17.943 9.576L10.0077 17.568L8.90951 16.47L16.8448 8.478C17.7186 7.614 18.1556 6.546 18.1556 5.274C18.1556 4.206 17.8102 3.321 17.1194 2.619C16.4286 1.917 15.5577 1.566 14.5067 1.566C13.3023 1.566 12.2395 2.016 11.3184 2.916L2.58606 11.718C1.88936 12.426 1.54101 13.224 1.54101 14.112C1.54101 14.796 1.75947 15.357 2.19638 15.795C2.63329 16.233 3.18829 16.452 3.86138 16.452C4.74702 16.452 5.53818 16.098 6.23489 15.39L12.9126 8.694C13.5384 8.034 13.8514 7.452 13.8514 6.948C13.8514 6.696 13.7569 6.498 13.568 6.354C13.379 6.21 13.1547 6.138 12.8949 6.138C12.3281 6.138 11.8203 6.378 11.3716 6.858L5.34925 12.906L4.26877 11.808L10.2734 5.76C11.0528 4.968 11.9266 4.572 12.8949 4.572C13.5798 4.572 14.1702 4.794 14.6662 5.238C15.1621 5.682 15.4101 6.246 15.4101 6.93C15.4101 7.866 14.9437 8.82 14.0108 9.792L7.33308 16.488C6.34116 17.496 5.18393 18 3.86138 18C2.76319 18 1.84508 17.631 1.10705 16.893C0.369015 16.155 0 15.228 0 14.112C0 12.792 0.495957 11.628 1.48787 10.62L10.2203 1.818C11.4483 0.606 12.8772 0 14.5067 0C15.9828 0 17.2197 0.504 18.2176 1.512C19.2154 2.52 19.7143 3.774 19.7143 5.274Z"
        className={classes.icon}
        fill={color}
      />
    </svg>
  );
};

export default AttachmentIcon;
