import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps, useHistory } from "react-router-dom";
import AccountList from "components/organisms/common/AccountList";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { handleFailure } from "resHandlers";
import { fetchCompanyDetails } from "services/companies";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import { useCallback, useEffect, useState } from "react";
import { fetchCustomerDetails } from "services/customers";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";

const AccountsPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id: number = +match.params.id;
  const from = history?.location?.state as Object;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<CustomerDetails | CompanyDetails>();

  useEffect(() => {
    if (id && Object.values(from)[0] === "company") {
      void getCompanyDetails(id);
    }
    if (id && Object.values(from)[0] === "customer") {
      void getCustomerDetails(id);
    }
  }, [id]);

  const getCompanyDetails = async (id: number | string): Promise<void> => {
    try {
      setLoading(true);
      const companyDetails = await fetchCompanyDetails({
        companyId: id as number,
      });
      setDetails(companyDetails);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setDetails]
  );

  return (
    <MainTemplate>
      {details?.accounts !== undefined &&
        details?.totalAccounts !== undefined && (
          <AccountList
            accounts={details?.accounts}
            total={details?.totalAccounts}
            loading={loading}
          ></AccountList>
        )}
    </MainTemplate>
  );
};

export default AccountsPage;
