import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { RouteComponentProps } from "react-router";
import CompanyInfo from "components/organisms/companies/CompanyInfo";
import { fetchCompanyDetails } from "services/companies";
import { CompanyDetails } from "entities/clients/CompanyEntity";

const useStyles = makeStyles((theme) => ({
  watchIcon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#37383C",
  },
  offerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const UpdateCompanyInfo: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("company");
  const dispatch = useDispatch();
  const companyId: number = +match.params.id;
  const detailsInitialState: CompanyDetails = ({
    company: null,
  } as unknown) as CompanyDetails;
  const [details, setDetails] = useState(detailsInitialState);

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetails(companyDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetails, dispatch]
  );

  useEffect(() => {
    if (companyId) {
      void getCompanyDetails(companyId);
    }
  }, [companyId]);

  return (
    <MainTemplate>
      <div className={classes.offerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("company__title__info")}</QuidTitle>
          </Grid>
        </Grid>
        <CompanyInfo
          refetch={() => getCompanyDetails(companyId)}
          company={details?.company}
        />
      </div>
    </MainTemplate>
  );
};

export default UpdateCompanyInfo;
