import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { Merchant } from "entities/clients/MerchantEntity";
import {
  OfferListParams,
  OfferListResponse,
  OfferDetailsParams,
  OfferDetailsResponse,
  OfferImageDetailsParams,
  CustomerOfferListResponse,
  MerchantOfferListResponse,
  CustomerOffer,
  Offer,
  OfferRedeemedListResponse,
  StatusEnumsResponse,
  DownloadOfferParams,
} from "entities/offer/OfferEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function deleteOffers(
  params: OfferDetailsParams
): Promise<OfferListResponse> {
  try {
    const res = await apiClient.post<OfferListResponse>(
      `/offers/v1/offers/${params.offerId}/delete`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fecthOfferStatuses(): Promise<StatusEnumsResponse> {
  try {
    const res = await apiClient.get<StatusEnumsResponse>(
      `/offers/v1/referenceData/offers/statuses`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOffersList(
  params: OfferListParams
): Promise<OfferListResponse> {
  try {
    const res = await apiClient.get<OfferListResponse>(
      `/offers/v1/offers/`,
      "company",
      {
        page: params.page,
        size: params.size,
        order: "offerId",
        orderType: "desc",
        ...(params.keyword && { keyword: params.keyword }),
        ...(params.status && { status: params.status }),
        ...(params.offerType && { offerType: params.offerType }),
        ...(params.currency && { currency: params.currency }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOfferByOfferId(
  params: OfferDetailsParams
): Promise<OfferDetailsResponse> {
  try {
    const res = await apiClient.get<OfferDetailsResponse>(
      `/offers/v1/offers/${params.offerId}/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getIndividualsThatRedeemedOfferByOfferId(params: {
  offerId: number;
}): Promise<OfferRedeemedListResponse> {
  try {
    const res = await apiClient.get<OfferRedeemedListResponse>(
      `/offers/v1/offers/${params.offerId}/customers`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOffersByCustomerId(
  customerId: number,
  params: OfferListParams
): Promise<OfferListResponse> {
  try {
    const res = await apiClient.get<OfferListResponse>(
      `/offers/v1/customers/${customerId}/offers`,
      "account",
      {
        page: params.page,
        size: params.size,
      }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCustomerOffersByCustomerId(
  customerId: number,
  params: OfferListParams
): Promise<CustomerOfferListResponse> {
  try {
    const res = await apiClient.get<CustomerOfferListResponse>(
      `/offers/v1/customers/parties/${customerId}/offers`,
      "account",
      {
        page: params.page,
        size: params.size,
      }
    );
    const offersWithId = res.customerOffers.map((offer: CustomerOffer) => ({
      ...offer,
      id: offer.offer.offerId,
    }));
    return { pagination: res.pagination, customerOffers: offersWithId };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchMerchantOffersByMerchantId(
  partyId: number | undefined,
  params: OfferListParams
): Promise<MerchantOfferListResponse> {
  try {
    const res = await apiClient.get<MerchantOfferListResponse>(
      `/offers/v1/merchants/parties/${partyId}/offers`,
      "account",
      {
        page: params.page,
        size: params.size,
      }
    );
    const offersWithId = res.merchantOffers.map((offer: Offer) => ({
      id: offer.offerId,
      ...offer,
    }));
    return { pagination: res.pagination, merchantOffers: offersWithId };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchMerchantById(merchantId: number): Promise<Merchant> {
  try {
    const res = await apiClient.get<Merchant>(
      `/offers/v1/merchants/${merchantId}`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchOfferImageByImageId(
  params: OfferImageDetailsParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/offers/v1/offers/images/${params.imageId}`,
      "account",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadOffersDocument(
  params: DownloadOfferParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/offers/v1/offers/csv`,
      "account",
      {
        ...(params && {
          merchantId: params?.merchantId,
          offerType: params?.offerType,
          status: params?.status,
        }),
      },
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
