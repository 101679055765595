import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  time: {
    maxWidth: "calc(100% / 12 * 11)",
    marginLeft: "max(calc(100% / 12 * 1), 120px)",
  },
  timeWrapper: {
    maxWidth: "calc(100vw / 12 * 10.2)",
    padding: "calc(100vw / 12 * 0.25) 0px",
    margin: "0px auto",
  },
  body1: {
    color: "#91A1AD",
  },
}));

const TimeComponent = () => {
  const classes = useStyles();
  const time = new Date();

  return (
    <div className={classes.time}>
      <div className={classes.timeWrapper}>
        <Typography variant="h5">
          {time.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          {`${time.toLocaleDateString("en-US", {
            weekday: "long",
          })} ${time.getDate()}
          ${time.toLocaleString("en-US", { month: "long" })}
          ${time.toLocaleString("en-US", { year: "numeric" })}`}
        </Typography>
      </div>
    </div>
  );
};

export default TimeComponent;
