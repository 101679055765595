import { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { useHistory } from "react-router";
import ApproveRejectButtons from "./ApproveRejectButtons";
import QuidFormControl from "components/atoms/QuidFormControl";
import QuidTextField from "components/atoms/QuidTextField";
import { FormProvider, useForm } from "react-hook-form";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { Document } from "entities/clients/DocumentEntity";
import { VerificationRequest } from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  usefulLinks: {
    justifyContent: "center",
  },
  textarea: {
    marginTop: 18,
    marginBottom: 22,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    padding: 50,
    backgroundColor: "#FAFAFA",
    paddingTop: 100,

    "& .box": {
      display: "flex",
      flexWrap: "wrap",
      padding: "50px 100px",
      backgroundColor: "#FFF",
      borderRadius: 60,

      "& .MuiFormControlLabel-root, & .MuiTextField-root, & .MuiNumberField-root": {
        width: "100%",
      },

      "& .reject": {
        textAlign: "center",
        marginBottom: 20,
        color: "#FF6F0F",
      },

      "& .actions": {
        minHeight: 50,
        margin: "0 10px",

        "&-reject": {
          backgroundColor: "#FF6F0F",
        },
      },
    },
  },
}));

interface ApproveRejectBoxProps {
  checks: any;
  task?: any;
  accept: (values: VerificationRequest) => Promise<unknown>;
  reject: (values: VerificationRequest) => Promise<unknown>;
  docs?: Document[];
  disableAccept?: boolean;
  disableReject?: boolean;
}

const infoAcceptIsNotPresent = (doc?: Document) => {
  if (
    doc?.additionalInfo !== null &&
    doc?.additionalInfo !== "" &&
    doc?.additionalInfo !== "{}" &&
    doc?.additionalInfo !== undefined
  ) {
    return false;
  }
  return true;
};

const hasAcceptUncompletedInfo = (docs?: Document[]) => {
  return docs?.some((doc) => infoAcceptIsNotPresent(doc));
};

const defaultValues: VerificationRequest = {
  variables: {},
  checks: {},
  result: {
    status: "",
    reason: "",
    description: "",
  },
};

const ApproveRejectBox = ({
  accept,
  reject,
  checks,
  task,
  docs,
  disableAccept,
  disableReject,
}: ApproveRejectBoxProps) => {
  const classes = useStyles();
  const { t } = useTranslation("validations");
  const history = useHistory();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const checkAcceptAdditionalInfo = hasAcceptUncompletedInfo(docs);
  const checkRejectButton =
    !methods.watch("result.reason") || !methods.watch("result.description");

  const isDisableAcceptButton =
    checkAcceptAdditionalInfo || (disableAccept ? disableAccept : false);

  const isDisableRejectButton =
    checkRejectButton || (disableReject ? disableAccept : false);

  useEffect(() => {
    if (task) {
      methods.reset({
        checks: task.checks,
        result: task.result || defaultValues.result,
        variables: task.variables,
      });
    }
  }, [task]);

  const onReject = async (values: VerificationRequest) => {
    if (!values?.result?.description || !values?.result?.reason) {
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: "To reject, description and reason should be fill",
          type: "error",
        })
      );
      return;
    }
    try {
      await reject(values);
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const onAccept = async (values: VerificationRequest) => {
    if (
      !Object.keys(values.checks).every((c) => values.checks[c]) ||
      values.result.description ||
      values.result.reason
    ) {
      dispatch(
        promptsSlice.actions.openSnackbar({
          message:
            "To accept, all the checks must be marked and the other fields empty",
          type: "error",
        })
      );
      return;
    }
    try {
      await accept(values);
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Grid item xs={12} className={classes.container}>
          <Grid item xs={10} className="box">
            <Grid item xs={6}>
              <Typography variant="h6" className="section-title">
                {t("approveActionsChecks__title")}
              </Typography>
              {Object.keys(checks).map((c: string, i: number) => (
                <QuidFormControl
                  key={`checkbox-verification-${i}`}
                  formControl={<Checkbox color="primary" />}
                  name={`checks.${c}`}
                  defaultValues={checks}
                  label={t(`approvalChecks.${c}`)}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className="section-title">
                {t("approveActionsMessage__title")}
              </Typography>
              <QuidTextField
                name="result.reason"
                rules={{}}
                label="Select a reason"
                defaultValues={defaultValues}
                textFieldProps={{ select: true, fullWidth: true }}
              >
                <MenuItem value="">Select a reason</MenuItem>
                <MenuItem value="incorrect-requirements">
                  Incorrect requirements
                </MenuItem>
                <MenuItem value="fraud-attempt">Fraud attempt</MenuItem>
                <MenuItem value="risk-appetite-policy">
                  Risk appetite policy
                </MenuItem>
              </QuidTextField>
              <QuidTextField
                name="result.description"
                rules={{}}
                label="Type a message for the client about the rejection*"
                defaultValues={defaultValues}
                textFieldProps={{
                  className: classes.textarea,
                  multiline: true,
                  rows: 4,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ApproveRejectButtons
                disableReject={isDisableRejectButton}
                disableAccept={isDisableAcceptButton}
                onAccept={methods.handleSubmit(onAccept)}
                onReject={methods.handleSubmit(onReject)}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.usefulLinks}>
                  {Object.keys(checks).filter((check: string) => {
                    return check === "DIRECTORS_AUSTRALIAN_CHECKED";
                  }).length >= 1 && (
                    <div className={classes.buttonsWrapper}>
                      <Typography
                        variant="h6"
                        className="section-title-vertical-margin"
                      >
                        Utility Links
                      </Typography>
                    </div>
                  )}
                  {Object.keys(checks)
                    .filter((check: string) => {
                      return check === "DIRECTORS_AUSTRALIAN_CHECKED";
                    })
                    .map((c: string) => {
                      if (c === "DIRECTORS_AUSTRALIAN_CHECKED") {
                        return (
                          <div>
                            <a
                              target="_blank"
                              href="https://abr.business.gov.au/"
                            >
                              <Typography variant="body1">
                                https://abr.business.gov.au/
                              </Typography>
                            </a>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ApproveRejectBox;
