import { makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import clsx from "clsx";
import CrossIcon from "./icons/CrossIcon";

const useStyles = makeStyles((theme) => ({
  "@keyframes modalFadeIn": {
    from: { transform: "translateY(-100%);opacity: 0;" },
    to: { transform: "translateY(0);opacity: 1;" },
  },
  "@keyframes modalFadeOut": {
    from: { transform: "translateY(0);opacity: 1;" },
    to: { transform: "translateY(-100%);opacity: 0;" },
  },
  modalOpening: {
    animationName: "$modalFadeIn",
  },
  modalClosing: {
    animationName: "$modalFadeOut",
  },
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    background: "#FAFAFA",
    zIndex: 100,
    right: 0,
    display: "flex",
    transform: "translateY(-100%);",
    animationDuration: ".5s",
    animationFillMode: "forwards",
  },
  closeBtn: {
    position: "absolute",
    top: "3.7%",
    right: "2.1%",
    width: 52,
    height: 52,
    background: theme.palette.secondary.main,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

export interface FullScreenModalProps {
  children: ReactNode | ReactNode[];
  open: boolean | undefined;
  onClose: () => void;
}

const FullScreenModal: React.FC<FullScreenModalProps> = (props) => {
  const classes = useStyles(props);

  const modalCn = clsx(
    classes.container,
    typeof props.open !== "undefined"
      ? props.open === true
        ? classes.modalOpening
        : classes.modalClosing
      : undefined
  );

  return (
    <div className={modalCn}>
      <div className={classes.closeBtn} onClick={() => props.onClose()}>
        <CrossIcon color="#ffffff" />
      </div>
      {props.children}
    </div>
  );
};

export default FullScreenModal;
