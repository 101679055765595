import { makeStyles, Typography } from "@material-ui/core";
import QuidUploadField from "components/atoms/QuidUploadField";
import Attachment from "components/molecules/verifications/Attachment";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { DetailsRow } from "./DetailsBlock";
import { updateDocument } from "api/tasks";
import { Document } from "entities/clients/DocumentEntity";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  selfieDocContainer: {
    "& .MuiTextField-root": {
      display: "block",
    },
  },
}));

interface SelfieDocBlockProps {
  doc: Document;
  refetch: () => Promise<void>;
  taskId: string;
  downloadDoc: (params: any) => Promise<FilePayload>;
  taskKey: Task["taskDefinitionKey"];
  isAssignedToUser: boolean;
}

const SelfieDocBlock: React.FC<SelfieDocBlockProps> = ({
  doc,
  refetch,
  taskId,
  downloadDoc,
  taskKey,
  isAssignedToUser,
}) => {
  const { t } = useTranslation(["validations", "documentTypes", "countries"]);
  const classes = useStyles();

  const onDocChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length === 1) {
      try {
        await updateDocument({
          taskKey,
          taskId,
          documentId: (doc.id as unknown) as string,
          request: { document: e.target.files[0] },
        });
        handleSuccessfulMessage("Document uploaded");
        refetch();
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  return (
    <div className={classes.selfieDocContainer}>
      <Typography variant="h6" className="section-title">
        {t("document__selfie__title")}
      </Typography>
      <Attachment attachment={doc} downloadDoc={downloadDoc} />
      <DetailsRow label="Selfie" value="selfie" index={0} />

      {isAssignedToUser && (
        <div>
          <Typography variant="h6" className="section-title">
            Replace image
          </Typography>
          <QuidUploadField
            inputId="selfie-doc"
            onChange={onDocChange}
            label="Upload document"
          />
        </div>
      )}
    </div>
  );
};

export default SelfieDocBlock;
