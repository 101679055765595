import React, { useCallback, useEffect, useState } from "react";
import { PageChangeParams } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import { FetchTasksSearchParams, Task } from "entities/tasks/TaskEntity";
import { makeStyles } from "@material-ui/core";
import { fetchHistoricalTasksCompanyFormationsSearch } from "api/tasks";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#37383C",
    marginTop: 30,
    "& .MuiDataGrid": {
      "&-colCellWrapper": {
        height: "40px",
      },
      "&-row": {
        backgroundColor: "#FFFFFF",
        borderBottom: "2px solid #FFFFFF",
        borderTop: "1px solid #FFFFFF",
      },
      "&-columnsContainer": {
        backgroundColor: theme.palette.secondary.main,
        color: "#FFFFFF",
      },
      "&-iconSeparator": {
        display: "none",
      },
    },
  },
}));

interface taskProps {
  customerId: FetchTasksSearchParams;
}

const CompanyPastTask: React.FC<taskProps> = ({ customerId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pageNo, setPageNo] = useState(0);
  const [pastTask, setPastTask] = useState<Task[]>([]);
  const [pastTotal, setPastTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      void fetchPastCompanyTasks(customerId as number);
    }
  }, [customerId]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const [columnsVerificationHistorical] = useVerificationColumns({
    pageNo,
    isHistory: "true",
  });

  const fetchPastCompanyTasks = useCallback(
    async (id: number | string): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchHistoricalTasksCompanyFormationsSearch({
          customerId: id as number,
        });
        setPastTask(res.tasks);
        setPastTotal(res.total);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setPastTask]
  );

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      className={classes.root}
      rows={pastTask}
      columns={columnsVerificationHistorical}
      disableSelectionOnClick
      hideHeader
      rowCount={pastTotal}
      loading={loading}
    />
  );
};

export default CompanyPastTask;
