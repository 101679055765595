import {
  ReconciliationParams,
  ReconciliationResponse,
  TransactionReferencesRequest,
  TransactionReferencesResponse,
} from "../entities/Accounts";
import { apiClient } from "../utils/api/apiClient";

export async function fetchRelatedEntityData(
  params: TransactionReferencesRequest
): Promise<TransactionReferencesResponse> {
  try {
    const res = await apiClient.get<TransactionReferencesResponse>(
      `/accounts/v1/transfers/from/transaction/${params.relatedEntityId}`,
      "account"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchAllReconciliations(
  params: ReconciliationParams
): Promise<ReconciliationResponse> {
  try {
    const res = await apiClient.get<ReconciliationResponse>(
      `/accounts/v1/reconciliation/bo/all`,
      "account",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "asc",
      }
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function postChangeReconciliationStatus(
  params: ReconciliationParams
): Promise<ReconciliationResponse> {
  try {
    const res = await apiClient.post<ReconciliationResponse>(
      `/accounts/v1/reconciliation/bo/change/status`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}
