import { Address, PartyAddress } from "entities/clients/AddressEntity";
import {
  AddressStatus,
  EnhancedAddress,
} from "entities/clients/CustomerBackofficeEntity";

export const flattenAddresses = (addresses: PartyAddress[]) =>
  addresses
    ? addresses.map(
        ({
          address,
          partyId,
          use,
          statuses,
        }: {
          address: Address;
          partyId: number;
          status: string;
          use?: string;
          statuses?: AddressStatus[];
        }): EnhancedAddress => {
          return {
            ...address,
            partyId,
            ...(use && { use }),
            ...(statuses && { statuses }),
          };
        }
      )
    : [];
