import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/authentication";
import SidebarComponent from "components/atoms/SidebarComponent";
import TimeComponent from "components/atoms/TimeComponent";

const useStyles = makeStyles(() => ({
  page: {
    maxWidth: "calc(100% / 12 * 11)",
    marginLeft: "max(calc(100% / 12 * 1), 120px)",
  },
  pageWrapper: {
    background: "#FAFAFA",
    maxWidth: "calc(100vw / 12 * 9)",
    padding: "calc(100vw / 12 * 0.3) calc(100vw / 12 * 0.6)",
    margin: "0px auto",
  },
}));

const MainTemplate: React.FC = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onLogout = () => {
    void dispatch(logout() as any);
  };

  return (
    <>
      <SidebarComponent onLogout={onLogout} />
      <TimeComponent />
      <div className={classes.page}>
        <div className={classes.pageWrapper}>{props.children}</div>
      </div>
    </>
  );
};

export default MainTemplate;
