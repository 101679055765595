import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import { ServerFailure } from "features/core/Failure";
import { useTranslation } from "react-i18next";
import { TABLE_PAGE_SIZE } from "shared/constants";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { fetchCompaniesList } from "api/companies";
import { PartiesPagination } from "entities/accounts/Pagination";
import { Company } from "entities/clients/CompanyEntity";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloadCompaniesCsv } from "services/companies";
import Loader from "components/atoms/icons/Loader";
import SearchIcon from "components/atoms/icons/SearchIcon";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  companyContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
}));

const Companies: React.FC = () => {
  const { t } = useTranslation("company");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [corporates, setCorporates] = useState([] as Company[]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    from: "",
    to: "",
  });
  const [pagination, setPagination] = useState({} as PartiesPagination);

  useEffect(() => {
    const getCompanies = async (): Promise<void> => {
      setLoading(true);
      try {
        const companyRes = await fetchCompaniesList({
          pageNo,
          pageSize: TABLE_PAGE_SIZE,
          ...(filters.search && { keyword: filters.search }),
          ...(filters.from && { from: (filters.from as unknown) as number }),
          ...(filters.to && { to: (filters.to as unknown) as number }),
        });
        setPagination(companyRes?.pagination);
        setCorporates(companyRes?.companyList);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCompanies();
  }, [pageNo, filters]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloadCompaniesCsv({
        fileName: "companies",
        ...(filters.search && { keyword: filters.search }),
        ...(filters.from && { from: (filters.from as unknown) as number }),
        ...(filters.to && { to: (filters.to as unknown) as number }),
      }),
  });

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "companyName",
      headerName: t("listTable__header_name__companyName"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "vatNumber",
      headerName: t("listTable__header_name__vatNumber"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "companyDetails",
      headerAlign: "center",
      headerName: "Details",
      renderCell: (params: CellParams): ReactElement => {
        const company = params?.row as Company;
        return <DetailButton to={`/companies/${company.id}`} />;
      },
    },
  ];

  return (
    <>
      <>
        {documentLoading && (
          <div className={classes.downloadFile}>
            <Loader />
          </div>
        )}
      </>
      <MainTemplate>
        <div className={classes.companyContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              {process.env.REACT_APP_KEYCLOAK_REALM === "quid" && (
                <QuidTitle>{t("details__title__company")}</QuidTitle>
              )}
              {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" ||
                process.env.REACT_APP_KEYCLOAK_REALM === "elviria" ||
                process.env.REACT_APP_KEYCLOAK_REALM === "ats" ||
                (process.env.REACT_APP_KEYCLOAK_REALM === "vaffa" && (
                  <QuidTitle>{t("details__title")}</QuidTitle>
                ))}
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  className={classes.csvBtn}
                  onClick={() => onCsvDownload()}
                >
                  <CsvIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("customer__account__from_id")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      from: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.from}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("customer__account__to_id")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      to: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.to}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("details__search")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilters((state) => ({
                      ...state,
                      search: (e.target.value as unknown) as string,
                    }))
                  }
                  value={filters.search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={corporates}
            rowCount={pagination?.totalEntries}
            disableSelectionOnClick
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default Companies;
