import { Currency } from "dinero.js";
import { AddressType, AddressUse } from "entities/clients/AddressEntity";
import { CompanyType } from "entities/clients/CompanyEntity";
import { Country } from "entities/clients/CountryEntity";
import { DocumentSubType } from "entities/clients/DocumentEntity";
import { FetchEnumsResponse } from "entities/clients/Enums";
import {
  FinancialEmploymentType,
  FinancialEmploymentSector,
  FinancialSourceType,
  FinancialCompanySourceType,
} from "entities/clients/FinancialEntity";
import { InvestementStatuses } from "entities/clients/Investements";
import { PersonTitle } from "entities/clients/PersonEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function getCountries(): Promise<Country[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<Country[]>>(
      "/parties/v1/enums/countries",
      "company"
    );

    const { enumValues }: { enumValues: Country[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function getCurrencies(): Promise<Currency[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<Currency[]>>(
      "/parties/v1/enums/financial/currencies",
      "company"
    );

    const { enumValues }: { enumValues: Currency[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchPersonTitle(): Promise<PersonTitle[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<PersonTitle[]>>(
      "/parties/v1/enums/people/titles",
      "company"
    );

    const { enumValues }: { enumValues: PersonTitle[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchDocumentTypes(): Promise<DocumentType[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<DocumentType[]>>(
      "/parties/v1/enums/documents/types",
      "company"
    );

    const { enumValues }: { enumValues: DocumentType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getInvestmentEnums(): Promise<InvestementStatuses> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<InvestementStatuses>>(
      "/investments/v1/referenceData/investments/statuses",
      "company"
    );

    const { enumValues }: { enumValues: InvestementStatuses } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchDocumentSubTypes(): Promise<DocumentSubType[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<DocumentSubType[]>>(
      "/parties/v1/enums/documents/subtypes",
      "company"
    );

    const { enumValues }: { enumValues: DocumentSubType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchAddressTypes(): Promise<AddressType[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<AddressType[]>>(
      "/parties/v1/enums/addresses/types",
      "company"
    );

    const { enumValues }: { enumValues: AddressType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCompanyTypes(): Promise<CompanyType[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<CompanyType[]>>(
      "/parties/v1/enums/companies/legal-entities",
      "company"
    );

    const { enumValues }: { enumValues: CompanyType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchAddressUses(): Promise<AddressUse[]> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<AddressUse[]>>(
      "/parties/v1/enums/addresses/uses",
      "company"
    );

    const { enumValues }: { enumValues: AddressUse[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchFinancialEmploymentTypes(): Promise<
  FinancialEmploymentType[]
> {
  try {
    const res = await apiClient.get<
      FetchEnumsResponse<FinancialEmploymentType[]>
    >("/parties/v1/enums/financial/employments", "company");

    const { enumValues }: { enumValues: FinancialEmploymentType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchFinancialEmploymentSectors(): Promise<
  FinancialEmploymentSector[]
> {
  try {
    const res = await apiClient.get<
      FetchEnumsResponse<FinancialEmploymentSector[]>
    >("/parties/v1/enums/financial/sectors", "company");

    const { enumValues }: { enumValues: FinancialEmploymentSector[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchFinancialSourceTypes(): Promise<
  FinancialSourceType[]
> {
  // TODO we should split between individuals and companies
  try {
    const res = await apiClient.get<FetchEnumsResponse<FinancialSourceType[]>>(
      "/parties/v1/enums/financial/sources/individual",
      "company"
    );
    const { enumValues }: { enumValues: FinancialSourceType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchFinancialCompanySourceTypes(): Promise<
  FinancialCompanySourceType[]
> {
  // TODO we should split between individuals and companies
  try {
    const res = await apiClient.get<
      FetchEnumsResponse<FinancialCompanySourceType[]>
    >("/parties/v1/enums/financial/sources/company", "company");
    const { enumValues }: { enumValues: FinancialCompanySourceType[] } = res;
    return enumValues;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchDocumentSubTypesByType(
  type: string
): Promise<FetchEnumsResponse<DocumentSubType[]>> {
  try {
    const res = await apiClient.get<FetchEnumsResponse<DocumentSubType[]>>(
      `/parties/v1/enums/documents/subtypes/${type}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
