import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { RouteComponentProps } from "react-router";
import { getIndividualsThatRedeemedOfferByOfferId } from "api/offers";
import { OfferCustomersRedeemed } from "entities/offer/OfferEntity";

const useStyles = makeStyles((theme) => ({
  customerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
}));

const CustomersRedeemOffer: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const dispatch = useDispatch();
  const offerId: number = parseInt(match.params.id, 10);
  const [customers, setCustomers] = useState([] as OfferCustomersRedeemed[]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const getCustomers = async (): Promise<void> => {
      setLoading(true);
      try {
        const customerListRes = await getIndividualsThatRedeemedOfferByOfferId({
          offerId,
        });
        const { offerPartyDetailResponses } = customerListRes;
        setCustomers(
          offerPartyDetailResponses.map((customerOffer) => ({
            id: customerOffer.partyId,
            ...customerOffer,
          }))
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCustomers();
  }, [pageNo]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const columns: ColDef[] = [
    { field: "id", headerName: t("listTable__header_name__Id"), flex: 0.5 },
    {
      field: "name",
      headerName: t("listTable__header_name__name"),
      flex: 1,
    },
    {
      field: "offerPartyType",
      headerName: t("listTable__header_name__offer__party__type"),
      flex: 1,
    },
  ];

  return (
    <MainTemplate>
      <div className={classes.customerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("customer__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.filterContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <TextField
                label={t("filter__search")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                value={search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <SearchIcon color="#fff" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={customers}
          rowCount={customers?.length}
          pageSize={customers?.length}
          hideFooter={true}
        />
      </div>
    </MainTemplate>
  );
};

export default CustomersRedeemOffer;
