import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import ProofOfAddressBlock from "components/molecules/verifications/ProofOfAddressBlock";
import { formatDate } from "utils";
import { useSelector } from "react-redux";
import VerificationTemplate from "templates/VerificationTemplate";
import jwt_decode from "jwt-decode";
import {
  updateDocumentDetails,
  updateDocument,
  acceptTask,
  rejectTask,
} from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Document } from "entities/clients/DocumentEntity";
import {
  acceptDocumentCompanies,
  rejectDocumentCompanies,
} from "api/companies";
import { VerificationRequest } from "entities/tasks/Common";
import { handleFailure } from "resHandlers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  notSupported: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    padding: "16px",
    color: "#fafafa",
    backgroundColor: "#FB4C4C",
  },
}));

const addressVerificationChecks = {
  DOCUMENT_READABLE: false,
  DETAILS_MATCH: false,
  POA_TYPE_ACCEPTED: false,
  ISSUED_WITHIN_3_MONTHS: false,
};

export interface IdentityDetails {
  [key: string]: string;
}

export interface AddressDetailsInterface {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postalCode: string;
}

const PersonAddressVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation("validations");
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();

  const taskVariables = task?.variables;
  const personDetail = taskVariables?.person;
  const addressDetail = taskVariables?.address;
  const proofOfAddressDocuments = task?.documents?.filter(
    (d) => d.type === "PROOF_OF_ADDRESS"
  );
  const documents = task?.documents?.filter(
    (d) => d.type === "PROOF_OF_ADDRESS"
  );
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const toPrintPerson: IdentityDetails = {
    personId: personDetail?.id,
    personName: personDetail?.name,
    personSurname: personDetail?.surname,
    personFiscalCode: personDetail?.fiscalCode || "n/a",
    personGender: personDetail?.gender || "n/a",
    personBirthDate: personDetail?.birthDate
      ? formatDate(personDetail.birthDate)
      : "n/a",
    personCountryOfBirth: getCountry(personDetail?.countryOfBirth) || "",
    personNationality: getCountry(personDetail?.nationality) || "",
    personType: taskVariables?.personType,
  };

  const toPrintAddress: AddressDetailsInterface = {
    addressLine1: addressDetail?.addressLine1,
    addressLine2: addressDetail?.addressLine2 || "--",
    city: addressDetail?.city,
    country: getCountry(addressDetail?.country) || "",
    postalCode: addressDetail?.postalCode,
  };

  const onClickApprove = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      documents?.map((document?: Document) => {
        if (document) {
          acceptDocumentCompanies(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickReject = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      documents?.map((document?: Document) => {
        if (document) {
          rejectDocumentCompanies(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (!task) {
    return null;
  }

  return (
    <VerificationTemplate
      refetch={fetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {personDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintPerson}
              label="person_details__title"
            />
          </div>
        )}
        {addressDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintAddress}
              label="addressDetails__title"
            />
          </div>
        )}
        {proofOfAddressDocuments &&
          proofOfAddressDocuments?.map((document) => (
            <div className={classes.detailsWrapperThird}>
              <ProofOfAddressBlock
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
                updateDocDetails={(params) =>
                  updateDocumentDetails({ ...params, taskKey })
                }
                updateDoc={(params) => updateDocument({ ...params, taskKey })}
                refetch={refetch}
                doc={document}
                taskId={taskId}
                canReplace={isHistory === undefined && isAssignedToUser}
              />
            </div>
          ))}
      </div>
      {addressDetail.country === "US" ? (
        <div className={classes.notSupported}>
          {t("country_not_supported", {
            country: addressDetail.country,
          })}
        </div>
      ) : null}
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            docs={documents}
            task={task}
            disableAccept={addressDetail.country === "US"}
            checks={addressVerificationChecks}
            accept={(request) => onClickApprove(request)}
            reject={(request) => onClickReject(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default PersonAddressVerificationComp;
