import { NoParams, UseCase } from "../../../core/UseCase";
import { ServerFailure } from "../../../core/Failure";
import { AuthenticationRepository } from "../repositories/AuthenticationRepository";
import { ApiClientError } from "@portit/core/entities/Api";

export class LogoutUser extends UseCase<NoParams, void> {
  private readonly repository: AuthenticationRepository;

  constructor(repo: AuthenticationRepository) {
    super();
    this.repository = repo;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async run(params: NoParams | undefined): Promise<void> {
    try {
      await this.repository.signOut();
      return;
    } catch (err: any) {
      const e = ServerFailure.fromApiClientError(err as ApiClientError);
      throw e;
    }
  }
}
