import React, { ReactNode } from "react";
import { makeStyles, Grid, Card } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DirectorPerson, UboPerson } from "entities/clients/PersonEntity";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
    borderRadius: 55,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    position: "relative",
    paddingBottom: 20,
    lineHeight: "28px",
    color: "#37383C",
  },
  editIcon: {
    cursor: "pointer",
    padding: "8px 8px",
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "8px",
    right: "16px",
  },
  directorName: {
    fontFamily: "Comfortaa",
    fontStyle: "normal",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    fontSize: "28px",
    lineHeight: "45px",
    paddingTop: "16px",
  },
  removeLink: {
    display: "inline-block",
    margin: "0 10px",
    cursor: "pointer",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: theme.palette.secondary.main,
  },
}));

type PersonCardProps = {
  onRemove: (id: number) => void;
  person: DirectorPerson | UboPerson;
  personType: "directors" | "ubo";
  children: ReactNode;
  companyId?: number;
};

const PersonCard: React.FC<PersonCardProps> = ({
  onRemove,
  person,
  personType,
  children,
  companyId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("person");
  return (
    <Grid item xs={4} key={person?.id}>
      <Card className={classes.paper}>
        <Link to={`/companies/${companyId}/${personType}/${person.id}`}>
          <Edit className={classes.editIcon} />
        </Link>
        <h1 className={classes.directorName}>{person?.name || "n/a"}</h1>
        {children}
        <div>
          <a className={classes.removeLink} onClick={() => onRemove(person.id)}>
            {t("person__card__remove")}
          </a>
        </div>
      </Card>
    </Grid>
  );
};

export default PersonCard;
