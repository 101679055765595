import React, { useCallback, useEffect, useState } from "react";
import { PageChangeParams } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import { FetchTasksSearchParams, Task } from "entities/tasks/TaskEntity";
import { makeStyles } from "@material-ui/core";
import { fetchTasksCompanyFormationsSearched } from "api/tasks";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
}));

interface taskProps {
  customerId: FetchTasksSearchParams;
}

const CompanyTask: React.FC<taskProps> = ({ customerId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(0);
  const [task, setTask] = useState<Task[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      void fetchCompanyTasks(customerId as number);
    }
  }, [customerId]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const [columnsVerification] = useVerificationColumns({
    pageNo,
  });

  const fetchCompanyTasks = useCallback(
    async (id: number | string): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchTasksCompanyFormationsSearched({
          customerId: id as number,
        });
        setTask(res.tasks);
        setTotal(res.total);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setTask]
  );

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      className={classes.root}
      rows={task}
      columns={columnsVerification}
      disableSelectionOnClick
      hideHeader
      rowCount={total}
      loading={loading}
    />
  );
};

export default CompanyTask;
