import { makeStyles, Grid, Button } from "@material-ui/core";
import { sendWelcomeEmail } from "api/customers";
import { fetchProductDetails } from "api/product";
import clsx from "clsx";
import QuidTitle from "components/atoms/QuidTitle";
import CardDetails from "components/molecules/CardDetails";
import InfoBox from "components/molecules/InfoBox";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { ContractDetailResponse } from "entities/products/ProductEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { fetchCustomerDetails } from "services/customers";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

interface Item {
  label: string;
  path: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  buttonsContainer: {
    textAlign: "center",
    justifyContent: "center",
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
  actionWrapper: {
    display: "flex",
  },
  usernameWrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontFamily: "Comfortaa,sans-serif",
    color: "#000000",
    fontSize: 28,
    fontWeight: 700,
  },
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
  },
  tabsWrapper: {
    backgroundColor: "#FAFAFA",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  customerLink: {
    textAlign: "right",
    color: theme.palette.primary.main,
    marginBottom: 10,
    textDecoration: "none",
  },
  title: {
    marginTop: 60,
  },
  container: {
    minHeight: "100px",
  },
  verticalAlign: {
    alignItems: "center",
  },
}));

const ProductDetails: React.FC<
  RouteComponentProps<{ partyId: string; productId: string }>
> = ({ match }) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const dispatch = useDispatch();
  const productId = match.params.productId;
  const customerId = match.params.partyId;
  const [bottomSections, setBottomSections] = useState<Item[]>([]);
  const [
    productDetails,
    setProductDetails,
  ] = useState<ContractDetailResponse>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();

  const btnMl = clsx(`${classes.buttonMargin}--left`);
  const [loading, setLoading] = useState(false);

  const productDetailsData: any[] = [
    {
      title: t("details__label__contract_id"),
      value: productDetails?.contractId || "n/a",
    },
    {
      title: t("details__label__product_description"),
      value: productDetails?.product?.description || "n/a",
    },
    {
      title: t("details__label__product_id"),
      value: productDetails?.id || "n/a",
    },
    {
      title: t("details__label__owner_id"),
      value: productDetails?.ownerId || "n/a",
    },
    {
      title: t("details__label__status"),
      value: productDetails?.status || "n/a",
    },
  ];

  const cardLabelsCustomerProductDetailsHorizontal = [
    {
      label: t("customer_details_card_accounts"),
      path: `/customers/${customerId}/accounts`,
    },
    {
      label: t("customer_details_card_financials"),
      path: `/customers/${customerId}/financial`,
    },
  ];

  useEffect(() => {
    void getCustomerDetails(customerId);
    void getProductDetails(productId);
  }, [dispatch, productId]);

  const getCustomerDetails = useCallback(
    async (customerId: string): Promise<void> => {
      try {
        const details = await fetchCustomerDetails({
          customerId: Number(customerId),
        });
        setCustomerDetails(details);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setProductDetails]
  );

  const getProductDetails = useCallback(
    async (productId: number | string): Promise<void> => {
      try {
        const details = await fetchProductDetails(productId as number);
        setProductDetails(details);
        setBottomSections(cardLabelsCustomerProductDetailsHorizontal);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setProductDetails]
  );

  const sendWelcomeEmailVerification = async (userId: string | undefined) => {
    setLoading(true);
    try {
      if (userId !== undefined) {
        await sendWelcomeEmail(userId);
        handleSuccessfulMessage(t("email__verification__user__sent__message"));
      }
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("product__details__info_title")}</QuidTitle>
        <Grid container className={(classes.container, classes.verticalAlign)}>
          <Grid item xs={8}>
            <InfoBox
              title={t("product_details_info_title")}
              items={productDetailsData}
              product={productDetails}
            />
          </Grid>
          <Grid item xs={4}>
            <CardDetails
              isColumn={true}
              items={bottomSections}
              from={"customer"}
            ></CardDetails>
          </Grid>
        </Grid>
        {productDetails?.product?.description === "Investment" && (
          <Grid container className={classes.buttonsContainer}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                className={btnMl}
                disabled={loading}
                onClick={() =>
                  sendWelcomeEmailVerification(
                    customerDetails?.customer?.user?.userId
                  )
                }
              >
                {t("product__detail__btn__send__email")}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </MainTemplate>
  );
};

export default ProductDetails;
