import { acceptCustomerDocument, rejectCustomerDocument } from "api/customers";
import DocumentList from "components/organisms/common/DocumentList";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import {
  downloadCustomerDocument,
  fetchCustomerDetails,
  uploadCustomerDocument,
} from "services/customers";
import useDocHandlers from "shared/hooks/useDocHandlers";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";
import {
  acceptCompanyDocument,
  rejectCompanyDocument,
  uploadCompanyDocument,
} from "api/companies";
import {
  fetchCompanyDetails,
  downloadCompanyDocumentById,
} from "services/companies";
import { CompanyDetails } from "entities/clients/CompanyEntity";

const DocumentsInfo: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("customer");
  const dispatch = useDispatch();
  const from = useHistory().location?.state as Object;
  const id: number = +match.params.id;
  const [details, setDetails] = useState<CustomerDetails | CompanyDetails>();

  useEffect(() => {
    if (id && Object.values(from)[0] === "customer") {
      void getCustomerDetails(id);
    }
    if (id && Object.values(from)[0] === "company") {
      void getCompanyDetails(id);
    }
  }, [id]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetails]
  );

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetails(companyDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetails]
  );

  const {
    documentLoading,
    onDocAccept,
    onDocDownload,
    onDocReject,
    onDocUpload,
  } = useDocHandlers({
    t,
    entity: {
      id,
      propName:
        Object.values(from)[0] === "customer" ? "customerId" : "companyId",
    },
    refetchEntity:
      Object.values(from)[0] === "customer"
        ? getCustomerDetails
        : getCompanyDetails,
    download:
      Object.values(from)[0] === "customer"
        ? downloadCustomerDocument
        : downloadCompanyDocumentById,
    accept:
      Object.values(from)[0] === "customer"
        ? acceptCustomerDocument
        : acceptCompanyDocument,
    reject:
      Object.values(from)[0] === "customer"
        ? rejectCustomerDocument
        : rejectCompanyDocument,
    upload:
      Object.values(from)[0] === "customer"
        ? uploadCustomerDocument
        : uploadCompanyDocument,
  });

  return (
    <MainTemplate>
      <DocumentList
        documents={details?.documents}
        totalDocuments={details?.documents?.length}
        accept={onDocAccept}
        reject={onDocReject}
        download={onDocDownload}
        loading={documentLoading}
        onUploadDocuments={onDocUpload}
      />
    </MainTemplate>
  );
};
export default DocumentsInfo;
