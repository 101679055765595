import { AccountDatasetResponse } from "entities/accounts/AccountDataset";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function fetchBankAccountTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/bankaccounttypes/`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCountry(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/countries/`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchIdentificationTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/identificationtypes/`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchPaymentStatus(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/paymentstatus/`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchTransactionStatus(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/transactionstatus/`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchAccountTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/accounttypes`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchTransactionTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/transactiontypes`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchTransactionInboundTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/transactiontypes/inbound`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchTransactionOutboundTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/transactiontypes/outbound`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchTransactionDirection(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/transactiondirection/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchPaymentTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/paymenttypes/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchCurrencies(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/currencies/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchLegalEntities(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/legalentities/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchAccountStatus(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/accountstatus/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchRoutingCodeTypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/routingcodetypes/`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchTransactionSubtypes(): Promise<AccountDatasetResponse> {
  try {
    const res = await apiClient.get<AccountDatasetResponse>(
      `/accounts/v1/reference/transaction/subtypes`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
