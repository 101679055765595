import { Grid } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import {
  fetchAllReconciliations,
  postChangeReconciliationStatus,
} from "@portit/core/api/Accounts";
import { ReconciliationDetails } from "@portit/core/entities/Accounts";
import DetailButton from "components/atoms/DetailButton";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import ReconciliationDetailModal from "components/organisms/accounts/ReconciliationDetailModal";
import { Pagination } from "entities/accounts/Pagination";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TABLE_PAGE_SIZE } from "shared/constants";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const ReconciliationList: React.FC = () => {
  const { t } = useTranslation("account");

  const dispatch = useDispatch();

  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({} as Pagination);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [reconciliations, setReconciliations] = useState<
    ReconciliationDetails[]
  >([]);
  const [
    selectedReconciliation,
    setSelectedReconciliation,
  ] = useState<ReconciliationDetails>({} as ReconciliationDetails);

  const status = [
    {
      code: "fixed",
      text: "Fixed",
    },
    {
      code: "pending",
      text: "Pending",
    },
    {
      code: "assigned",
      text: "Assigned",
    },
  ];

  const getReconciliations = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchAllReconciliations({
        page: pageNo,
        size: TABLE_PAGE_SIZE,
      });
      setPagination(res?.pagination);
      setReconciliations(res?.reconciliation_list);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [pageNo]);

  useEffect(() => {
    void getReconciliations();
  }, [dispatch, pageNo]);

  const onCloseModalDetails = () => {
    setIsModalDetailsOpen(false);
  };

  const handleDetailsClick = (id: string) => {
    if (id) {
      const slectedReconciliation = reconciliations?.filter(
        (reconciliation) => reconciliation?.id?.toString() === id
      )?.[0];

      setSelectedReconciliation(slectedReconciliation);
      setIsModalDetailsOpen(true);
    }
  };

  const changeReconcilationStatus = async (
    id: string,
    status: string
  ): Promise<void> => {
    try {
      await postChangeReconciliationStatus({
        status,
        reconciliationId: Number(id),
      });
      void getReconciliations();
      setIsModalDetailsOpen(false);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const columns: ColDef[] = [
    { field: "id", headerName: t("listTable__header_name__id"), flex: 0 },
    {
      field: "anomaly_type",
      headerName: t("listTable__header_name__anomaly_type"),
      flex: 2,
      renderCell: (params) => {
        return params.row.anomaly_type ? params.row.anomaly_type : "n/a";
      },
    },
    {
      field: "priority",
      headerName: t("listTable__header_name__priority"),
      flex: 2,
      renderCell: (params) => {
        return params.row.priority ? params.row.priority : "n/a";
      },
    },
    {
      field: "status",
      headerName: t("listTable__header_name__status"),
      flex: 1,
      renderCell: (params) => {
        return params.row.status ? params.row.status : "n/a";
      },
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        const reconciliationId = params.row.id.toString();

        return (
          <DetailButton onClick={() => handleDetailsClick(reconciliationId)} />
        );
      },
    },
  ];

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  return (
    <>
      <ReconciliationDetailModal
        statusArr={status}
        isOpen={isModalDetailsOpen}
        onCloseModal={onCloseModalDetails}
        reconciliation={selectedReconciliation}
        onSubmit={(id: string, status: string) =>
          changeReconcilationStatus(id, status)
        }
      />
      <MainTemplate>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("reconciliation__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        {reconciliations && (
          <Grid item xs={12}>
            <QuidDataGrid
              disableSelectionOnClick
              sortModel={[{ field: "id", sort: "desc" }]}
              loading={loading}
              columns={columns}
              rows={reconciliations}
              onPageChange={onPageChange}
              rowCount={pagination?.total_entries}
            />
          </Grid>
        )}
      </MainTemplate>
    </>
  );
};

export default ReconciliationList;
