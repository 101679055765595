import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { useEffect, useState } from "react";
import { getProductsManagementCount } from "api/product";
import { ProductCountDetails } from "entities/products/ProductEntity";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getInvestementManagemntStats } from "api/investments";
import { InvestementCountDetails } from "entities/clients/Investements";
import { PaymentsCryptoCountDetails } from "@portit/core/entities/Crypto";
import { fetchCryptoPaymentsManagemntStats } from "@portit/core/api/Crypto";

const useStyles = makeStyles(() => ({
  rowAllign: {
    width: "100%",
    marginTop: 20,
    textAlign: "center",
  },
  item: {
    background: "#FAFAFA",
    padding: "15px",
    margin: "15px",
    cursor: "pointer",
  },
  title: {
    textAlign: "center",
  },
  textBlock: {
    textAlign: "center",
    paddingBottom: "32px",
  },
}));

const DashboardProducts = () => {
  const [productsManagement, setProductsManagement] = useState(
    [] as ProductCountDetails[]
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("dashboardPage");
  const history = useHistory();
  const [filters, setFilters] = useState([] as String[]);
  const [investemnts, setInvestements] = useState<InvestementCountDetails>();
  const [
    paymentsCrypto,
    setPaymentsCrypto,
  ] = useState<PaymentsCryptoCountDetails>();

  useEffect(() => {
    const getTotalTask = async (): Promise<void> => {
      try {
        const productManagementResponse = await getProductsManagementCount();
        setProductsManagement(productManagementResponse?.productCounts);
        if (process.env.REACT_APP_PROJECT === "strfiinvest") {
          const res = await getInvestementManagemntStats();
          setInvestements(res);
          const res1 = await fetchCryptoPaymentsManagemntStats();
          setInvestements(res);
          setPaymentsCrypto(res1);
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };
    void getTotalTask();
    productsFilter();
  }, [process.env.REACT_APP_PROJECT]);

  const productsFilter = () => {
    switch (process.env.REACT_APP_PROJECT) {
      case "quid":
        setFilters(["Company", "Account", "Card", "Walletlight", "Walletfull"]);
        break;
      case "toonie":
        setFilters(["Walletlight", "Walletfull", "Offers"]);
        break;
      case "elviria":
        setFilters(["Account", "Investment"]);
        break;
      case "strfiinvest":
        setFilters(["Account", "Investment"]);
        break;
      case "vaffa":
      case "ats":
        setFilters(["Account", "Vaffacustody", "Vaffabase"]);
        break;
      default:
        setFilters(["Company", "Account", "Card", "Walletlight", "Walletfull"]);
        break;
    }
  };

  return (
    <>
      {process.env.REACT_APP_PROJECT === "strfiinvest" && (
        <>
          <QuidTitle marginBottom="10px">{t("Dashboard")}</QuidTitle>
          <Grid container>
            <Grid item xs={4}>
              <div className={classes.item}>
                <div
                  onClick={() => {
                    history.push({
                      pathname: `/investments`,
                    });
                  }}
                  className="button"
                >
                  <QuidTitle className={classes.title} marginBottom="0px">
                    {"Investment"}
                  </QuidTitle>
                  <div className={classes.textBlock}>
                    <Typography variant="body1">
                      {t("CANCELLED")}: {investemnts?.statuses?.Cancelled ?? 0}
                    </Typography>
                    <Typography variant="body1">
                      {t("REJECTED")}: {investemnts?.statuses?.Rejected ?? 0}
                    </Typography>
                    <Typography variant="body1">
                      {t("PENDING")}: {investemnts?.statuses?.Pending ?? 0}
                    </Typography>
                    <Typography variant="body1">
                      {t("COMPLETED")}: {investemnts?.statuses?.Done ?? 0}
                    </Typography>
                    <Typography variant="body1">
                      {t("SUCCEEDED")}: {investemnts?.statuses?.Processed ?? 0}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.item}>
                <div
                  onClick={() => {
                    history.push({
                      pathname: `/investments`,
                    });
                  }}
                  className="button"
                >
                  <QuidTitle className={classes.title} marginBottom="0px">
                    {"Crypto Payments"}
                  </QuidTitle>
                  <div className={classes.textBlock}>
                    <Typography variant="body1">
                      {t("DONE")}: {paymentsCrypto?.statuses?.Done ?? 0}
                    </Typography>
                    <Typography variant="body1">
                      {t("INITIATED")}:{" "}
                      {paymentsCrypto?.statuses?.Initiated ?? 0}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <QuidTitle marginBottom="10px">{t("Dashboard__products")}</QuidTitle>
      <div className={classes.rowAllign}>
        <Grid container>
          {productsManagement?.length &&
            productsManagement?.map((productManagement) => {
              return filters?.map((filter) => {
                if (filter === productManagement.product.description) {
                  return (
                    <Grid item xs={4}>
                      <div className={classes.item}>
                        <div
                          onClick={() =>
                            history.push({
                              pathname: `/products/${productManagement?.product?.id}`,
                            })
                          }
                          className="button"
                        >
                          <QuidTitle
                            className={classes.title}
                            marginBottom="0px"
                          >
                            {productManagement?.product?.description}
                          </QuidTitle>
                          <div className="textBlock">
                            <Typography variant="body1">
                              {t("CANCELLED")}:{" "}
                              {productManagement?.counts?.CANCELLED ?? 0}
                            </Typography>
                            <Typography variant="body1">
                              {t("PENDING")}:{" "}
                              {productManagement?.counts?.PENDING ?? 0}
                            </Typography>
                            <Typography variant="body1">
                              {t("COMPLETED")}:{" "}
                              {productManagement?.counts?.COMPLETED ?? 0}
                            </Typography>
                            <Typography variant="body1">
                              {t("SUCCEEDED")}:{" "}
                              {productManagement?.counts?.SUCCEEDED ?? 0}
                            </Typography>
                            <Typography variant="body1">
                              {t("WAITING LIST")}:{" "}
                              {productManagement?.counts?.WAITING_LIST ?? 0}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                }
              });
            })}
        </Grid>
      </div>
    </>
  );
};

export default DashboardProducts;
