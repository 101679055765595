import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import CompaniesList from "components/organisms/customers/CompanyList";
import { RouteComponentProps } from "react-router";

const CompanyPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("company");
  const customerId: number = parseInt(match.params.id, 10);

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("details__title")}</QuidTitle>
        </Grid>
      </Grid>
      <CompaniesList customerId={customerId}></CompaniesList>
    </MainTemplate>
  );
};

export default CompanyPage;
