import React, { useEffect, useState, useCallback } from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ApproveRejectButtons from "components/atoms/ApproveRejectButtons";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { acceptTransaction, rejectTransaction } from "api/accounts";
import { AcquiringPayment } from "entities/accounts/Transaction";
import { formatDate } from "utils";
import { BalanceDetails } from "@portit/core/entities/Accounts";
import { BalanceResponse } from "entities/accounts/Balance";

interface PaymentDetailModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  payment?: AcquiringPayment;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginTop: "16px",
    fontSize: 16,
  },
}));

const AcquiringPaymentDetailModal: React.FC<PaymentDetailModalProps> = ({
  setOpen,
  open,
  payment,
}) => {
  const { t } = useTranslation("payments");

  const classes = useStyles();
  const isStatusToPendingReview = payment?.status === "pending_to_review";

  const [missingHours, setMissingHours] = useState("");
  const [isLessThan4Days, setIsLessThan4Days] = useState(false);
  const [disabledActions, setDisabledActions] = useState(false);

  const [
    destinationBalanceDetails,
    setDestinationBalanceDetails,
  ] = useState<BalanceResponse>();

  const [
    sourceBalanceDetails,
    setSourceBalanceDetails,
  ] = useState<BalanceResponse>();

  const transactionDetailsToPrint: AcquiringPayment = {
    paymentSessionId: payment?.paymentSessionId || "n/a",
    status: payment?.status || "n/a",
    walletId: payment?.walletId || "n/a",
    currency: payment?.currency || "n/a",
    amount: payment?.amount || 0,
    created: payment?.created ? formatDate(payment?.created, "dd/MM/yyyy") : "",
    updated: payment?.updated ? formatDate(payment?.updated, "dd/MM/yyyy") : "",
    reason: payment?.reason || "n/a",
    toonieFees: payment?.toonieFees || "n/a",
  };

  const onAccept = async () => {
    setDisabledActions(true);
    try {
      await acceptTransaction({
        transaction_id: (payment?.paymentSessionId as unknown) as number,
      });
      handleSuccessfulMessage("Action received");
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setOpen(false);
      setDisabledActions(false);
    }
  };

  const onReject = async () => {
    setDisabledActions(true);
    try {
      await rejectTransaction({
        transaction_id: (payment?.paymentSessionId as unknown) as number,
      });
      handleSuccessfulMessage("Action received");
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setOpen(false);
      setDisabledActions(false);
    }
  };

  const sourceBalanceDetailsToPrint: BalanceDetails = {
    wallet_id: sourceBalanceDetails?.wallet_id || "n/a",
    account_holder: sourceBalanceDetails?.account_holder || "n/a",
    account_name: sourceBalanceDetails?.account_name || "n/a",
    first_name: sourceBalanceDetails?.first_name || "n/a",
    last_name: sourceBalanceDetails?.last_name || "n/a",
    account_type: sourceBalanceDetails?.account_type || "n/a",
  };

  const destinationBalanceDetailsToPrint: BalanceDetails = {
    wallet_id: destinationBalanceDetails?.wallet_id || "n/a",
    account_holder: destinationBalanceDetails?.account_holder || "n/a",
    account_name: destinationBalanceDetails?.account_name || "n/a",
    first_name: destinationBalanceDetails?.first_name || "n/a",
    last_name: destinationBalanceDetails?.last_name || "n/a",
    account_type: destinationBalanceDetails?.account_type || "n/a",
  };

  const msToTime = useCallback((s: number) => {
    const hours = Math.floor(s / 3600000);
    const minutes = Math.floor((s % 3600000) / 60000);
    return `${hours}h ${minutes}m`;
  }, []);

  const checkIfLessThan4Days = useCallback(() => {
    let getTimeToday = null;
    let hoursToDeadline = null;
    let getTimeTransaction = null;
    let differenceBetweenTime = null;

    const HOURS_4_DAYS = 96;
    const MILLISECONDS_4_DAYS = 60 * 60 * 1000 * 24 * 4;
    const MILLISECONDS_HOURS_4_DAYS = HOURS_4_DAYS * 60 * 60 * 1000;

    if (isStatusToPendingReview && payment) {
      getTimeToday = new Date().getTime();
      getTimeTransaction = Date.parse(payment.created);

      differenceBetweenTime = getTimeToday - getTimeTransaction;

      hoursToDeadline = MILLISECONDS_HOURS_4_DAYS - differenceBetweenTime;

      if (differenceBetweenTime <= MILLISECONDS_4_DAYS) {
        setMissingHours(msToTime(hoursToDeadline));
        setIsLessThan4Days(true);
      } else {
        setIsLessThan4Days(false);
      }
    }
  }, [isStatusToPendingReview, payment]);

  useEffect(() => {
    checkIfLessThan4Days();
  }, [isStatusToPendingReview, payment]);

  const onResetAndCloseModal = () => {
    setOpen(false);
    setSourceBalanceDetails(undefined);
    setDestinationBalanceDetails(undefined);
  };

  return (
    <FullScreenModal open={open} onClose={onResetAndCloseModal}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("acquiring_payment__details__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            <DetailsBlock
              label="details__transaction__details"
              toPrint={transactionDetailsToPrint}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            {sourceBalanceDetails && (
              <DetailsBlock
                label="Source balance details"
                toPrint={sourceBalanceDetailsToPrint}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            {destinationBalanceDetails && (
              <DetailsBlock
                label="Destination balance details"
                toPrint={destinationBalanceDetailsToPrint}
              />
            )}
          </Grid>
        </Grid>
        {isStatusToPendingReview && isLessThan4Days && (
          <Typography variant="h6" className={classes.sectionTitle}>
            {t("4__days__Check", { hoursLeft: missingHours })}
          </Typography>
        )}
        <div style={{ flex: "0 0 15%", alignItems: "center", display: "flex" }}>
          {isStatusToPendingReview && (
            <ApproveRejectButtons
              disabled={disabledActions}
              onAccept={onAccept}
              onReject={onReject}
              labelAcceptButton={
                isLessThan4Days
                  ? t("button__4_days_check__confirm_and_send")
                  : t("button__4_days_check__approve")
              }
            />
          )}
        </div>
      </div>
    </FullScreenModal>
  );
};

export default AcquiringPaymentDetailModal;
