import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import CustomerProfiles from "components/organisms/customers/CustomerProfiles";
import { useCallback, useEffect, useState } from "react";
import { fetchCustomerDetails } from "services/customers";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { RouteComponentProps } from "react-router";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";

const Profiles: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { t } = useTranslation("profiles");
  const dispatch = useDispatch();
  const customerId: number = +match.params.id;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<CustomerDetails>();

  useEffect(() => {
    if (customerId) {
      void getCustomerDetails(customerId);
    }
  }, [customerId]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  return (
    <MainTemplate>
      <Grid container>
        <Grid item xs={12}>
          <QuidTitle variant="h1">{t("profile__title")}</QuidTitle>
        </Grid>
      </Grid>
      <CustomerProfiles
        profiles={details?.profiles}
        loading={loading}
      ></CustomerProfiles>
    </MainTemplate>
  );
};

export default Profiles;
