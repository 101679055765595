import React, { ChangeEvent, useEffect, useState } from "react";
import { makeStyles, Typography, MenuItem } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidUploadField from "components/atoms/QuidUploadField";
import Attachment from "components/molecules/verifications/Attachment";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { DetailsRow } from "./DetailsBlock";
import { format } from "date-fns";
import { Document } from "entities/clients/DocumentEntity";
import { Country } from "entities/clients/CountryEntity";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import {
  VerificationDocumentUploadParams,
  VerificationDocumentAdditionalParams,
} from "entities/tasks/Common";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  identityDocContainer: {
    "& .MuiTextField-root": {
      display: "block",
    },
  },
}));

interface IdentityDocBlockProps {
  docs: Document[];
  refetch: () => Promise<void>;
  taskId: string;
  updateDoc: (params: VerificationDocumentUploadParams) => Promise<void>;
  updateDocDetails: (
    params: VerificationDocumentAdditionalParams
  ) => Promise<void>;
  downloadDoc: (params: {
    documentId: number;
    fileName: string;
    avoidDownloading: boolean;
  }) => Promise<FilePayload>;
  isAssignedToUser: boolean;
}
interface FormValues {
  documentNumber: string;
  issuingDate: string;
  expiringDate: string;
  issuingCountry: string;
  issuingProvider: string;
}

const defaultValues: FormValues = {
  documentNumber: "",
  issuingDate: "",
  expiringDate: "",
  issuingCountry: "",
  issuingProvider: "",
};

const IdentityDocBlock: React.FC<IdentityDocBlockProps> = ({
  docs,
  refetch,
  taskId,
  updateDoc,
  updateDocDetails,
  downloadDoc,
  isAssignedToUser,
}) => {
  const { t } = useTranslation(["validations", "documentTypes", "countries"]);
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const [currentIssueDate, setCurrentIssueDate] = useState("");

  const methods = useForm({
    mode: "onBlur",
    defaultValues: docs?.[0]?.additionalInfo
      ? JSON.parse(docs[0].additionalInfo)
      : defaultValues,
  });

  useEffect(() => {
    methods.reset(JSON.parse(docs[0].additionalInfo));
  }, [methods.reset, docs?.[0]?.additionalInfo]);

  const filterCharacters = (value: string) => {
    const docNumberFiltered = value.replace(/[^a-zA-Z0-9]/g, "");
    methods.setValue("documentNumber", docNumberFiltered);
  };

  const updateDocumentDetails = async (doc: Document, values: FormValues) => {
    try {
      await updateDocDetails({
        taskId,
        documentId: (doc?.id as unknown) as string,
        request: { additionalDetails: JSON.stringify(values) },
      });

      refetch();
      handleSuccessfulMessage("Document info changed");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const onFormSubmit = async (values: FormValues) => {
    if (
      values.issuingCountry === "" ||
      values.issuingDate === "" ||
      values.expiringDate === "" ||
      values.documentNumber === "" ||
      values.issuingProvider === ""
    )
      return;
    docs.forEach((doc: Document) => updateDocumentDetails(doc, values));
  };

  const onDocChange = async (
    e: ChangeEvent<HTMLInputElement>,
    docId: string
  ) => {
    if (e.target?.files?.length === 1) {
      try {
        await updateDoc({
          taskId,
          documentId: docId,
          request: { document: e.target.files[0] },
        });
        refetch();
        handleSuccessfulMessage("Document uploaded");
        methods.reset();
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };
  return (
    <div className={classes.identityDocContainer}>
      <Typography variant="h6" className="section-title">
        {t("document__details__title")}
      </Typography>
      {docs?.map((doc) => (
        <div key={doc.id} style={{ flex: 1 }}>
          <Attachment attachment={doc} downloadDoc={downloadDoc} />
          <DetailsRow
            label="Document type"
            value={t(`documentTypes:${doc.subType}`)}
            index={0}
          />
          {isAssignedToUser && (
            <div>
              <Typography variant="h6" className="section-title">
                Replace document
              </Typography>
              <QuidUploadField
                inputId="identity-doc"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onDocChange(e, doc.id.toString())
                }
                label="Upload document"
              />
            </div>
          )}
        </div>
      ))}
      {isAssignedToUser && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onFormSubmit)}>
            <QuidTextField
              name="issuingProvider"
              label="Issuing provider"
              rules={{
                required: t("issuingProvider__required") as string,
              }}
              defaultValues={defaultValues}
              onBlur={methods.handleSubmit(onFormSubmit)}
              textFieldProps={{ onBlur: methods.handleSubmit(onFormSubmit) }}
            />
            <QuidTextField
              name="issuingCountry"
              rules={{
                required: t("issuing__country__is__required") as string,
              }}
              textFieldProps={{
                select: true,
                fullWidth: true,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              label={t("Issuing Country")}
              defaultValues={defaultValues}
              onBlur={methods.handleSubmit(onFormSubmit)}
            >
              <MenuItem value="">{t("Issuing Country")}</MenuItem>
              {countries.map((country: Country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.text}
                </MenuItem>
              ))}
            </QuidTextField>
            <QuidTextField
              name="documentNumber"
              label="Document number"
              rules={{
                required: t("documentNumber__required") as string,
              }}
              defaultValues={defaultValues}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                filterCharacters(e?.target?.value)
              }
              onBlur={methods.handleSubmit(onFormSubmit)}
              textFieldProps={{ onBlur: methods.handleSubmit(onFormSubmit) }}
            />
            <QuidTextField
              name="issuingDate"
              label="Issuing date"
              rules={{
                required: t("issueDate__required") as string,
              }}
              defaultValues={defaultValues}
              onBlur={methods.handleSubmit(onFormSubmit)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCurrentIssueDate(e?.target?.value);
                methods.setValue("expiringDate", e?.target?.value);
              }}
              inputProps={{ max: format(new Date(), "yyyy-MM-dd") }}
              textFieldProps={{
                margin: "normal",
                type: "date",
                InputLabelProps: { shrink: true },
                onBlur: methods.handleSubmit(onFormSubmit),
              }}
            />
            <QuidTextField
              name="expiringDate"
              label="Expiring date"
              rules={{
                required: t("expiryDate__required") as string,
              }}
              defaultValues={defaultValues}
              onBlur={methods.handleSubmit(onFormSubmit)}
              inputProps={{
                min: currentIssueDate,
              }}
              textFieldProps={{
                margin: "normal",
                type: "date",
                InputLabelProps: { shrink: true },
                onBlur: methods.handleSubmit(onFormSubmit),
              }}
            />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default IdentityDocBlock;
