import { BaseApiClient, ApiClientConfig, UrlResolver } from "./apiRealm";
import { Serializer } from "../Serializer";
import { AxiosRequestConfig, AxiosInstance, AxiosResponse } from "axios";

export class apiClientAxios extends BaseApiClient {
  private token: string | null = null;
  private profile: string | null = null;
  private api: AxiosInstance;
  private serializer: Serializer;

  constructor(
    axiosInstance: AxiosInstance,
    urlResolver: UrlResolver,
    jsonSerializer: Serializer
  ) {
    super(urlResolver);
    this.api = axiosInstance;
    this.serializer = jsonSerializer;

    // converts JSON to JS object
    this.api.interceptors.response.use(this.deserialize.bind(this));
    this.api.interceptors.request.use(this.serialize.bind(this));

    // apply base url from the env file and eventually the auth headers
    this.api.interceptors.request.use(this.addHeaders.bind(this));
  }

  onGet<R>(
    url: string,
    params?: { [key: string]: string },
    config: ApiClientConfig = {}
  ): Promise<R> {
    config.params = params;
    return this.api.get(url, config).then((response: AxiosResponse) => {
      return response.data as R;
    });
  }

  onPut<R>(
    url: string,
    params?: {
      [key: string]: string | object | boolean | number | Blob | File;
    },
    config?: ApiClientConfig
  ): Promise<R> {
    if (
      config?.headers &&
      config.headers["Content-Type"] === "multipart/form-data" &&
      params
    ) {
      const data = new FormData();
      Object.keys(params).forEach((key) => {
        if (params?.[key] instanceof Blob) {
          data.append(key, params?.[key] as Blob);
        } else {
          data.append(key, params?.[key]?.toString());
        }
      });

      return this.api.put(url, data, config).then((response) => {
        return response.data as R;
      });
    }

    return this.api.put(url, params, config).then((response) => {
      return response.data as R;
    });
  }

  onPost<R>(
    url: string,
    params?: {
      [key: string]: string | object | boolean | number | Blob | File;
    },
    config?: ApiClientConfig
  ): Promise<R> {
    if (
      config?.headers &&
      config.headers["Content-Type"] === "multipart/form-data" &&
      params
    ) {
      const data = new FormData();
      Object.keys(params).forEach((key) => {
        if (params?.[key] instanceof Blob) {
          data.append(key, params?.[key] as Blob);
        } else {
          data.append(key, params?.[key]?.toString());
        }
      });

      return this.api.post(url, data, config).then((response) => {
        return response.data as R;
      });
    }

    return this.api.post(url, params, config).then((response) => {
      return response.data as R;
    });
  }

  onHead<R>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.head(url, config).then((response) => {
      return response.data as R;
    });
  }

  onPatch<R>(
    url: string,
    params?: {
      [key: string]: string | object | boolean | number | Blob | File;
    },
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.patch(url, params, config).then((response) => {
      return response.data as R;
    });
  }

  onDelete<R>(url: string, params?: any, config?: ApiClientConfig): Promise<R> {
    return this.api.delete(url, config).then((response) => {
      return response.data as R;
    });
  }

  setProfile(value: string): void {
    this.profile = value;
  }
  setToken(value: string): void {
    this.token = value;
  }

  private addHeaders(param: AxiosRequestConfig) {
    const res = { ...param };

    // inject token when present
    if (this.token) {
      res.headers = {
        Authorization: `Bearer ${this.token}`,
        ...res.headers,
      };
    }

    if (this.profile) {
      res.headers = {
        partyId: `${this.profile}`,
        ...res.headers,
      };
    }

    return res;
  }

  private deserialize(resp: AxiosResponse): AxiosResponse {
    resp.data = this.serializer.deserialize(resp.data);
    return resp;
  }

  private serialize(resp: AxiosRequestConfig): AxiosRequestConfig {
    resp.data = this.serializer.serialize(resp.data);
    return resp;
  }
}
