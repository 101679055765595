import React, { ChangeEvent, useState } from "react";
import {
  IconButton,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { createComment } from "api/tasks";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    zIndex: 999,
    boxShadow: "-12px 22px 61px -11px rgba(0,0,0,0.75);",
    padding: 14,
    backgroundColor: "#fff",

    "& .MuiOutlinedInput-root": {
      borderRadius: 40,
    },
  },
  uploadingFiles: {
    fontSize: 14,
    color: "#B0B0B0",
  },
  pointer: { cursor: "pointer" },
  removePointerEvts: { pointerEvents: "none" },
  attachmentsContainer: {
    width: 365,
    maxHeight: 400,
    minHeight: 100,
    overflowY: "scroll",
    padding: 14,
  },
  attachmentsCloseButtonWithoutattachments: {
    width: "auto !important",
    position: "absolute",
    top: 14,
    right: 14,
  },
  attachmentsCloseButtonWithattachments: {
    width: "auto !important",
    minWidth: "auto",
    marginBottom: 12,
    marginLeft: "auto",
    padding: 0,
    marginRight: 0,
    display: "block",
    "& .MuiButton-label": {
      minWidth: "auto",
    },
  },
  commentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 16,

    "& >p:first-child": {
      wordBreak: "break-all",
      fontSize: 16,
    },
  },
  commentBody: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    padding: 20,
  },
  time: {
    fontSize: 10,
    color: "#929292",
  },
  commentText: {
    fontSize: 14,
    color: "#fff",
    overflowWrap: "break-word",
  },
  inputWrapper: {
    backgroundColor: "#fff",
  },
  inputBtn: { height: 40, width: "40px !important", minWidth: 40 },
  rowContainer: { marginBottom: 24 },
}));

interface AddCommentProps {
  taskKey: Task["taskDefinitionKey"];
  taskId: string;
  refetch: any;
  hasAttachment: Boolean;
}

const CommentsAdd: React.FC<AddCommentProps> = ({
  taskKey,
  taskId,
  refetch,
  hasAttachment,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState<File | null | undefined>();

  const sendComment = async () => {
    try {
      await createComment({
        taskKey,
        taskId,
        request: {
          comment,
          files,
        },
      });
      setComment("");
      setFiles(undefined);
      handleSuccessfulMessage("Comment sent");
      if (refetch) {
        refetch(null);
      }
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <>
      {files && (
        <Typography component="span" className={classes.uploadingFiles}>
          You've selected: {files?.name}
        </Typography>
      )}
      <TextField
        label="Type a comment"
        variant="outlined"
        margin="normal"
        fullWidth
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setComment(e.target.value)
        }
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            sendComment();
          }
        }}
        value={comment}
        id="standard-name"
        InputProps={{
          endAdornment: (
            <>
              {hasAttachment && (
                <div>
                  <input
                    type="file"
                    hidden
                    multiple
                    id="fileUpload"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target?.files?.length === 1) {
                        setFiles(e.target.files[0]);
                      }
                    }}
                  />
                  <label htmlFor="fileUpload" className={classes.pointer}>
                    <IconButton
                      color="primary"
                      className={classes.removePointerEvts}
                    >
                      <CropOriginalIcon />
                    </IconButton>
                  </label>
                </div>
              )}

              <Button
                onClick={sendComment}
                color="primary"
                variant="contained"
                className={classes.inputBtn}
              >
                <ArrowUpwardIcon />
              </Button>
            </>
          ),
        }}
      />
    </>
  );
};

export default CommentsAdd;
