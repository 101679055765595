import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { assignMeTask, unassignMeTask } from "api/tasks";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles((theme) => ({
  assignButton: {
    width: "auto !important",
    fontSize: 14,
    lineHeight: "normal",
    padding: 8,
    textTransform: "uppercase",
    backgroundColor: theme.palette.secondary.main,
  },
  unassignButton: {
    width: "auto !important",
    minWidth: "40px!important",
    padding: 0,
    marginLeft: 10,
    lineHeight: "normal",
    textTransform: "uppercase",
    backgroundColor: theme.palette.secondary.main,
  },
  assigneeButton: {
    backgroundColor: theme.palette.secondary.main,
    padding: 5,
    width: "auto !important",
    borderRadius: 30,
    color: "#fff",
    textTransform: "none",
  },
}));

interface AssignTaskButtonProps {
  task: Task;
  unassignable?: boolean;
  onUnassignRedirectTo?: string;
  refetch?: () => void;
}

const AssignTaskButton: React.FC<AssignTaskButtonProps> = ({
  task,
  unassignable,
  refetch,
  onUnassignRedirectTo,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [hover, setHover] = useState(false);

  const assign = async () => {
    try {
      await assignMeTask({
        taskId: task.id,
        taskKey: task.taskDefinitionKey,
      });
      if (refetch) {
        await refetch();
      }
    } catch (e) {}
  };

  const unassign = async () => {
    try {
      await unassignMeTask({
        taskId: task.id,
        taskKey: task.taskDefinitionKey,
      });
      if (onUnassignRedirectTo) {
        history.push(onUnassignRedirectTo);
        return;
      }
      if (refetch) {
        await refetch();
      }
    } catch (e) {}
  };

  if (task.assignee) {
    return (
      <div
        style={{ display: "flex" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && unassignable ? (
          <Button
            color="primary"
            variant="contained"
            className={classes.assigneeButton}
            onClick={() => unassign()}
          >
            unassign
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            className={classes.assigneeButton}
          >
            {task.assignee.split("@")[0]}
          </Button>
        )}
      </div>
    );
  }

  return (
    <Button
      color="primary"
      variant="contained"
      className={classes.assignButton}
      onClick={assign}
    >
      get it
    </Button>
  );
};

export default AssignTaskButton;
