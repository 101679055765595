import {
  CreateAddressParams,
  PartyAddress,
} from "entities/clients/AddressEntity";
import {
  CompanyDirectorsResponse,
  CompanyUbosResponse,
  CompanyFinancialResponse,
  DeleteCompanyUboParams,
  Company,
  CompanyResponse,
  CompanyDocumentsResponse,
  UpdateCompanyParams,
  CompanyDetailsForm,
  CompanyListParams,
  CompanyList,
  UploadCompanyDocumentParams,
  CompanyAddressResponse,
  DeleteCompanyDirectorParams,
  AcceptCompanyDocumentParams,
  RejectCompanyDocumentParams,
  DownloadCompaniesParams,
} from "entities/clients/CompanyEntity";
import {
  FinancialParams,
  FilePayload,
  EnhancedAddress,
  AccountInfoResponse,
} from "entities/clients/CustomerBackofficeEntity";
import { CompanyDocument } from "entities/clients/DocumentEntity";
import { FinancialData } from "entities/clients/FinancialEntity";
import { Director, Ubo } from "entities/clients/PersonEntity";
import { handleApiClientError } from "utils";
import { flattenAddresses } from "utils/addresses";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function createCompanyAddress(
  params: CreateAddressParams
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/parties/v1/companies/${params.id}/addresses`,
      "company",
      { address: params.address }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function acceptDocumentCompanies(
  companiesId: string,
  documentId: string
): Promise<any> {
  try {
    await apiClient.put<void>(
      `/parties/v1/companies/${companiesId}/documents/${documentId}/accept`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCompanyDetailsById(
  id: string | number
): Promise<CompanyResponse> {
  try {
    const res = await apiClient.get<CompanyResponse>(
      `/parties/v1/companies/${id}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function cloneDocumentsFromDirectorToUbo(
  personId: string,
  uboId: string
): Promise<any> {
  try {
    await apiClient.put<void>(
      `/parties/v1/people/${personId}/documents/${uboId}/clone`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function rejectDocumentCompanies(
  companiesId: string,
  documentId: string
): Promise<any> {
  try {
    await apiClient.put<void>(
      `/parties/v1/companies/${companiesId}/documents/${documentId}/tocustomer`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCompanyDirectorByCompanyId(
  id: number
): Promise<Director[]> {
  try {
    const res: CompanyDirectorsResponse = await apiClient.get(
      `parties/v1/companies/${id}/directors`,
      "company"
    );
    const { directors }: { directors: Director[] } = res;
    return directors;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCompanyUbosByCompanyID(id: number): Promise<Ubo[]> {
  try {
    const res: CompanyUbosResponse = await apiClient.get(
      `/parties/v1/companies/${id}/ubos`,
      "company"
    );
    const { ubos } = res;
    return ubos;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCompanyFinancial(
  financialData: FinancialParams
): Promise<FinancialData> {
  try {
    const res: CompanyFinancialResponse = await apiClient.post(
      `/parties/v1/companies/${financialData.customerId}/financial`,
      "company",
      { financialData }
    );
    const { financialData: result }: { financialData: FinancialData } = res;
    return result;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCompanyFinancialByCompanyId(
  id: number
): Promise<FinancialData> {
  try {
    const res = await apiClient.get<CompanyFinancialResponse>(
      `/parties/v1/companies/${id}/financial`,
      "company"
    );

    const { financialData }: { financialData: FinancialData } = res;
    return financialData;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function deleteCompanyUbo(
  params: DeleteCompanyUboParams
): Promise<void> {
  try {
    await apiClient.delete(
      `/parties/v1/companies/${params?.companyId}/ubos/${params?.personId}`,
      "company"
    );
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadCompanyDocument(
  companyId: number,
  documentId: number
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/companies/${companyId}/documents/${documentId}/download`,
      "company",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCompanyByCompanyId(id: number): Promise<Company> {
  try {
    const res: CompanyResponse = await apiClient.get(
      `parties/v1/companies/${id}`,
      "company"
    );
    const { company }: { company: Company } = res;
    return company;
  } catch (err: any) {
    throw err;
  }
}

export async function getCompanyDocumentsByCompanyId(
  id: number
): Promise<CompanyDocumentsResponse> {
  try {
    const res: CompanyDocumentsResponse = await apiClient.get(
      `parties/v1/companies/${id}/documents`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCompanyDetails(
  params: UpdateCompanyParams
): Promise<{ company: CompanyDetailsForm }> {
  try {
    const res = await apiClient.put<{ company: CompanyDetailsForm }>(
      `/parties/v1/companies/${params?.id}`,
      "company",
      {
        company: params?.body,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCompaniesList(
  params: CompanyListParams
): Promise<CompanyList> {
  try {
    const res = await apiClient.get<CompanyList>(
      "parties/v1/companies/",
      "company",
      {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        ...(params.keyword && { keyword: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function uploadCompanyDocument(
  params: UploadCompanyDocumentParams
): Promise<{
  document: CompanyDocument;
}> {
  try {
    const res = await apiClient.post<{ document: CompanyDocument }>(
      `/parties/v1/companies/${
        params?.id
      }/documents?type=${params?.type.toString()}&sub_type=${params?.subType.toString()}`,
      "company",
      params.body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCompanyAddresses(
  id: number
): Promise<EnhancedAddress[]> {
  try {
    const res = await apiClient.get<CompanyAddressResponse>(
      `parties/v1/companies/${id}/addresses`,
      "company"
    );

    const { addresses }: { addresses: PartyAddress[] } = res;

    return flattenAddresses(addresses);
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function deleteCompanyDirector(
  params: DeleteCompanyDirectorParams
): Promise<void> {
  try {
    await apiClient.delete(
      `/parties/v1/companies/${params?.companyId}/directors/${params?.personId}`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function acceptCompanyDocument(
  params: AcceptCompanyDocumentParams
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/companies/${params?.companyId}/documents/${params?.documentId}/accept`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function rejectCompanyDocument(
  params: RejectCompanyDocumentParams
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/companies/${params?.companyId}/documents/${params?.documentId}/reject`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCompanyAccounts(
  companyId: number
): Promise<AccountInfoResponse> {
  try {
    const res = await apiClient.get<AccountInfoResponse>(
      `/parties/v1/companies/${companyId}/account`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadCompaniesCSVDocument(
  params: DownloadCompaniesParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/companies/csv`,
      "company",
      {
        ...(params.keyword && { keyword: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      },
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
