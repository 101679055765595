const IconSidebarStrfi: React.FC = () => {
  return (
    <svg
      width="50"
      height="75"
      viewBox="0 0 25 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2348 0V3.90984C11.3677 5.73247 3.86443 14.3948 3.86443 24.7722C3.86443 27.6237 2.31473 30.1127 0 31.4454V24.7722C0 12.2587 9.21968 1.86183 21.2348 0Z"
        fill="#7B61FF"
      />
      <path
        d="M21.2348 5.88281V9.84166C14.5847 11.5565 9.65124 17.6026 9.65124 24.7853C9.65124 30.8901 5.57105 36.064 0 37.7201V33.6143C3.40344 32.1248 5.78683 28.7245 5.78683 24.7755C5.78683 15.4663 12.4368 7.67605 21.2348 5.88281Z"
        fill="#7B61FF"
      />
      <path
        d="M21.2366 11.8359V15.9417C17.8332 17.4312 15.4498 20.8315 15.4498 24.7805C15.4498 34.0897 8.80966 41.8898 0.0117188 43.6829V39.7241C6.66166 38.0093 11.5952 31.9633 11.5952 24.7805C11.5854 18.6659 15.6558 13.5017 21.2366 11.8359Z"
        fill="#7B61FF"
      />
      <path
        d="M21.2348 18.1016V24.7747C21.2348 37.2883 12.015 47.685 0 49.5469V45.6371C9.86702 43.8144 17.3702 35.152 17.3702 24.7747C17.3702 21.933 18.9298 19.4342 21.2348 18.1016Z"
        fill="#7B61FF"
      />
    </svg>
  );
};

export default IconSidebarStrfi;
