import { makeStyles } from "@material-ui/core";
import { ColDef } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import TaskAttachmentsModal from "components/atoms/TaskAttachmentsModal";
import TaskCommentsModal from "components/atoms/TaskCommentsModal";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import React, { ReactNode } from "react";
import { useRouteMatch } from "react-router";

interface VerificationTemplateProps {
  children: ReactNode;
  task: TaskDetail;
  columns: ColDef[];
  refetch: any;
  isHistory: Boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: 60,
  },
  root: {
    "& .MuiTypography-h1": {
      fontSize: 48,
      textAlign: "center",
      marginBottom: 50,
    },
    "& .section": {
      padding: 50,
      backgroundColor: "#FAFAFA",
    },
    "& .section-title": {
      fontSize: 18,
      color: theme.palette.secondary.main,
      marginBottom: 25,
    },
    "& .section-title-vertical-margin": {
      fontSize: 18,
      color: theme.palette.secondary.main,
      marginTop: 25,
      marginBottom: 10,
    },
    "& .section-title-no-margin": {
      fontSize: 18,
      color: theme.palette.secondary.main,
    },
    "& .document-details": {
      marginTop: 50,

      "& .form-control": {
        width: "100%",
        marginTop: 0,
        marginBottom: 15,
      },
    },
    "& .download-link": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: 20,

      "& p": {
        fontSize: 14,
        marginLeft: 10,
      },
    },
  },
}));

const VerificationTemplate: React.FC<VerificationTemplateProps> = ({
  children,
  task,
  columns,
  refetch,
  isHistory,
}) => {
  const classes = useStyles();
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();

  return (
    <>
      <div className={classes.container}>
        <QuidDataGrid
          rows={[task.task]}
          columns={columns}
          disableSelectionOnClick
          hideHeader
          hideFooter
          pageSize={1}
          rowCount={1}
        />
      </div>
      <TaskCommentsModal
        attachments={task.attachments}
        comments={task.comments}
        taskKey={taskKey}
        taskId={taskId}
        refetch={refetch}
        isHistory={isHistory}
      />
      <TaskAttachmentsModal
        attachments={task.attachments}
        comments={task.comments}
        taskKey={taskKey}
        taskId={taskId}
        refetch={refetch}
        isHistory={isHistory}
      />
      <div className={classes.root}>{children}</div>
    </>
  );
};

export default VerificationTemplate;
