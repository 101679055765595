import { Box, Fab, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import { useCallback, useEffect, useState } from "react";
import { fetchCompanyDetails } from "services/companies";
import { handleFailure } from "resHandlers";
import DirectorList from "components/organisms/companies/DirectorList";
import { ArrowBack } from "@material-ui/icons";

const detailsFirstState: CompanyDetails = ({
  directors: null,
} as unknown) as CompanyDetails;

const Directors: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("director");
  const id: number = parseInt(match.params.id, 10);
  const [details, setDetails] = useState(detailsFirstState);

  useEffect(() => {
    if (id) {
      void getCompanyDetails(id);
    }
  }, [id]);

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetails(companyDetails);
      } catch (err: any) {
        return handleFailure(err);
      }
    },
    [setDetails]
  );

  const goBack = () => {
    history.back();
  };

  return (
    <MainTemplate>
      <Box marginBottom={2}>
        <Grid container>
          <Grid item xs={4}>
            <Fab color="primary" aria-label="back" onClick={goBack}>
              <ArrowBack />
            </Fab>
          </Grid>
          <Grid item xs={4}>
            <QuidTitle>{t("directors__title")}</QuidTitle>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Box>
      <DirectorList
        directors={details?.directors}
        refetch={() => getCompanyDetails(id)}
      />
    </MainTemplate>
  );
};

export default Directors;
