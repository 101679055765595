import { Grid } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import OpportunitiesList from "components/organisms/opportunities/OpportunitiesList";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";

const OpportunitiesPage: React.FC = () => {
  const { t } = useTranslation("opportunities");
  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("opportunities__header_name__tab")}</QuidTitle>
        </Grid>
      </Grid>
      <OpportunitiesList />
    </MainTemplate>
  );
};

export default OpportunitiesPage;
