import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import UbosList from "components/organisms/companies/UboList";
import { useEffect, useState } from "react";
import { fetchCompanyDetails } from "services/companies";
import { handleFailure } from "resHandlers";
import { CompanyDetails } from "entities/clients/CompanyEntity";

const detailsFirstState: CompanyDetails = ({
  company: null,
  products: null,
  directors: null,
  addresses: null,
  financialData: null,
  profiles: null,
  documents: null,
  history: null,
  investmentInfo: null,
  investmentPreferences: null,
} as unknown) as CompanyDetails;

const UBO: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { t } = useTranslation("ubo");
  const id: number = parseInt(match.params.id, 10);
  const [details, setDetails] = useState(detailsFirstState);

  useEffect(() => {
    if (id) {
      void getCompanyDetails(id);
    }
  }, [id]);

  const getCompanyDetails = async (id: number | string): Promise<void> => {
    try {
      const companyDetails = await fetchCompanyDetails({
        companyId: id as number,
      });
      setDetails(companyDetails);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("ubo__title")}</QuidTitle>
        </Grid>
      </Grid>
      <UbosList ubos={details?.ubos} refetch={() => getCompanyDetails(id)} />
    </MainTemplate>
  );
};

export default UBO;
