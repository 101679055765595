import { AuthenticationRepository } from "features/authentication/domain/repositories/AuthenticationRepository";
import { ApiClient } from "@portit/core/utils/api/apiRealm";
import { Persistence } from "features/core/Persistence";
import { Scheduler } from "features/core/Scheduler";
import qs from "querystring";
import jwt_decode from "jwt-decode";
import { differenceInSeconds, isFuture } from "date-fns";

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
}

export interface ClientSettings {
  realm: string;
  id: string;
  baseURL: string;
}

type AccessTokenDecoded = {
  exp: number;
  iat: number;
};

export class AuthenticationRepositoryImpl implements AuthenticationRepository {
  private readonly client: ClientSettings;
  private readonly apiClient: ApiClient;
  private readonly scheduler: Scheduler;
  private readonly storage: Persistence;

  private refreshTokenJobId = "";

  private _currentToken?: TokenResponse | undefined;
  private get currentToken(): TokenResponse | undefined {
    return this._currentToken;
  }

  private set currentToken(value: TokenResponse | undefined) {
    this._currentToken = value;
    if (value) {
      this.storage.set("token", value);
      this.apiClient.setToken(value?.access_token);
    } else {
      this.storage.remove("token");
      this.apiClient.setToken("");
    }
  }

  constructor(
    client: ClientSettings,
    apiClient: ApiClient,
    scheduler: Scheduler,
    storage: Persistence
  ) {
    this.client = client;
    this.apiClient = apiClient;
    this.scheduler = scheduler;
    this.storage = storage;

    // last valid token received from keycloak
    const localToken = this.storage.get<TokenResponse>("token");
    if (localToken !== undefined) {
      const decodedAccessToken: AccessTokenDecoded = jwt_decode(
        localToken.access_token
      );
      const expiresAt = new Date(decodedAccessToken.exp * 1000);

      if (isFuture(expiresAt)) {
        // token is still valid
        this.resetScheduler(localToken);
      } else {
        // local token is not valid
        this.storage.remove("token");
        // TODO handle logout in the application
      }
    }
  }

  get loggedIn(): boolean {
    return this.currentToken?.access_token !== undefined;
  }

  /**
   * Tries to sign in the user calling the keycloak apis.
   * If keycloak returns a good auth token, stores locally both the
   * token and the time when the token was received, so that
   * speculations can be done on whether the token is still valid
   * at some point in the future.
   *
   * Finally
   * - propagates the token to the api client, so it can be injected to the subsequent api calls
   * - schedules a call to the refresh token api (keycloak)
   */
  async signIn(username: string, password: string): Promise<any> {
    const tokenResponse = await this.apiClient.post<TokenResponse>(
      "authenticate",
      "auth",
      qs.stringify({
        grant_type: "password",
        client_id: this.client.id,
        username,
        password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const decoded: any = jwt_decode(tokenResponse.access_token);
    if (decoded?.userType !== "BACKOFFICE") {
      throw new Error("You're not enabled to login");
    } else {
      this.resetScheduler(tokenResponse);

      return tokenResponse;
    }
  }

  /**
   * Clears current session and calls remote api logout
   */
  async signOut(): Promise<void> {
    this.scheduler.remove(this.refreshTokenJobId);
    return this.keycloakLogout().then(() => {
      this.currentToken = undefined;
    });
  }

  /**
   * Refreshes current authentication token against keycloak api
   */
  refresh(): Promise<void> {
    return this.refreshKeycloakToken().then(this.resetScheduler);
  }

  resetScheduler(tk: TokenResponse): void {
    this.currentToken = tk;
    const decodedAccessToken: AccessTokenDecoded = jwt_decode(tk.access_token);
    const expiresAt = new Date(decodedAccessToken.exp * 1000);
    this.refreshTokenJobId = this.scheduler.exec(
      differenceInSeconds(expiresAt, new Date()) - 50,
      this.refresh.bind(this)
    );
  }

  private async keycloakLogout() {
    try {
      await this.apiClient.post(
        "logout",
        "auth",
        qs.stringify({
          client_id: this.client.id,
          refresh_token: this.currentToken?.refresh_token,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
    } catch (e) {
      // keep logging out
    }
  }

  private async refreshKeycloakToken(): Promise<TokenResponse> {
    if (!this.currentToken) {
      return Promise.reject(
        new Error("Called token refresh with invalid current token")
      );
    }
    return await this.apiClient.post<TokenResponse>(
      "refresh",
      "auth",
      qs.stringify({
        grant_type: "refresh_token",
        client_id: this.client.id,
        refresh_token: this.currentToken?.refresh_token,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
}
