import React, { ChangeEvent, useEffect, useState } from "react";
import { ColDef, PageChangeParams, SearchIcon } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { fetchCryptoWalletsManagement } from "@portit/core/api/Crypto";
import {
  PaginationCrypto,
  WalletDetailResponse,
} from "@portit/core/entities/Crypto";
import MainTemplate from "templates/MainTemplate";
import { Grid, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { TABLE_PAGE_SIZE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },

  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  flex05: {
    flex: 0.5,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  addAccountBtn: {
    height: "100%",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  taskLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const CryptoWalletsPage: React.FC = () => {
  const { t } = useTranslation("crypto");
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [wallets, setWallets] = useState([] as WalletDetailResponse[]);
  const [pagination, setPagination] = useState<PaginationCrypto>();

  const [filter, setFilter] = useState({
    walletAddress: "",
    walletId: "",
    partyId: "",
  });

  useEffect(() => {
    const getCryptoWalletsManagement = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCryptoWalletsManagement({
          size: TABLE_PAGE_SIZE,
          page: pageNo,
          ...(filter.walletAddress && { walletAddress: filter.walletAddress }),
          ...(filter.walletId && { walletId: filter.walletId }),
          ...(filter.partyId && { partyId: filter.partyId }),
        });
        setPagination(res?.pagination);
        setWallets(res?.wallets);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCryptoWalletsManagement();
  }, [pageNo, filter]);

  const columns: ColDef[] = [
    { field: "id", headerName: t("listTable__header_name__id"), width: 75 },
    {
      field: "partyId",
      headerName: t("listTable__header_name__party_id"),
      width: 75,
    },
    {
      width: 250,
      field: "customerName",
      headerName: t("listTable__header_name__customer_name"),
      renderCell: (params) => {
        if (params?.row?.customer?.type === "INDIVIDUAL") {
          return (
            <>
              {params?.row?.customer?.surname} {params?.row?.customer?.name}
            </>
          );
        } else {
          return (
            <>
              {params?.row?.customer?.company?.companyName} (
              {params?.row?.customer?.surname} {params?.row?.customer?.name})
            </>
          );
        }
      },
    },
    {
      field: "walletAddress",
      headerName: t("listTable__header_name__wallet_address"),
      flex: 1,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => (
        <DetailButton
          to={`/crypto/${params?.row?.partyId}/wallets/${params?.row?.id}`}
        />
      ),
    },
  ];

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("cryptowallets__page__title")}</QuidTitle>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={3}>
          <TextField
            label={t("filter__wallet_address")}
            variant="outlined"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({
                ...state,
                walletAddress: e.target.value,
              }))
            }
            value={filter.walletAddress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.searchBox}>
                    <SearchIcon />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("filter__wallet_id")}
            variant="outlined"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, walletId: e.target.value }))
            }
            value={filter.walletId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.searchBox}>
                    <SearchIcon />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("filter__party_id")}
            variant="outlined"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, partyId: e.target.value }))
            }
            value={filter.partyId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.searchBox}>
                    <SearchIcon />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <QuidDataGrid
        disableSelectionOnClick
        sortModel={[{ field: "id", sort: "desc" }]}
        loading={loading}
        columns={columns}
        rows={wallets}
        rowCount={pagination?.total_entries}
        onPageChange={onPageChange}
      />
    </MainTemplate>
  );
};

export default CryptoWalletsPage;
