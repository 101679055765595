import { createSlice } from "@reduxjs/toolkit";
import { Task } from "entities/tasks/TaskEntity";
import { reqStatus } from "shared/entities/reqStatus";

export type tasksSliceState = {
  "tasks/fetchAccountTasks": { list: Task[]; status: reqStatus; total: number };
  "tasks/fetchAccountTasksSearch": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
  "tasks/fetchHistoricalAccountTasksSearch": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
  "tasks/fetchHistoricalTasksSearch": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
  "tasks/fetchTasksSearch": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
  "tasks/fetchTasks": { list: Task[]; status: reqStatus; total: number };
  "tasks/fetchCompFormTasks": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
  "tasks/fetchHistoricalCompFormTasksSearch": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
  "tasks/fetchCompFormTasksSearch": {
    list: Task[];
    status: reqStatus;
    total: number;
  };
};

export const defaultState: tasksSliceState = {
  "tasks/fetchTasks": { list: [], status: reqStatus.IDLE, total: 0 },
  "tasks/fetchTasksSearch": { list: [], status: reqStatus.IDLE, total: 0 },
  "tasks/fetchHistoricalTasksSearch": {
    list: [],
    status: reqStatus.IDLE,
    total: 0,
  },
  "tasks/fetchCompFormTasks": { list: [], status: reqStatus.IDLE, total: 0 },
  "tasks/fetchHistoricalCompFormTasksSearch": {
    list: [],
    status: reqStatus.IDLE,
    total: 0,
  },
  "tasks/fetchCompFormTasksSearch": {
    list: [],
    status: reqStatus.IDLE,
    total: 0,
  },
  "tasks/fetchAccountTasks": { list: [], status: reqStatus.IDLE, total: 0 },
  "tasks/fetchAccountTasksSearch": {
    list: [],
    status: reqStatus.IDLE,
    total: 0,
  },
  "tasks/fetchHistoricalAccountTasksSearch": {
    list: [],
    status: reqStatus.IDLE,
    total: 0,
  },
};

const handleFetchPending = (
  state: tasksSliceState,
  payload: { type: keyof tasksSliceState }
) => {
  const splitType = payload.type.split("/");
  const rootType = `${splitType[0]}/${splitType[1]}` as keyof tasksSliceState;
  return {
    ...defaultState, // it cleans up the other rootTypes to default state
    [rootType]: {
      ...state[rootType],
      status: reqStatus.PENDING,
    },
  };
};

const handleFetchFulfilled = (
  state: tasksSliceState,
  {
    payload,
  }: { payload: { type: keyof tasksSliceState; total: number; tasks: Task[] } }
) => {
  const splitType = payload.type.split("/");
  const rootType = `${splitType[0]}/${splitType[1]}` as keyof tasksSliceState;
  return {
    ...state,
    [rootType]: {
      ...state[rootType],
      status: reqStatus.FULFILLED,
      total: payload.total,
      list: payload.tasks,
    },
  };
};

const handleFetchRejected = (
  state: tasksSliceState,
  payload: { type: keyof tasksSliceState }
) => {
  const splitType = payload.type.split("/");
  const rootType = `${splitType[0]}/${splitType[1]}` as keyof tasksSliceState;
  return {
    ...state,
    [rootType]: {
      ...state[rootType],
      total: 0,
      list: [],
      status: reqStatus.REJECTED,
    },
  };
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState: defaultState,
  reducers: {},
  extraReducers: {
    "tasks/fetchAccountTasks/pending": handleFetchPending,
    "tasks/fetchAccountTasksSearch/pending": handleFetchPending,
    "tasks/fetchHistoricalAccountTasksSearch/pending": handleFetchPending,
    "tasks/fetchCompFormTasks/pending": handleFetchPending,
    "tasks/fetchHistoricalCompFormTasksSearch/pending": handleFetchPending,
    "tasks/fetchCompFormTasksSearch/pending": handleFetchPending,
    "tasks/fetchTasksSearch/pending": handleFetchPending,
    "tasks/fetchTasks/pending": handleFetchPending,
    "tasks/fetchHistoricalTasksSearch/pending": handleFetchPending,

    "tasks/fetchAccountTasks/fulfilled": handleFetchFulfilled,
    "tasks/fetchAccountTasksSearch/fulfilled": handleFetchFulfilled,
    "tasks/fetchHistoricalAccountTasksSearch/fulfilled": handleFetchFulfilled,
    "tasks/fetchCompFormTasks/fulfilled": handleFetchFulfilled,
    "tasks/fetchHistoricalCompFormTasksSearch/fulfilled": handleFetchFulfilled,
    "tasks/fetchCompFormTasksSearch/fulfilled": handleFetchFulfilled,
    "tasks/fetchTasks/fulfilled": handleFetchFulfilled,
    "tasks/fetchTasksSearch/fulfilled": handleFetchFulfilled,
    "tasks/fetchHistoricalTasksSearch/fulfilled": handleFetchFulfilled,

    "tasks/fetchAccountTasks/rejected": handleFetchRejected,
    "tasks/fetchAccountTasksSearch/rejected": handleFetchRejected,
    "tasks/fetchHistoricalAccountTasksSearch/rejected": handleFetchRejected,
    "tasks/fetchCompFormTasks/rejected": handleFetchRejected,
    "tasks/fetchHistoricalCompFormTasksSearch/rejected": handleFetchRejected,
    "tasks/fetchCompFormTasksSearch/rejected": handleFetchRejected,
    "tasks/fetchTasks/rejected": handleFetchRejected,
    "tasks/fetchTasksSearch/rejected": handleFetchRejected,
    "tasks/fetchHistoricalTasksSearch/rejected": handleFetchRejected,
  },
});

export default tasksSlice;

export type TaskKey =
  | "tasks/fetchAccountTasks"
  | "tasks/fetchAccountTasksSearch"
  | "tasks/fetchHistoricalAccountTasksSearch"
  | "tasks/fetchHistoricalTasksSearch"
  | "tasks/fetchTasksSearch"
  | "tasks/fetchTasks"
  | "tasks/fetchCompFormTasks"
  | "tasks/fetchHistoricalCompFormTasksSearch"
  | "tasks/fetchCompFormTasksSearch";

export const getTasks = (state: tasksSliceState, taskKey: TaskKey): Task[] =>
  state?.[taskKey]?.list;

export const getTasksTotal = (
  state: tasksSliceState,
  taskKey: TaskKey
): number => state?.[taskKey]?.total;

export const getTaskRequestStatus = (
  state: tasksSliceState,
  taskKey: TaskKey
): reqStatus => state?.[taskKey]?.status;
