const IconUser: React.FC = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 2.76344C3.26522 2.76344 3.51957 2.66046 3.70711 2.47715C3.89464 2.29384 4 2.04522 4 1.78599C4 1.52675 3.89464 1.27813 3.70711 1.09482C3.51957 0.911514 3.26522 0.808533 3 0.808533H1C0.734784 0.808533 0.48043 0.911514 0.292893 1.09482C0.105357 1.27813 0 1.52675 0 1.78599V15.4704C0 15.7296 0.105357 15.9782 0.292893 16.1615C0.48043 16.3448 0.734784 16.4478 1 16.4478H3C3.26522 16.4478 3.51957 16.3448 3.70711 16.1615C3.89464 15.9782 4 15.7296 4 15.4704C4 15.2111 3.89464 14.9625 3.70711 14.7792C3.51957 14.5959 3.26522 14.4929 3 14.4929H2V2.76344H3ZM16.82 8.06125L14 4.15143C13.8471 3.9407 13.615 3.79773 13.3545 3.75377C13.0941 3.70981 12.8264 3.76844 12.61 3.91684C12.5018 3.99092 12.4098 4.0852 12.3391 4.19425C12.2684 4.30329 12.2206 4.42494 12.1982 4.55217C12.1759 4.6794 12.1796 4.80969 12.2091 4.93552C12.2386 5.06134 12.2933 5.18022 12.37 5.28528L14.09 7.65072H6C5.73478 7.65072 5.48043 7.7537 5.29289 7.93701C5.10536 8.12032 5 8.36893 5 8.62817C5 8.88741 5.10536 9.13603 5.29289 9.31934C5.48043 9.50265 5.73478 9.60563 6 9.60563H14L12.2 11.9515C12.1212 12.0542 12.0639 12.1711 12.0313 12.2954C11.9987 12.4198 11.9915 12.5492 12.01 12.6762C12.0286 12.8033 12.0726 12.9255 12.1395 13.036C12.2064 13.1464 12.2949 13.2429 12.4 13.32C12.5731 13.4468 12.7836 13.5154 13 13.5154C13.1552 13.5154 13.3084 13.4801 13.4472 13.4123C13.5861 13.3444 13.7069 13.2459 13.8 13.1245L16.8 9.21465C16.9281 9.04933 16.999 8.84848 17.0026 8.64114C17.0062 8.4338 16.9423 8.23071 16.82 8.06125Z"
        fill="#999"
      />
    </svg>
  );
};

export default IconUser;
