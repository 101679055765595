import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import DetailIcon from "components/atoms/icons/DetailIcon";
import SearchIcon from "components/atoms/icons/SearchIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { handleFailure } from "../../../resHandlers";
import { ServerFailure } from "features/core/Failure";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import { TABLE_PAGE_SIZE } from "shared/constants";
import {
  getAccountsTransactionDirection,
  getAccountsTransactionStatus,
} from "../../../store/reducers/app.reducer";
import PaymentDetailModal from "../payments/PaymentDetailModal";
import { NetworkFailure } from "../../../features/core/NetworkFailure";
import promptsSlice from "../../../store/reducers/prompts.reducer";
import StatusBadge from "components/atoms/StatusBadge";
import {
  fetchAccountTransactionsByBalanceId,
  fetchTransactionDetailsByTransactionId,
} from "api/accounts";
import { Pagination } from "entities/accounts/Pagination";
import { AccountDataset } from "entities/accounts/AccountDataset";
import {
  Transaction,
  TransactionDetailResponse,
  FetchTransactionDetailParams,
} from "entities/accounts/Transaction";
import { formatDate } from "utils";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import {
  downloadOptimizedTransactionsCsv,
  downloadTransactionsCsv,
} from "services/accounts";
export interface TransactionDetailProps {
  balanceId: number;
}
import { getAccountsTransactionTypes } from "store/reducers/app.reducer";
import CsvIconDemo from "components/atoms/icons/CsvIconDemo";

type TransactionFilter = {
  search: string;
  from: string;
  to: string;
  status: string;
  currency: string;
  account_type: string;
  transaction_type: string;
  direction: string;
  payment_types: string;
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginTop: "160px",
    marginBottom: 32,
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  pb32: {
    paddingBottom: 32,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  status: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  paymentsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexTextFields: {
    flex: 1,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  detailLink: {
    color: theme.palette.secondary.main,
  },
}));

const TransactionDetail: React.FC<TransactionDetailProps> = ({ balanceId }) => {
  const { t } = useTranslation("account");
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pagination, setPagination] = useState({} as Pagination);
  const [] = useState(1);
  const [transactions, setTransactions] = useState([] as Transaction[]);
  const [filter, setFilter] = useState({} as TransactionFilter);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState(0);
  const [transactionDetail, setTransactionDetail] = useState<
    TransactionDetailResponse | undefined
  >();
  const [loading, setLoading] = useState(false);
  const paymentTypes = useSelector(getAccountsTransactionTypes);
  const paymentStatuses = useSelector(getAccountsTransactionStatus);
  const paymentDirections = useSelector(getAccountsTransactionDirection);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const transactionRes = await fetchAccountTransactionsByBalanceId({
          balance_id: balanceId,
          page: pageNo,
          size: TABLE_PAGE_SIZE,
          ...(filter.search && { keyword: filter.search }),
          ...(filter.from && { date_from: filter.from }),
          ...(filter.to && { date_to: filter.to }),
          ...(filter.transaction_type && {
            transaction_type: filter.transaction_type,
          }),
          ...(filter.account_type && { account_type: filter.account_type }),
          ...(filter.direction && { direction: filter.direction }),
          ...(filter.payment_types && { payment_types: filter.payment_types }),
          ...(filter.status && { status: filter.status }),
        });
        const { pagination, transaction_list: transactions } = transactionRes;
        setPagination(pagination);
        setTransactions(transactions);
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [
    pageNo,
    filter?.search,
    filter?.from,
    filter?.to,
    filter?.account_type,
    filter?.transaction_type,
    filter?.currency,
    filter?.status,
    filter?.direction,
    filter?.payment_types,
  ]);

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "status",
      headerName: t("transactionTable__header_name__status"),
      flex: 1,
      renderCell: (params) => (
        <div className={classes.status}>
          <StatusBadge status={params?.row?.status} />
        </div>
      ),
    },
    {
      field: "type",
      headerName: t("transactionTable__header_name__type"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("transactionTable__header_name__createdAt"),
      type: "date",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.created_at, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.created_at, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "completed_at",
      headerName: t("transactionTable__header_name__completedAt"),
      type: "date",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.completed_at, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.completed_at, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: t("transactionTable__header_name__amount"),
      flex: 1,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.amount, params?.row?.currency),
    },
    {
      field: "reference",
      headerName: t("transactionTable__header_name__reference"),
      flex: 1,
    },
    {
      field: "receipt",
      width: 100,
      headerName: t("transactionTable__header_name__receipt"),
      renderCell: (params) => {
        const id = params?.row?.id as number;
        if (!id) {
          return <DetailIcon />;
        }
        return (
          <Button
            onClick={() =>
              fetchTransactionDetail({
                balance_id: params?.row?.balance_id,
                transaction_id: params?.row?.id as number,
              })
            }
          >
            <DetailIcon />
          </Button>
        );
      },
    },
  ];

  const fetchTransactionDetail = async (
    params: FetchTransactionDetailParams
  ) => {
    setLoading(true);
    try {
      const res = await fetchTransactionDetailsByTransactionId(params);

      setTransactionDetail(res);
      setModalOpen(true);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const { onCsvDownload } = useCsvDownloadHandler({
    download: () =>
      downloadTransactionsCsv({
        fileName: "balances-transactions ",
        balance_id: balanceId,
        ...(filter.search && { keyword: filter.search }),
        ...(filter.from && { date_from: filter.from }),
        ...(filter.to && { date_to: filter.to }),
        ...(filter.transaction_type && {
          transaction_type: filter.transaction_type,
        }),
        ...(filter.account_type && { account_type: filter.account_type }),
        ...(filter.direction && { direction: filter.direction }),
        ...(filter.payment_types && { payment_types: filter.payment_types }),
        ...(filter.status && { status: filter.status }),
      }),
  });

  const { onCsvDownload: onCsvOptimizedDownload } = useCsvDownloadHandler({
    download: () =>
      downloadOptimizedTransactionsCsv({
        fileName: "balances-transactions-demo ",
        balance_id: balanceId,
        ...(filter.search && { keyword: filter.search }),
        ...(filter.from && { date_from: filter.from }),
        ...(filter.to && { date_to: filter.to }),
        ...(filter.transaction_type && {
          transaction_type: filter.transaction_type,
        }),
        ...(filter.account_type && { account_type: filter.account_type }),
        ...(filter.direction && { direction: filter.direction }),
        ...(filter.payment_types && { payment_types: filter.payment_types }),
        ...(filter.status && { status: filter.status }),
      }),
  });

  return (
    <>
      <PaymentDetailModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={transactionDetail}
      />
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {t("account__transactions")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={4}>
          <TextField
            label={t("filter__search")}
            variant="outlined"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, search: e.target.value }))
            }
            value={filter.search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.searchBox}>
                    <SearchIcon color="#ffffff" />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("filter__from")}
            variant="outlined"
            type="date"
            fullWidth
            value={filter.from}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, from: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("filter__to")}
            variant="outlined"
            type="date"
            fullWidth
            value={filter.to}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, to: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("filter__statuses")}
            variant="outlined"
            select
            fullWidth
            value={filter.status}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, status: e.target.value }))
            }
          >
            <MenuItem value="">{t("filter__statuses")}</MenuItem>
            {paymentStatuses?.map((option: AccountDataset, index: number) => (
              <MenuItem key={`${option}-${index}`} value={option.code}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("filter__direction")}
            variant="outlined"
            select
            fullWidth
            value={filter.direction}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, direction: e.target.value }))
            }
          >
            <MenuItem value="">{t("filter__direction")}</MenuItem>
            {paymentDirections?.map((option: AccountDataset, index: number) => (
              <MenuItem key={`${option}-${index}`} value={option.code}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("filter__transaction_type")}
            variant="outlined"
            select
            fullWidth
            value={filter.transaction_type}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({
                ...state,
                transaction_type: e.target.value,
              }))
            }
          >
            <MenuItem value="">{t("filter__transaction_type")}</MenuItem>
            {paymentTypes?.map((option: AccountDataset, index: number) => (
              <MenuItem key={`${option}-${index}`} value={option.code}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={1}>
          <Button
            onClick={() => onCsvDownload()}
            className={classes.csvBtn}
            variant="outlined"
          >
            <CsvIcon />
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            className={classes.csvBtn}
            onClick={() => onCsvOptimizedDownload()}
          >
            <CsvIconDemo />
          </Button>
        </Grid>
      </Grid>
      <QuidDataGrid
        onPageChange={onPageChange}
        sortModel={[{ field: "id", sort: "desc" }]}
        loading={loading}
        columns={columns}
        rows={transactions}
        rowCount={pagination?.total_entries}
      />
      <div className={classes.pb32} />
    </>
  );
};

export default TransactionDetail;
