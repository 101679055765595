import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsPaymentTypes } from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";

import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { RouteComponentProps } from "react-router";
import { handleFailure } from "resHandlers";
import { fetchBalanceByBalanceId } from "api/accounts";
import {
  getBeneficiariesByBalanceId,
  validatePayment,
  getFees,
  performPayment,
} from "api/payments";
import { BalanceResponse } from "entities/accounts/Balance";
import { BeneficiaryInfo, FeeResponse } from "entities/accounts/Payment";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  column4: { width: "calc(100% / 3 - 30px)", padding: "0px 15px" },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  paymentDetailBlockss: {
    marginTop: "unset",
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
  accountDetailsWrapperSelected: {
    background: "#fff",
    padding: 15,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
  beneficiaries: {
    width: "100%",
    overflowY: "scroll",
    paddingRight: "14px",
    boxSizing: "content-box",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
  search: {
    marginLeft: "10px",
  },
  actionButton: {
    minHeight: 50,
    margin: "0 10px",
    backgroundColor: "#FF6F0F",
  },
  feeButton: {
    minHeight: 50,
    margin: "0 10px",
    backgroundColor: "#F59D27",
  },
  rejectButton: {
    minHeight: 50,
    margin: "0 10px",
    backgroundColor: "#FB4C4C",
  },
  beneficiariesContainer: {
    minWidth: "300px",
    height: "530px",
    overflow: "hidden",
  },
  formContainer: {
    minWidth: "300px",
    minHeight: "300px",
  },
}));

interface FormValues {
  amount?: number;
  reference: string;
  description: string;
  payment_type: string;
}

interface PaymentType {
  code: string;
  text: string;
}

interface BalanceDetailsToPrint {
  [key: string]: number | string;
}

const PaymentsMakePayment: React.FC<
  RouteComponentProps<{ accountId: string; id: string }>
> = ({ match }) => {
  const { t } = useTranslation("payments");
  const classes = useStyles();
  const dispatch = useDispatch();
  const balanceId = parseInt(match.params.id, 10);
  const [details, setDetails] = useState({} as BalanceResponse);
  const [beneficiaries, setBeneficiaries] = useState([] as BeneficiaryInfo[]);
  const [
    selectedBeneficiary,
    setSelectedBeneficiary,
  ] = useState<BeneficiaryInfo>();
  const [fee, setFee] = useState<FeeResponse>();
  const paymentTypes = useSelector(getAccountsPaymentTypes);
  const [search, setSearch] = useState("");
  const defaultValues: FormValues = {
    amount: 0,
    description: "",
    reference: "",
    payment_type: "",
  };
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    const fetchBalanceDetails = async () => {
      try {
        const res = await fetchBalanceByBalanceId({ balance_id: balanceId });
        setDetails(res);
      } catch (err: any) {
        handleFailure(err);
      }
    };
    fetchBalanceDetails();
  }, []);

  useEffect(() => {
    const getBeneficiaries = async () => {
      try {
        const res = await getBeneficiariesByBalanceId({
          balance_id: balanceId,
          keyword: search,
        });
        setBeneficiaries(res.beneficiaries);
      } catch (err: any) {
        handleFailure(err);
      }
    };
    getBeneficiaries();
  }, [search, balanceId]);

  const validate = async (values: FormValues) => {
    return await validatePayment({
      amount: values.amount,
      balance_id: details.balance_id,
      beneficiary_id: selectedBeneficiary?.beneficiary_id,
      currency: selectedBeneficiary?.currency,
      payment_type: values.payment_type,
      reason: values.description,
      reference: values.reference,
    });
  };
  const getFee = async (values: FormValues) => {
    try {
      const fee = await getFees({
        amount: values.amount,
        currency: selectedBeneficiary?.currency,
        payment_type: "regular",
      });
      setFee(fee);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const makePayment = async (values: FormValues) => {
    try {
      await performPayment({
        amount: values.amount,
        balance_id: details.balance_id,
        beneficiary_id: selectedBeneficiary?.beneficiary_id,
        currency: selectedBeneficiary?.currency,
        payment_type: defaultValues.payment_type || "regular",
        reason: values.description,
        reference: values.reference,
      });

      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("payment__success__message"),
          type: "success",
        })
      );
      setSelectedBeneficiary(undefined);
      reset(defaultValues);
      setFee(undefined);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const balanceDetails: BalanceDetailsToPrint = {
    available_amount: MoneyFormatter(
      details?.available_amount,
      details?.currency as DineroFactory.Currency
    ),
    current_amount: MoneyFormatter(
      details?.current_amount,
      details?.currency as DineroFactory.Currency
    ),
  };

  const onFormSubmit = async (values: FormValues) => {
    if (fee) {
      makePayment(values);
      reset(defaultValues);
    } else {
      getFee(values);
      validate(values);
    }
  };
  const areNotUndefined = (
    fee?: FeeResponse,
    selectedBeneficiary?: BeneficiaryInfo
  ) => {
    return !(!!fee && !!selectedBeneficiary);
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("make_payments_title")}</QuidTitle>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("make_payment_beneficiary")}
                </QuidTitle>
                <TextField
                  label={t("filter__search")}
                  name="from_search"
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value)
                  }
                  value={search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                {beneficiaries?.map(
                  (beneficiary: BeneficiaryInfo, index: number) => {
                    if (
                      beneficiary?.beneficiary_id ===
                      selectedBeneficiary?.beneficiary_id
                    ) {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (selectedBeneficiary) {
                              setSelectedBeneficiary(undefined);
                            } else {
                              setSelectedBeneficiary(beneficiary);
                            }
                          }}
                          className={classes.accountDetailsWrapperSelected}
                        >
                          <div>
                            <Typography variant="body1">
                              {beneficiary.name}
                              <br />
                              <small>
                                {beneficiary.bank_account_holder_name}
                              </small>
                            </Typography>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedBeneficiary(beneficiary);
                          }}
                          className={classes.accountDetailsWrapper}
                        >
                          <Typography variant="body1">
                            {beneficiary.name}
                            <br />
                            <small>
                              {beneficiary.bank_account_holder_name}
                            </small>
                          </Typography>
                        </div>
                      );
                    }
                  }
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("make_payment_payment_detail")}
                </QuidTitle>
                <QuidTextField
                  name="payment_type"
                  rules={{
                    required: t("payment__type__required") as string,
                  }}
                  textFieldProps={{
                    select: true,
                    fullWidth: true,
                  }}
                  label={t("payment__type")}
                  defaultValues={defaultValues}
                >
                  {paymentTypes.map((type: PaymentType) => (
                    <MenuItem key={type.code} value={type.text}>
                      {type.text}
                    </MenuItem>
                  ))}
                </QuidTextField>
                <QuidTextField
                  name="amount"
                  rules={{
                    required: t("payment__amount__required") as string,
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  label={t("payment__amount")}
                  defaultValues={defaultValues}
                />
                <QuidTextField
                  name="description"
                  rules={{
                    required: t("payment__description__required") as string,
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  label={t("payment__description")}
                  defaultValues={defaultValues}
                />
                <QuidTextField
                  name="reference"
                  rules={{
                    required: t("payment__reference__required") as string,
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  label={t("payment__reference")}
                  defaultValues={defaultValues}
                />
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("make_payment_balance_detail")}
                </QuidTitle>
                <DetailsBlock
                  label={t("balance__amount__details")}
                  toPrint={balanceDetails}
                />
                {fee && (
                  <DetailsBlock label={t("fee__details")} toPrint={fee} />
                )}
              </div>
            </div>
            <div className={classes.containerInnerButtons}>
              <Grid className={classes.gridItem} item xs={2}>
                <Button
                  disabled={!selectedBeneficiary}
                  className={classes.feeButton}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {t("payment__modal__action__get__fee")}
                </Button>
              </Grid>
              <Grid className={classes.gridItem} item xs={2}>
                <Button
                  disabled={areNotUndefined(fee, selectedBeneficiary)}
                  type="submit"
                  className={classes.actionButton}
                  variant="contained"
                  color="primary"
                >
                  {t("payment__modal__action__make__payment")}
                </Button>
              </Grid>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default PaymentsMakePayment;
