import React, { ChangeEvent } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import QuidUploadField from "components/atoms/QuidUploadField";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { DetailsRow } from "./DetailsBlock";
import Attachment from "components/molecules/verifications/Attachment";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import {
  updateCompanyDocument,
  createCompanyDocument,
  updateCompanyDocumentDetails,
} from "api/tasks";
import {
  getCompanyDocument,
  getHistoricalCompanyDocument,
} from "services/tasks";
import { Document } from "entities/clients/DocumentEntity";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiTextField-root": {
      display: "block",
    },
  },
  imgPreview: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 28,
  },
}));

interface CreateOrUpdateCompanyDocumentWithDetailsProps {
  doc?: Document;
  refetch: () => Promise<void>;
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  companyId: string;
  canReplace: boolean;
  isHistory?: string;
}

interface FormValues {
  companyNumber: string;
}

const defaultValues: FormValues = {
  companyNumber: "",
};

const CreateOrUpdateCompanyDocumentWithDetails: React.FC<CreateOrUpdateCompanyDocumentWithDetailsProps> = ({
  doc,
  refetch,
  taskId,
  taskKey,
  companyId,
  canReplace,
  isHistory,
}) => {
  const { t } = useTranslation(["validations", "documentTypes", "countries"]);
  const classes = useStyles();
  const methods = useForm({
    mode: "onBlur",
    defaultValues: doc?.additionalInfo
      ? JSON.parse(doc.additionalInfo)
      : defaultValues,
  });
  const onDocChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length === 1) {
      if (doc) {
        try {
          await updateCompanyDocument({
            taskId,
            taskKey,
            companyId,
            documentId: (doc.id as unknown) as string,
            request: { document: e.target.files[0] },
          });
          handleSuccessfulMessage("Document uploaded");
          refetch();
        } catch (err: any) {
          return handleFailure(err);
        }
      } else {
        try {
          await createCompanyDocument({
            taskId,
            taskKey,
            companyId,
            request: { document: e.target.files[0] },
          });
          handleSuccessfulMessage("Document uploaded");
          refetch();
        } catch (err: any) {
          return handleFailure(err);
        }
      }
    }
  };
  const onFormSubmit = async (values: FormValues) => {
    if (values.companyNumber === "") return;

    try {
      await updateCompanyDocumentDetails({
        taskId,
        taskKey,
        companyId,
        documentId: (doc?.id as unknown) as string,
        request: { additionalDetails: JSON.stringify(values) },
      });
      handleSuccessfulMessage("Document info changed");
      refetch();
    } catch (err: any) {
      return handleFailure(err);
    }
  };
  return (
    <div className={classes.container}>
      {doc && (
        <div>
          <Attachment
            downloadDoc={(params) =>
              isHistory === undefined
                ? getCompanyDocument({
                    ...params,
                    taskId,
                    taskKey,
                    companyId,
                  })
                : getHistoricalCompanyDocument({
                    ...params,
                    taskId,
                    taskKey,
                    companyId,
                  })
            }
            attachment={doc}
            imgClassName={classes.imgPreview}
          />
          <DetailsRow
            label="document type"
            value={t(`documentTypes:${doc?.type || "n/a"}`)}
            index={0}
          />
          <DetailsRow
            label="document subtype"
            value={t(`documentTypes:${doc?.subType || "n/a"}`)}
            index={0}
          />
        </div>
      )}
      <br />
      {canReplace && (
        <div>
          <Typography variant="h6" className="section-title">
            {!doc && <div>{t("company__document__create")}</div>}
            {doc && <div>{t("company__document__replace")}</div>}
          </Typography>
          <QuidUploadField
            inputId="financial-doc"
            onChange={onDocChange}
            label="Upload document"
          />
          {doc && (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onFormSubmit)}>
                <Typography variant="h6" className="section-title">
                  {t("Document details")}
                </Typography>
                <QuidTextField
                  name="companyNumber"
                  label="Company number"
                  rules={{
                    required: t("companyNumber__required") as string,
                  }}
                  defaultValues={defaultValues}
                  onBlur={methods.handleSubmit(onFormSubmit)}
                  textFieldProps={{
                    onBlur: methods.handleSubmit(onFormSubmit),
                  }}
                />
              </form>
            </FormProvider>
          )}
        </div>
      )}
      <br />
    </div>
  );
};

export default CreateOrUpdateCompanyDocumentWithDetails;
