import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { RouteComponentProps, useHistory } from "react-router";
import NewsCreateOrEdit from "components/organisms/news/NewsCreateOrEdit";
import { DetailsNews } from "entities/news/NewsEntity";
import { updateNews } from "api/news";

const useStyles = makeStyles(() => ({
  newsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
}));

const NewsUpdate: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("news");
  const dispatch = useDispatch();
  const history = useHistory();
  const newsId: number = +match.params.id;
  const newsDetails = history.location.state as DetailsNews;
  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmit = async (values: DetailsNews) => {
    if (newsId) {
      setIsLoading(true);
      try {
        await updateNews({
          newsId,
          values,
        });
        history.push(`/news`);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <MainTemplate>
      <div className={classes.newsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("page__title__info_details")}</QuidTitle>
          </Grid>
        </Grid>
        <NewsCreateOrEdit
          news={newsDetails}
          onFormSubmit={onFormSubmit}
          isLoading={isLoading}
          mode={"update"}
        />
      </div>
    </MainTemplate>
  );
};

export default NewsUpdate;
