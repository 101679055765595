import { ApiClientError } from "@portit/core/entities/Api";

export class UnknownSwitchTypeError extends Error {
  constructor(type: any) {
    super(`Unknown action type: ${type.toString()}`);
  }
}

export class Failure {}

export class UndefinedParamFailure extends Failure {
  private readonly undefinedParam: string;

  constructor(undefinedParam: string) {
    super();
    this.undefinedParam = undefinedParam;
  }

  toString(): string {
    return `The parameter ${this.undefinedParam} is undefined`;
  }
}

/**
 * It is used for defined a server side error. For example: PageNotFound(404)
 */
export abstract class ServerFailure extends Failure {
  readonly error: ApiClientError;

  protected constructor(error: ApiClientError) {
    super();
    this.error = error;
  }

  /**
   * Converts a server side error [ApiClientError] into a failure [ServerFailure]
   *
   * @param error
   */
  static fromApiClientError(error: ApiClientError): ServerFailure {
    if (!error?.response?.status) throw error;
    switch (error?.response?.status) {
      case 401:
        return new AuthorizationFailure(error);
      case 403:
        return new ForbiddenFailure(error);
      case 500:
        return new InternalServerFailure(error);
      case 503:
        return new ServiceUnavailableFailure(error);
      default:
        return new UnmappedStatusCodeFailure(error);
    }
  }

  toString(): string | undefined {
    return `${this.constructor.toString()}(${JSON.stringify(this.error)})`;
  }
}

export class UnmappedStatusCodeFailure extends ServerFailure {
  constructor(error: ApiClientError) {
    super(error);
  }
}

/**
 * Internal server error 500
 */
export class InternalServerFailure extends ServerFailure {
  constructor(error: ApiClientError) {
    super(error);
  }

  get code(): string | undefined {
    return this.error?.response?.data?.code;
  }

  get id(): string | undefined {
    return this.error?.response?.data?.id;
  }

  get message(): string | undefined {
    return this.error?.response?.data?.message;
  }

  get errors(): Array<PortitFailureDetail> | undefined {
    return this.error?.response?.data?.errors;
  }

  toString(): string | undefined {
    return `${this.constructor.name}(${JSON.stringify(
      this.error?.response?.data
    )})`;
  }
}

export class PortitFailureDetail {
  errorCode = "";
  message = "";
  path = "";
  url = "";
}

/**
 * 503: Service is temporary unavailable
 */
export class ServiceUnavailableFailure extends ServerFailure {
  constructor(error: ApiClientError) {
    super(error);
  }
}

/**
 * 403: Forbidden
 */
export class ForbiddenFailure extends ServerFailure {
  constructor(error: ApiClientError) {
    super(error);
  }
}

/**
 * 401: Not authorized
 */
export class AuthorizationFailure extends ServerFailure {
  constructor(error: ApiClientError) {
    super(error);
  }
}
