import { Button, Checkbox, Grid, makeStyles } from "@material-ui/core";
import QuidFormControl from "components/atoms/QuidFormControl";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface DebitModalProps {}

const useStyles = makeStyles(() => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  mt56: {
    marginTop: 56,
  },
}));

const DebitModal: React.FC<DebitModalProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation("payments");

  type FormValues = {
    amount: string;
    reference: string;
    creditRevenueAccount: boolean;
  };
  const defaultValues: FormValues = {
    amount: "",
    reference: "",
    creditRevenueAccount: false,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onFormSubmit = () => {};

  return (
    <div className={classes.modalBody}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12} className={classes.mt56}>
              <QuidTitle>{t("debit__modal__title")}</QuidTitle>
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="amount"
                rules={{
                  required: t("debit__modal__amount__required") as string,
                }}
                textFieldProps={{
                  fullWidth: true,
                }}
                label={t("debit__modal__amount")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidTextField
                name="reference"
                rules={{
                  required: t("debit__modal__reference__required") as string,
                }}
                textFieldProps={{
                  fullWidth: true,
                }}
                label={t("debit__modal__reference")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <QuidFormControl
                formControl={<Checkbox color="primary" />}
                name="creditRevenueAccount"
                defaultValues={defaultValues}
                label={t("debit__modal__credit__revenue__account")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                {t("debit__modal__confirm")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default DebitModal;
