import { ServerFailure } from "features/core/Failure";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "../reducers/prompts.reducer";
import {
  fetchTasksKYCSearched,
  fetchAllTasks,
  fetchHistoricalSearchedTasks,
  fetchAllTasksCompanyFormations,
  fetchTasksCompanyFormationsSearched,
  fetchHistoricalTasksCompanyFormationsSearch,
  fetchTasksAccountsSearched,
  fetchHistoricalSearchedTasksAccounts,
  fetchAllTasksAccounts,
} from "api/tasks";

import {
  ResponseTasks,
  FetchTasksParams,
  FetchTasksSearchParams,
  TaskCategories,
} from "entities/tasks/TaskEntity";

export const fetchTasksGenerator = (
  actionKey: string,
  fetch: Promise<ResponseTasks>
) =>
  createAsyncThunk(actionKey, async (_, { dispatch }) => {
    try {
      const res = await fetch;

      if (res.tasks) {
        res.tasks = res.tasks.map((t) => ({
          ...t,
          createTime: t.createTime.toString(),
          endTime: t.endTime?.toString(),
        }));
      }

      return { type: actionKey, ...res };
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
      return { type: actionKey };
    }
  })();

export const fetchTasks = ({
  category,
  params,
}: {
  category: string;
  params?: FetchTasksParams | FetchTasksSearchParams;
}) => {
  switch (category) {
    case TaskCategories.TASK_SEARCH:
      return fetchTasksGenerator(
        "tasks/fetchTasksSearch",
        fetchTasksKYCSearched(params)
      );
    case TaskCategories.TASK:
      return fetchTasksGenerator(
        "tasks/fetchTasks",
        fetchAllTasks(params as FetchTasksParams)
      );
    case TaskCategories.HISTORIC_TASK_SEARCH:
      return fetchTasksGenerator(
        "tasks/fetchHistoricalTasksSearch",
        fetchHistoricalSearchedTasks(params as FetchTasksParams)
      );
    case TaskCategories.COMPANY_FORMATION_TASK:
      return fetchTasksGenerator(
        "tasks/fetchCompFormTasks",
        fetchAllTasksCompanyFormations(params as FetchTasksParams)
      );

    case TaskCategories.COMPANY_FORMATION_TASK_SEARCH:
      return fetchTasksGenerator(
        "tasks/fetchCompFormTasksSearch",
        fetchTasksCompanyFormationsSearched(params as FetchTasksParams)
      );

    case TaskCategories.HISTORIC_COMPANY_FORMATION_TASK_SEARCH:
      return fetchTasksGenerator(
        "tasks/fetchHistoricalCompFormTasksSearch",
        fetchHistoricalTasksCompanyFormationsSearch(params as FetchTasksParams)
      );

    case TaskCategories.ACCOUNT_TASK_SEARCH:
      return fetchTasksGenerator(
        "tasks/fetchAccountTasksSearch",
        fetchTasksAccountsSearched(params)
      );

    case TaskCategories.HISTORIC_ACCOUNT_TASK_SEARCH:
      return fetchTasksGenerator(
        "tasks/fetchHistoricalAccountTasksSearch",
        fetchHistoricalSearchedTasksAccounts(params)
      );

    case TaskCategories.ACCOUNT_TASK:
      return fetchTasksGenerator(
        "tasks/fetchAccountTasks",
        fetchAllTasksAccounts(params as FetchTasksParams)
      );
  }
};
