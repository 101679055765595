import { RouteComponentProps } from "react-router";
import MainTemplate from "templates/MainTemplate";
import { useEffect, useState } from "react";
import { handleFailure } from "resHandlers";
import InvestementPreference from "components/organisms/common/InvestementPreference";
import { InvestmentPreferences } from "entities/clients/Investements";
import { getInvestementPreferencesByPartyId } from "api/investments";

const InvestmentsPreferencesPage: React.FC<
  RouteComponentProps<{ id: string }>
> = ({ match }) => {
  const id: number = +match.params.id;
  const [details, setDetails] = useState<InvestmentPreferences>();

  useEffect(() => {
    if (id) {
      void getInvestmentPreferencesById(id);
    }
  }, [id]);

  const getInvestmentPreferencesById = async (id: number): Promise<void> => {
    try {
      const investmentPreferencesDetails = await getInvestementPreferencesByPartyId(
        id
      );
      setDetails(investmentPreferencesDetails);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <MainTemplate>
      <InvestementPreference investmentPreferences={details} />
    </MainTemplate>
  );
};

export default InvestmentsPreferencesPage;
