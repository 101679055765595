import {
  InvestmentsDatasetResponse,
  InvestmentsDatasetResult,
} from "entities/investments/InvestmentsDatasets";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function fetchOpportunitiesStatus(): Promise<InvestmentsDatasetResult> {
  try {
    const res = await apiClient.get<InvestmentsDatasetResponse>(
      `/investments/v1/referenceData/opportunities/statuses`,
      "account"
    );
    const result = res?.referenceDataList.map((value: string) => {
      return {
        code: value,
        text: value,
      };
    });
    return { values: result };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchInvestmentsStatus(): Promise<InvestmentsDatasetResult> {
  try {
    const res = await apiClient.get<InvestmentsDatasetResponse>(
      `/investments/v1/referenceData/investments/statuses`,
      "account"
    );
    const result = res?.referenceDataList.map((value: string) => {
      return {
        code: value,
        text: value,
      };
    });
    return { values: result };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
