import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  statusBadge: {
    width: "14px",
    height: "14px",
    borderRadius: "30px",
    "&-disabled": {
      backgroundColor: "#FB4C4C",
    },
    "&-enabled_no_trading": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-pending_customer": {
      backgroundColor: "#FFCF23",
    },
    "&-pending_provider": {
      backgroundColor: "#FFCF23",
    },
    "&-enabled": {
      backgroundColor: "#A1F714",
    },
    "&-rejected": {
      backgroundColor: "#FB4C4C",
    },
  },
  statusBadgeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
  },
}));

export type AccountBadgeStatuses =
  | "pending"
  | "enabled"
  | "disabled"
  | "enabled_no_trading"
  | "pending_customer";
export interface AccountStatusBadgeProps {
  status?: AccountBadgeStatuses;
}

const AccountStatusBadge: React.FC<AccountStatusBadgeProps> = ({
  status,
}: AccountStatusBadgeProps) => {
  const classes = useStyles();
  const badgeCn = clsx(classes.statusBadge, `${classes.statusBadge}-${status}`);
  return (
    <div className={classes.statusBadgeContainer}>
      <div className={badgeCn}></div>
    </div>
  );
};

export default AccountStatusBadge;
