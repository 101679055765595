import { NoParams, UseCase } from "../../../core/UseCase";
import { AuthenticationRepository } from "../repositories/AuthenticationRepository";

export enum AuthState {
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
}

export class GetAuthenticationState extends UseCase<NoParams, AuthState> {
  private authenticationRepository: AuthenticationRepository;

  constructor(authenticationRepository: AuthenticationRepository) {
    super();
    this.authenticationRepository = authenticationRepository;
  }

  // eslint-disable-next-line @typescript-eslint/require-await,@typescript-eslint/no-unused-vars
  async run(params: NoParams): Promise<AuthState> {
    if (this.authenticationRepository.loggedIn) {
      return AuthState.LOGGED_IN;
    }

    return AuthState.LOGGED_OUT;
  }
}
