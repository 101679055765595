import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import VerificationTemplate from "templates/VerificationTemplate";
import jwt_decode from "jwt-decode";
import ReactJson from "react-json-view";
import { acceptTask, rejectTask } from "api/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";
import ApproveRejectBox from "components/atoms/ApproveRejectBox";

const useStyles = makeStyles((theme) => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 0px",
    boxSizing: "border-box",
  },
  errorTabsContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "40px",
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },
}));

export interface IdentityDetails {
  [key: string]: string;
}

export interface CompanyDetails {
  id: number;
  companyName: string;
  companyNumber: string;
  legalEntityType: string;
  legalEntityValue: string;
  authorized: string;
  incorporationDate: string;
}

export interface BusinessResult {
  fourStopCorporateId: number;
  status: string;
  complete: string;
  results?: string;
}

const ErrorHandlerVerification: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  setIsLoading: (isLoading: boolean) => void;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();

  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );

  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const errorDetails1 = {
    code: task?.variables?.error?.code || "n/a",
    id: task?.variables?.error?.id || "n/a",
    errors: task?.variables?.error?.errors || "n/a",
    errorActivitiId: task?.variables?.errorActivitiId || "n/a",
    message: task?.variables?.error?.message || "n/a",
  };

  const errorDetails2 = {
    financialId: task?.variables?.financialId || "n/a",
    addressId: task?.variables?.addressId || "n/a",
    workflowUuid: task?.variables?.workflowUuid || "n/a",
    productId: task?.variables?.productId || "n/a",
    accountId: task?.variables?.accountId || "n/a",
    customerId: task?.variables?.customer?.id || "n/a",
  };

  const correctRequirementCheck = {
    ALL_IS_OK: false,
  };

  if (!task) {
    return null;
  }

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.errorTabsContainer}>
        <div className={classes.detailsWrapperThird}>
          <DetailsBlock
            label={t("title__details_error")}
            toPrint={errorDetails1}
          />
        </div>
        <div className={classes.detailsWrapperThird}>
          <DetailsBlock
            label={t("title__details_ids")}
            toPrint={errorDetails2}
          />
        </div>
        <div className={classes.detailsWrapperThird}>
          <Typography variant="h6" className={classes.sectionTitle}>
            {t("title__details_json")}
          </Typography>
          <ReactJson
            style={{ fontSize: 12 }}
            src={task.variables ?? task}
            collapsed={true}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            quotesOnKeys={false}
          />
        </div>
      </div>
      {!isHistory && isAssignedToUser && (
        <ApproveRejectBox
          task={task}
          checks={correctRequirementCheck}
          accept={(request) => handleApproveTask(request)}
          reject={(request) => handleRejectTask(request)}
        />
      )}
    </VerificationTemplate>
  );
};

export default ErrorHandlerVerification;
