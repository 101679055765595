import {
  InvestmentsParams,
  InvestmentsResponse,
} from "../entities/Investments";
import { Customers } from "../entities/Parties";
import { apiClient } from "../utils/api/apiClient";

export async function getInvestmentsBo(
  params: InvestmentsParams
): Promise<InvestmentsResponse> {
  try {
    const res = await apiClient.get<InvestmentsResponse>(
      `/investments/v1/investments/bo`,
      "investments",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
        min: params.min,
        max: params.max,
        fromDate: params.fromDate,
        toDate: params.toDate,
        status: params.status,
        partyId: params.partyId,
      }
    );

    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );

    return {
      ...res,
      investmentsList: res.investmentsList.map((investment) => {
        return {
          ...investment,
          customer: resCustomers.customerList.find(
            (customer) => customer.id === investment.partyId
          ),
        };
      }),
    };
  } catch (err: any) {
    throw err;
  }
}
