import { downloadAllUserCsv } from "api/users";
import { format } from "date-fns";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { DownloadAllUsersCsvParams } from "entities/clients/UserEntity";
import fileDownload from "js-file-download";

export async function downloadUsersCsv(
  params: DownloadAllUsersCsvParams
): Promise<FilePayload> {
  try {
    const res = await downloadAllUserCsv();
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}
