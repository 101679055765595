import { makeStyles, Typography } from "@material-ui/core";
import { fetchAttachment, fetchHistorycalAttachment } from "api/tasks";
import { format } from "date-fns";
import { Attachment } from "entities/clients/Attachment";
import { Comment } from "entities/tasks/TaskCommentEntity";
import { Task } from "entities/tasks/TaskEntity";
import AttachmentWithoutPreview from "./AttachmentWithoutPreview";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    zIndex: 999,
    boxShadow: "-12px 22px 61px -11px rgba(0,0,0,0.75);",
    padding: 14,
    backgroundColor: "#fff",

    "& .MuiOutlinedInput-root": {
      borderRadius: 40,
    },
  },
  uploadingFiles: {
    fontSize: 14,
    color: "#B0B0B0",
  },
  pointer: { cursor: "pointer" },
  removePointerEvts: { pointerEvents: "none" },
  attachmentsContainer: {
    width: 365,
    maxHeight: 400,
    minHeight: 100,
    overflowY: "scroll",
    padding: 14,
  },
  attachmentsCloseButtonWithoutattachments: {
    width: "auto !important",
    position: "absolute",
    top: 14,
    right: 14,
  },
  attachmentsCloseButtonWithattachments: {
    width: "auto !important",
    minWidth: "auto",
    marginBottom: 12,
    marginLeft: "auto",
    padding: 0,
    marginRight: 0,
    display: "block",
    "& .MuiButton-label": {
      minWidth: "auto",
    },
  },
  commentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 16,

    "& >p:first-child": {
      wordBreak: "break-all",
      fontSize: 16,
    },
  },
  commentBody: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    padding: 20,
  },
  time: {
    fontSize: 10,
    color: "#929292",
  },
  commentText: {
    fontSize: 14,
    color: "#fff",
    overflowWrap: "break-word",
  },
  inputWrapper: {
    backgroundColor: "#fff",
  },
  inputBtn: { height: 40, width: "40px !important", minWidth: 40 },
  rowContainer: { marginBottom: 24 },
}));

interface CommentRowProps {
  comment: Comment;
  idx: number;
  attachments: Attachment[];
  taskKey: Task["taskDefinitionKey"];
  taskId: string;
  isHistory: Boolean;
}

const CommentRow: React.FC<CommentRowProps> = ({
  comment,
  idx,
  attachments,
  taskId,
  taskKey,
  isHistory,
}) => {
  const classes = useStyles();

  const { fullMessage, username, time } = comment;

  return (
    <div className={classes.rowContainer}>
      <div className={classes.commentHeader}>
        <Typography>{username}</Typography>
        <Typography className={classes.time}>
          {format(new Date(time), "dd/MM/yyyy HH:mm:ss")}
        </Typography>
      </div>
      <div
        className={classes.commentBody}
        style={{ backgroundColor: idx % 2 ? "#B3B3B3" : "#32135A" }}
      >
        <Typography className={classes.commentText}>{fullMessage}</Typography>
      </div>
      {attachments?.map((a) => (
        <AttachmentWithoutPreview
          attachment={a}
          downloadDoc={(params) => {
            return isHistory === undefined || isHistory === false
              ? fetchAttachment({
                  ...params,
                  taskId,
                  taskKey,
                  commentId: a.comment,
                  attachmentId: a.id,
                })
              : fetchHistorycalAttachment({
                  ...params,
                  taskId,
                  taskKey,
                  commentId: a.comment,
                  attachmentId: a.id,
                });
          }}
        />
      ))}
    </div>
  );
};

export default CommentRow;
