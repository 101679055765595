import { makeStyles, Typography } from "@material-ui/core";
import QuidUploadField from "components/atoms/QuidUploadField";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { DetailsRow } from "./DetailsBlock";
import Attachment from "components/molecules/verifications/Attachment";
import { updateCompanyPersonDocument } from "api/tasks";
import {
  getCompanyPersonDocument,
  getHistoricalCompanyPersonDocument,
} from "services/tasks";
import { Document } from "entities/clients/DocumentEntity";
import { Task } from "entities/tasks/TaskEntity";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiTextField-root": {
      display: "block",
    },
  },
  imgPreview: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 28,
  },
}));

interface GetOrUpdatePersonDocumentProps {
  doc?: Document;
  refetch: () => Promise<void>;
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  companyId: string;
  personId: string;
  personType: string;
  showDetail: boolean;
  label: string;
  canReplace: boolean;
  isHistory?: string;
}

const GetOrUpdatePersonDocument: React.FC<GetOrUpdatePersonDocumentProps> = ({
  doc,
  refetch,
  taskId,
  taskKey,
  companyId,
  personId,
  personType,
  showDetail,
  label,
  canReplace,
  isHistory,
}) => {
  const { t } = useTranslation(["documentTypes", "validations", "countries"]);
  const classes = useStyles();

  const onDocChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length === 1) {
      if (doc) {
        try {
          await updateCompanyPersonDocument({
            taskId,
            taskKey,
            companyId,
            personId,
            personType,
            documentId: (doc.id as unknown) as string,
            request: { document: e.target.files[0] },
          });
          handleSuccessfulMessage("Document uploaded");
          refetch();
        } catch (err: any) {
          return handleFailure(err);
        }
      }
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="h6" className="section-title">
        {t(label)}
      </Typography>
      {doc && (
        <Attachment
          downloadDoc={(params) =>
            isHistory === undefined
              ? getCompanyPersonDocument({
                  ...params,
                  taskId,
                  taskKey,
                  companyId,
                  personId,
                  personType,
                })
              : getHistoricalCompanyPersonDocument({
                  ...params,
                  taskId,
                  taskKey,
                  companyId,
                  personId,
                  personType,
                })
          }
          attachment={doc}
          imgClassName={classes.imgPreview}
        />
      )}

      {showDetail ? (
        <>
          <DetailsRow
            label="document type"
            value={t(`documentTypes:${doc?.type || "n/a"}`)}
            index={0}
          />
          <DetailsRow
            label="document subtype"
            value={t(`documentTypes:${doc?.subType || "n/a"}`)}
            index={0}
          />
          <br />{" "}
        </>
      ) : (
        ""
      )}
      {canReplace ? (
        <div>
          <Typography variant="h6" className="section-title">
            Replace document
          </Typography>
          <QuidUploadField
            inputId={`financial-doc-${doc?.id}`}
            onChange={onDocChange}
            label="Upload document"
          />
        </div>
      ) : (
        <div>Can Not Be Replaced</div>
      )}
    </div>
  );
};

export default GetOrUpdatePersonDocument;
