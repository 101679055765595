import React, { useEffect, useState } from "react";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { CustomerHistory } from "entities/clients/HistoryEntity";
import { formatDate } from "utils";
import { useDispatch } from "react-redux";
import { PartiesPagination } from "entities/accounts/Pagination";
import { getCustomerHistoryByCustomerId } from "api/customers";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { TABLE_PAGE_SIZE } from "shared/constants";

interface HistoryCustomerProps {
  customerId: string;
}

const HistoryCustomerList: React.FC<HistoryCustomerProps> = ({
  customerId,
}) => {
  const [loading, setLoading] = useState(false);
  const [histories, setHistories] = useState([] as CustomerHistory[]);
  const dispatch = useDispatch();
  const { t } = useTranslation("history");
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as PartiesPagination);

  useEffect(() => {
    const getCustomerHistories = async (): Promise<void> => {
      if (customerId) {
        setLoading(true);
        try {
          const res = await getCustomerHistoryByCustomerId({
            customerId,
            pageSize: TABLE_PAGE_SIZE,
            pageNo,
          });
          setPagination(res.pagination);
          setHistories(res.history);
        } catch (e: unknown) {
          const message =
            e instanceof ServerFailure
              ? (e as ServerFailure)?.error?.message
              : (e as NetworkFailure)?.message;

          dispatch(
            promptsSlice.actions.openSnackbar({
              message,
              type: "error",
            })
          );
        } finally {
          setLoading(false);
        }
      }
    };

    void getCustomerHistories();
    return (): void => {};
  }, [customerId, pageNo]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const historyCustomerHeaders: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "activity",
      headerName: t("history__header_name__activity"),
      headerAlign: "center",
      align: "center",
      flex: 0.7,
    },
    {
      field: "status",
      headerName: t("history__header_name__status"),
      headerAlign: "center",
      align: "center",
      flex: 0.7,
    },
    {
      field: "comment",
      headerName: t("history__header_name__comment"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "executed",
      headerName: t("history__header_name__executed"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (params) => {
        const value = params?.row as CustomerHistory;
        return value.executed ? formatDate(params.row.executed) : "";
      },
    },
  ];

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      rows={histories || []}
      columns={historyCustomerHeaders}
      disableSelectionOnClick
      rowCount={pagination?.totalEntries}
      loading={loading}
    />
  );
};

export default HistoryCustomerList;
