import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams, SearchIcon } from "@material-ui/data-grid";
import DetailIcon from "components/atoms/icons/DetailIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import React, { ChangeEvent, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getAccountsCurrencies,
  getAccountStatus,
} from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import { handleFailure } from "resHandlers";
import AccountStatusBadge from "components/organisms/accounts/AccountStatusBadge";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { AccountResponse } from "entities/accounts/Account";
import DetailButton from "components/atoms/DetailButton";
import { downloadAccountsCsv, fetchUserAccountBo } from "services/accounts";
import {
  AccountDataset,
  AccountDatasetCurrency,
} from "entities/accounts/AccountDataset";
import { Pagination } from "entities/accounts/Pagination";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import CsvIcon from "components/atoms/icons/CsvIcon";
import Loader from "components/atoms/icons/Loader";
import { DashboardTaskTypes } from "entities/tasks/TaskEntity";
import { getAllProviders } from "api/accounts";
import { sanitizeProvidersString } from "utils/sanitizeProvidersString";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    minHeight: "54px",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const AccountsRevenue: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("account");
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([] as AccountResponse[]);
  const [pageNo, setPageNo] = useState(0);
  const [allProviders, setAllProviders] = useState<string[]>([]);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    type: "revenue",
    feeCurrency: "",
    customerType: "",
    provider: "",
  });
  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloadAccountsCsv({
        fileName: "accounts",
        ...(filter?.search && { keyword: filter?.search }),
        ...(filter?.type && { type: filter?.type }),
        ...(filter?.feeCurrency && { feeCurrency: filter?.feeCurrency }),
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.customerType && { entity: filter?.customerType }),
        ...(filter?.provider && { provider: filter?.provider }),
      }),
  });

  const accountStatus = useSelector(getAccountStatus);
  const currencies = useSelector(getAccountsCurrencies);
  const customerTypeKeys: DashboardTaskTypes[] = [
    { code: "individual", text: t("filter__individual") },
    { code: "company", text: t("filter__company") },
  ];

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "account_name",
      headerName: t("listTable__header_name__accountName"),
      flex: 1,
    },
    {
      field: "account_holder",
      headerName: t("listTable__header_name__accountHolder"),
      flex: 1,
    },
    {
      field: "account_type",
      headerName: t("listTable__header_name__accountType"),
      flex: 1,
    },
    {
      field: "legal_entity",
      flex: 1,
      headerName: t("listTable__header_name__customer_type"),
    },
    {
      field: "status",
      flex: 1.2,
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => (
        <>
          <AccountStatusBadge status={params?.row?.status} />
          {params?.row?.status}
        </>
      ),
    },
    {
      field: "details",
      width: 100,
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        const accountId = params?.row?.account_id as number;
        if (!accountId) {
          return <DetailIcon />;
        }
        return <DetailButton to={`/accounts/${accountId}`} />;
      },
    },
  ];

  const fetchAllProviders = useCallback(async ({ ignore }): Promise<void> => {
    try {
      if (!ignore) {
        const res = await getAllProviders();
        setAllProviders(res);
      }
    } catch (e: any) {
      handleFailure(e);
    }
  }, []);

  useEffect(() => {
    let ignore = false;

    fetchAllProviders({ ignore });
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    const fetchRevenueAccounts = async () => {
      setLoading(true);
      try {
        const accountRes = await fetchUserAccountBo({
          ...(filter?.search && { keyword: filter?.search }),
          ...(filter?.type && { type: filter?.type }),
          ...(filter?.feeCurrency && { feeCurrency: filter?.feeCurrency }),
          ...(filter?.status && { status: filter?.status }),
          ...(filter?.customerType && { entity: filter?.customerType }),
          ...(filter?.provider && { provider: filter?.provider }),
          size: TABLE_PAGE_SIZE,
          page: pageNo,
        });

        const { pagination, accounts } = accountRes;
        setAccounts(accounts);
        setPagination(pagination);
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setLoading(false);
      }
    };

    void fetchRevenueAccounts();
  }, [pageNo, filter]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  return (
    <MainTemplate>
      <>
        {documentLoading && (
          <div className={classes.downloadFile}>
            <Loader />
          </div>
        )}
      </>
      <div className={classes.accountsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("revenue__accounts__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <TextField
              label={t("filter__search")}
              variant="outlined"
              fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, search: e.target.value }))
              }
              value={filter.search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={classes.searchBox}>
                      <SearchIcon />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={t("filter__currency")}
              variant="outlined"
              select
              fullWidth
              value={filter.feeCurrency}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({
                  ...state,
                  feeCurrency: e.target.value,
                }))
              }
            >
              <MenuItem value="">{t("filter__currency")}</MenuItem>
              {currencies?.map(
                (currency: AccountDatasetCurrency, index: number) => (
                  <MenuItem
                    key={`${currency.code}-${index}`}
                    value={currency.code}
                  >
                    {currency?.text}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={t("filter__status")}
              variant="outlined"
              select
              fullWidth
              value={filter.status}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({
                  ...state,
                  status: e.target.value,
                }))
              }
            >
              <MenuItem value="">{t("filter__status")}</MenuItem>
              {accountStatus?.map((option: AccountDataset, index: number) => (
                <MenuItem key={`${option}-${index}`} value={option.code}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={t("filter__providers")}
              variant="outlined"
              select
              fullWidth
              value={filter.provider}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({
                  ...state,
                  provider: e.target.value,
                }))
              }
            >
              <MenuItem value="">{t("filter__provider")}</MenuItem>
              {allProviders?.map((provider: string, index: number) => (
                <MenuItem
                  key={`${provider}-${index}`}
                  value={provider?.toLowerCase()}
                >
                  {sanitizeProvidersString(provider)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={t("filter__customer_types")}
              variant="outlined"
              select
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({
                  ...state,
                  customerType: e.target.value,
                }))
              }
              fullWidth
              value={filter.customerType}
            >
              <MenuItem value="">
                <em>{t("filter__allTypes")}</em>
              </MenuItem>
              {customerTypeKeys?.map(
                (value: DashboardTaskTypes, index: number) => (
                  <MenuItem key={`${value}-${index}`} value={value.code}>
                    {`${t(value.text)}`}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="outlined"
              className={classes.csvBtn}
              onClick={() => onCsvDownload()}
            >
              <CsvIcon />
            </Button>
          </Grid>
        </Grid>
        <QuidDataGrid
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={accounts}
          rowCount={pagination?.total_entries}
        />
      </div>
    </MainTemplate>
  );
};

export default AccountsRevenue;
