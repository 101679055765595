import { Persistence } from "features/core/Persistence";

export interface Logger {
  error: (args: any) => void;
}

export class LocalStoragePersistence implements Persistence {
  private readonly prefix: string = "_quid:";

  private logger: Logger;

  constructor(logger?: Logger) {
    this.logger = logger ?? console;
  }

  clear(): Persistence {
    const items = { ...localStorage };
    Object.keys(items).map((key) => {
      if (key.indexOf(this.prefix) === 0) {
        localStorage.removeItem(key);
      }
    });
    return this;
  }

  remove(key: string): Persistence {
    localStorage.removeItem(`${this.prefix}${key}`);
    return this;
  }

  get<T = any>(key: string): T | undefined {
    try {
      const value = localStorage.getItem(`${this.prefix}${key}`);
      if (value) {
        return JSON.parse(value) as T;
      } else {
        this.logger.error(`cache miss for ${key}`);
      }
    } catch (e) {
      this.logger.error(e);
    }

    return undefined;
  }

  set<T = any>(key: string, value: T): Persistence {
    localStorage.setItem(`${this.prefix}${key}`, JSON.stringify(value));
    return this;
  }
}
