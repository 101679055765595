import React, { useState, ChangeEvent } from "react";
import { Grid, makeStyles, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { updateDataFile } from "api/opportunities";
import QuidUploadField from "components/atoms/QuidUploadField";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";

const useStyles = makeStyles(() => ({
  gridContainerDataFile: {
    maxWidth: "90%",
    width: "full",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "end",
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
}));

export interface OpportunitiesDetailsProps {
  opportunityId?: number;
  fetch: Function;
  datafileTypes: Array<string>;
}

const OpportunitiesDataFiles: React.FC<OpportunitiesDetailsProps> = ({
  opportunityId,
  fetch,
  datafileTypes,
}: OpportunitiesDetailsProps) => {
  const { t } = useTranslation("opportunities");
  const classes = useStyles();
  const [dataFileType, setDataFileType] = useState("Presentation");

  const defaultValues = {
    dataFileType: dataFileType || "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const onDataFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateDataFile({
          opportunityId,
          dataFileType,
          request: { datafile: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__datafile_uploaded"));
        fetch(opportunityId);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Grid item className={classes.gridContainerDataFile}>
          <QuidTextField
            name="dataFileType"
            textFieldProps={{
              select: true,
              fullWidth: true,
            }}
            rules={{
              required: t("data_file_type__required") as string,
            }}
            label={t("data_file_type")}
            defaultValues={defaultValues}
          >
            {datafileTypes?.map((option, index) => (
              <MenuItem
                key={`${option}-${index}`}
                value={option}
                onClick={() => setDataFileType(option)}
              >
                {option}
              </MenuItem>
            ))}
          </QuidTextField>
          <QuidUploadField
            inputId="uploadDataFile"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onDataFileChange(e)}
            label={t("details__label__upload_data_file")}
          />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default OpportunitiesDataFiles;
