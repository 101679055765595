import React, { ReactElement, useState } from "react";
import { CellParams, ColDef } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles } from "@material-ui/core";

import QuidTitle from "components/atoms/QuidTitle";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { AccountInfo } from "entities/accounts/Account";
import NationFormatter from "shared/formatters/NationFormatter";
import AccountDetailModal from "./AccountDetailModal";
import DetailButton from "components/atoms/DetailButton";

interface AccountListProps {
  loading: boolean;
  accounts: AccountInfo[];
  total: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    addressSpacing: {
      marginTop: 30,
      marginBottom: 30,
    },
    accountContainer: {
      width: "100%",
      marginTop: 30,
      minHeight: 500,
    },
    container: {
      paddingBottom: "60px",
    },
  })
);

const AccountList: React.FC<AccountListProps> = ({
  accounts,
  total,
  loading,
}: AccountListProps) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const [detailModalId, setDetailModalId] = useState<number>();

  const onCloseDetailModal = () => {
    setDetailModalId(undefined);
  };

  const onDetailOpen = (addressId: number) => {
    setDetailModalId(addressId);
  };

  const columns: ColDef[] = [
    {
      flex: 1,
      field: "id",
      headerName: "ID",
    },
    {
      flex: 1,
      field: "accountPurpose",
      headerName: t("accounts__header_name__account_purpose"),
    },
    {
      flex: 1,
      field: "paymentOrigin",
      headerName: t("accounts__header_name_payment_origin"),
      renderCell: (param: CellParams): ReactElement => {
        const countryId = (param?.row as AccountInfo).paymentOrigin;
        return <NationFormatter countryId={countryId} />;
      },
    },
    {
      flex: 1,
      field: "paymentDestination",
      headerName: t("accounts__header_name__payment_destination"),
      renderCell: (param: CellParams): ReactElement => {
        const countryId = (param?.row as AccountInfo).paymentDestination;
        return <NationFormatter countryId={countryId} />;
      },
    },
    {
      flex: 1,
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => (
        <DetailButton onClick={() => onDetailOpen(params.row.id as number)} />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <AccountDetailModal
        onCloseModal={onCloseDetailModal}
        accountId={detailModalId}
        accounts={accounts}
      />
      <QuidTitle variant="h1">{t("accounts__header_name__tab")}</QuidTitle>
      <div className={classes.accountContainer}>
        <QuidDataGrid
          rowCount={total}
          rows={accounts}
          columns={columns}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AccountList;
