import {
  CreateAddressParams,
  PartyAddress,
} from "entities/clients/AddressEntity";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { PeopleDocument } from "entities/clients/DocumentEntity";
import {
  PersonResponse,
  AddressResponse,
  PeopleDocumentResponse,
  UpdatePeopleParams,
  UploadDocumentParams,
  UploadDocumentResponse,
  AcceptPeopleDocumentParams,
  RejectPeopleDocumentParams,
  DirectorResponse,
  UboResponse,
} from "entities/clients/People";
import { Director, Person, Ubo } from "entities/clients/PersonEntity";
import { handleApiClientError } from "utils";
import { flattenAddresses } from "utils/addresses";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function createPeopleAddress(
  params: CreateAddressParams
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/parties/v1/people/${params.id}/addresses`,
      "company",
      { address: params.address }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getPeopleDetails(personId: string): Promise<Person> {
  try {
    const res = await apiClient.get<PersonResponse>(
      `/parties/v1/people/${personId}`,
      "company"
    );
    const { person }: { person: Person } = res;
    return person;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getDirectorDetails(
  companyId: string,
  personId: string
): Promise<Director> {
  try {
    const res = await apiClient.get<DirectorResponse>(
      `/parties/v1/companies/${companyId}/directors/${personId}`,
      "company"
    );
    const { director }: { director: Director } = res;
    return director;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getUboDetails(
  companyId: string,
  personId: string
): Promise<Ubo> {
  try {
    const res = await apiClient.get<UboResponse>(
      `/parties/v1/companies/${companyId}/ubos/${personId}`,
      "company"
    );
    const { ubo }: { ubo: Ubo } = res;
    return ubo;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getPeopleAddresses(
  personId: string
): Promise<AddressResponse> {
  try {
    const res = await apiClient.get<{
      addresses: PartyAddress[];
      total: number;
    }>(`/parties/v1/people/${personId}/addresses`, "company");
    return { ...res, addresses: flattenAddresses(res.addresses) };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getPeopleDocuments(
  personId: string
): Promise<PeopleDocumentResponse> {
  try {
    const res = await apiClient.get<PeopleDocumentResponse>(
      `/parties/v1/people/${personId}/documents`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadPeopleDocument(
  personId: string,
  documentId: number
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/people/${personId}/documents/${documentId}/download`,
      "company",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updatePeople(params: UpdatePeopleParams): Promise<void> {
  try {
    await apiClient.put<PersonResponse>(
      `/parties/v1/people/${params.id}`,
      "company",
      { person: { ...params } }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateDirectorNotes(
  companyId: string,
  personId: string,
  note: string
) {
  try {
    await apiClient.put<PersonResponse>(
      `/parties/v1/companies/${companyId}/directors/${personId}/note`,
      "company",
      { note }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateUboNotes(
  companyId: string,
  personId: string,
  note: string
) {
  try {
    await apiClient.put<PersonResponse>(
      `/parties/v1/companies/${companyId}/ubos/${personId}/note`,
      "company",
      { note }
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function uploadPeopleDocument(
  params: UploadDocumentParams
): Promise<PeopleDocument> {
  try {
    const res = await apiClient.post<UploadDocumentResponse>(
      `/parties/v1/people/${
        params.id
      }/documents?type=${params.type.toString()}&sub_type=${params.subType.toString()}`,
      "company",
      params.body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const { document }: { document: PeopleDocument } = res;
    return document;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function acceptPeopleDocument(
  params: AcceptPeopleDocumentParams
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/people/${params?.personId}/documents/${params?.documentId}/accept`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function rejectPeopleDocument(
  params: RejectPeopleDocumentParams
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/people/${params?.personId}/documents/${params?.documentId}/reject`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
