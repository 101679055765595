export const sanitizeProvidersString = (provider: string) => {
  if (provider) {
    const providerClone = provider;
    const providerWhiteSpace = providerClone?.replaceAll("_", " ");

    return (
      providerWhiteSpace.charAt(0).toUpperCase() +
      providerWhiteSpace.slice(1)?.toLowerCase()
    );
  }
};
