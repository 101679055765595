/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ModalAction = {
  label: string;
  handler: () => void;
};

export type SnackbarType = "warning" | "error" | "success";

export type ModalState = {
  message?: string;
  actions?: ModalAction[];
};

export type SnackbarState = {
  open: boolean;
  message?: string;
  type: SnackbarType;
  url?: string;
  data?: string;
};

export type promptsSliceState = {
  modal: ModalState;
  snackbar: SnackbarState;
};

export const defaultState: promptsSliceState = {
  modal: {},
  snackbar: {
    message: "",
    type: "error",
    open: false,
  },
};

const promptsSlice = createSlice({
  name: "prompts",
  initialState: defaultState,
  reducers: {
    openModal(state, { payload }: PayloadAction<ModalState>) {
      return {
        ...state,
        modal: {
          ...state.modal,
          message: payload.message,
          actions: payload.actions,
        },
      };
    },
    closeModal(state) {
      return {
        ...state,
        modal: {},
      };
    },
    openSnackbar(
      state,
      {
        payload,
      }: PayloadAction<{
        type: SnackbarType;
        message: string;
        url?: string;
        data?: string;
      }>
    ) {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          type: payload.type,
          message: payload.message,
          open: true,
          url: payload.url,
          data: payload.data,
        },
      };
    },
    closeSnackbar(state) {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    },
  },
});

export default promptsSlice;

export const getModalOpen = (state: promptsSliceState): boolean =>
  !!state.modal.message;
export const getModalState = (state: promptsSliceState): ModalState =>
  state.modal;

export const getSnackbarState = (state: promptsSliceState): SnackbarState =>
  state.snackbar;
