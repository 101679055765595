import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import { Link, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { arrayBufferToBase64 } from "utils";
import { handleFailure } from "resHandlers";
import {
  fetchOpportunityImageByImageId,
  fetchOpportunityLogoByLogoId,
} from "api/opportunities";

const useStyle = makeStyles(() => ({
  image: {
    maxWidth: "100%",
  },
}));

type OfferImageProps = {
  imageId: number;
  type: string;
  className?: any;
};

const OpportunityImage: React.FC<OfferImageProps> = (
  { imageId, className },
  type
) => {
  const { t } = useTranslation("validations");
  const [image, setImage] = useState<any>();
  const classes = useStyle();

  useEffect(() => {
    const fetchOfferImage = async () => {
      try {
        if (type === "logo") {
          const offerRes = await fetchOpportunityLogoByLogoId({
            imageId,
          });
          setImage(offerRes);
        } else {
          const offerRes = await fetchOpportunityImageByImageId({
            imageId,
          });
          setImage(offerRes);
        }
      } catch (err: any) {
        handleFailure(err);
      }
    };

    if (imageId) {
      fetchOfferImage();
    }
  }, [imageId]);

  if (!image) {
    return (
      <div>
        <Link>
          <ErrorIcon />
          {t("document__document_not_found")}
        </Link>
      </div>
    );
  }

  return (
    <>
      <img
        src={`data:image/png;base64,${arrayBufferToBase64(image.data.buffer)}`}
        className={`${classes.image} ${className ?? ""}`}
      />
    </>
  );
};

export default OpportunityImage;
