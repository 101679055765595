import { Params, UseCase } from "../../../core/UseCase";
import { AuthenticationRepository } from "../repositories/AuthenticationRepository";
import { handleApiClientError } from "utils";

export interface AuthenticateUserParams extends Params {
  username: string;
  password: string;
}

export class AuthenticateUser extends UseCase<AuthenticateUserParams, void> {
  private readonly repository: AuthenticationRepository;

  constructor(repo: AuthenticationRepository) {
    super();
    this.repository = repo;
  }

  async run(params: AuthenticateUserParams): Promise<void> {
    try {
      await this.repository.signIn(params.username, params.password);
      return;
    } catch (err: any) {
      const e = handleApiClientError(err);
      throw e;
    }
  }
}
