import React, { useCallback, useEffect, useState } from "react";
import { PageChangeParams } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import { FetchTasksSearchParams, Task } from "entities/tasks/TaskEntity";
import { makeStyles } from "@material-ui/core";
import {
  fetchHistoricalSearchedTasks,
  fetchHistoricalSearchedTasksAccounts,
  fetchHistoricalTasksCompanyFormationsSearch,
  fetchWorkflowGBGIndividualEkycHistoryTasksSearch,
  fetchWorkflowGBGIndividualIdentityHistoryTasksSearch,
} from "api/tasks";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#37383C",
    marginTop: 30,
    "& .MuiDataGrid": {
      "&-colCellWrapper": {
        height: "40px",
      },
      "&-row": {
        backgroundColor: "#FFFFFF",
        borderBottom: "2px solid #FFFFFF",
        borderTop: "1px solid #FFFFFF",
      },
      "&-columnsContainer": {
        backgroundColor: theme.palette.secondary.main,
        color: "#FFFFFF",
      },
      "&-iconSeparator": {
        display: "none",
      },
    },
  },
}));

interface taskProps {
  customerId: FetchTasksSearchParams;
}

const VerificationPastTask: React.FC<taskProps> = ({ customerId }) => {
  const classes = useStyles();
  const [pageNo, setPageNo] = useState(0);
  const [pastTask, setPastTask] = useState<Task[]>([]);
  const [pastTotal, setPastTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      void fetchPastTasks(customerId as number);
    }
  }, [customerId]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const [columnsVerificationHistorical] = useVerificationColumns({
    pageNo,
    isHistory: "true",
  });

  const fetchPastTasks = useCallback(
    async (id: number | string): Promise<void> => {
      setLoading(true);
      try {
        const resKYC = await fetchHistoricalSearchedTasks({
          customerId: id as number,
        });
        const resGBGHistoryIdentity = await fetchWorkflowGBGIndividualIdentityHistoryTasksSearch(
          {
            customerId: id as number,
          }
        );
        const resGBGHistoryEkyc = await fetchWorkflowGBGIndividualEkycHistoryTasksSearch(
          {
            customerId: id as number,
          }
        );

        switch (process.env.REACT_APP_PROJECT) {
          case "quid":
            const resAccountQuid = await fetchHistoricalSearchedTasksAccounts({
              customerId: id as number,
            });
            const resCompany = await fetchHistoricalTasksCompanyFormationsSearch(
              {
                customerId: id as number,
              }
            );
            setPastTask(
              resAccountQuid.tasks.concat(resCompany.tasks).concat(resKYC.tasks)
            );
            setPastTotal(
              resAccountQuid.total + resCompany.total + resKYC.total
            );
            break;

          case "toonie":
            const resAccountToonie = await fetchHistoricalSearchedTasksAccounts(
              {
                customerId: id as number,
              }
            );

            setPastTask([
              ...resAccountToonie.tasks,
              ...resKYC.tasks,
              ...resGBGHistoryIdentity.tasks,
              ...resGBGHistoryEkyc.tasks,
            ]);

            setPastTotal(
              resAccountToonie.total +
                resKYC.total +
                resGBGHistoryIdentity.total +
                resGBGHistoryEkyc.total
            );
            break;

          case "elviria":
            const resAccountElviria = await fetchHistoricalSearchedTasksAccounts(
              {
                customerId: id as number,
              }
            );

            setPastTask(resAccountElviria.tasks.concat(resKYC.tasks));
            setPastTotal(resAccountElviria.total + resKYC.total);
            break;

          case "strfiinvest":
            const resAccountStrfi = await fetchHistoricalSearchedTasksAccounts({
              customerId: id as number,
            });

            setPastTask(resAccountStrfi.tasks.concat(resKYC.tasks));
            setPastTotal(resAccountStrfi.total + resKYC.total);
            break;

          case "cosmo":
            setPastTask(resKYC.tasks);
            setPastTotal(+resKYC.total);
            break;
        }
      } catch (err: any) {
        // Split per project
      } finally {
        setLoading(false);
      }
    },
    [setPastTask]
  );

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      className={classes.root}
      rows={pastTask}
      columns={columnsVerificationHistorical}
      disableSelectionOnClick
      hideHeader
      rowCount={pastTotal}
      loading={loading}
    />
  );
};

export default VerificationPastTask;
