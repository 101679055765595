import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  versionP: { fontSize: 14, color: "#555" },
}));

const AppVersion: React.FC = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.versionP}>
      Version: {process.env.REACT_APP_VERSION}
    </Typography>
  );
};

export default AppVersion;
