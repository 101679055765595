import {
  acceptCustomerDocument,
  createCustomerAddress,
  getCustomerAddresses,
  rejectCustomerDocument,
} from "api/customers";
import { Address } from "entities/clients/AddressEntity";
import {
  AddressStatus,
  CustomerDetails,
} from "entities/clients/CustomerBackofficeEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { handleFailure } from "resHandlers";
import {
  downloadCustomerDocument,
  fetchCustomerDetails,
  uploadCustomerDocument,
} from "services/customers";
import useDocHandlers from "shared/hooks/useDocHandlers";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";
import {
  createCompanyAddress,
  fetchCompanyAddresses,
  acceptCompanyDocument,
  rejectCompanyDocument,
  uploadCompanyDocument,
} from "api/companies";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import {
  downloadCompanyDocumentById,
  fetchCompanyDetails,
} from "services/companies";
import AddressList from "components/organisms/common/AddressList";

const Adresses: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { t } = useTranslation("customer");
  const dispatch = useDispatch();
  const history = useHistory();
  const id: number = +match.params.id;
  const from = history.location?.state as Object;
  const defaultAddressStatusParams: AddressStatus = {
    status: "CURRENT",
    use: "",
    verified: "PENDING",
  };

  const detailsInitialStateCustomer: CustomerDetails = ({
    customer: null,
    profiles: null,
    documents: null,
    addresses: null,
    financialData: null,
    history: null,
    companies: null,
    investmentInfo: null,
    investmentPreference: null,
    products: null,
  } as unknown) as CustomerDetails;
  const [detailsCustomer, setDetailsCustomer] = useState(
    detailsInitialStateCustomer
  );

  const detailsInitialStateCompany: CompanyDetails = ({
    company: null,
    addresses: null,
  } as unknown) as CompanyDetails;
  const [detailsCompany, setDetailsCompany] = useState(
    detailsInitialStateCompany
  );

  useEffect(() => {
    if (id && Object.values(from)[0] === "customer") {
      void getCustomerDetails(id);
    }
    if (id && Object.values(from)[0] === "company") {
      void getCompanyDetails(id);
    }
  }, [id]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetailsCustomer(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetailsCustomer]
  );

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetailsCompany(companyDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    },
    [setDetailsCustomer]
  );

  const onCreateAddressCustomer = async (
    address: Address,
    use: string,
    partyId?: number
  ) => {
    try {
      await createCustomerAddress({
        id,
        address: {
          address,
          partyId: partyId || 0,
          status: defaultAddressStatusParams.status,
          ...(use && { use }),
        },
      });
      refetchAddressCustomer();
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const onCreateAddressCompany = async (
    address: Address,
    use: string,
    partyId?: number
  ) => {
    try {
      await createCompanyAddress({
        id,
        address: {
          address,
          status: defaultAddressStatusParams.status,
          partyId: partyId || 0,
          ...(use && { use }),
        },
      });
      refetchAddressCompany();
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const refetchAddressCustomer = async () => {
    try {
      const addresses = await getCustomerAddresses(id);
      setDetailsCustomer((customerDetails: CustomerDetails) => ({
        ...customerDetails,
        addresses,
      }));
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const refetchAddressCompany = async () => {
    try {
      const addresses = await fetchCompanyAddresses(id);
      setDetailsCompany((companyDetails: CompanyDetails) => ({
        ...companyDetails,
        addresses,
      }));
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const { onDocUpload } = useDocHandlers({
    t,
    entity: {
      id,
      propName:
        Object.values(from)[0] === "customer" ? "customerId" : "companyId",
    },
    refetchEntity:
      Object.values(from)[0] === "customer"
        ? getCustomerDetails
        : getCompanyDetails,
    download:
      Object.values(from)[0] === "customer"
        ? downloadCustomerDocument
        : downloadCompanyDocumentById,
    accept:
      Object.values(from)[0] === "customer"
        ? acceptCustomerDocument
        : acceptCompanyDocument,
    reject:
      Object.values(from)[0] === "customer"
        ? rejectCustomerDocument
        : rejectCompanyDocument,
    upload:
      Object.values(from)[0] === "customer"
        ? uploadCustomerDocument
        : uploadCompanyDocument,
  });

  return (
    <MainTemplate>
      {Object.values(from)[0] === "customer" && (
        <AddressList
          addresses={detailsCustomer?.addresses}
          total={detailsCustomer?.addresses?.length}
          createAddress={onCreateAddressCustomer}
          customerProfiles={detailsCustomer?.profiles}
          refetch={refetchAddressCustomer}
          onUploadDocuments={onDocUpload}
          isCustomer={true}
          detailsCustomer={detailsCustomer}
        />
      )}
      {Object.values(from)[0] === "company" && (
        <AddressList
          addresses={detailsCompany?.addresses}
          total={detailsCompany?.addresses?.length}
          createAddress={onCreateAddressCompany}
          customerProfiles={detailsCompany?.profiles}
          refetch={refetchAddressCompany}
          onUploadDocuments={onDocUpload}
          isCustomer={false}
          detailsCompany={detailsCompany}
        />
      )}
    </MainTemplate>
  );
};

export default Adresses;
