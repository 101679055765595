import { makeStyles } from "@material-ui/core";
import { PageChangeParams } from "@material-ui/data-grid";
import { fetchTasksAccountsSearched } from "api/tasks";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { Task } from "entities/tasks/TaskEntity";
import { useEffect, useState } from "react";
import { handleFailure } from "resHandlers";
import useVerificationColumns from "shared/hooks/useVerificationColumns";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
}));

interface Props {
  customerId: number;
}

const AccountTasksList: React.FC<Props> = ({ customerId }) => {
  const classes = useStyles();
  const [pageNo, setPageNo] = useState(0);
  const [accountTasks, setAccountTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (customerId) {
      void getTasks(customerId);
    }
  }, [customerId]);

  const [columnsAccount] = useVerificationColumns({
    pageNo,
  });

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const getTasks = async (id: number | string): Promise<void> => {
    setLoading(true);
    try {
      const tasks = await fetchTasksAccountsSearched({
        companyId: id as number,
      });
      setAccountTasks(tasks.tasks);
      setTotal(tasks.total);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      className={classes.root}
      rows={accountTasks}
      columns={columnsAccount}
      disableSelectionOnClick
      hideHeader
      rowCount={total}
      loading={loading}
    />
  );
};

export default AccountTasksList;
