import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ServerFailure } from "features/core/Failure";
import { Button, Grid } from "@material-ui/core";
import MainTemplate from "templates/MainTemplate";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import QuidTitle from "components/atoms/QuidTitle";
import InfoBox from "components/molecules/InfoBox";
import { formatDate } from "utils";
import { DetailsNews } from "entities/news/NewsEntity";
import { deleteNewsById, getNewsById } from "api/news";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  container: {
    minHeight: "100px",
  },
  verticalAlign: {
    alignItems: "center",
  },
  updateBtn: { marginLeft: "16px" },
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
    "& .actions-cancel": {
      backgroundColor: " #ff0000",
    },
  },
}));

const NewsDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("news");
  const history = useHistory();
  const newsId = +match.params.id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [news, setNews] = useState<DetailsNews>();
  const [isLoading, setIsLoading] = useState(false);

  const newDetailsData = [
    {
      title: t("new_details_info_id"),
      value: news?.id || "n/a",
    },
    {
      title: t("new_details_info_date"),
      value: formatDate(news?.date) || "n/a",
    },
    {
      title: t("new_details_info_title"),
      value: news?.title || "n/a",
    },
    {
      title: t("new_details_info_subtitle"),
      value: news?.subTitle || "n/a",
    },
    {
      title: t("new_details_info_body"),
      value: news?.body || "n/a",
    },
    {
      title: t("new_details_url"),
      value: news?.url || "n/a",
    },
    {
      title: t("new_details_opportunity_id"),
      value: news?.opportunityId || "n/a",
    },
  ];

  const fetchNewDetails = useCallback(
    async (id: number): Promise<void> => {
      setIsLoading(true);
      try {
        const res = await getNewsById({
          newsId: id as number,
        });
        setNews(res);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (newsId) {
      void fetchNewDetails(newsId as number);
    }
  }, [fetchNewDetails]);

  const onDeleteNews = async () => {
    try {
      await deleteNewsById({ newsId: newsId as number });
      history.push("/news");
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const doRedirectToUpdate = () => {
    history.push({ pathname: `/news/${newsId}/view`, state: news });
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("page__title__newDetails")}</QuidTitle>
        <Grid container className={(classes.container, classes.verticalAlign)}>
          <Grid item xs={8}>
            <InfoBox
              title={t("new_details_title_infobox__title")}
              items={newDetailsData}
            />
          </Grid>
        </Grid>
        <div className={classes.actionButtons}>
          <Button
            className="actions-cancel"
            variant="contained"
            color="primary"
            disabled={isLoading || !news}
            onClick={() => onDeleteNews()}
          >
            {t("label_button__delete")}
          </Button>
          <Button
            className={classes.updateBtn}
            variant="contained"
            color="primary"
            disabled={isLoading || !news}
            onClick={() => doRedirectToUpdate()}
          >
            {t("label_button__update")}
          </Button>
        </div>
      </div>
    </MainTemplate>
  );
};

export default NewsDetails;
