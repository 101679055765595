import React from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";

interface BulkDetailModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  data?: any;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  reactJsonContainer: {
    display: "flex",
    rowGap: 16,
    marginTop: 32,
    flexDirection: "column",
  },
}));

const BulkDetailModal: React.FC<BulkDetailModalProps> = ({
  setOpen,
  open,
  data,
}) => {
  const { t } = useTranslation("payments");

  const classes = useStyles();
  const transfer = data;

  const generalInfo: any = {
    status: transfer?.status || "n/a",
  };

  const walletFrom: any = {
    wallet_id: transfer?.source_balance?.wallet_id || "n/a",
    available_amount:
      MoneyFormatter(
        transfer?.source_balance?.available_amount,
        transfer?.source_balance?.currency
      ) || "n/a",
    current_amount:
      MoneyFormatter(
        transfer?.source_balance?.current_amount,
        transfer?.source_balance?.currency
      ) || "n/a",
    account_id: transfer?.source_balance?.account?.id || "n/a",
    account_name: transfer?.source_balance?.account?.name || "n/a",
  };

  const walletTo: any = {
    wallet_id: transfer?.destination_balance?.wallet_id || "n/a",
    available_amount:
      MoneyFormatter(
        transfer?.destination_balance?.available_amount,
        transfer?.destination_balance?.currency
      ) || "n/a",
    current_amount:
      MoneyFormatter(
        transfer?.destination_balance?.current_amount,
        transfer?.destination_balance?.currency
      ) || "n/a",
    account_id: transfer?.destination_balance?.account?.id || "n/a",
    account_name: transfer?.destination_balance?.account?.name || "n/a",
  };

  return (
    <FullScreenModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("transfer__details__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            <DetailsBlock
              label={t("bulk__wallet__generalInfo")}
              toPrint={generalInfo}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            <DetailsBlock
              label={t("bulk__wallet__source")}
              toPrint={walletFrom}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailsBlock
              label={t("bulk__wallet__destination")}
              toPrint={walletTo}
            />
          </Grid>
        </Grid>
      </div>
    </FullScreenModal>
  );
};

export default BulkDetailModal;
