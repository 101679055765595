import { makeStyles, Grid, Button } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { fetchBulkTransfers } from "api/accounts";
import { Pagination } from "entities/accounts/Pagination";
import { formatDate } from "utils";
import { useHistory } from "react-router";
import DetailButton from "components/atoms/DetailButton";

const useStyles = makeStyles(() => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  container: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexCsv: {
    flex: 0.1,
    marginLeft: "auto",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      color: "#000",
    },
  },
}));

const PaymentsInternalBulk: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("payments");
  const [loading, setLoading] = useState(false);
  const [bulkTransfers, setBulkTransfers] = useState<any[]>([]);
  const [pagination, setPagination] = useState<Partial<Pagination>>({});
  const [pageNo, setPageNo] = useState(0);

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 75 },
    {
      flex: 1,
      field: "created_at",
      headerName: t("bulkTable__header_name__createdAt"),
      type: "date",
      renderCell: (params) => {
        return (
          <div>
            {formatDate(params.row?.created_at, "dd/MM/yyyy")}{" "}
            <small>{formatDate(params.row?.created_at, "HH:mm:ss")}</small>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: t("bulkTable__header_name__status"),
      renderCell: (params) => {
        return params.row?.status || "n/a";
      },
    },
    {
      flex: 1,
      field: "note",
      headerName: t("bulkTable__header_name__note"),
      type: "date",
      renderCell: (params) => {
        return params.row?.note || "n/a";
      },
    },
    {
      field: "",
      headerName: t("bulkTable__header_name__details"),
      renderCell: (params) => {
        if (!params?.row?.id) {
          return <></>;
        }
        return <DetailButton to={`/payments/bulk/${params?.row?.id}`} />;
      },
    },
  ];

  useEffect(() => {
    const fetchInternalPaymentsBulk = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchBulkTransfers({
          page: pageNo,
          size: TABLE_PAGE_SIZE,
        });
        setPagination(res?.pagination);
        setBulkTransfers(res?.transfers_bulks);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void fetchInternalPaymentsBulk();
  }, [pageNo]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  return (
    <MainTemplate>
      <div className={classes.container}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("bulk__payments__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.flexCsv}>
          <Button
            variant="outlined"
            className={classes.csvBtn}
            onClick={() => history.push("/payments/bulk/new")}
          >
            {t("internal__transfer__new__bulk__payments")}
          </Button>
        </div>
        <QuidDataGrid
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={bulkTransfers}
          rowCount={pagination?.total_entries}
        />
      </div>
    </MainTemplate>
  );
};

export default PaymentsInternalBulk;
