import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import BeneficiaryBankInfo from "components/organisms/bankTransfer/BeneficiaryBankInfo";
import { AccountDatasetCurrency } from "entities/accounts/AccountDataset";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAccountsCurrencies } from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";

const useStyles = makeStyles(() => ({
  container: { padding: 140, backgroundColor: "#FAFAFA" },
  selectsContainer: {
    display: "flex",
    justifyContent: "space-around",
    "& .MuiSelect-root": { width: 250, backgroundColor: "#fff" },
  },
  debitUser: {
    backgroundColor: "#fff",
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
  },
  beneficiaryAccountFC: { marginBottom: 16 },
  debitAccountFC: { marginTop: 16 },
  detailsContainer: {
    width: 320,
    margin: "90px auto 0 auto",
  },
  submit: {
    display: "flex",
    margin: "50px auto 0 auto !important",
    padding: "10px 15px",
  },
}));

const defaultValues = {
  currency: "",
  amount: "",
  reference: "",
  description: "",
};

const NewBankTransfer: React.FC = () => {
  const { t } = useTranslation("bankTransfer");
  const classes = useStyles();
  const currencies = useSelector(getAccountsCurrencies);
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onFormSubmit = () => {};

  return (
    <MainTemplate>
      <QuidTitle>{t("bank__transfer__page__title")}</QuidTitle>

      <div className={classes.container}>
        <div className={classes.selectsContainer}>
          <div>
            <QuidTitle fontSize={18}>
              {t("bank__transfer__head__from__label")}
            </QuidTitle>

            <div className={classes.debitUser}>
              <Box>
                <Typography>Name Limited</Typography>
                <Typography>34 67 89 45728192</Typography>
              </Box>

              <Typography>NZ</Typography>
            </div>

            <FormControl className={classes.debitAccountFC} variant="outlined">
              <InputLabel>
                {t("bank__transfer__account__placeholder")}
              </InputLabel>

              <Select variant="outlined" label="Select account">
                <MenuItem value={"test"} selected>
                  test
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <QuidTitle fontSize={18}>
              {t("bank__transfer__head__to__label")}
            </QuidTitle>

            <FormControl
              className={classes.beneficiaryAccountFC}
              variant="outlined"
            >
              <InputLabel>
                {t("bank__transfer__beneficiary__placeholder")}
              </InputLabel>

              <Select variant="outlined" label="Select beneficiary">
                <MenuItem value={"test"} selected>
                  test
                </MenuItem>
              </Select>
            </FormControl>
            <Link to="#">
              <Typography color="primary" variant="subtitle2">
                {t("bank__transfer__beneficiary__add")}
              </Typography>
            </Link>
            <BeneficiaryBankInfo />
          </div>
        </div>
        <div className={classes.detailsContainer}>
          <QuidTitle fontSize={26}>
            {t("bank__transfer__payment__details__title")}
          </QuidTitle>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <QuidTextField
                rules={{}}
                name="currency"
                defaultValues={defaultValues}
                label={t("bank__transfer__currency__placeholder")}
                textFieldProps={{ select: true, fullWidth: true }}
              >
                <MenuItem value="">
                  {t("bank__transfer__currency__placeholder")}
                </MenuItem>
                {currencies?.map(
                  (currency: AccountDatasetCurrency, index: number) => (
                    <MenuItem
                      key={`${currency.code}-${index}`}
                      value={currency.code}
                    >
                      {currency?.text}
                    </MenuItem>
                  )
                )}
              </QuidTextField>

              <QuidTextField
                textFieldProps={{ fullWidth: true }}
                name="amount"
                rules={{
                  required: t(
                    "bank__transfer__form__amount__required"
                  ) as string,
                }}
                label={t("bank__transfer__form__amount__label")}
                defaultValues={defaultValues}
              />

              <QuidTextField
                textFieldProps={{ fullWidth: true }}
                rules={{}}
                name="reference"
                label={t("bank__transfer__form__reference__label")}
                defaultValues={defaultValues}
              />

              <QuidTextField
                textFieldProps={{ fullWidth: true }}
                name="description"
                rules={{}}
                label={t("bank__transfer__form__description__label")}
                defaultValues={defaultValues}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}
              >
                {t("bank__transfer__create__button")}
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </MainTemplate>
  );
};

export default NewBankTransfer;
