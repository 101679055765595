import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { Pagination } from "entities/accounts/Pagination";
import { fecthOfferStatuses, fetchOffersList } from "api/offers";
import { Offer } from "entities/offer/OfferEntity";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { AccountDatasetCurrency } from "entities/accounts/AccountDataset";
import { getAccountsCurrencies } from "store/reducers/app.reducer";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloaOffersCsv } from "services/offers";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles((theme) => ({
  watchIcon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#37383C",
  },
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  offerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const Offers: React.FC = () => {
  const classes = useStyles();
  const TABLE_PAGE_SIZE = 10;
  const { t } = useTranslation("offer");
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([] as Offer[]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filter, setFilter] = useState({
    status: "",
    keyword: "",
    offerType: "",
    currency: "",
  });

  const [statuses, setStatuses] = useState([] as string[]);
  const currencies = useSelector(getAccountsCurrencies);

  useEffect(() => {
    const getOfferStatuses = async () => {
      try {
        const res = await fecthOfferStatuses();
        setStatuses(res?.referenceDataList);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };

    void getOfferStatuses();
  }, []);

  useEffect(() => {
    const getOffers = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchOffersList({
          ...(filter?.keyword && { keyword: filter?.keyword }),
          ...(filter?.status && { status: filter?.status }),
          ...(filter?.offerType && { offerType: filter?.offerType }),
          ...(filter?.currency && { currency: filter?.currency }),
          page,
          size: TABLE_PAGE_SIZE,
        });
        setPagination(res?.pagination);
        setOffers(
          res?.offers.map((offer) => ({
            id: offer.offerId,
            ...offer,
          }))
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getOffers();
  }, [page, filter.offerType, filter.status, filter.keyword, filter.currency]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
      width: 350,
      renderCell: (params) => <>{params?.row?.offerId}</>,
    },
    {
      field: "title",
      headerName: t("listTable__header_name__title"),
      width: 350,
    },
    {
      field: "currency",
      headerName: t("listTable__header_name__currency"),
      width: 300,
    },
    {
      field: "merchant",
      headerName: t("listTable__header_name__merchant"),
      width: 250,
      renderCell: (params) => <>{params?.row?.merchant?.name}</>,
    },
    {
      field: "stock",
      headerName: t("listTable__header_name__stock"),
      width: 200,
      renderCell: (params) => (
        <div>
          {params.row.stock === null || params.row.stock === 0
            ? "unlimited"
            : params?.row?.stock - params?.row?.redeemed}
        </div>
      ),
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      width: 100,
      renderCell: (params) => (
        <DetailButton to={`/offers/${params?.row?.offerId}`} />
      ),
    },
  ];

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () =>
      downloaOffersCsv({
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.offerType && { offerType: filter?.offerType }),
        ...(filter?.keyword && { merchantName: filter?.keyword }),
        ...(filter?.currency && { merchantName: filter?.currency }),
      }),
  });

  return (
    <>
      {documentLoading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.offerContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("offer__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__search")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFilter((state) => ({
                      ...state,
                      keyword: e.target.value,
                    }));
                  }}
                  value={filter.keyword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__status")}
                  variant="outlined"
                  select
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({
                      ...state,
                      status: e.target.value,
                    }))
                  }
                  fullWidth
                  value={filter.status}
                >
                  <MenuItem value="">
                    <em>{t("filter__status")}</em>
                  </MenuItem>
                  {statuses?.map((status: string, index: number) => (
                    <MenuItem key={`${status}-${index}`} value={status}>
                      {`${t(status)}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__currency")}
                  variant="outlined"
                  select
                  fullWidth
                  value={filter.currency}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({
                      ...state,
                      currency: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">{t("filter__currency")}</MenuItem>
                  {currencies?.map(
                    (currency: AccountDatasetCurrency, index: number) => (
                      <MenuItem
                        key={`${currency.code}-${index}`}
                        value={currency.code}
                      >
                        {currency?.text}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  className={classes.csvBtn}
                  onClick={() => onCsvDownload()}
                >
                  <CsvIcon />
                </Button>
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={offers}
            rowCount={pagination?.total_entries}
            pageSize={10}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default Offers;
