import { makeStyles, Tab, Fab } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import MainTemplate from "templates/MainTemplate";
import TabPanel from "components/atoms/TabPanel";
import Tabs from "components/atoms/Tabs";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import UboInfo from "../components/organisms/ubos/UboInfo";
import AddressList from "components/organisms/common/AddressList";
import { useTranslation } from "react-i18next";
import useDocHandlers from "shared/hooks/useDocHandlers";
import { handleFailure } from "resHandlers";
import QuidTitle from "components/atoms/QuidTitle";
import { ArrowBack } from "@material-ui/icons";
import {
  acceptPeopleDocument,
  rejectPeopleDocument,
  getPeopleAddresses,
  createPeopleAddress,
  updatePeople,
  uploadPeopleDocument,
  updateUboNotes,
} from "api/people";

import {
  downloadPeopleDocumentById,
  getUboDetailsByPersonId,
} from "services/people";

import {
  PeopleDetails,
  UboDetails,
  UpdatePeopleParams,
} from "entities/clients/People";
import { Ubo } from "entities/clients/PersonEntity";
import { Address } from "entities/clients/AddressEntity";
import DocumentList from "components/organisms/common/DocumentList";
import { AddressStatus } from "entities/clients/CustomerBackofficeEntity";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex1: {
    flex: 1,
  },
  tabsWrapper: {
    backgroundColor: "#FAFAFA",
  },
}));

const UboDetail: React.FC<
  RouteComponentProps<{ companyId: string; id: string }>
> = ({ match }) => {
  const classes = useStyles();
  const { t } = useTranslation("ubo");
  const [value, setValue] = useState(0);
  const [, setLoading] = useState(false);
  const [details, setDetails]: [
    details: UboDetails,
    setDetails: Function
  ] = useState({});
  const dispatch = useDispatch();
  const personId: string = match?.params?.id;
  const companyId: string = match?.params?.companyId;
  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setValue(newValue);
  };
  const defaultAddressStatusParams: AddressStatus = {
    status: "CURRENT",
    use: "",
    verified: "PENDING",
  };

  const getUboDetails = useCallback(
    async (personId: string | number): Promise<void> => {
      setLoading(true);
      try {
        const details = await getUboDetailsByPersonId(
          companyId as string,
          personId as string
        );
        setDetails(details);
      } catch (error: any) {
        return handleFailure(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const {
    documentLoading,
    onDocAccept,
    onDocDownload,
    onDocReject,
    onDocUpload,
  } = useDocHandlers({
    t,
    entity: { id: personId, propName: "personId" },
    refetchEntity: getUboDetails,
    download: downloadPeopleDocumentById,
    accept: acceptPeopleDocument,
    reject: rejectPeopleDocument,
    upload: uploadPeopleDocument,
  });

  const updateUbo = async (params: UpdatePeopleParams, note: string) => {
    setLoading(true);
    try {
      await updatePeople(params);
      await updateUboNotes(companyId, personId, note);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("snackbar__update__details__success"),
          type: "success",
        })
      );
      getUboDetails(personId);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const refetchAddress = async () => {
    setLoading(true);

    try {
      const addRes = await getPeopleAddresses(personId);

      const { addresses, total: totalAddresses } = addRes;
      setDetails((details: PeopleDetails) => ({
        ...details,
        addresses,
        totalAddresses,
      }));
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getUboDetails(personId);
    return (): void => {};
  }, []);

  const onCreateAddress = (address: Address, use: string, partyId?: number) => {
    return createPeopleAddress({
      id: parseInt(personId, 10),
      address: {
        partyId: partyId || 0,
        address,
        status: defaultAddressStatusParams.status,
        ...(use && { use }),
      },
    });
  };

  const goBack = () => {
    history.back();
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <div className={classes.flex1}>
          <Fab color="primary" aria-label="back" onClick={goBack}>
            <ArrowBack />
          </Fab>
        </div>
        <div className={classes.flexCenter}>
          <QuidTitle>{t("details__title")}</QuidTitle>
        </div>
        <div className={classes.tabsWrapper}>
          <Tabs handleChange={handleChange} value={value} maxTabWidth="50%">
            <Tab label={t("tab__label__general__info")} />
            <Tab label={t("tab__label__documents")} />
            <Tab label={t("tab__label__addresses")} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UboInfo ubo={details?.ubo as Ubo} updateUbo={updateUbo} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DocumentList
              totalDocuments={details?.totalDocuments}
              documents={details?.documents}
              accept={onDocAccept}
              reject={onDocReject}
              download={onDocDownload}
              loading={documentLoading}
              onUploadDocuments={onDocUpload}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AddressList
              onUploadDocuments={onDocUpload}
              createAddress={onCreateAddress}
              addresses={details?.addresses || []}
              refetch={refetchAddress}
              total={details?.totalAddresses}
            />
          </TabPanel>
        </div>
      </div>
    </MainTemplate>
  );
};

export default UboDetail;
