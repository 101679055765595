import { Customers } from "@portit/core/entities/Parties";
import { apiClient } from "@portit/core/utils/api/apiClient";
import { PartiesPagination } from "entities/accounts/Pagination";
import {
  CartList,
  CartListParams,
  LandBuyersList,
  LandPaymentsList,
  LandPrepurchaseList,
  Payment,
  PaymentsListParams,
  Product,
} from "entities/clients/CommerceEntity";
import { handleApiClientError } from "utils";

export async function fetchCartList(params: CartListParams): Promise<CartList> {
  try {
    const res = await apiClient.get<CartList>(
      "/metaland/v1/commerce/management/carts",
      "company",
      {
        page: params.pageNo,
        size: params.pageSize,
        id: "id",
        orderType: "desc",
        ...(params.keyword && { id: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      }
    );

    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );

    return {
      ...res,
      carts: res.carts.map((cart) => {
        return {
          ...cart,
          customer: resCustomers.customerList.find(
            (customer) => customer.id.toString() === cart.partyId
          ),
        };
      }),
    };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchProductsList(
  params: CartListParams
): Promise<{ products: Product[]; pagination: PartiesPagination }> {
  try {
    const res = await apiClient.get<{
      products: Product[];
      pagination: PartiesPagination;
    }>("/metaland/v1/commerce/management/products", "company", {
      page: params.pageNo,
      size: params.pageSize,
      id: "id",
      orderType: "desc",
      ...(params.keyword && { id: params.keyword }),
      ...(params.from && { from: params.from }),
      ...(params.to && { to: params.to }),
    });
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchPaymentsList(
  params: PaymentsListParams
): Promise<{ payments: Payment[]; pagination: PartiesPagination }> {
  try {
    const res = await apiClient.get<{
      payments: Payment[];
      pagination: PartiesPagination;
    }>("/metaland/v1/commerce/management/payments", "company", {
      page: params.pageNo,
      size: params.pageSize,
      id: "id",
      orderType: "desc",
      ...(params.keyword && { id: params.keyword }),
      ...(params.from && { from: params.from }),
      ...(params.to && { to: params.to }),
    });

    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );

    return {
      ...res,
      payments: res.payments.map((payment) => {
        return {
          ...payment,
          customer: resCustomers.customerList.find(
            (customer) => customer.id === payment.partyId
          ),
        };
      }),
    };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchLandPaymentsList(
  params: any
): Promise<LandPaymentsList> {
  try {
    const res = await apiClient.get<LandPaymentsList>(
      "/metaland/v1/metaland/management/payments/initiated",
      "company",
      {
        page: params.pageNo,
        size: params.pageSize,
        id: "id",
        orderType: "desc",
        ...(params.keyword && { id: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchLandBuyersList(
  params: any
): Promise<LandBuyersList> {
  try {
    const res = await apiClient.get<LandBuyersList>(
      "/metaland/v1/metaland/management/buyers",
      "company",
      {
        page: params.pageNo,
        size: params.pageSize,
        id: "id",
        orderType: "desc",
        ...(params.keyword && { id: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      }
    );
    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );

    return {
      ...res,
      content: res.content.map((content) => {
        return {
          ...content,
          customer: resCustomers.customerList.find(
            (customer) => customer.id === content.partyId
          ),
        };
      }),
    };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchLandPrePurchaseList(
  params: any
): Promise<LandPrepurchaseList> {
  try {
    const res = await apiClient.get<LandPrepurchaseList>(
      "/metaland/v1/metaland/management/prepurchase",
      "company",
      {
        page: params.pageNo,
        size: params.pageSize,
        id: "id",
        orderType: "desc",
        ...(params.keyword && { id: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      }
    );
    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );

    return {
      ...res,
      content: res.content.map((prepurchase) => {
        return {
          ...prepurchase,
          customer: resCustomers.customerList.find(
            (customer) => customer.id === prepurchase.partyId
          ),
        };
      }),
    };
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
