import { Grid, makeStyles, Typography } from "@material-ui/core";
import { GenericObject } from "entities/tasks/Identity";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";

interface IDetailsProps {
  labelName?: string;
  label: string;
  value: string;
  index: Number;
}

const useStyles = makeStyles((theme) => ({
  details: {
    width: "100%",
    display: "flex",
    marginBottom: 5,
    overflowWrap: "break-word",

    "& .label": {
      color: "#8E8E8E",
      paddingRight: "20px",
    },
    "& .label, & .value": {
      fontSize: 16,
    },
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },
}));

const isJson = (str: string) => {
  try {
    str = typeof str !== "string" ? JSON.stringify(str) : str;
    str = JSON.parse(str);
  } catch (e) {
    return false;
  }

  if (typeof str === "object" && str !== null) {
    return true;
  }

  return false;
};

export const DetailsRow = ({
  label,
  labelName,
  value,
  index,
}: IDetailsProps) => {
  const classes = useStyles();
  return (
    <div className={classes.details} key={index.toString()}>
      <Grid item xs={6}>
        <Typography className="label">{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className="value">
          {value &&
          isJson(value) &&
          labelName !== "created_at" &&
          labelName !== "transferred_at" &&
          labelName !== "updated_at" &&
          labelName !== "seattles_at" &&
          labelName !== "completed_at" ? (
            <ReactJson
              style={{ fontSize: 12 }}
              src={JSON.parse(value)}
              collapsed={true}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              quotesOnKeys={false}
            />
          ) : (
            value
          )}
        </Typography>
      </Grid>
    </div>
  );
};

interface DetailsBlockProps {
  toPrint?: GenericObject;
  label: string;
  children?: ReactNode;
}

const DetailsBlock: React.FC<DetailsBlockProps> = ({
  toPrint,
  label,
  children,
}) => {
  const { t } = useTranslation("validations");
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6" className={classes.sectionTitle}>
        {t(label)}
      </Typography>
      {toPrint &&
        Object.keys(toPrint).map((keyName: string, index: Number) => (
          <DetailsRow
            label={t(keyName)}
            labelName={keyName}
            value={toPrint[keyName]}
            index={index}
            key={index.toString()}
          />
        ))}
      {children}
    </div>
  );
};

export default DetailsBlock;
