import GetAppIcon from "@material-ui/icons/GetApp";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { Button, Link, makeStyles } from "@material-ui/core";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { Document as PdfDocument, Page, pdfjs } from "react-pdf";
import { arrayBufferToBase64 } from "utils";
import { Attachment } from "entities/clients/Attachment";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { Document } from "entities/clients/DocumentEntity";

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

const useStyles = makeStyles((theme) => ({
  downLink: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    textDecoration: "underline",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 28,
  },
  imgPreview: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 28,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    background: theme.palette.secondary.main,
    color: "#ffffff",
    "&.MuiButton-root": {
      width: 28,
      height: 28,
      borderRadius: 30,
      minWidth: 28,
      marginLeft: 8,
      marginRight: 8,
    },
  },
}));

type AttachmentProps = {
  attachment: Attachment | Document;
  customerId?: number;
  imgClassName?: any;
  downloadDoc: (params: any) => Promise<FilePayload>;
};

const AttachmentComp = ({
  attachment,
  imgClassName,
  downloadDoc,
}: AttachmentProps) => {
  const { t } = useTranslation("validations");
  const classes = useStyles();
  const [docFetchRes, setDocFetchRes] = useState<any>();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const download = async () => {
    fileDownload(
      docFetchRes.data.buffer,
      attachment.name,
      docFetchRes.data.contentType
    );
  };

  const isDownloadedPdf =
    docFetchRes && docFetchRes.data.contentType === "application/pdf";

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const res = await downloadDoc({
          documentId: attachment.id,
          fileName: attachment.name,
        });
        setDocFetchRes(res);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        });
      }
    };

    if (attachment) {
      fetchDocument();
    }
  }, [attachment]);

  if (!docFetchRes) {
    return (
      <div>
        <Link className={classes.downLink}>
          <ErrorIcon />
          {t("document__document_not_found")}
        </Link>
      </div>
    );
  }

  return (
    <>
      <Link onClick={download} className={classes.downLink}>
        <GetAppIcon />
        {attachment.name}
      </Link>
      {isDownloadedPdf ? (
        <>
          <PdfDocument
            file={docFetchRes.data.buffer}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width={300} />
          </PdfDocument>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              onClick={() => {
                if (pageNumber - 1 >= 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            >
              {"<"}
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                if (pageNumber + 1 <= numPages) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            >
              {">"}
            </Button>
          </div>
        </>
      ) : (
        <img
          src={`data:image/png;base64,${arrayBufferToBase64(
            docFetchRes.data.buffer
          )}`}
          className={imgClassName || classes.imgPreview}
        />
      )}
    </>
  );
};

export default AttachmentComp;
