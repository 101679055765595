import { AuthenticationRepository } from "features/authentication/domain/repositories/AuthenticationRepository";
import { NetworkFailure } from "./features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import { ApiClientError } from "@portit/core/entities/Api";

/**
 * Given any object, will return the first key that has a null-ish value
 *
 * @param map
 */
export const findUndefined = (map: {
  [key: string]: any;
}): string | undefined => {
  return Object.keys(map).find((k) => !map[k]);
};

export class FakeAuthenticationRepository implements AuthenticationRepository {
  loggedIn = true;

  refresh(): Promise<any> {
    return Promise.resolve(undefined);
  }

  signIn(): Promise<any> {
    return Promise.resolve(undefined);
  }

  signOut(): Promise<any> {
    return Promise.resolve(undefined);
  }
}

export const handleApiClientError = (error: ApiClientError) => {
  if (!error.response) {
    return new NetworkFailure(error.message);
  }
  return ServerFailure.fromApiClientError(error);
};

export const formatDate = (date?: string | Date, pattern?: string): string => {
  if (!date) {
    return "n/a";
  }

  const formatDate = typeof date === "string" ? new Date(date) : (date as Date);

  if (pattern === "dd/MM/yyyy" || pattern === undefined) {
    return new Intl.DateTimeFormat("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(formatDate);
  }

  if (pattern === "HH:mm") {
    return new Intl.DateTimeFormat("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(formatDate);
  }

  if (pattern === "HH:mm:ss") {
    return new Intl.DateTimeFormat("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(formatDate);
  }

  if (pattern === "dd/MM/yyyy HH:mm:ss") {
    return new Intl.DateTimeFormat("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(formatDate);
  }

  if (pattern === "weekday dd/MM/yyyy HH:mm:ss") {
    return new Intl.DateTimeFormat("it-IT", {
      weekday: "short",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(formatDate);
  }

  return "n/a";
};

export const realm = () => {
  if (process.env.REACT_APP_KEYCLOAK_REALM === "elviria") {
    return "elviria";
  }
  if (process.env.REACT_APP_KEYCLOAK_REALM === "toonie") {
    return "toonie";
  }
  if (process.env.REACT_APP_KEYCLOAK_REALM === "quid") {
    return "quid";
  }
  if (process.env.REACT_APP_KEYCLOAK_REALM === "vaffa") {
    return "vaffa";
  }
  if (process.env.REACT_APP_KEYCLOAK_REALM === "ats") {
    return "vaffa";
  }
};

export const firstCapitalLetter = (word?: string) => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const toCents = (amount: string | number) => {
  if (typeof amount === "string") {
    if (isNaN(parseFloat(amount.replace(",", ".")))) {
      return 0;
    }
    const decimal = amount.split(".");
    if (decimal.length > 2) {
      return Math.floor(
        ((parseFloat(amount.replace(",", ".")).toFixed(
          2
        ) as unknown) as number) * 100
      );
    }
    return Math.round(
      ((parseFloat(amount.replace(",", ".")).toFixed(2) as unknown) as number) *
        100
    );
  }
  if (typeof amount !== "number") {
    return 0;
  }
  if (amount) {
    return Math.floor(((amount.toFixed(2) as unknown) as number) * 100);
  }
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
