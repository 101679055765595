import React, { ReactElement } from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { Cart, CartProduct } from "entities/clients/CommerceEntity";
import { CellParams, ColDef } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";

interface CartDetailModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  data?: Cart;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
    display: "flex",
    flexDirection: "column",
    width: "25px",
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
  },
  grid: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vw/3)",
  },
}));

const CartDetailModal: React.FC<CartDetailModalProps> = ({
  setOpen,
  open,
  data,
}) => {
  const { t } = useTranslation("commerce");
  const classes = useStyles();

  const cartDetailsToPrint = {
    id: data?.id || 0,
    partyId: data?.partyId || "n/a",
    discountPercentage: data?.discountPercentage
      ? `${data?.discountPercentage} %`
      : "0 %",
    discountAmount: data?.discountAmount || "--",
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "title",
      headerAlign: "center",
      align: "center",
      headerName: "Title",
      flex: 1,
      renderCell: (params: CellParams): ReactElement => {
        const product = params?.row as CartProduct;
        return <div className={classes.cell}>{product.product.title}</div>;
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "price",
      headerAlign: "center",
      align: "center",
      headerName: "Price",
      flex: 1,
      renderCell: (params: CellParams): ReactElement => {
        const product = params?.row as CartProduct;
        return <div className={classes.cell}>{product.product.price}</div>;
      },
    },
  ];
  return (
    <FullScreenModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalBody}>
        <Grid
          container
          direction={"row"}
          justify="center"
          alignItems={"center"}
        >
          <Grid item xs={4} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("details__cart__details")}
            </Typography>
            <DetailsBlock toPrint={cartDetailsToPrint} label={""} />
          </Grid>
          <Grid item xs={10} className={classes.grid}>
            <QuidDataGrid
              sortModel={[{ field: "id", sort: "desc" }]}
              columns={columns}
              rows={data?.products || []}
              rowCount={data?.products.length}
              pageSize={data?.products.length}
              disableSelectionOnClick
            />
          </Grid>
        </Grid>
      </div>
    </FullScreenModal>
  );
};

export default CartDetailModal;
